import { combineReducers } from "redux";
import Customizer from '../customizer/reducer'
import userReducer from '../reducers/user'
import dashboardDataReducer from '../reducers/dashboard/index'
import roleListReducer from '../reducers/role-list'
import permissionListReducer from './permission-list/permission-list'
import getUserPermissionReducer from './permission-list/get-user-permission-data'
import accountSummaryReducer from './user-detail/account-summary/index'
import personalInformationReducer from './user-detail/personal-information/index'
import accountInformationReducer from './user-detail/account-information/index'
import timeLineReducer from './pending-verification/time-line'
import detailVerificationReducer from './pending-verification/detail-verification'
import listBankReducer from './deposit-list/index'
import listPartnerReducer from './transaction/index'
import listChannelReducer from './transfer/index'
import listPartnerPrabayarReducer from './product-list/pra-bayar/list-partner'
import listCategoryPrabayarReducer from './product-list/pra-bayar/list-category'
import listProviderPrabayarReducer from './product-list/pra-bayar/list-provider'
import listPartnerPascabayarReducer from './product-list/pasca-bayar/list-partner'
import listCategoryPascabayarReducer from './product-list/pasca-bayar/list-category'
import listProviderPascabayarReducer from './product-list/pasca-bayar/list-provider'
import listCategoryProductSortReducer from './product-sort/category/index'
import listProviderProductSortReducer from './product-sort/provider/index'


const rootReducer = combineReducers({
  Customizer,
  userReducer,
  roleListReducer,
  permissionListReducer,
  getUserPermissionReducer,
  dashboardDataReducer,
  accountSummaryReducer,
  personalInformationReducer,
  accountInformationReducer,
  timeLineReducer,
  detailVerificationReducer,
  listBankReducer,
  listPartnerReducer,
  listChannelReducer,
  listPartnerPrabayarReducer,
  listCategoryPrabayarReducer,
  listProviderPrabayarReducer,
  listPartnerPascabayarReducer,
  listCategoryPascabayarReducer,
  listProviderPascabayarReducer,
  listCategoryProductSortReducer,
  listProviderProductSortReducer,
});

export default rootReducer;