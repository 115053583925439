// lOGIN
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

// REGISTER
export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAILED = "REGISTER_FAILED"

// DASHBOARD
export const DASHBOARD_TRANSACTION_REQUEST = "DASHBOARD_TRANSACTION_REQUEST"
export const DASHBOARD_TRANSACTION_SUCCESS = "DASHBOARD_TRANSACTION_SUCCESS"
export const DASHBOARD_TRANSACTION_FAILED = "DASHBOARD_TRANSACTION_FAILED"


//ROLE LIST
export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST" 
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS" 
export const ROLE_LIST_FAILED = "ROLE_LIST_FAILED" 

// PERMISSION LIST
export const PERMISSION_LIST_REQUEST = "PERMISSION_LIST_REQUEST" 
export const PERMISSION_LIST_SUCCESS = "PERMISSION_LIST_SUCCESS" 
export const PERMISSION_LIST_FAILED = "PERMISSION_LIST_FAILED"

//USER PERMISSION
export const GET_USER_PERMISSION_REQUEST = "GET_USER_PERMISSION_REQUEST"
export const GET_USER_PERMISSION_SUCCESS = "GET_USER_PERMISSION_SUCCESS"
export const GET_USER_PERMISSION_FAILED = "GET_USER_PERMISSION_FAILED"

//EDIT USER ADMIN
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST" 
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS" 
export const EDIT_USER_FAILED = "EDIT_USER_FAILED"

// USER DETAIL
export const ACCOUNT_SUMMARY_REQUEST = "ACCOUNT_SUMMARY_REQUEST" 
export const ACCOUNT_SUMMARY_SUCCESS = "ACCOUNT_SUMMARY_SUCCESS" 
export const ACCOUNT_SUMMARY_FAILED = "ACCOUNT_SUMMARY_FAILED"

export const ACCOUNT_INFORMATION_REQUEST = "ACCOUNT_INFORMATION_REQUEST" 
export const ACCOUNT_INFORMATION_SUCCESS = "ACCOUNT_INFORMATION_SUCCESS" 
export const ACCOUNT_INFORMATION_FAILED = "ACCOUNT_INFORMATION_FAILED"

export const PERSONAL_INFORMATION_REQUEST = "PERSONAL_INFORMATION_REQUEST" 
export const PERSONAL_INFORMATION_SUCCESS = "PERSONAL_INFORMATION_SUCCESS" 
export const PERSONAL_INFORMATION_FAILED = "PERSONAL_INFORMATION_FAILED"
export const PERSONAL_PICTURE = "PERSONAL_PICTURE"
export const UPLOAD_PERSONAL_PICTURE = "UPLOAD_PERSONAL_PICTURE"
export const KTP_PICTURE = "KTP_PICTURE"
export const UPLOAD_KTP_PICTURE = "UPLOAD_KTP_PICTURE"

// PENDING VERIFICATION
export const GET_TIMELINE_REQUEST = "GET_TIMELINE_REQUEST"
export const GET_TIMELINE_SUCCESS = "GET_TIMELINE_SUCCESS"
export const GET_TIMELINE_FAILED = "GET_TIMELINE_FAILED"

export const GET_DETAIL_VERIFICATION_REQUEST = "GET_DETAIL_VERIFICATION_REQUEST"
export const GET_DETAIL_VERIFICATION_SUCCESS = "GET_DETAIL_VERIFICATION_SUCCESS"
export const GET_DETAIL_VERIFICATION_FAILED = "GET_DETAIL_VERIFICATION_FAILED"

// DEPOSIT LIST
export const GET_LIST_BANK_REQUEST = "GET_LIST_BANK_REQUEST";
export const GET_LIST_BANK_SUCCESS = "GET_LIST_BANK_SUCCESS";
export const GET_LIST_BANK_FAILED = "GET_LIST_BANK_FAILED";

// TRANSACTION
export const GET_LIST_PARTNER_REQUEST = "GET_LIST_PARTNER_REQUEST";
export const GET_LIST_PARTNER_SUCCESS = "GET_LIST_PARTNER_SUCCESS";
export const GET_LIST_PARTNER_FAILED = "GET_LIST_PARTNER_FAILED";

export const GET_LIST_CHANNEL_REQUEST = "GET_LIST_CHANNEL_REQUEST";
export const GET_LIST_CHANNEL_SUCCESS = "GET_LIST_CHANNEL_SUCCESS";
export const GET_LIST_CHANNEL_FAILED = "GET_LIST_CHANNEL_FAILED";

//PRODUCT LIST PRA-BAYAR
export const GET_LIST_PARTNER_PRABAYAR_REQUEST = "GET_LIST_PARTNER_PRABAYAR_REQUEST";
export const GET_LIST_PARTNER_PRABAYAR_SUCCESS = "GET_LIST_PARTNER_PRABAYAR_SUCCESS";
export const GET_LIST_PARTNER_PRABAYAR_FAILED = "GET_LIST_PARTNER_PRABAYAR_FAILED";

export const GET_LIST_CATEGORY_PRABAYAR_REQUEST = "GET_LIST_CATEGORY_PRABAYAR_REQUEST";
export const GET_LIST_CATEGORY_PRABAYAR_SUCCESS = "GET_LIST_CATEGORY_PRABAYAR_SUCCESS";
export const GET_LIST_CATEGORY_PRABAYAR_FAILED = "GET_LIST_CATEGORY_PRABAYAR_FAILED";

export const GET_LIST_PROVIDER_PRABAYAR_REQUEST = "GET_LIST_PROVIDER_PRABAYAR_REQUEST";
export const GET_LIST_PROVIDER_PRABAYAR_SUCCESS = "GET_LIST_PROVIDER_PRABAYAR_SUCCESS";
export const GET_LIST_PROVIDER_PRABAYAR_FAILED = "GET_LIST_PROVIDER_PRABAYAR_FAILED";

//PRODUCT LIST PASCA-BAYAR
export const GET_LIST_PARTNER_PASCABAYAR_REQUEST = "GET_LIST_PARTNER_PASCABAYAR_REQUEST";
export const GET_LIST_PARTNER_PASCABAYAR_SUCCESS = "GET_LIST_PARTNER_PASCABAYAR_SUCCESS";
export const GET_LIST_PARTNER_PASCABAYAR_FAILED = "GET_LIST_PARTNER_PASCABAYAR_FAILED";

export const GET_LIST_CATEGORY_PASCABAYAR_REQUEST = "GET_LIST_CATEGORY_PASCABAYAR_REQUEST";
export const GET_LIST_CATEGORY_PASCABAYAR_SUCCESS = "GET_LIST_CATEGORY_PASCABAYAR_SUCCESS";
export const GET_LIST_CATEGORY_PASCABAYAR_FAILED = "GET_LIST_CATEGORY_PASCABAYAR_FAILED";

export const GET_LIST_PROVIDER_PASCABAYAR_REQUEST = "GET_LIST_PROVIDER_PASCABAYAR_REQUEST";
export const GET_LIST_PROVIDER_PASCABAYAR_SUCCESS = "GET_LIST_PROVIDER_PASCABAYAR_SUCCESS";
export const GET_LIST_PROVIDER_PASCABAYAR_FAILED = "GET_LIST_PROVIDER_PASCABAYAR_FAILED";

// PRODUCT SORT
export const GET_LIST_CATEGORY_PRODUCT_SORT_REQUEST = "GET_LIST_CATEGORY_PRODUCT_SORT_REQUEST";
export const GET_LIST_CATEGORY_PRODUCT_SORT_SUCCESS = "GET_LIST_CATEGORY_PRODUCT_SORT_SUCCESS";
export const GET_LIST_CATEGORY_PRODUCT_SORT_FAILED = "GET_LIST_CATEGORY_PRODUCT_SORT_FAILED";

export const GET_LIST_PROVIDER_PRODUCT_SORT_REQUEST = "GET_LIST_PROVIDER_PRODUCT_SORT_REQUEST";
export const GET_LIST_PROVIDER_PRODUCT_SORT_SUCCESS = "GET_LIST_PROVIDER_PRODUCT_SORT_SUCCESS";
export const GET_LIST_PROVIDER_PRODUCT_SORT_FAILED = "GET_LIST_PROVIDER_PRODUCT_SORT_FAILED";


/* CUSTOMIZER */
export const WATCH_CUSTOMIZER ="WATCH_CUSTOMIZER";
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";