import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "../../../assets/scss/custom/manage-user-custom.scss";
// import { dataSuspend } from "../../../data/dummyTableData";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap";

const Suspend = (props) => {

  const columnsSuspend = [
    {
      name: "Status",
      center: true,
      width: "400px",
      cell: (row)=>(
        <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] suspend") ? false : true} id={`Active${row.id}`} type="radio" name={`status${row.id}`} value="ACTIVE" onClick={(event)=>{updateStatusSuspend(row.id,event.target.value)}} defaultChecked={row.status === 'ACTIVE' ? true : false}/>
            <Label className="mb-0" for={`Active${row.id}`}>{Option}<span className="digits"> Active</span></Label>
          </div>
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] suspend") ? false : true} id={`Pending${row.id}`} type="radio" name={`status${row.id}`} value="PENDING"  onClick={(event)=>updateStatusSuspend(row.id,event.target.value)} defaultChecked={row.status === 'PENDING' ? true : false}/>
            <Label className="mb-0" for={`Pending${row.id}`}>{Option}<span className="digits"> Pending</span></Label>
          </div>
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] suspend") ? false : true} id={`Suspend${row.id}`} type="radio" name={`status${row.id}`} value="SUSPEND" onClick={(event)=>updateStatusSuspend(row.id,event.target.value)} defaultChecked={row.status === 'SUSPEND' ? true : false}/>
            <Label className="mb-0" for={`Suspend${row.id}`}>{Option}<span className="digits"> Suspend</span></Label>
          </div>
        </FormGroup>
      )
    },
    // {
    //   name: "Upgrade",
    //   selector: "upgrade",
    //   center: true,
    //   width: "250px",
    // },
    {
      name: "Control",
      selector: "control",
      center: true,
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Sponsor",
      selector: "sponsor",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "Saldo",
      selector: "saldo",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Commission",
      selector: "commission",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Point",
      selector: "point",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Register Date",
      selector: "register_date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div>
          {userPermission.includes("[update] suspend") ? (
            <Button
            color="primary"
            className="btn-xs"
            id="buttonToUserDetail"
            onClick={() =>
              history.push(`${process.env.PUBLIC_URL}/user/user-detail/${row.id}`)
            }
          >
            <i className="fa fa-user"></i>
          </Button>
          ) : null}
          {/* <Tooltip placement="bottom" isOpen={tooltipOpen} target="buttonToUserDetail" toggle={toggle}>
        Go To User Detail
      </Tooltip> */}
          {/* <Button
            color="primary"
            className="btn-xs ml-2"
            onClick={(data) => DisplayEditUserList(data)}
          >
            <i class="fa fa-edit"></i>
          </Button>
          <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDelete()}
          >
            <i class="fa fa-trash"></i>
          </Button> */}
        </div>
      ),
    },
  ];

  // STATE REDUX
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const history = useHistory();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [images, setImage] = useState([]);
  const [smallImages, setsmallImages] = useState([]);
  const [activeTab, setActiveTab] = useState("3");
  const { register, handleSubmit, errors } = useForm();
  const [editSuspend, setEditSuspend] = useState(false);

  const [sweetAlert, setSweetAlert] = useState(false);

  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  // Display Modal Form
  const DisplayEditSuspend = () => setEditSuspend(!editSuspend);

  // state filter table
  const [filterTable, setFilterTable] = useState({
    basic: '',
    suspend:'',
    full_service:'',
    sponsor:'',
    anomali_control:'',
  })
  const [keyword, setKeyword] = useState('')

  // ACTION HANDLER
  const handleStartDate = (date) => {
    setStartDate(date);
    // console.log("start Date handler : ", date.toLocaleDateString('en-US'))
  };


  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const onSearch = (event) => {
    // event.prevenDefault();
    setKeyword(event.target.value)
  }

  const handleSortSuspend = (column, sortDirection) => {
    setSortColumnSuspend(column.selector);
    setSortDirectionSuspend(sortDirection);
  };

  const [suspendTable, setSuspendTable] = useState({})
  const [ pageSuspend, setPageSuspend] = useState(1);
  const [ sortColumnSuspend, setSortColumnSuspend ] = useState("");
  const [ sortDirectionSuspend, setSortDirectionSuspend ] = useState("");
  const [ countPageSuspend, setCountPageSuspend ] = useState(5);

  // FETCH DATA TABLE
  const getSuspendTable = () =>{
  
    axios.get('/suspend/data', {
      params: {
        page: pageSuspend,
        per_page: countPageSuspend,
        sort_column: sortColumnSuspend,
        sort_direction: sortDirectionSuspend,
        keyword: keyword,
        ...filterTable,
        starting_date: startDate && startDate.toLocaleDateString('en-US'),
        ending_date: endDate && endDate.toLocaleDateString('en-US')
      }
    })
    .then((response)=>{
      console.log("getUserListTable Response : ",response)
      setSuspendTable(response.data)
    })
    .catch((error)=>{
      console.log("getUserListTable Error : ", error)
    })
  };


  // UPDATE STATUS SUSPEND WITH TOGGLE SWITCH
  const updateStatusSuspend = (id,status) => {
    const URI = `/suspend/${id}/toggle-status`

    axios
    .post(URI , {
      status: status
    })
    .then(res => {
      toast.success("Success Update Status !", {
        position: toast.POSITION.TOP_RIGHT
        });
      let newData = [...suspendTable.data]
      // newData[id].status = status
      for (let i=0; i < newData.length; i++) {
        if (newData[i].id == id) {
          newData[i].status = status
          // console.log("response : ", newData[i])
          // console.log("response status : ", newData[i].status)
          // console.log("response id :", newData[i].id)
          // console.log("params id :", id)
        }
      }
      // setUpdateTroubleAndAvailableResponse(!updateTroubleAndAvailableResponse)
      // console.log("updateTroubleAndAvailable Response: ", newData[id].status)
      // console.log("DataTable Response: ", newData)
    })
    .catch(err => {
      toast.error("Failed Update Status !", {
        position: toast.POSITION.TOP_RIGHT
        });
      // console.log("updateTroubleAndAvailable Error: ", err)
    })
  }



  useEffect(() => {
    getSuspendTable()
  }, [
    pageSuspend,
    countPageSuspend,
    sortColumnSuspend,
    sortDirectionSuspend
  ]);

  const initilindex = { index: 0, isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const onMovePrev = () => {
    const prev = (photoIndex.index + images.length - 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: prev });
  };
  const onMoveNext = () => {
    const next = (photoIndex.index + 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: next });
  };

  return (
    <Fragment>
      <Breadcrumb parent="User" title="Suspend" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            {userPermission.includes("[read] suspend") ? (
              <Card className="mb-0">
              <CardBody>
                  <Form className="needs-validation" onSubmit={(event)=>{
                    event.preventDefault();
                    getSuspendTable()
                  }}>
                    <div className="form-row">
                      <Col md="12 mb-3">
                        <Input
                          className="form-control"
                          name="keyword"
                          type="text"
                          placeholder="Keyword"
                          onChange={(event)=>onSearch(event)}
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      {/* Filter Registration Date */}
                      <Col md="7 mb-3">
                        <Row>
                          <Col md="5 mb-3">
                            <Label htmlFor="validationCustom03">
                              {"Starting Date"}
                            </Label>
                            <DatePicker
                              className="datepicker-here form-control mt-2"
                              selected={startDate}
                              onChange={handleStartDate}
                            />
                          </Col>
                          <Col md="5 mb-3">
                            <Label htmlFor="validationCustom03">
                              {"Ending Date"}
                            </Label>
                            <DatePicker
                              className="datepicker-here form-control mt-2"
                              selected={endDate}
                              onChange={handleEndDate}
                            />
                          </Col>
                        </Row>
                      </Col>

                      {/* Filter */}
                      <Col md="5 mb-3">
                        <Label htmlFor="validationCustom03">{"Filter"}</Label>
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <Row>
                              <div className="checkbox checkbox-dark">
                                <Input id="Basic" name="basic" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    basic: "Basic"
                                  }): setFilterTable({
                                    ...filterTable,
                                    basic:''
                                  })}
                                } />
                                {console.log("filter table",filterTable)}
                                <Label for="Basic">{"Basic"}</Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-suspend" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    suspend: "Suspend"
                                  }): setFilterTable({
                                    ...filterTable,
                                    suspend:''
                                  })}
                                }/>
                                <Label for="filter1-suspend">{"suspend"}</Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-sponsor" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    sponsor: "Sponsor"
                                  }): setFilterTable({
                                    ...filterTable,
                                    sponsor:''
                                  })}
                                }/>
                                <Label for="filter1-sponsor">{"sponsor"}</Label>
                              </div>
                            </Row>
                            <Row>
                              <div className="checkbox checkbox-dark">
                                <Input
                                  id="filter1-full-service"
                                  type="checkbox"
                                  onChange={
                                    (event)=>{ event.target.checked ? setFilterTable({
                                      ...filterTable,
                                      full_service: "Full Service"
                                    }): setFilterTable({
                                      ...filterTable,
                                      full_service:''
                                    })}
                                  }
                                />
                                <Label for="filter1-full-service">
                                  {"Full Service"}
                                </Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-control" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    anomali_control: "Anomali Control"
                                  }): setFilterTable({
                                    ...filterTable,
                                    anomali_control:''
                                  })}
                                }/>
                                <Label for="filter1-control">
                                  {"Anomali Control"}
                                </Label>
                              </div>
                            </Row>
                          </FormGroup>
                      </Col>
                    </div>

                    {/* Button For Action Form */}
                    <Row className="justify-content-between">
                      <Col md="5">
                        <Row className="justify-content-around">
                          <Button color="secondary" type="submit">{"Search"}</Button>
                          <Button
                            color="primary"
                            className="sweet-8"
                            name="alertSuccess"
                            onClick={Displayalert}
                          >
                            {"Download"}
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                  <DataTable
                    columns={columnsSuspend}
                    data={suspendTable.data}
                    pagination={true}
                    paginationServer
                    paginationTotalRows={suspendTable.total}
                    onChangePage={(pageSuspend) =>
                      setPageSuspend(pageSuspend)
                    }
                    onChangeRowsPerPage={(countPageSuspend) =>
                      setCountPageSuspend(
                        countPageSuspend
                      )
                    }
                    onSort={handleSortSuspend}
                    paginationPerPage={countPageSuspend}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  />
                  {/* Modal Edit Suspend */}
                {/* <Modal
                  isOpen={editSuspend}
                  toggle={() => DisplayEditSuspend()}
                  centered
                >
                  <ModalHeader toggle={() => DisplayEditSuspend()}>
                    {"Edit Suspend"}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col xl="12">
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>User ID</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="VP0283784" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Sponsor</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="VP0843784" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Full Name</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="Agung Cahyadi" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Phone Number</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="083947237" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Email</p>
                            </Col>
                            <Col xl="6">
                              <Input
                                type="text"
                                defaultValue="cahyadi@gmail.com"
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Saldo</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="Rp. 100,503" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Commission</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="Rp. 100,000" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Point</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="130" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Control</p>
                            </Col>
                            <Col xl="6">
                              <Input type="text" defaultValue="0" />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Status</p>
                            </Col>
                            <Col xl="6">
                              <Input type="select">
                                <option>Active</option>
                                <option>Non Active</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Type</p>
                            </Col>
                            <Col xl="6">
                              <Input type="select">
                                <option>All</option>
                                <option>Full Service</option>
                                <option>Basic</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Registration Date</p>
                            </Col>
                            <Col xl="6">
                              <DatePicker
                                className="datepicker-here form-control"
                                selected={startDate}
                                onChange={handleStartDate}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <Row className="justify-content-center">
                          <Col xl="3" md="3">
                            <Button
                              color="secondary"
                              type="reset"
                              className="mr-1"
                              onClick={() => DisplayEditSuspend()}
                            >
                              {"Cancel"}
                            </Button>
                          </Col>
                          <Col xl="3" md="3">
                            <Button color="primary">
                              {"Save"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal> */}
              </CardBody>
            </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Suspend;
