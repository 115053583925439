import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from "react-select"; 
import "../../assets/scss/custom/deposit-custom.scss";
// import {dataTableTransaction} from '../../data/dummyTableData'

import axios from "axios";

const Mutation = () => {

  const columnsMutation = [
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width:"170px"
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      center: true,
      width:'170px'
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: "credit",
      sortable: true,
      center: true,
    },
    {
      name: "Beginning Balance",
      sortable: true,
      center: true,
      width:"200px",
      cell:(row)=>(
        <span>{`Rp.${parseFloat(`${row.beginning_balance}`).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "End Balance",
      sortable: true,
      center: true,
      width:"200px",
      cell:(row)=>(
        <span>{`Rp.${parseFloat(`${row.end_balance}`).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      center: true,
      width:"450px",

    },
  ];

  const [sweetAlert, setSweetAlert] = useState(false);
  const [pointMutationDetail, setPointMutationDetail] = useState(false);

  const listType = [
    {value:"ALL", label:"ALL"},
    {value:"DEBIT", label:"DEBIT"},
    {value:"CREDIT", label:"CREDIT"},
  ]

  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  const showPointMutationDetail = () =>
    setPointMutationDetail(!pointMutationDetail);


  const { register, handleSubmit, errors } = useForm();

  // ACTION HANDLER
  const handleSortMutation = (column, sortDirection) => {
    setSortColumnMutation(column.selector);
    setSortDirectionMutation(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchMutation = (event) => {setKeywordMutation(event.target.value)};

  // STATE DATA TABLE
  const [mutationTable, setMutationTable] = useState({});
  const [pageMutation, setPageMutation] = useState(1);
  const [sortColumnMutation,setSortColumnMutation] = useState("");
  const [sortDirectionMutation,setSortDirectionMutation] = useState("");
  const [countPageMutation,setCountPageMutation] = useState(5);
  const [keywordMutation, setKeywordMutation] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState('')
  

  // FETCHING DATA TABLE
  const getDataTableMutation = () => {
    const URI = `mutation/data`;

    return axios
      .get(URI, {
        params: {
          page: pageMutation,
          per_page: countPageMutation,
          sort_column: sortColumnMutation,
          sort_direction: sortDirectionMutation,
          keyword: keywordMutation,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          type: type,
        },
      })
      .then((response) => {
        setMutationTable(response.data);
        console.log("getMutationTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDataTableMutation()
  }, [
    pageMutation,
    sortColumnMutation,
    sortDirectionMutation,
    countPageMutation]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Home" title="Mutation" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchMutation(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="12" xs="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Type"}</Label>
                        <Select
                        options={listType}
                        onChange={(event)=>{
                          setType(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTableMutation()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  // title="Arnold Movies"
                  columns={columnsMutation}
                  data={mutationTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={mutationTable.total}
                  onChangePage={(pageMutation) =>
                    setPageMutation(pageMutation)
                  }
                  onChangeRowsPerPage={(countPageMutation) =>
                    setCountPageMutation(
                      countPageMutation
                    )
                  }
                  onSort={handleSortMutation}
                  paginationPerPage={countPageMutation}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Mutation;
