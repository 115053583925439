import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../layout/breadcrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import "../../assets/scss/custom/config-admin-custom.scss";
// import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
// import {
//   registerActions,
//   editUserActions,
// } from "../../redux/action/user/index";
import {
  getPermissionList,
  // getUserPermissionData,
} from "../../redux/action/permission-list/index";
import { getRoleList } from "../../redux/action/role-list/index";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  Table,
  CardHeader,
} from "reactstrap";

const ConfigAdmin = (props) => {
  const columnsUserOnConfigAdmin = [
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
      center: true,
    },
    {
      name: "Role",
      selector: "role_name",
      center: true,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div>
          {userPermission.includes("[update] configure admin") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              // console.log("id row: ", row.id);
              getUserData(row.id);
              setUserId(row.id);
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] configure admin") ? (
            <Button
            color="danger"
            className="btn-xs ml-3"
            onClick={() => {
              deleteUser(row.id);
            }}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      ),
    },
  ];

  const columnsRole = [
    {
      name: "Role Name",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div>
          {userPermission.includes("[update] configure admin") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              // showEditRole();
              setDataEditRole(row)
              getUserPermissionData(row.id);
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
        </div>
      ),
    },
  ];


  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const history = useHistory();
  const dispatch = useDispatch()

  const roleList = useSelector((state) => state.roleListReducer.Data.data);
  // const userPermissionData = useSelector(
  //   (state) => state.getUserPermissionReducer.Data.data
  // );
  // console.log("user permission data :", userPermissionData)
  const permissionList = useSelector(
    (state) => state.permissionListReducer.Data.data
  );
  // console.log("data permission list :", permissionList);
  const registerData = useSelector((state) => state.userReducer.registerData);
  const edited = useSelector((state) => state.userReducer.editData);
  // console.log("edited :", edited);
  // console.log("role list:", roleList);
  // console.log("register Data :", registerData.data.status);

  const [addAdmin, setAddAdmin] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  
  const [addRole, setAddRole] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [dataEditRole, setDataEditRole] = useState({})
  console.log("dataEditRole: " ,dataEditRole)


  // STATE ROLE
  const [roleListDataTable, setRoleListDataTable] = useState({});
  const [pageRole, setPageRole] = useState(1);
  const [sortColumnRole, setSortColumnRole] = useState("");
  const [sortDirectionRole, setSortDirectionRole] = useState("");
  const [countPageRole, setCountPageRole] = useState(5);
  const [keywordRole, setKeywordRole]=useState('')

  const [userPermissionData, setUserPermissionData]=useState()
  console.log("state awal user permission: ", userPermissionData)

  // STATE USER
  const [userDataTable, setUserDataTable] = useState({});
  const [pageUser, setPageUser] = useState(1);
  const [sortColumnUser, setSortColumnUser] = useState("");
  const [sortDirectionUser, setSortDirectionUser] = useState("");
  const [countPageUser, setCountPageUser] = useState(5);
  const [userDeleted, setUserDeleted] = useState(false);
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState();
  const [keywordUser, setKeywordUser] = useState('');
  
  const [addAdminResponse, setAddAdminResponse]=useState(false)

  const [edit, setEdit] = useState({
    full_name: "",
    email: "",
    role_id: 0,
  });
  const [editAdminResponse, setEditAdminResponse]=useState(false)


  const [sweetAlert, setSweetAlert] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [register, setRegister] = useState({
    full_name: "",
    email: "",
    password: "",
    role_id: 1,
  });



  console.log('register data :', register)
  // console.log("edit data :", edit);

  // const handlerRemoveCheckList = (data, id) => {
  //   for ( var i = 0; i < data.length; i++) {
  //     if(data[i] == id) {
  //       setUserPermissionData(data.splice(i,1))
  //     }
  //   }
  // }

  const handleChangeRegister = (event) => {
    setRegister({
      ...register,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeEdit = (event) => {
    setEdit({
      ...edit,
      [event.target.name]: event.target.value,
    });
  };

  // const handleSearchUser = (event) =>{
  //   setKeywordUser(event.target.value)
  // }

  const handleSortUser = (column, sortDirection) => {
    setSortColumnUser(column.selector);
    setSortDirectionUser(sortDirection);
  };

  const handleSortRole = (column, sortDirection) => {
    setSortColumnRole(column.selector);
    setSortDirectionRole(sortDirection);
  };

  const DisplayAlertSuccessRegister = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Register Success",
      text: "",
      icon: "success",
    });
  };

  // Display Modal Form
  const showAddAdmin = () => setAddAdmin(!addAdmin);
  const showEditAdmin = () => setEditAdmin(!editAdmin);
  const showAddRole = () => setAddRole(!addRole);
  const showEditRole = () => setEditRole(!editRole);

  const [togglePassword, setTogglePassword] = useState(false);

  // const handleCheckRole = (event) => {
  //   if(permissionList !== undefined){
  //     permissionList.forEach(childrens => {
  //       childrens.forEach(permissions =>{
  //         permissions.includes(userPermissionData) && true
  //       })
  //     });
  //   }
  // }

  const getRoleDataTable = () => {
    axios
      .get(`privilege/roles-data`, {
        params : {
          page: pageRole,
          per_page: countPageRole,
          sort_column: sortColumnRole,
          sort_direction: sortDirectionRole,
          keyword: keywordRole
        }
      })
      .then((res) => {
        setRoleListDataTable(res.data);
        // console.log("data response role table :", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserDataTable = () => {
    axios
      .get(`privilege/users-data`, {
        params : {
          page: pageUser,
          per_page: countPageUser,
          sort_column: sortColumnUser,
          sort_direction: sortDirectionUser,
          keyword: keywordUser
        }
      })
      .then((res) => {
        setUserDataTable(res.data);
        console.log("data getUserDataTable :", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = (id) => {
    axios
      .get(`privilege/user-detail/${id}`)
      .then((res) => {
        setUserData(res.data);
        console.log("getUserData response :", res.data)
        setEdit({
          full_name: res.data.data.full_name,
          email: res.data.data.email,
          role_id: res.data.data.role_id,
        });
        showEditAdmin(id);
        // console.log("data response getUserData :", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // POST NEW USER ADMIN
  const registerActions = () => {
    // console.log("register value", value);
  
    const uriUsers = "privilege/store-user";
  
    return axios
      .post(uriUsers, register)
      .then((response) => {
        console.log("response register: ", response);
        // DisplayAlertSuccessRegister()
        toast.success("Success Add Account !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAddAdmin(!addAdmin)
        setAddAdminResponse(!addAdminResponse)
        // dispatch(registerSuccess(response));
      })
      .catch((error) => {
        console.log("response error", error.response);
        // if (error.response.data.errors.email[0] === "The email has already been taken.") {
        //   toast.warn(`${error.response.data.errors.email[0]}`, {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // } else {
        //   toast.error(`${error.response.data.message}`, {
        //     position: toast.POSITION.TOP_RIGHT
        //     })
        // }
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
      });
  };

  const editUserActions = () => {
  
    const uriUsers = `privilege/update-user/${userId}`;
  
    return axios
      .post(uriUsers, edit)
      .then((response) => {
        console.log("response edit user: ", response);
        // DisplayAlertSuccessRegister()
        toast.success("Success Edit Account !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEditAdmin(!editAdmin)
        setEditAdminResponse(!editAdminResponse)
        // dispatch(editUserSuccess(response));
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // dispatch(editUserFailed(error.response));
        console.log("edit admin error: ", error)
  
      });
  };

  const deleteUser = (id) => {
    axios
      .post(`privilege/delete-user/${id}`)
      .then((res) => {
        setUserDeleted(!userDeleted);
        // console.log("data response delete :", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserPermissionData = (id) => {
    //   event.preventDefault();
    //   console.log("register value");
    
      const uriUsers = `privilege/get-role-permissions/${id}`;
    
      return axios
        .get(uriUsers)
        .then((response) => {
          console.log("response get user permission", response);
          // dispatch(getUserPermissionSuccess(response.data));
          setUserPermissionData(response.data.data)
          showEditRole();
          // history.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    };

  const updateRole = (id,data) => {
    const URI = `/privilege/update-role-permissions/${id}`

    axios
    .post(URI, {
      permissions: data
    })
    .then(response => {
      toast.success("Success update privilage !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEditRole(!editRole)
      console.log("updateRole Response: ", response)
    })
    .catch(error => {
      console.log("updateRole Error: ", error)

      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
    })
  }

  useEffect(() => {
    dispatch(getRoleList());
    dispatch(getPermissionList());
  }, [])

  // ROLE DATA TABLE LIFECYCLE
  useEffect(() => {
    getRoleDataTable();
    
  }, [
    pageRole,
    countPageRole,
    sortColumnRole,
    sortDirectionRole,
  ])

  useEffect((e) => {

    // dispatch(getUserPermissionData(id));
    getUserDataTable();
  }, [
    pageUser,
    countPageUser,
    sortColumnUser,
    sortDirectionUser,
    userDeleted,
    editAdminResponse,
    addAdminResponse,
    // edit,
  ]);

  return (
    <Fragment>
      <Breadcrumb parent="Home" title="Configure Admin" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="text-center">
                  <Nav tabs className="search-list">
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => setActiveTab("1")}
                      >
                        <i className="icon-user"></i>
                        {"User"}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => setActiveTab("2")}
                      >
                        <i className="icon-settings"></i>
                        {"Role"}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                  {/*User Section */}
                  <TabPane tabId="1" className="search-links fade show">
                    <Card className="mb-0">
                      <CardHeader className="d-flex">
                        <h6 className="mb-0">{"User List"}</h6>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xl="12">
                            <Form
                            //   className="needs-validation"
                            //   noValidate=""
                              onSubmit={(e)=>{
                                e.preventDefault()
                                getUserDataTable()
                              }}
                            >
                              <Row className="justify-content-center mb-3">
                                <Col xl="7" sm="12">
                                  <Input
                                    className="form-control mb-4"
                                    name="keyword"
                                    onChange={(event) => {
                                      // event.preventDefault()
                                      setKeywordUser(event.target.value)
                                    }}
                                    type="text"
                                    placeholder="Keyword"
                                  />
                                </Col>
                                <Col xl="5" sm="12">
                                  <Row className="justify-content-between">
                                    <Col xl="5" sm="6" xs="5">
                                      <Button
                                        color="secondary"
                                        type="submit"
                                      >
                                        {"Search"}
                                      </Button>
                                    </Col>
                                    <Col xl="5" sm="6" xs="7">
                                      <Row className="justify-content-end">
                                      {userPermission.includes("[create] configure admin") ? (
                                        <Button
                                        color="primary"
                                        name="alertSuccess"
                                        className="mr-3"
                                        onClick={() => showAddAdmin()}
                                      >
                                        {"Add User"}
                                      </Button>
                                      ) : null}
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                            <DataTable
                              columns={columnsUserOnConfigAdmin}
                              data={userDataTable.data}
                              pagination={true}
                              paginationServer
                              paginationTotalRows={userDataTable.total}
                              onChangePage={(pageUser) => setPageUser(pageUser)}
                              onChangeRowsPerPage={(countPageUser) =>
                                setCountPageUser(countPageUser)
                              }
                              onSort={handleSortUser}
                              paginationPerPage={countPageUser}
                              paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    {/* Modal Add User Admin */}
                    <Modal
                      isOpen={addAdmin}
                      toggle={() => showAddAdmin()}
                      centered
                      className="theme-form"
                    >
                      <ModalHeader toggle={() => showAddAdmin()}>
                        {"Add Admin"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(event) => {
                            event.preventDefault()
                            registerActions();
                          }}
                        >
                          <Row>
                            <Col xl="12" md="12">
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Full Name"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      id="ppob-product-code"
                                      type="text"
                                      name="full_name"
                                      onChange={(event) =>
                                        handleChangeRegister(event)
                                      }
                                      className="form-control digits"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Email"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      id="ppob-product-code"
                                      type="email"
                                      name="email"
                                      onChange={(event) =>
                                        handleChangeRegister(event)
                                      }
                                      className="form-control digits"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Password"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      className="form-control"
                                      type={
                                        togglePassword ? "text" : "password"
                                      }
                                      name="password"
                                      onChange={(event) =>
                                        handleChangeRegister(event)
                                      }
                                      required=""
                                    />
                                    <div
                                      className="show-hide custom-show-hide"
                                      onClick={() =>
                                        setTogglePassword(!togglePassword)
                                      }
                                    >
                                      <span
                                        className={togglePassword ? "" : "show"}
                                      ></span>
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Role"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      type="select"
                                      name="role_id"
                                      id="ppob-product-name"
                                      onChange={(event) =>
                                        handleChangeRegister(event)
                                      }
                                    >
                                      {roleList ? (
                                        roleList.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.value}
                                          >
                                            {item.text}
                                          </option>
                                        ))
                                      ) : (
                                        <p>data kosong</p>
                                      )}
                                    </Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                              <Row className="justify-content-center">
                                <Col xl="3" md="3">
                                  <Button
                                    color="secondary"
                                    type="reset"
                                    className="mr-1"
                                    onClick={() => showAddAdmin()}
                                  >
                                    {"Cancel"}
                                  </Button>
                                </Col>
                                <Col xl="3" md="3">
                                  <Button color="primary" type="submit">{"Confirm"}</Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>

                    {/* Modal Edit User*/}
                    <Modal
                      isOpen={editAdmin}
                      toggle={() => showEditAdmin()}
                      centered
                      className="theme-form"
                    >
                      <ModalHeader toggle={() => showEditAdmin()}>
                        {"Edit Admin"}
                      </ModalHeader>
                      <ModalBody>
                        {/* {console.log("id row dalam modal: ", userId)} */}
                        <Form
                          onSubmit={(event) => {
                            event.preventDefault()
                            editUserActions();
                          }}
                        >
                          <Row>
                            <Col xl="12" md="12">
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Full Name"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      id="ppob-product-code"
                                      type="text"
                                      name="full_name"
                                      defaultValue={
                                        userData !== undefined
                                          ? userData.data.full_name
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleChangeEdit(event)
                                      }
                                      className="form-control digits"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Email"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      id="ppob-product-code"
                                      type="email"
                                      name="email"
                                      defaultValue={
                                        userData !== undefined
                                          ? userData.data.email
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleChangeEdit(event)
                                      }
                                      className="form-control digits"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Role"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      type="select"
                                      name="role_id"
                                      defaultValue={
                                        userData !== undefined
                                          ? userData.data.role_id
                                          : ""
                                      }
                                      id="ppob-product-name"
                                      onChange={(event) =>
                                        handleChangeEdit(event)
                                      }
                                    >
                                      {roleList ? (
                                        roleList.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.value}
                                          >
                                            {item.text}
                                          </option>
                                        ))
                                      ) : (
                                        <p>data kosong</p>
                                      )}
                                    </Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                              <Row className="justify-content-center">
                                <Col xl="3" md="3">
                                  <Button
                                    color="secondary"
                                    type="reset"
                                    className="mr-1"
                                    onClick={() => showEditAdmin()}
                                  >
                                    {"Cancel"}
                                  </Button>
                                </Col>
                                <Col xl="3" md="3">
                                  <Button color="primary" type="submit">
                                    {"Confirm"}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </TabPane>

                  {/* Role Section */}
                  <TabPane tabId="2" className="search-links fade show">
                    <Card className="mb-0">
                      <CardHeader className="d-flex">
                        <h6 className="mb-0">{"User Role"}</h6>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xl="12">
                            <Form
                            //   className="needs-validation"
                            //   noValidate=""
                            //   onSubmit={handleSubmit(onSubmit)}
                            >
                              <Row className="justify-content-center mb-3">
                                <Col xl="7" sm="12">
                                  <Input
                                    className="form-control mb-4"
                                    name="keyword"
                                    type="text"
                                    placeholder="Keyword"
                                    onChange={(event)=>{
                                      // event.preventDefault()
                                      setKeywordRole(event.target.value)
                                    }}
                                  />
                                </Col>
                                <Col xl="5" sm="12">
                                  <Row className="justify-content-center">
                                    <Col xl="5" sm="6" xs="5">
                                      <Button
                                        color="secondary"
                                        onClick={()=>getRoleDataTable()}
                                      >
                                        {"Search"}
                                      </Button>
                                    </Col>
                                    <Col xl="5" sm="6" xs="7">
                                      <Row className="justify-content-end">
                                      {userPermission.includes("[create] configure admin") ? (
                                        <Button
                                        color="primary"
                                        onClick={() => showAddRole()}
                                      >
                                        {"Add Role"}
                                      </Button>
                                      ) : null}
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                            <DataTable
                              columns={columnsRole}
                              data={roleListDataTable.data}
                              pagination={true}
                              paginationServer
                              paginationTotalRows={roleListDataTable.total}
                              onChangePage={(pageRole) => setPageRole(pageRole)}
                              onChangeRowsPerPage={(countPageRole) =>
                                setCountPageRole(countPageRole)
                              }
                              onSort={handleSortRole}
                              paginationPerPage={countPageRole}
                              paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    {/* Modal Add Role */}
                    <Modal
                      isOpen={addRole}
                      toggle={() => showAddRole()}
                      centered
                      className="theme-form"
                    >
                      <ModalHeader toggle={() => showAddRole()}>
                        {"Add Role"}
                      </ModalHeader>
                      <ModalBody>
                        <Form>
                          <Row>
                            <Col xl="12" md="12">
                              <FormGroup>
                                <Row className="justify-content-around">
                                  <Col xl="4" md="4">
                                    <Label>{"Role Name"}</Label>
                                  </Col>
                                  <Col xl="6" md="6">
                                    <Input
                                      id="ppob-product-code"
                                      type="text"
                                      name="full_name"
                                      onChange={(event) =>
                                        handleChangeRegister(event)
                                      }
                                      className="form-control digits"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <Row className="justify-content-center">
                                <Col xl="3" md="3">
                                  <Button
                                    color="secondary"
                                    type="reset"
                                    className="mr-1"
                                    onClick={() => showAddRole()}
                                  >
                                    {"Cancel"}
                                  </Button>
                                </Col>
                                <Col xl="3" md="3">
                                  <Button color="primary">{"Confirm"}</Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>

                    {/* Edit Role Privilege */}
                    <Modal
                      isOpen={editRole}
                      toggle={() => showEditRole()}
                      centered
                      size="xl"
                    >
                      <ModalHeader toggle={() => showEditRole()}>
                        {"Edit Role"}
                      </ModalHeader>
                      <ModalBody>
                        <Form 

                        >
                          <Row>
                            <Col xl="12" md="12">
                              <FormGroup>
                                <Label>Role Name</Label>
                                <Input type="text" defaultValue={dataEditRole.name}/>
                              </FormGroup>
                              <Table>
                                <thead className="thead-dark">
                                  <tr>
                                    <th>{"Menu Name"}</th>
                                    <th width="100" className="text-center">
                                      {"Read"}
                                    </th>
                                    <th width="100" className="text-center">
                                      {"Create"}
                                    </th>
                                    <th width="100" className="text-center">
                                      {"Update"}
                                    </th>
                                    <th width="100" className="text-center">
                                      {"Delete"}
                                    </th>
                                  </tr>
                                </thead>

                                {console.log("persmission data: ", userPermissionData)}

                                {permissionList !== undefined ? (
                                  // mapping data terluar
                                  permissionList.map((item, index) => (
                                    <tbody key={index}>
                                      <tr className="thead-light">
                                        <th
                                          scope="row"
                                          className="font-weight-bold"
                                        >
                                          {item.name}
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                      {/* mapping children */}
                                      {item.childrens !== undefined ? (
                                        item.childrens.map((item, index) => (
                                          <tr key={index}>
                                            <td>{item.name}</td>
                                            {/* mapping permissions */}
                                            {item.permissions !== undefined ? (
                                              item.permissions.map(
                                                (item, index) => (
                                                  <td
                                                    width="200"
                                                    className="text-center"
                                                    key={index}
                                                  >
                                                    {userPermissionData !==
                                                    undefined ? (
                                                      <input
                                                        type="checkbox"
                                                        name={`permissions${item.id}`}
                                                        onChange={(event)=>{
                                                          const check = userPermissionData.find(data => data == event.target.value)
                                                          if (check) {
                                                            console.log("remove")
                                                            for ( var i = 0; i < userPermissionData.length; i++) {
                                                              if(userPermissionData[i] == item.id) {
                                                                userPermissionData.splice(i,1)
                                                                // setUserPermissionData(newData)
                                                                console.log("newDataRemove: ", userPermissionData)
                                                              }
                                                            }
                                                          } else {
                                                            console.log("push")
                                                            // setUserPermissionData(userPermissionData.push(item.id))
                                                            userPermissionData.push(item.id)
                                                            // setUserPermissionData(newData)
                                                            console.log("newDataPush :", userPermissionData)
                                                          }
                                                        }}
                                                        value={
                                                          item.id
                                                        }
                                                        defaultChecked={
                                                          userPermissionData.includes(item.id) ? true :false
                                                        }
                                                      >
                                                        {/* {console.log("value",userPermissionData.includes(item.id))}
                                                  {console.log("checked",userPermissionData.includes(item.id))}
                                                  {console.log("item id",item.id)} */}
                                                  {/* {console.log("item id",item.id)} */}
                                                  {/* {console.log("checked",userPermissionData.includes(item.id))} */}

                                                      </input>
                                                    ) : (
                                                      <p>
                                                        data user permission
                                                        kosong
                                                      </p>
                                                    )}
                                                  </td>
                                                )
                                              )
                                            ) : (
                                              <p>data permission kosong</p>
                                            )}
                                          </tr>
                                        ))
                                      ) : (
                                        <p>data children kosong</p>
                                      )}
                                    </tbody>
                                  ))
                                ) : (
                                  <p>data permission kosong</p>
                                )}
                              </Table>
                            </Col>
                          </Row>
                          <Row className="justify-content-center ml-5 mt-5">
                            <Col xl="3" className="ml-5">
                              <Button color="secondary" onClick={()=>showEditRole()}>Cancel</Button>
                            </Col>
                            {console.log("newData: ", userPermissionData)}
                            <Col xl="3">
                              <Button color="primary" onClick={()=>{
                                userPermissionData.sort(function(a, b){return a - b})
                                // console.log("SendingData: ", userPermissionData)
                                // console.log("sendingDataWithSorting :", newData)
                                console.log("data ID: ", dataEditRole.id)
                                updateRole(dataEditRole.id, userPermissionData)
                                }}>
                                Confirm
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ConfigAdmin;
