import axios from 'axios'
import {
    GET_LIST_PARTNER_REQUEST,
    GET_LIST_PARTNER_SUCCESS,
    GET_LIST_PARTNER_FAILED
} from '../../actionTypes'


export const listPartnerRequest = () => {
    return {
      type: GET_LIST_PARTNER_REQUEST,
    };
  };
  
  export const listPartnerSuccess = (value) => {
    return {
      type: GET_LIST_PARTNER_SUCCESS,
      payload: value,
    };
  };
  
  export const listPartnerFailed = (value) => {
    return {
      type: GET_LIST_PARTNER_FAILED,
      error: value,
    };
  };

  export const getListPartner= () => (dispatch) => {
    //   event.preventDefault();
      // console.log("register value");
    
      const URI = `transaction/partner`;
    
      return axios
        .get(URI)
        .then((response) => {
          console.log("getListPartner response", response);
          dispatch(listPartnerSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };