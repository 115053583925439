import {
    GET_LIST_PROVIDER_PASCABAYAR_REQUEST,
    GET_LIST_PROVIDER_PASCABAYAR_SUCCESS,
    GET_LIST_PROVIDER_PASCABAYAR_FAILED
} from '../../../actionTypes'

const initialState = {
    data:[],
    isLoading:false,
    error:''
}

const listProviderPascabayarReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PROVIDER_PASCABAYAR_REQUEST:
            return {
                isLoading: true
            }
        case GET_LIST_PROVIDER_PASCABAYAR_SUCCESS:
            return {
                isLoading: false,
                data:action.payload
            }
        case GET_LIST_PROVIDER_PASCABAYAR_FAILED:
            return {
                isLoading:false,
                error: action.error
            }
        default:
            return state;
    }
}

export default listProviderPascabayarReducer