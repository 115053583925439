import axios from 'axios'
import {
    PERSONAL_INFORMATION_REQUEST,
    PERSONAL_INFORMATION_SUCCESS,
    PERSONAL_INFORMATION_FAILED,
    PERSONAL_PICTURE,
    UPLOAD_PERSONAL_PICTURE,
    KTP_PICTURE
} from '../../../actionTypes'

export const personalInformationRequest = () => {
    return {
      type: PERSONAL_INFORMATION_REQUEST,
    };
  };
  
  export const personalInformationSuccess = (value) => {
    return {
      type: PERSONAL_INFORMATION_SUCCESS,
      payload: value,
    };
  };
  
  export const personalInformationFailed = (value) => {
    return {
      type: PERSONAL_INFORMATION_FAILED,
      error: value,
    };
  };

  export const personalPicture = (value) => {
    return {
      type: PERSONAL_PICTURE,
      payload: value,
    }
  }

  export const uploadPersonalPicture = (value) => {
    return {
      type: UPLOAD_PERSONAL_PICTURE,
      payload : value
    }
  }

  export const ktpPicture = (value) => {
    return {
      type: KTP_PICTURE,
      payload: value,
    }
  }

  export const getPersonalInformation = (id) => (dispatch) => {
    //   event.preventDefault();
      // console.log("register value");
    
      const uriUsers = `user-detail/${id}/personal-information`;
    
      return axios
        .get(uriUsers)
        .then((response) => {
          console.log("getPersonalInformation response", response);
          dispatch(personalInformationSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };

  export const getPersonalPicture = (id) => (dispatch) => {
    axios
    .get(`https://neko01.otlet.id/api/user-detail/${id}/personal-picture`)
    .then(res => dispatch(personalPicture(res.data)))
    .catch(err => console.log("getPersonalPicture error :", err))

  }

  export const postUploadPersonalPicture = (id, file, event) => (dispatch) => {
    event.preventDefault()

    const formData = new FormData();
    formData.append('personal',file)
    const config = {
      headers:{
        'content-type':'multipart/form-data'
      }
    }

    axios
    .post(`user-detail/${id}/personal-information/upload-personal`,formData,config)
    .then(res => {
      dispatch(uploadPersonalPicture(res.data))
       console.log('postUploadPicture :', res.data)
      })
    .catch(err => console.log('uploadPersonalPicture :', err))
  }

  export const getKtpPicture = (id) => (dispatch) =>{
    axios
    .get(`https://neko01.otlet.id/api/user-detail/${id}/foto-ktp`)
    .then(res => dispatch(ktpPicture(res.data)))
    .catch(err => console.log('getKtpPicture error :', err))
  }