import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "../../../assets/scss/custom/manage-user-custom.scss";
// import { dataSuspend } from "../../../data/dummyTableData";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap";

const UserMapping = (props) => {
  // Data User Mapping
  const dataUserMapping = [
    {
      id: 1,
      uid: "VP0035063",
      sponsor: "VP0245063",
      full_name: "Samsul Baharudin",
      phone_number: "083947584653",
      email: "samsul@gmail.com",
      location: "Bogor",
      last_active: "1 Jam Yang Lalu",
      register_date: "01 jan 2020 08:15:22 WIB",
      action: (
        <Button
          color="primary"
          className="btn-xs ml-2"
          onClick={() => DisplayMessageFormUserID()}
        >
          <i class="fa fa-envelope-o"></i>
        </Button>
      ),
    },
    {
      id: 2,
      uid: "VP0035063",
      sponsor: "VP0245063",
      full_name: "Eren Yeager",
      phone_number: "02347584653",
      email: "yeager_titan@gmail.com",
      location: "Jakarta",
      last_active: "10 Jam Yang Lalu",
      register_date: "20 jan 2020 10:15:22 WIB",
      action: (
        <Button
          color="primary"
          className="btn-xs ml-2"
          onClick={() => DisplayMessageFormUserID()}
        >
          <i class="fa fa-envelope-o"></i>
        </Button>
      ),
    },
    {
      id: 3,
      uid: "VP0435063",
      sponsor: "VP0384963",
      full_name: "Erik Jeko",
      phone_number: "083947584653",
      email: "jeko@gmail.com",
      location: "Yogyakarta",
      last_active: "40 Menit Yang Lalu",
      register_date: "11 jan 2020 18:15:22 WIB",
      action: (
        <Button
          color="primary"
          className="btn-xs ml-2"
          onClick={() => DisplayMessageFormUserID()}
        >
          <i class="fa fa-envelope-o"></i>
        </Button>
      ),
    },
    {
      id: 4,
      uid: "VP034234",
      sponsor: "VP0432523",
      full_name: "Jhon Cena",
      phone_number: "08242384653",
      email: "cena@gmail.com",
      location: "Parung Panjang",
      last_active: "1 Jam Yang Lalu",
      register_date: "21 jan 2020 08:15:22 WIB",
      action: (
        <Button
          color="primary"
          className="btn-xs ml-2"
          onClick={() => DisplayMessageFormUserID()}
        >
          <i class="fa fa-envelope-o"></i>
        </Button>
      ),
    },
  ];

  const columnsUserMapping = [
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Sponsor",
      selector: "sponsor",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "Location",
      selector: "location",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Last Active",
      selector: "last_active",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Register Date",
      selector: "register_date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Action",
      center: true,
      width: "150px",
      cell: ()=>(
        <div>
          {userPermission.includes("[create] user mapping") || userPermission.includes("[update] user mapping") ? (
            <Button
            color="primary"
            className="btn-xs ml-2"
            onClick={() => DisplayMessageFormUserID()}
          >
            <i class="fa fa-envelope-o"></i>
          </Button>
          ) : null}

        </div>
      )
    },
  ];

  // STATE REDUX
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const history = useHistory();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [images, setImage] = useState([]);
  const [smallImages, setsmallImages] = useState([]);
  const [activeTab, setActiveTab] = useState("2");
  const { register, handleSubmit, errors } = useForm();
  const [MessageForm, setMessageForm] = useState(false);
  const [MessageFormUserID, setMessageFormUserID] = useState(false);
  const [editUserList, setEditUserList] = useState(false);
  const [editSuspend, setEditSuspend] = useState(false);

  const [sweetAlert, setSweetAlert] = useState(false);


  // const listChannel = [
  //   {value:''}
  // ]



  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  // Display Modal Form
  const DisplayMessageForm = () => setMessageForm(!MessageForm);
  const DisplayMessageFormUserID = () =>
    setMessageFormUserID(!MessageFormUserID);

  // ACTION HANDLER
  const handleStartDate = (date) => {
    setStartDate(date);
    // console.log("start Date handler : ", date.toLocaleDateString('en-US'))
  };


  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const onSearch = (event) => {
    // event.prevenDefault();
    setKeyword(event.target.value)
  }

  const handleSortUserMapping = (column, sortDirection) => {
    setSortColumnUserMapping(column.selector);
    setSortDirectionUserMapping(sortDirection);
  };

  // State for action handler
  const [userMappingTable, setUserMappingTable] = useState({})
  const [ pageUserMapping, setPageUserMapping] = useState(1);
  const [ sortColumnUserMapping, setSortColumnUserMapping ] = useState("");
  const [ sortDirectionUserMapping, setSortDirectionUserMapping ] = useState("");
  const [ countPageUserMapping, setCountPageUserMapping ] = useState(5);
  const [filterTable, setFilterTable] = useState({
    basic: '',
    suspend:'',
    full_service:'',
    sponsor:'',
    anomali_control:'',
  })
  const [keyword, setKeyword] = useState('')

  // FETCHING DATATABLE
  const getUserMappingTable = () =>{
  
    axios.get('/user-mapping/data', {
      params: {
        page: pageUserMapping,
        per_page: countPageUserMapping,
        sort_column: sortColumnUserMapping,
        sort_direction: sortDirectionUserMapping,
        keyword: keyword,
        ...filterTable,
        starting_date: startDate && startDate.toLocaleDateString('en-US'),
        ending_date: endDate && endDate.toLocaleDateString('en-US')
      }
    })
    .then((response)=>{
      console.log("getUserListTable Response : ",response)
      setUserMappingTable(response.data)
    })
    .catch((error)=>{
      console.log("getUserListTable Error : ", error)
    })
  };

  useEffect(() => {
    getUserMappingTable()
  }, [pageUserMapping,sortColumnUserMapping,sortDirectionUserMapping,countPageUserMapping]);

  const initilindex = { index: 0, isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const onMovePrev = () => {
    const prev = (photoIndex.index + images.length - 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: prev });
  };
  const onMoveNext = () => {
    const next = (photoIndex.index + 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: next });
  };

  return (
    <Fragment>
      <Breadcrumb parent="User" title="User Mapping" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {userPermission.includes("[read] user mapping") ? (
              <Card className="mb-0">
              <CardBody>
              <Form className="needs-validation" onSubmit={(event)=>{
                    event.preventDefault();
                    getUserMappingTable()
                  }}>
                    <div className="form-row">
                      <Col md="12 mb-3">
                        <Input
                          className="form-control"
                          name="keyword"
                          type="text"
                          placeholder="Keyword"
                          onChange={(event)=>onSearch(event)}
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      {/* Filter Registration Date */}
                      <Col md="7 mb-3">
                        <Row>
                          <Col md="5 mb-3" sm="6" xs="6">
                            <Label htmlFor="validationCustom03">
                              {"Starting Date"}
                            </Label>
                            {/* <Input className="form-control" name="city" type="text" placeholder="City" innerRef={register({ required: true })} /> */}
                            <DatePicker
                              className="datepicker-here form-control mt-2"
                              selected={startDate}
                              onChange={handleStartDate}
                            />
                          </Col>
                          <Col md="5 mb-3" sm="6" xs="6">
                            <Label htmlFor="validationCustom03">
                              {"Ending Date"}
                            </Label>
                            <DatePicker
                              className="datepicker-here form-control mt-2"
                              selected={endDate}
                              onChange={handleEndDate}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {/* Filter */}
                      <Col md="5 mb-3">
                        <Label htmlFor="validationCustom03">{"Filter"}</Label>
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <Row>
                              <div className="checkbox checkbox-dark">
                                <Input id="Basic" name="basic" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    basic: "Basic"
                                  }): setFilterTable({
                                    ...filterTable,
                                    basic:''
                                  })}
                                } />
                                {console.log("filter table",filterTable)}
                                <Label for="Basic">{"Basic"}</Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-suspend" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    suspend: "Suspend"
                                  }): setFilterTable({
                                    ...filterTable,
                                    suspend:''
                                  })}
                                }/>
                                <Label for="filter1-suspend">{"suspend"}</Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-sponsor" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    sponsor: "Sponsor"
                                  }): setFilterTable({
                                    ...filterTable,
                                    sponsor:''
                                  })}
                                }/>
                                <Label for="filter1-sponsor">{"sponsor"}</Label>
                              </div>
                            </Row>
                            <Row>
                              <div className="checkbox checkbox-dark">
                                <Input
                                  id="filter1-full-service"
                                  type="checkbox"
                                  onChange={
                                    (event)=>{ event.target.checked ? setFilterTable({
                                      ...filterTable,
                                      full_service: "Full Service"
                                    }): setFilterTable({
                                      ...filterTable,
                                      full_service:''
                                    })}
                                  }
                                />
                                <Label for="filter1-full-service">
                                  {"Full Service"}
                                </Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-control" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    anomali_control: "Anomali Control"
                                  }): setFilterTable({
                                    ...filterTable,
                                    anomali_control:''
                                  })}
                                }/>
                                <Label for="filter1-control">
                                  {"Anomali Control"}
                                </Label>
                              </div>
                            </Row>
                          </FormGroup>
                      </Col>
                    </div>
                    {/* Button For Action Form */}
                    <Row className="justify-content-between">
                      <Col md="5">
                        <Row className="justify-content-around">
                          <Button color="secondary" className="mb-4">{"Search"}</Button>
                          <Button
                            color="primary"
                            className="sweet-8 mb-4"
                            name="alertSuccess"
                            onClick={Displayalert}
                          >
                            {"Download"}
                          </Button>
                        </Row>
                      </Col>
                      <Col md="3">
                        {userPermission.includes("[create] user mapping") || userPermission.includes("[update] user mapping") ? (
                          <Button
                          color="primary"
                          className="sweet-8"
                          name="alertSuccess"
                          onClick={DisplayMessageForm}
                        >
                          {"Send Message"}
                        </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>

                  <DataTable
                    columns={columnsUserMapping}
                    data={userMappingTable.data}
                    selectableRows={true}
                    pagination={true}
                    paginationServer
                    paginationTotalRows={userMappingTable.total}
                    onChangePage={(pageUserMapping) =>
                      setPageUserMapping(pageUserMapping)
                    }
                    onChangeRowsPerPage={(countPageUserMapping) =>
                      setCountPageUserMapping(
                        countPageUserMapping
                      )
                    }
                    onSort={handleSortUserMapping}
                    paginationPerPage={countPageUserMapping}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  />

                  {/* Modal For Message All Or Selected User */}
                  <Modal
                    isOpen={MessageForm}
                    toggle={() => DisplayMessageForm()}
                    centered
                  >
                    <ModalHeader toggle={() => DisplayMessageForm()}>
                      {"Send Message"}
                    </ModalHeader>
                    <ModalBody>
                      <Form>
                        <Row>
                          <Col xl="12" md="12">
                            <FormGroup>
                              <Row className="justify-content-around mb-5">
                                <Col xl="2" md="2">
                                  <Label>{"Channel"}</Label>
                                </Col>
                                <Col xl="8" md="8">
                                  <Input
                                    id="select-channel"
                                    type="select"
                                    name="select-channel"
                                    className="form-control digits"
                                  >
                                    <option>{"Pilih Channel"}</option>
                                    <option>{"WhatsApp"}</option>
                                    <option>{"Mobile Apps"}</option>
                                    <option>{"Telegram"}</option>
                                  </Input>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row className="justify-content-around mb-5">
                                <Col xl="2" md="2">
                                  <Label>{"Message Content"}</Label>
                                </Col>
                                <Col xl="8" md="8">
                                  <Input
                                    type="textarea"
                                    name="message-text-area"
                                    id="message-text-area"
                                    rows="10"
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row className="justify-content-around mb-5">
                                <Col xl="2" md="2">
                                  <Label>{"All User Result"}</Label>
                                </Col>
                                <Col xl="8" md="8">
                                <Input type="checkbox" id="checkbox-all-message" className="ml-1"/>
                                </Col>
                              </Row>
                            </FormGroup>
                            <Row className="justify-content-center">
                              <Col xl="3" md="3">
                                <Button
                                  color="secondary"
                                  type="reset"
                                  className="mr-1"
                                  onClick={() => DisplayMessageForm()}
                                >
                                  {"Cancel"}
                                </Button>
                              </Col>
                              <Col xl="3" md="3">
                                <Button color="primary" >
                                  {"Send"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                  {/* Modal For Message By User ID */}
                  <Modal
                    isOpen={MessageFormUserID}
                    toggle={() => DisplayMessageFormUserID()}
                    centered
                  >
                    <ModalHeader toggle={() => DisplayMessageFormUserID()}>
                      {"Send Message For (Nama User/User ID)"}
                    </ModalHeader>
                    <ModalBody>
                      <Form>
                        <Row>
                          <Col xl="12" md="12">
                            <FormGroup>
                              <Row className="justify-content-around mb-5">
                                <Col xl="2" md="2">
                                  <Label>{"Channel"}</Label>
                                </Col>
                                <Col xl="8" md="8">
                                  <Input
                                    id="select-channel"
                                    type="select"
                                    name="select-channel"
                                    className="form-control digits"
                                  >
                                    <option>{"Pilih Channel"}</option>
                                    <option>{"WhatsApp"}</option>
                                    <option>{"Mobile Apps"}</option>
                                    <option>{"Telegram"}</option>
                                  </Input>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row className="justify-content-around mb-5">
                                <Col xl="2" md="2">
                                  <Label>{"Message Content"}</Label>
                                </Col>
                                <Col xl="8" md="8">
                                  <Input
                                    type="textarea"
                                    name="message-text-area"
                                    id="message-text-area"
                                    rows="10"
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <Row className="justify-content-center">
                              <Col xl="3" md="3">
                                <Button
                                  color="secondary"
                                  type="reset"
                                  className="mr-1"
                                  onClick={() => DisplayMessageFormUserID()}
                                >
                                  {"Cancel"}
                                </Button>
                              </Col>
                              <Col xl="3" md="3">
                                <Button color="primary" >
                                  {"Send"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
              </CardBody>
            </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserMapping;
