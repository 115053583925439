import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
// import {useState} from 'react'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
} from "../../actionTypes";

export const registerRequest = (value) => {
  return {
    type: REGISTER_REQUEST,
    payload: value,
  };
};

export const registerSuccess = (value) => {
  return {
    type: REGISTER_SUCCESS,
    payload: value,
  };
};

export const registerFailed = (value) => {
  return {
    type: REGISTER_FAILED,
    error: value,
  };
};

export const loginRequest = (value) => {
  return {
    type: LOGIN_REQUEST,
    payload: value,
  };
};

export const loginSuccess = (value) => {
  return {
    type: LOGIN_SUCCESS,
    payload: value,
  };
};

export const loginFailed = (value) => {
  return {
    type: LOGIN_FAILED,
    error: value,
  };
};

export const editUserRequest = (value) => {
  return {
    type: EDIT_USER_REQUEST,
    payload: value,
  };
};

export const editUserSuccess = (value) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: value,
  };
};

export const editUserFailed = (value) => {
  return {
    type: EDIT_USER_FAILED,
    error: value,
  };
};

export const registerActions = (value,event) => (dispatch) => {
  event.preventDefault();
  console.log("register value", value);

  const uriUsers = "privilege/store-user";

  return axios
    .post(uriUsers, value)
    .then((response) => {
      console.log("response register: ", response);
      // DisplayAlertSuccessRegister()
      toast.success("Success Add Account !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(registerSuccess(response));
    })
    .catch((error) => {
      dispatch(registerFailed(error.response));
      console.log("response error", error.response);
      if (error.response.data.errors.email[0] === "The email has already been taken.") {
        toast.warn(`${error.response.data.errors.email[0]}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT
          })
      }
    });
};




export const loginActions = (value, event, history) => (dispatch) => {
  event.preventDefault();

  const uriLogin = `/login-admin`;
  // console.log("data env :", uriLogin)

  return axios
    .post(uriLogin, value)
    .then((response) => {
      // console.log(response)
      if (response.data.token !== undefined) {
        dispatch(loginRequest());
        dispatch(loginSuccess(response.data));
        history.push(`${process.env.PUBLIC_URL}/dashboard`);
      }
    })
    .catch((error) => {
      SweetAlert.fire({
        title: "Login Failed",
        text: " Wrong Email Or Password",
        icon: "error",
      });
      console.log("data error", error);
    });
};

export const editUserActions = (id,value, event) => (dispatch) => {
  event.preventDefault();
  console.log("edit user value", value);

  const uriUsers = `privilege/update-user/${id}`;

  return axios
    .post(uriUsers, value)
    .then((response) => {
      console.log("response edit user: ", response);
      // DisplayAlertSuccessRegister()
      toast.success("Success Edit Account !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(editUserSuccess(response));
    })
    .catch((error) => {
      toast.error("Failed Edit Account !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(editUserFailed(error.response));

    });
};