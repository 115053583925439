import axios from 'axios'
import {
    GET_LIST_CATEGORY_PRODUCT_SORT_REQUEST,
    GET_LIST_CATEGORY_PRODUCT_SORT_SUCCESS,
    GET_LIST_CATEGORY_PRODUCT_SORT_FAILED,

    GET_LIST_PROVIDER_PRODUCT_SORT_REQUEST,
    GET_LIST_PROVIDER_PRODUCT_SORT_SUCCESS,
    GET_LIST_PROVIDER_PRODUCT_SORT_FAILED,
} from '../../actionTypes'

export const listCategoryProductSortRequest = () =>{
    return {
        type: GET_LIST_CATEGORY_PRODUCT_SORT_REQUEST
    }
}

export const listCategoryProductSortSuccess = (value) => {
    return {
        type:GET_LIST_CATEGORY_PRODUCT_SORT_SUCCESS,
        payload: value
    }
}

export const listCategoryProductSortFailed = (value) => {
    return {
        type: GET_LIST_CATEGORY_PRODUCT_SORT_FAILED,
        error: value
    }
}

// fetching data list category
export const getListCategoryProductSort = () => (dispatch) => {
    const URI = `product-sort/category`

    axios
    .get(URI)
    .then(res => {
        dispatch(listCategoryProductSortSuccess(res.data))
        console.log("getListCategoryProductSort response: ", res.data)
    })
    .catch(err => {
        // dispatch(listCategoryProductSortFailed(err))
        console.log("getListCategoryProductSort error: ", err)
    })
}


// LIST PROVIDER ACTION
export const listProviderProductSortRequest = () =>{
    return {
        type: GET_LIST_PROVIDER_PRODUCT_SORT_REQUEST
    }
}

export const listProviderProductSortSuccess = (value) => {
    return {
        type:GET_LIST_PROVIDER_PRODUCT_SORT_SUCCESS,
        payload: value
    }
}

export const listProviderProductSortFailed = (value) => {
    return {
        type: GET_LIST_PROVIDER_PRODUCT_SORT_FAILED,
        error: value
    }
}
// fetching data list Provider
export const getListProviderProductSort = () => (dispatch) => {
    const URI = `product-sort/provider`

    axios
    .get(URI)
    .then(res => {
        dispatch(listProviderProductSortSuccess(res.data))
        console.log("getListProviderProductSort response: ", res.data)
    })
    .catch(err => {
        // dispatch(listProviderProductSortFailed(err))
        console.log("getListProviderProductSort error: ", err)
    })
}