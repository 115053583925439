import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux"
import Breadcrumb from "../../../layout/breadcrumb";
import axios from "axios";
import moment from 'moment';
import "../../../assets/scss/custom/manage-user-custom.scss";
// import { dataSuspend } from "../../../data/dummyTableData";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  Tooltip
} from "reactstrap";
import fileDownload from "js-file-download";

const UserList = (props) => {

  const columnsUserList = [
    {
      name: "Status",
      sortable: true,
      center: true,
      cell:(row)=>(
        row.status === 'PENDING' ? <Badge color="warning">Pending</Badge> : ( row.status === 'ACTIVE' ? <Badge color="success">Active</Badge> : <Badge color="danger">Suspend</Badge> )
      )
    },
    {
      name: "Control",
      selector: "control",
      sortable: true,
      center: true,
      width:'150px'
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Sponsor",
      selector: "sponsor",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      width: "350px",
    },
    {
      name: "Saldo",
      selector: "saldo",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Commission",
      selector: "commission",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Point",
      selector: "point",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Register Date",
      selector: "register_date",
      sortable: true,
      center: true,
      width: "300px",
    },
    {
      name: "Action",
      center: true,
      width: "200px",
      cell: (row) => (
        <div>
          {userPermission.includes("[update] user list") ? (
            <Button
            color="primary"
            className="btn-xs"
            id="buttonToUserDetail"
            onClick={() =>
              history.push(`${process.env.PUBLIC_URL}/user/user-detail/${row.id}`)
            }
          >
            <i class="fa fa-user"></i>
          </Button>
          ): null}
        </div>
      ),
    },
  ];

  const userPermission = useSelector(state => state.userReducer.data.user.permissions)
  // console.log("userPermission: ", userPermission)

  const found = userPermission.includes("[read] user list")
  // console.log("found: ", found)

  const history = useHistory();
  const [keyword, setKeyword] = useState('')
  const [images, setImage] = useState([]);
  const [smallImages, setsmallImages] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const { register, handleSubmit, errors } = useForm();
  const [MessageFormUserID, setMessageFormUserID] = useState(false);
  const [editUserList, setEditUserList] = useState(false);
  const [editSuspend, setEditSuspend] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // STATE FOR ADD NEW USER
  const [addNewUser, setAddNewUser]=useState(false);
  const [addNewUserValue, setAddNewUserValue]=useState({
    name:"",
    shop_name:"",
    whatsapp_number:"",
    email:"",
    refferal_code:"",
  })
  // console.log("addNewUserValue: ", addNewUserValue)
  const [addNewUserResponse, setAddNewUserResponse]=useState(false)


  const showAddUser = () => setAddNewUser(!addNewUser)
  

  // state filter table
  const [filterTable, setFilterTable] = useState({
    basic: '',
    suspend:'',
    full_service:'',
    sponsor:'',
    anomali_control:'',
  })

  const [sweetAlert, setSweetAlert] = useState(false);
  //   const bookmarklist = useSelector(content => content.Bookmarkapp.bookmark);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  // moment().format('YYYY-MM-dd')
    

  // ACTION HANDLER
  const handleSortUserList = (column, sortDirection) => {
    setSortColumnUserList(column.selector);
    setSortDirectionUserList(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};

  // STATE DATA TABLE
  const [userListTable, setUserListTable] = useState({})
  const [pageUserList, setPageUserList] = useState(1);
  const [sortColumnUserList,setSortColumnUserList] = useState("");
  const [sortDirectionUserList,setSortDirectionUserList] = useState("");
  const [countPageUserList,setCountPageUserList] = useState(5);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onSearch = (event) => {
    // event.prevenDefault();
    setKeyword(event.target.value)
  }

  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    const URI = `user-list/download`;

    if (startDate == undefined || endDate == undefined) {
      setSweetAlert(true);
      SweetAlert.fire({
        title: "Download Failed",
        text: "Tanggal Awal dan Akhir harus diisi",
        icon: "error",
      });
      return false;
    }

    return axios
      .get(URI, {
        params: {
          keyword: keyword,
          ...filterTable,
          starting_date: startDate && startDate.toLocaleDateString('en-US'),
          ending_date: endDate && endDate.toLocaleDateString('en-US')
          },
        responseType: 'blob'
      })
      .then((response) => {
        fileDownload(response.data, 'report user list.xlsx')
        setSweetAlert(true);
        SweetAlert.fire({
          title: "Download Success",
          text: "",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Display Modal Form
  const DisplayEditUserList = () => setEditUserList(!editUserList);

  //Fetch Datatable
  const getUserListTable = () =>{
  
    axios.get('/user-list/data', {
      params: {
        page: pageUserList,
        per_page: countPageUserList,
        sort_column: sortColumnUserList,
        sort_direction: sortDirectionUserList,
        keyword: keyword,
        ...filterTable,
        starting_date: startDate && startDate.toLocaleDateString('en-US'),
        ending_date: endDate && endDate.toLocaleDateString('en-US')
      }
    })
    .then((response)=>{
      // console.log("getUserListTable Response : ",response)
      setUserListTable(response.data)
    })
    .catch((error)=>{
      // console.log("getUserListTable Error : ", error)
    })
  };

  // AXIOS FOR ADD NEW USER
  const registerNewUser = () => {
    const URI = `/user-list/register`

    axios
    .post(URI, addNewUserValue)
    .then(response => {
      console.log("registerNewUser: ", response)
      toast.success("Success Register New User !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setAddNewUser(!addNewUser)
      setAddNewUserResponse(!addNewUserResponse)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log(error);
    })
  }


  useEffect(() => {
    getUserListTable()
  }, [
    pageUserList,
    sortColumnUserList,
    sortDirectionUserList,
    countPageUserList,
    addNewUserResponse,
  ]);

  return (
    <Fragment>
      <Breadcrumb parent="User" title="User List" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Row>
              <Col xl="12">
                {userPermission.includes("[read] user list") ? (
                  <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate="" onSubmit={(event)=>{
                      event.preventDefault()
                      getUserListTable()
                    }}>
                      <div className="form-row">
                        <Col md="12 mb-3">
                          <Input
                            className="form-control"
                            name="keyword"
                            type="text"
                            placeholder="Keyword"
                            onChange={(event)=>onSearch(event)}
                          />
                        </Col>
                      </div>
                      <div className="form-row">
                        {/* Filter Registration Date */}
                        <Col md="7 mb-3">
                          <Row>
                            <Col md="5 mb-3" sm="6" xs="6">
                              <Label htmlFor="validationCustom01">
                                {"Starting Date"}
                              </Label>
                              <DatePicker
                                className="datepicker-here form-control mt-2"
                                dateFormat='yyyy-MM-dd'
                                selected={startDate}
                                onChange={handleStartDate}
                                />
                            </Col>
                            <Col md="5 mb-3" sm="6" xs="6">
                              <Label htmlFor="validationCustom03">
                                {"Ending Date"}
                              </Label>
                              <DatePicker
                                className="datepicker-here form-control mt-2"
                                dateFormat='yyyy-MM-dd'
                                selected={endDate}
                                onChange={handleEndDate}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {/* Filter */}
                        <Col md="5 mb-3" sm="12" xs="12">
                          <Label htmlFor="validationCustom03">{"Filter"}</Label>
                          <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <Row>
                              <div className="checkbox checkbox-dark">
                                <Input id="Basic" name="basic" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    basic: "Basic"
                                  }): setFilterTable({
                                    ...filterTable,
                                    basic:''
                                  })}
                                } />
                                {/* {console.log("filter table",filterTable)} */}
                                <Label for="Basic">{"Basic"}</Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-suspend" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    suspend: "Suspend"
                                  }): setFilterTable({
                                    ...filterTable,
                                    suspend:''
                                  })}
                                }/>
                                <Label for="filter1-suspend">{"suspend"}</Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-sponsor" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    sponsor: "Sponsor"
                                  }): setFilterTable({
                                    ...filterTable,
                                    sponsor:''
                                  })}
                                }/>
                                <Label for="filter1-sponsor">{"sponsor"}</Label>
                              </div>
                            </Row>
                            <Row>
                              <div className="checkbox checkbox-dark">
                                <Input
                                  id="filter1-full-service"
                                  type="checkbox"
                                  onChange={
                                    (event)=>{ event.target.checked ? setFilterTable({
                                      ...filterTable,
                                      full_service: "Full Service"
                                    }): setFilterTable({
                                      ...filterTable,
                                      full_service:''
                                    })}
                                  }
                                />
                                <Label for="filter1-full-service">
                                  {"Full Service"}
                                </Label>
                              </div>
                              <div className="checkbox checkbox-dark">
                                <Input id="filter1-control" type="checkbox" onChange={
                                  (event)=>{ event.target.checked ? setFilterTable({
                                    ...filterTable,
                                    anomali_control: "Anomali Control"
                                  }): setFilterTable({
                                    ...filterTable,
                                    anomali_control:''
                                  })}
                                }/>
                                <Label for="filter1-control">
                                  {"Anomali Control"}
                                </Label>
                              </div>
                            </Row>
                          </FormGroup>
                        </Col>
                      </div>
                      {/* Button For Action Form */}
                      <Row className="justify-content-between">
                       <Col md="5">
                         <Row className="justify-content-around">
                       <Button color="secondary" type="submit" className="mb-3">{"Search"}</Button>
                        <Button
                          color="primary"
                          className="sweet-8 mb-3"
                          name="alertSuccess"
                          onClick={Displayalert}
                        >
                          {"Download"}
                        </Button>
                        </Row>
                       </Col>
                       <Col md="3">
                         {userPermission.includes("[create] user list") ? (
                           <Button
                           color="primary"
                           className="sweet-8"
                           name="alertSuccess"
                           onClick={()=>{
                             showAddUser()
                           }}
                         >
                           {"Register User"}
                         </Button>
                         ) : null}
                       </Col>
                      </Row>
                    </Form>
                    <DataTable
                      columns={columnsUserList}
                      data={userListTable.data}
                      pagination={true}
                      paginationServer
                      paginationTotalRows={userListTable.total}
                      onChangePage={(pageUserList) =>
                        setPageUserList(pageUserList)
                      }
                      onChangeRowsPerPage={(countPageUserList) =>
                        setCountPageUserList(
                          countPageUserList
                        )
                      }
                      onSort={handleSortUserList}
                      paginationPerPage={countPageUserList}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    />
                  </CardBody>
                </Card>
                ) : (null)}
              </Col>
            </Row>

            {/* Modal Add New User */}
            <Modal
              isOpen={addNewUser}
              toggle={() => showAddUser()}
              centered
            >
              <ModalHeader toggle={() => showAddUser()}>
                {"Register New User"}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl="12">
                    <FormGroup>
                      <Row>
                        <Col xl="4">
                          <p>Nama Lengkap</p>
                        </Col>
                        <Col xl="8">
                          <Input 
                          type="text" 
                          placeholder="Sesuai KTP"
                          onChange={(event)=>{
                            setAddNewUserValue({
                              ...addNewUserValue,
                              name: event.target.value
                            })
                          }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="4">
                          <p>Nama Toko</p>
                        </Col>
                        <Col xl="8">
                          <Input 
                          type="text" 
                          // placeholder="Masukan Nama Toko Jika Ada"
                          onChange={(event)=>{
                            setAddNewUserValue({
                              ...addNewUserValue,
                              shop_name: event.target.value
                            })
                          }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="4">
                          <p>Nomer WhatsApp</p>
                        </Col>
                        <Col xl="8">
                          <Input 
                          type="text" 
                          // placeholder="masukan nomer whatsApp"
                          onChange={(event)=>{
                            setAddNewUserValue({
                              ...addNewUserValue,
                              whatsapp_number: event.target.value
                            })
                          }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="4">
                          <p>Email</p>
                        </Col>
                        <Col xl="8">
                          <Input 
                          type="email" 
                          placeholder="Email aktif"
                          onChange={(event)=>{
                            setAddNewUserValue({
                              ...addNewUserValue,
                              email: event.target.value
                            })
                          }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="4">
                          <p>Kode Referal</p>
                        </Col>
                        <Col xl="8">
                          <Input 
                          type="text" 
                          // placeholder="Masukan Kode Referal"
                          onChange={(event)=>{
                            setAddNewUserValue({
                              ...addNewUserValue,
                              refferal_code: event.target.value
                            })
                          }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row className="justify-content-center mt-5">
                      <Col xl="3" md="3">
                        <Button
                          color="secondary"
                          type="reset"
                          className="mr-1"
                          onClick={() => showAddUser()}
                        >
                          {"Cancel"}
                        </Button>
                      </Col>
                      <Col xl="3" md="3">
                        <Button color="primary" onClick={()=>registerNewUser()}>
                          {"Confirm"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            {/* Modal Edit User List */}
            <Modal
              isOpen={editUserList}
              toggle={() => DisplayEditUserList()}
              centered
            >
              <ModalHeader toggle={() => DisplayEditUserList()}>
                {"Edit User List"}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl="12">
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>User ID</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="VP0283784" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Sponsor</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="VP0843784" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Full Name</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="Agung Cahyadi" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Phone Number</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="083947237" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Email</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="cahyadi@gmail.com" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Saldo</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="Rp. 100,503" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Commission</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="Rp. 100,000" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Point</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="130" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Control</p>
                        </Col>
                        <Col xl="6">
                          <Input type="text" defaultValue="0" />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Status</p>
                        </Col>
                        <Col xl="6">
                          <Input type="select">
                            <option>Active</option>
                            <option>Non Active</option>
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Type</p>
                        </Col>
                        <Col xl="6">
                          <Input type="select">
                            <option>All</option>
                            <option>Full Service</option>
                            <option>Basic</option>
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xl="6">
                          <p>Registration Date</p>
                        </Col>
                        <Col xl="6">
                          <DatePicker
                            className="datepicker-here form-control"
                            selected={startDate}
                            // onChange={handleStartDate}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row className="justify-content-center">
                      <Col xl="3" md="3">
                        <Button
                          color="secondary"
                          type="reset"
                          className="mr-1"
                          onClick={() => DisplayEditUserList()}
                        >
                          {"Cancel"}
                        </Button>
                      </Col>
                      <Col xl="3" md="3">
                        <Button color="primary">
                          {"Save"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserList;
