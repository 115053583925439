import axios from 'axios'
import { 
    GET_LIST_CHANNEL_REQUEST,
    GET_LIST_CHANNEL_SUCCESS,
    GET_LIST_CHANNEL_FAILED
 } from '../../actionTypes'

 export const listChannelRequest = () => {
     return {
         type : GET_LIST_CHANNEL_REQUEST
     }
 }

 export const listChannelSuccess = (value) =>{
     return {
         type : GET_LIST_CHANNEL_SUCCESS,
         payload: value
     }
 }

 export const listChannelFailed = (value) => {
     return {
         type : GET_LIST_CHANNEL_FAILED,
         error: value
     }
 }

 export const getListChannel = () => (dispatch) => {

    const URI = `transfer/channel`

    axios
    .get(URI)
    .then(res => {
        console.log('getListChannel response :', res);
        dispatch(listChannelSuccess(res.data))
    })
    .catch(err => console.log('getListChannel error: ', err))
 }