import {
    GET_TIMELINE_REQUEST,
    GET_TIMELINE_SUCCESS,
    GET_TIMELINE_FAILED
} from '../../actionTypes'

const initialState = {
    data:[],
    isLoading:false,
    error:''
}

const timeLineReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIMELINE_REQUEST:
            return {
                ...state,
                isLoading:true,
            }
        case GET_TIMELINE_SUCCESS:
            return {
                ...state,
                isLoading:false,
                data: action.payload
            }
        case GET_TIMELINE_FAILED:
            return {
                ...state,
                isLoading:false,
                error: action.error
            }
    
        default:
            return state;
    }
};

export default timeLineReducer;