import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useForm } from "react-hook-form";
import {
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { Grid, List } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";

const Partner = (props) => {

  // Columns For data table
  const columnsPartnerPascabayar = [
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
      width:'150px'
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Admin Charge",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <span>{`Rp.${(row.admin_charge).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Cash Back",
      sortable: true,
      center: true,
      width: "150px",
      cell:(row)=>(
        <span>{`Rp.${(row.cash_back).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Action",
      selector: "action",
      center: true,
      cell: (row)=>(
        <div>
          <Button
          color="secondary"
          className="btn-xs">
            <i class="fa fa-history"></i>
          </Button>
          {userPermission.includes("[update] partner") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditProductPascabayarId(row.id)
              setEditProductPascabayarCode(row.code)
              setEditProductPascabayarName(row.product_name)
              setEditProductPascabayarAdmin(row.admin_charge)
              setEditProductPascabayarCashback(row.cash_back)
              showEditProductPPOB()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] partner") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDeleteProductPascabayar(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  const columnsPartnerPrabayar = [
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
      center: true,
      width: "300px",
    },
    {
      name: "Price",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp.${(row.price).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      cell:(row)=>(
        <div>
           <Button
          color="secondary"
          className="btn-xs"
          onClick={()=>{
            getHistoryProductPrabayar(row.id)
          }}
          >
            <i class="fa fa-history"></i>
          </Button>
          {userPermission.includes("[update] partner") ? (
            <Button
            color="primary"
            className="btn-xs ml-2"
            onClick={() => {
              setEditProductPrabayarId(row.id)
              setEditProductPrabayarCode(row.code)
              setEditProductPrabayarName(row.product_name)
              setEditProductPrabayarPrice(row.price)
              showEditProductNonPPOB()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] partner") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDeleteProductPrabayar(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  const columnsHistoryProductPrabayar = [
    {
      name: "Product Code",
      selector: "product_code",
      sortable: true,
      width:"170px",
      center:true
    },
    {
      name: "Update Date",
      selector: "update_date",
      sortable: true,
      center: true,
      width: "250px",

    },
    {
      name: "Update By",
      selector: "update_by",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name:"Column Name",
      center: true,
      width:"150px",
      cell: (row) => (
        <ul>
          {row.column.map((item,index)=>(
            <div key={index}>
              <br/>
              <li>{`${item.toUpperCase()}  :`}</li>
            </div>
          ))}
        </ul>
      )
    },
    {
      name:"Before",
      center: true,
      width:"150px",
      cell: (row) => (
        <ul>
          {row.before.map((item,index)=>(
            <div key={index}>
              <br/>
              <li>{item}</li>
            </div>
          ))}
        </ul>
      )
    },
    {
      name:"After",
      center: true,
      width:"150px",
      cell: (row) => (
        <ul>
          {row.after.map((item,index)=>(
            <div key={index}>
              <br/>
              <li>{item}</li>
            </div>
          ))}
        </ul>
      )
    },
  ]

  // const dataHistoryProductPrabayar = [
  //   {
  //     product_code: 'T10',
  //     updated_date: '2021-05-27 10:57:02',
  //     update_by: 'SYSTEM',
  //     column: ['CODE','NAME','PRICE'],
  //     before: ['T5','Telkomsel 5rb','5000'],
  //     after: ['T10','Telkomsel 10rb','10000']
  //   },
  //   {
  //     product_code: 'T25',
  //     updated_date: '2022-05-27 10:57:02',
  //     update_by: 'SYSTEM',
  //     column: ['CODE','NAME','PRICE'],
  //     before: ['T5','Telkomsel 5rb','5000'],
  //     after: ['T25','Telkomsel 25rb','25000']
  //   }
  // ]

  // STATE USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  // REGISTER PARTNER
  const { register, handleSubmit, errors } = useForm();

  // ADD PRODUCT PARTNER
  const [addProductPPOB, setAddProductPPOB] = useState(false);
  const [addProductNonPPOB, setAddProductNonPPOB] = useState(false);
  const [addPartner, setAddPartner] = useState(false);

  // EDIT PRODUCT PARTNER
  const [editProductPPOB, setEditProductPPOB] = useState(false);
  const [editProductNonPPOB, setEditProductNonPPOB] = useState(false);
  const [alertDeleteSuccess, setAlertDeleteSuccess] = useState(false);

  // HISTORY PRODUCT PARTNER
  const [modalHistoryProductPrabayar, setModalHistoryProductPrabayar]=useState(false);
  const [dataHistoryProductPrabayar, setDataHistoryProductPrabayar]=useState()

  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line

  const [sidebaron, setSidebaron] = useState(true);
  const [activeTabPartner, setActiveTabPartner] = useState("1");
  const [activeTabProduct, setActiveTabProduct] = useState("1");
  // eslint-disable-next-line
  const [filterSidebar, setFilterSidebar] = useState(true);

  // Function show modal
  const showAddProductPPOB = () => setAddProductPPOB(!addProductPPOB);
  const showAddProductNonPPOB = () => setAddProductNonPPOB(!addProductNonPPOB);
  const showAddPartner = () => setAddPartner(!addPartner);

  const showEditProductPPOB = () => setEditProductPPOB(!editProductPPOB);
  const showEditProductNonPPOB = () => setEditProductNonPPOB(!editProductNonPPOB);
  const showHistoryProductPrabayar = () => setModalHistoryProductPrabayar(!modalHistoryProductPrabayar);

  // ALERT DELETE PRODUCT PRA-BAYAR
  const DisplayAlertDeleteProductPrabayar = (id) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductPrabayar(id)
        
      }
    })
  };

  // ALERT DELETE PRODUCT PASCA-BAYAR
  const DisplayAlertDeleteProductPascabayar = (id) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductPascabayar(id)
        
      }
    })
  };


  // TOGGLE WRAPPER FOR PARTNER LIST
  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.querySelector(".product-wrapper").classList.add("sidebaron");
    } else {
      setSidebaron(true);
      document.querySelector(".product-wrapper").classList.remove("sidebaron");
    }
  };


  // STATE DATA TABLE PRA-BAYAR
  const [partnerPrabayarTable, setPartnerPrabayarTable] = useState({});
  const [pagePartnerPrabayar, setPagePartnerPrabayar] = useState(1);
  const [sortColumnPartnerPrabayar,setSortColumnPartnerPrabayar] = useState("");
  const [sortDirectionPartnerPrabayar,setSortDirectionPartnerPrabayar] = useState("");
  const [countPagePartnerPrabayar,setCountPagePartnerPrabayar] = useState(5);
  const [keywordPartnerPrabayar, setKeywordPartnerPrabayar] = useState("");
  


  // STATE DATA TABLE PASCA-BAYAR
  const [partnerPascabayarTable, setPartnerPascabayarTable] = useState({});
  const [pagePartnerPascabayar, setPagePartnerPascabayar] = useState(1);
  const [sortColumnPartnerPascabayar,setSortColumnPartnerPascabayar] = useState("");
  const [sortDirectionPartnerPascabayar,setSortDirectionPartnerPascabayar] = useState("");
  const [countPagePartnerPascabayar,setCountPagePartnerPascabayar] = useState(5);
  const [keywordPartnerPascabayar, setKeywordPartnerPascabayar] = useState("");



  // STATE FOR LIST PARTNER
  const [listPartner, setListPartner]=useState()
  const [partnerId, setPartnerId]=useState(1)

  // STATE FOR ADD PARTNER
  const [addPartnerName, setAddPartnerName]=useState()
  const [addPartnerCode, setAddPartnerCode]=useState()
  const [addPartnerTransactionType, setAddPartnerTransactionType]=useState()
  console.log("Transaction type :", addPartnerTransactionType)
  const [addPartnerRepeat, setAddPartnerRepeat]=useState()
  const [addPartnerStatus, setAddPartnerStatus]=useState()
  const [addPartnerResponseStatus, setAddPartnerResponseStatus]=useState(false)

  // STATE FOR EDIT PARTNER
  const [editPartnerName, setEditPartnerName]=useState()
  const [editPartnerCode, setEditPartnerCode]=useState()
  const [editPartnerTransactionType, setEditPartnerTransactionType]=useState()
  // console.log("editPartnerTransactionType :", editPartnerTransactionType)
  const [editPartnerRepeat, setEditPartnerRepeat]=useState()
  const [editPartnerStatus, setEditPartnerStatus]=useState()
  const [editPartnerResponse, setEditPartnerResponse]=useState(false)

  
  // STATE FOR ADD PRODUCT PARTNER PRA-BAYAR
  const [addProductPrabayarCode, setAddProductPrabayarCode]=useState()
  const [addProductPrabayarName, setAddProductPrabayarName]=useState()
  const [addProductPrabayarPrice, setAddProductPrabayarPrice]=useState()
  const [addProductPrabayarResponseStatus,setAddProductPrabayarResponseStatus]=useState(false)

  // STATE FOR ADD PRODUCT PARTNER PASCA-BAYAR
  const [addProductPascabayarCode, setAddProductPascabayarCode]=useState()
  const [addProductPascabayarName, setAddProductPascabayarName]=useState()
  const [addProductPascabayarAdmin, setAddProductPascabayarAdmin]=useState()
  const [addProductPascabayarCashback, setAddProductPascabayarCashback]=useState()
  const [addProductPascabayarResponseStatus,setAddProductPascabayarResponseStatus]=useState(false)


  // STATE FOR EDIT PRODUCT PARTNER PRA-BAYAR
  const [editProductPrabayarId, setEditProductPrabayarId]=useState()
  const [editProductPrabayarCode, setEditProductPrabayarCode]=useState()
  const [editProductPrabayarName, setEditProductPrabayarName]=useState()
  const [editProductPrabayarPrice, setEditProductPrabayarPrice]=useState()
  const [editProductPrabayarResponseStatus, setEditProductPrabayarResponseStatus]=useState(false)

  // STATE FOR EDIT PRODUCT PARTNER PASCA-BAYAR
  const [editProductPascabayarId, setEditProductPascabayarId]=useState()
  const [editProductPascabayarCode, setEditProductPascabayarCode]=useState()
  const [editProductPascabayarName, setEditProductPascabayarName]=useState()
  const [editProductPascabayarAdmin, setEditProductPascabayarAdmin]=useState()
  const [editProductPascabayarCashback, setEditProductPascabayarCashback]=useState()
  const [editProductPascabayarResponseStatus, setEditProductPascabayarResponseStatus]=useState(false)


  // STATE FOR DELETE PRODUCT PARTNER PRA-BAYAR 
  const [deleteProductPrabayarStatus, setDeleteProductPrabayarStatus]=useState(false)

  // STATE FOR DELETE PRODUCT PARTNER PASCA-BAYAR 
  const [deleteProductPascabayarStatus, setDeleteProductPascabayarStatus]=useState(false)



  // ACTION HANDLER TABLE PRA-BAYAR
  const handleSortPartnerPrabayar = (column, sortDirection) => {
    setSortColumnPartnerPrabayar(column.selector);
    setSortDirectionPartnerPrabayar(sortDirection);
  };
  const onSearchPartnerPrabayar = (event) => {setKeywordPartnerPrabayar(event.target.value)};

  // ACTION HANDLER TABLE PASCA-BAYAR
  const handleSortPartnerPascabayar = (column, sortDirection) => {
    setSortColumnPartnerPascabayar(column.selector);
    setSortDirectionPartnerPascabayar(sortDirection);
  };
  const onSearchPartnerPascabayar = (event) => {setKeywordPartnerPascabayar(event.target.value)};

  // ACTION HANDLER FOR ADD PARTNER
  const addPartnerRepeatValue = (event) =>{
    setAddPartnerRepeat(event.target.value)
  }

  const addPartnerTransactionTypeValue = (event) => {
    setAddPartnerTransactionType(event.target.value)
  }

  const addPartnerStatusValue = (event) =>{
    setAddPartnerStatus(event.target.value)
  }

  // ACTION HANDLER FOR EDIT PARTNER
  const editPartnerRepeatValue = (event) =>{
    setEditPartnerRepeat(event.target.value)
  }

  const editPartnerTransactionTypeValue = (event) => {
    setEditPartnerTransactionType(event.target.value)
  }

  const editPartnerStatusValue = (event) =>{
    setEditPartnerStatus(event.target.value)
  }


  // GET PARTNER LIST
  const getPartnerList = () => {
    const URI = `partner/partner-list`

    axios
    .get(URI)
    .then(res => {
      console.log('getPartnerList response: ', res.data.data)
      setListPartner(res.data.data)
    })
    .catch(err => console.log('getPartnerList error: ', err))
  }

  // ADD NEW PARTNER
  const postAddPartner = () => {
    const URI = `/partner/store`

    axios
    .post(URI, {
      name:addPartnerName,
      code: addPartnerCode,
      transaction_type:addPartnerTransactionType,
      repeat:addPartnerRepeat,
      status:addPartnerStatus,
    })
    .then(res => {
      console.log('postAddPartner response: ', res.status)
      toast.success("Success Add New Partner !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setAddPartnerResponseStatus(!addPartnerResponseStatus)
      setAddPartner(!addPartner)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
      console.log('postAddPartner error: ', error)
    })
  }

  // EDIT PARTNER (CONFIGURE SECTION)
  const editPartner = (id,name,code,repeat,status,transaction_type) => {
    const URI = `partner/${id}/update`

    axios
    .post(URI, {
      name:editPartnerName === undefined ? name : editPartnerName,
      code:editPartnerCode === undefined ? code : editPartnerCode,
      transaction_type: editPartnerTransactionType === undefined ? (transaction_type === "CRON" ? "CRON" : "WEBHOOK") : (editPartnerTransactionType),
      repeat:editPartnerRepeat === undefined ? (repeat === true ? "YES" : "NO") : (editPartnerRepeat),
      status:editPartnerStatus === undefined ? (status === true ? "YES" : "NO") : (editPartnerStatus) ,
    })
    .then(res => {
      toast.success("Success Edit Partner !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setEditPartnerResponse(!editPartnerResponse)
      console.log("editPartner response: ", res)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
      console.log("editPartner error: ", error)
    })
  }



  // GET DATATABLE PRODUCT PRA-BAYAR
  const getPartnerPrabayarTable = (id) => {
    const URI = `partner/prabayar/${id}/data`

    axios
    .get(URI, {
      params: {
        page: pagePartnerPrabayar,
        per_page: countPagePartnerPrabayar,
        sort_column: sortColumnPartnerPrabayar,
        sort_direction: sortDirectionPartnerPrabayar,
        keyword: keywordPartnerPrabayar,
      },
    })
    .then(res => {
      console.log('getPartnerPrabayarTable response: ', res)
      setPartnerPrabayarTable(res.data)
    })
  }

  // ADD NEW PRODUCT FOR PARTNER PRA-BAYAR
  const addNewProductPrabayar = () =>{
    const URI = `partner/prabayar/store`

    axios
    .post(URI, {
      partner_id:partnerId,
      code:addProductPrabayarCode,
      name:addProductPrabayarName,
      price:addProductPrabayarPrice,
    })
    .then(res => {
      console.log("addNewProductPrabayar response: ", res)
      toast.success("Success Add New Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setAddProductNonPPOB(!addProductNonPPOB)
      setAddProductPrabayarResponseStatus(!addProductPrabayarResponseStatus)
    })
    .catch(error =>{
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error.response.data.errors);
    })
  }
  // GET HISTORY PRODUCT PRABAYAR
  const getHistoryProductPrabayar = (id) => {
    const URI = `partner/${id}/product-history`

    axios
    .get(URI)
    .then(response => {
      setDataHistoryProductPrabayar(response.data.data)
      setModalHistoryProductPrabayar(!modalHistoryProductPrabayar)
    })
    .catch(error => {
      console.log("getHistoryProductPrabayar :", error)
    })
  }

  // EDIT PRODUCT PARTNER PRA-BAYAR
  const editProductPrabayar = (id) => {
    const URI = `partner/prabayar/${id}/update`

    axios
    .post(URI, {
      code: editProductPrabayarCode,
      name: editProductPrabayarName,
      price: editProductPrabayarPrice,
    })
    .then(res => {
      console.log('editProductPrabayar response: ', res)
      toast.success("Success Edit Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setEditProductNonPPOB(!editProductNonPPOB)
      setEditProductPrabayarResponseStatus(!editProductPrabayarResponseStatus)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error.response.data.errors);
    })
  }

  // DELETE PRODUCT PRA-BAYAR
  const deleteProductPrabayar = (id) => {
    const URI = `partner/prabayar/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      setDeleteProductPrabayarStatus(!deleteProductPrabayarStatus)
      console.log("deleteProductPrabayar response: ", res)
      toast.success("Success Delete Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      console.log("deleteProductPrabayar error: ", err)
      toast.error("Failed Delete Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }


  // GET DATATABLE PRODUCT PASCA-BAYAR
  const getPartnerPascabayarTable = (id) => {
    const URI = `partner/pascabayar/${id}/data`

    axios
    .get(URI, {
      params: {
        page: pagePartnerPascabayar,
        per_page: countPagePartnerPascabayar,
        sort_column: sortColumnPartnerPascabayar,
        sort_direction: sortDirectionPartnerPascabayar,
        keyword: keywordPartnerPascabayar,
      },
    })
    .then(res => {
      console.log('getPartnerPascabayarTable response: ', res)
      setPartnerPascabayarTable(res.data)
    })
  }

  // ADD NEW PRODUCT FOR PARTNER PASCA-BAYAR
  const addNewProductPascabayar = () =>{
    const URI = `partner/pascabayar/store`

    axios
    .post(URI, {
      partner_id:partnerId,
      code:addProductPascabayarCode,
      name:addProductPascabayarName,
      admin:addProductPascabayarAdmin,
      cash_back: addProductPascabayarCashback,
    })
    .then(res => {
      console.log("addNewProductPascabayar response: ", res)
      toast.success("Success Add New Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setAddProductPPOB(!addProductPPOB)
      setAddProductPascabayarResponseStatus(!addProductPascabayarResponseStatus)
    })
    .catch(error =>{
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error.response.data.errors);
    })
  }

  // EDIT PRODUCT PARTNER PASCA-BAYAR
  const editProductPascabayar = (id) => {
    const URI = `partner/pascabayar/${id}/update`

    axios
    .post(URI, {
      code: editProductPascabayarCode,
      name: editProductPascabayarName,
      admin: editProductPascabayarAdmin,
      cash_back: editProductPascabayarCashback,
    })
    .then(res => {
      toast.success("Success Edit Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setEditProductPPOB(!editProductPPOB)
      setEditProductPascabayarResponseStatus(!editProductPascabayarResponseStatus)
      console.log('editProductPascabayar response: ', res)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error.response.data.errors);
    })
  }

  // DELETE PRODUCT PASCA-BAYAR
  const deleteProductPascabayar = (id) => {
    const URI = `partner/pascabayar/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      setDeleteProductPascabayarStatus(!deleteProductPascabayarStatus)
      console.log("deleteProductPascabayar response: ", res)
      toast.success("Success Delete Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      console.log("deleteProductPascabayar error: ", err)
      toast.error("Failed to Delete Product !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }

    // LIFECYCLE METHOD

    // PARTNER LIST
    useEffect(() => {
      getPartnerList()
    }, [
      editPartnerResponse,
    ])


    // PARTNER PRA-BAYAR RENDERING
    useEffect(() => {
      getPartnerPrabayarTable(partnerId)
    }, [
      addProductPrabayarResponseStatus,
      addPartnerResponseStatus,
      deleteProductPrabayarStatus,
      pagePartnerPrabayar,
      sortColumnPartnerPrabayar,
      sortDirectionPartnerPrabayar,
      countPagePartnerPrabayar,
      editProductPrabayarResponseStatus,
    ]);


    // PARTNER PASCA-BAYAR RENDERING
    useEffect(() => {
      getPartnerPascabayarTable(partnerId)
    }, [
      addProductPascabayarResponseStatus,
      editProductPascabayarResponseStatus,
      addPartnerResponseStatus,
      deleteProductPascabayarStatus,
      pagePartnerPascabayar,
      sortColumnPartnerPascabayar,
      sortDirectionPartnerPascabayar,
      countPagePartnerPascabayar,
    ]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Product" title="Partner" />
      <Container fluid={true} className="product-wrapper">
        <div className="product-grid email-wrap bookmark-wrap">
          <div className="feature-products">
            <Row className="justify-content-between">
              <Col xl="3">
                <div
                  className={`product-sidebar ${filterSidebar ? "" : "open"}`}
                >
                  <div className="filter-section">
                    <Card>
                      <CardHeader>
                        <h6 className="mb-0 f-w-600">
                          {"Partner List"}
                          <span className="pull-right">
                            <i
                              className="fa fa-chevron-down toggle-data"
                              onClick={onClickFilter}
                            ></i>
                          </span>
                        </h6>
                      </CardHeader>
                      <div className="left-filter">
                        <CardBody>
                          <div className="email-app-sidebar left-bookmark pr-4">
                            {listPartner !== undefined ? (
                              <Nav className="main-menu" role="tablist" >
                              {listPartner.map((item)=>(
                              <NavItem>
                              <a
                                className={
                                  activeTabPartner === `${item.id}` ? "active" : ""
                                }
                                onClick={() => {
                                  getPartnerPrabayarTable(item.id)
                                  setPartnerId(item.id)
                                  setActiveTabPartner(`${item.id}`)
                                }}
                              >
                                <span className="title"> {item.name}</span>
                              </a>
                            </NavItem>
                              ))}
                            </Nav>
                            ):(<p>data list partner kosong</p>)}
                          </div>
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>

              <Col xl="6" className="mt-1">
                <div className="text-center">
                  <Nav className="search-list">
                    <NavItem>
                      <NavLink
                        className={activeTabProduct === "1" ? "active" : ""}
                        onClick={() => {
                          getPartnerPrabayarTable(partnerId)
                          setActiveTabProduct("1")
                        }}
                      >
                        {/* <i className="icon-clipboard"></i> */}
                        {"Pra Bayar"}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTabProduct === "2" ? "active" : ""}
                        onClick={() => {
                          getPartnerPascabayarTable(partnerId)
                          setActiveTabProduct("2")
                        }}
                      >
                        {"Pasca Bayar"}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTabProduct === "3" ? "active" : ""}
                        onClick={() => setActiveTabProduct("3")}
                      >
                        {"Configure"}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>

              <Col xl="3" className="mt-2 mb-3">
                {userPermission.includes("[create] partner") ? (
                  <Button color="primary" onClick={() => showAddPartner()}>
                  Add Partner
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>

          {/* Modal Add partner */}
          <Modal isOpen={addPartner} toggle={() => showAddPartner()} centered>
            <ModalHeader toggle={() => showAddPartner()}>
              {"Add Partner"}
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col xl="12" md="12">
                    <FormGroup>
                      <Row className="justify-content-around">
                        <Col xl="4" md="4">
                          <Label>{"Partner Name"}</Label>
                        </Col>
                        <Col xl="6" md="6">
                          <Input
                            type="text"
                            name="ppob-product-name"
                            id="ppob-product-name"
                            onChange={(event)=>{
                              setAddPartnerName(event.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row className="justify-content-around">
                        <Col xl="4" md="4">
                          <Label>{"Partner Code"}</Label>
                        </Col>
                        <Col xl="6" md="6">
                          <Input
                            type="text"
                            name="ppob-product-name"
                            id="ppob-product-name"
                            onChange={(event)=>{
                              setAddPartnerCode(event.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row className="justify-content-around">
                          <Col xl="4" md="4">
                            <Label>{"Transaction Type"}</Label>
                          </Col>
                          <Col xl="6" md="6">
                            <Row className="justify-content-center ml-2">
                              <Col>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="addPartnerTransactionTypePrabayar"
                                    value='CRON'
                                    onChange={(event)=>addPartnerTransactionTypeValue(event)}
                                  />{" "}
                                  CRON
                                </Label>
                              </Col>
                              <Col>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="addPartnerTransactionTypePrabayar"
                                    value="WEBHOOK"
                                    onChange={(event)=>addPartnerTransactionTypeValue(event)}
                                  />{" "}
                                  WEBHOOK
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                    <FormGroup>
                        <Row className="justify-content-around">
                          <Col xl="4" md="4">
                            <Label>{"Repeat"}</Label>
                          </Col>
                          <Col xl="6" md="6">
                            <Row className="justify-content-center ml-2">
                              <Col>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="addPartnerRepeatPrabayar"
                                    value='YES'
                                    onChange={(event)=>addPartnerRepeatValue(event)}
                                  />{" "}
                                  Yes
                                </Label>
                              </Col>
                              <Col>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="addPartnerRepeatPrabayar"
                                    value="NO"
                                    onChange={(event)=>addPartnerRepeatValue(event)}
                                  />{" "}
                                  No
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-around">
                          <Col xl="4" md="4">
                            <Label>{"Status"}</Label>
                          </Col>
                          <Col xl="6" md="6">
                            <Row className="justify-content-center ml-2">
                              <Col>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="addPartnerStatusPrabayar"
                                    value='YES'
                                    onChange={(event)=>addPartnerStatusValue(event)}
                                  />{" "}
                                  ACTIVE
                                </Label>
                              </Col>
                              <Col>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="addPartnerStatusPrabayar"
                                    value="NO"
                                    onChange={(event)=>addPartnerStatusValue(event)}
                                  />{" "}
                                  NON ACTIVE
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                    <Row className="justify-content-center">
                      <Col xl="3" sm="6" xs="6">
                        <Button
                          color="secondary"
                          type="reset"
                          onClick={() => showAddPartner()}
                        >
                          {"Cancel"}
                        </Button>
                      </Col>
                      <Col xl="3" sm="6" xs="6">
                        <Button color="primary" className="mr-1" onClick={()=>postAddPartner()}>
                          {"Confirm"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          {/* Tab Content */}
          <div className="product-wrapper-grid">
            <Row className="gridRow">
              <Col xl="12">
                {listPartner !== undefined ? (
                  <TabContent activeTab={activeTabPartner}>
                  {listPartner.map((item)=>(
                    <TabPane tabId={`${item.id}`} className="search-links fade show">
                    <Card>
                      <CardHeader>
                        <h3>{item.name}</h3>
                      </CardHeader>

                      <TabContent activeTab={activeTabProduct}>
                        {/* Product Pasca Bayar */}
                        <TabPane tabId="2" className="search-links fade show">
                          <Card className="mb-0">
                            <CardHeader className="d-flex">
                              <h6 className="mb-0">{"Product Pasca Bayar"}</h6>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col xl="12">
                                  <Form
                                  onSubmit={(event)=>{
                                    event.preventDefault()
                                    getPartnerPascabayarTable(partnerId)
                                  }}
                                  >
                                    <div className="form-row">
                                      <Col xl="7" className="mb-3">
                                        <Input
                                          className="form-control"
                                          name="keyword"
                                          type="text"
                                          placeholder="Keyword"
                                          onChange={(event)=>onSearchPartnerPascabayar(event)}
                                        />
                                      </Col>
                                      <Col xl="5">
                                        <Row className="justify-content-around">
                                          <Button color="secondary" type="submit">
                                            {"Search"}
                                          </Button>
                                          {userPermission.includes("[create] partner") ? (
                                            <Button
                                            color="primary"
                                            className="sweet-8"
                                            name="alertSuccess"
                                            onClick={() => showAddProductPPOB()}
                                          >
                                            {"Add Product"}
                                          </Button>
                                          ) : null}
                                        </Row>
                                      </Col>
                                    </div>
                                  </Form>
                                  <DataTable
                                    columns={columnsPartnerPascabayar}
                                    data={partnerPascabayarTable.data}
                                    pagination={true}
                                    paginationServer
                                    paginationTotalRows={partnerPascabayarTable.total}
                                    onChangePage={(pagePartnerPascabayar) =>
                                      setPagePartnerPascabayar(pagePartnerPascabayar)
                                    }
                                    onChangeRowsPerPage={(countPagePartnerPascabayar) =>
                                      setCountPagePartnerPascabayar(
                                        countPagePartnerPascabayar
                                      )
                                    }
                                    onSort={handleSortPartnerPascabayar}
                                    paginationPerPage={countPagePartnerPascabayar}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </TabPane>

                        {/* Product Pra Bayar Section */}
                        <TabPane tabId="1">
                          <Card className="mb-0">
                            <CardHeader className="d-flex">
                              <h6 className="mb-0">{"Product Pra Bayar"}</h6>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col xl="12">
                                  <Form
                                  onSubmit={(event)=>{
                                    event.preventDefault()
                                    getPartnerPrabayarTable(partnerId)
                                  }}
                                  >
                                    <div className="form-row">
                                      <Col xl="7">
                                        <Input
                                          className="form-control mb-4"
                                          name="keyword"
                                          type="text"
                                          placeholder="Keyword"
                                          onChange={(event)=>onSearchPartnerPrabayar(event)}
                                        />
                                      </Col>
                                      <Col xl="5">
                                        <Row className="justify-content-around">
                                          <Button color="secondary" type="submit">
                                            {"Search"}
                                          </Button>
                                          {userPermission.includes("[create] partner") ? (
                                            <Button
                                            color="primary"
                                            className="sweet-8"
                                            name="alertSuccess"
                                            onClick={() =>
                                              showAddProductNonPPOB()
                                            }
                                          >
                                            {"Add Product"}
                                          </Button>
                                          ) : null}
                                        </Row>
                                      </Col>
                                    </div>
                                  </Form>
                                  <DataTable
                                    columns={columnsPartnerPrabayar}
                                    data={partnerPrabayarTable.data}
                                    pagination={true}
                                    paginationServer
                                    paginationTotalRows={partnerPrabayarTable.total}
                                    onChangePage={(pagePartnerPrabayar) =>
                                      setPagePartnerPrabayar(pagePartnerPrabayar)
                                    }
                                    onChangeRowsPerPage={(countPagePartnerPrabayar) =>
                                      setCountPagePartnerPrabayar(
                                        countPagePartnerPrabayar
                                      )
                                    }
                                    onSort={handleSortPartnerPrabayar}
                                    paginationPerPage={countPagePartnerPrabayar}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </TabPane>

                        {/* Configure Section  */}
                        <TabPane tabId="3" className="search-links fade show">
                          <Card>
                            <CardHeader>
                              <h6>Configure</h6>
                            </CardHeader>
                            <CardBody>
                              <Form
                              onSubmit={(event)=>{
                                event.preventDefault()
                                editPartner(item.id,item.name,item.code,item.repeat,item.status,item.transaction_type)
                              }}
                              >
                                <FormGroup>
                                  <Row className="justify-content-around">
                                    <Col xl="3">
                                      <Label>{"Name"}</Label>
                                    </Col>
                                    <Col xl="7">
                                      <Input 
                                      type="text" 
                                      defaultValue={item.name}
                                      onChange={(event)=>{
                                        setEditPartnerName(event.target.value)
                                      }}/>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around">
                                    <Col xl="3">
                                      <Label>{"Code"}</Label>
                                    </Col>
                                    <Col xl="7">
                                      <Input 
                                      type="text" 
                                      defaultValue={item.code}
                                      onChange={(event)=>{
                                        setEditPartnerCode(event.target.value)
                                      }}/>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around">
                                    <Col xl="4" md="4">
                                      <Label>{"Transaction Type"}</Label>
                                    </Col>
                                    <Col xl="6" md="6">
                                      <Row className="justify-content-center ml-2">
                                        <Col>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="editPartnerJenisTransaksi"
                                              value='CRON'
                                              defaultChecked={item.transaction_type === "CRON" ? true : false}
                                              onChange={(event)=>editPartnerTransactionTypeValue(event)}
                                            />{" "}
                                            CRON
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="editPartnerJenisTransaksi"
                                              value="WEBHOOK"
                                              defaultChecked={item.transaction_type === "WEBHOOK" ? true : false}
                                              onChange={(event)=>editPartnerTransactionTypeValue(event)}
                                            />{" "}
                                            WEBHOOK
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around">
                                    <Col xl="4" md="4">
                                      <Label>{"Repeat"}</Label>
                                    </Col>
                                    <Col xl="6" md="6">
                                      <Row className="justify-content-center ml-2">
                                        <Col>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="editPartnerRepeat"
                                              value='YES'
                                              defaultChecked={item.repeat === true ? true : false}
                                              onChange={(event)=>editPartnerRepeatValue(event)}
                                            />{" "}
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="editPartnerRepeat"
                                              value="NO"
                                              defaultChecked={item.repeat === false ? true : false}
                                              onChange={(event)=>editPartnerRepeatValue(event)}
                                            />{" "}
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around">
                                    <Col xl="4" md="4">
                                      <Label>{"Status"}</Label>
                                    </Col>
                                    <Col xl="6" md="6">
                                      <Row className="justify-content-center ml-2">
                                        <Col>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="editPartnerStatus"
                                              value='YES'
                                              defaultChecked={item.status === true ? true : false}
                                              onChange={(event)=>editPartnerStatusValue(event)}
                                            />{" "}
                                            ACTIVE
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="editPartnerStatus"
                                              value="NO"
                                              defaultChecked={item.status === false ? true : false}
                                              onChange={(event)=>editPartnerStatusValue(event)}
                                            />{" "}
                                            NON ACITVE
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <Row className="justify-content-center mt-3">
                                  {userPermission.includes("[update] partner") ? (
                                  <Col xl="3">
                                  <Button color="secondary" type="reset">
                                    {"Cancel"}
                                  </Button>
                                </Col>
                                  ) : null}
                                  {userPermission.includes("[update] partner") ? (
                                    <Col xl="3">
                                    <Button color="primary" type="submit">{"Confirm"}</Button>
                                  </Col>
                                  ) : null}
                                </Row>
                              </Form>
                            </CardBody>
                          </Card>
                        </TabPane>
                      </TabContent>
                    </Card>
                  </TabPane>
                  ))}
                </TabContent>
                ):(<p>data list partner kosong</p>)}


                {/* Modal Add Product Partner Pra Bayar */}
                <Modal
                  isOpen={addProductNonPPOB}
                  toggle={() => showAddProductNonPPOB()}
                  centered
                >
                  <ModalHeader
                    toggle={() => showAddProductNonPPOB()}
                  >
                    {"Add Product Pra Bayar"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Code"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="non-ppob-product-code"
                                  type="text"
                                  name="non-ppob-product-code"
                                  className="form-control digits"
                                  onChange={(event)=>{
                                    setAddProductPrabayarCode(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Product Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="text"
                                  name="non-ppob-product-name"
                                  id="non-ppob-product-name"
                                  onChange={(event)=>{
                                    setAddProductPrabayarName(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around ">
                              <Col xl="4" md="4">
                                <Label>{"Price"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {"Rp"}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    onChange={(event)=>{
                                      setAddProductPrabayarPrice(event.target.value)
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" sm="6" xs="6">
                              <Button
                                color="secondary"
                                type="reset"
                                onClick={() =>
                                  showAddProductNonPPOB()
                                }
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" sm="6" xs="6">
                              <Button
                                color="primary"
                                className="mr-1"
                                onClick={()=>addNewProductPrabayar()}
                              >
                                {"Confirm"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

              {/* Modal History Product */}
              <Modal
              isOpen={modalHistoryProductPrabayar}
              toggle={()=>showHistoryProductPrabayar()}
              centered
              size="xl"
              >
                <ModalHeader toggle={()=>showHistoryProductPrabayar()}>
                  {"History Product"}
                </ModalHeader>
                <ModalBody>
                <DataTable
                  columns={columnsHistoryProductPrabayar}
                  data={dataHistoryProductPrabayar}
                  pagination={true}
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                </ModalBody>

              </Modal>

                {/* Modal Edit Product Pra Bayar */}
                <Modal
                  isOpen={editProductNonPPOB}
                  toggle={() => showEditProductNonPPOB()}
                  centered
                >
                  <ModalHeader
                    toggle={() => showEditProductNonPPOB()}
                  >
                    {"Edit Product Pra Bayar"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Code"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="non-ppob-product-code"
                                  type="text"
                                  name="non-ppob-product-code"
                                  className="form-control digits"
                                  defaultValue={editProductPrabayarCode}
                                  onChange={(event)=>{
                                    setEditProductPrabayarCode(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Product Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="text"
                                  name="non-ppob-product-name"
                                  id="non-ppob-product-name"
                                  defaultValue={editProductPrabayarName}
                                  onChange={(event)=>{
                                    setEditProductPrabayarName(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around ">
                              <Col xl="4" md="4">
                                <Label>{"Price"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {"Rp"}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    defaultValue={editProductPrabayarPrice}
                                    onChange={(event)=>{
                                      setEditProductPrabayarPrice(event.target.value)
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" sm="6" xs="6">
                              <Button
                                color="secondary"
                                type="reset"
                                onClick={() =>
                                  showEditProductNonPPOB()
                                }
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" sm="6" xs="6">
                              <Button
                                color="primary"
                                className="mr-1"
                                onClick={()=>editProductPrabayar(editProductPrabayarId)}
                              >
                                {"Confirm"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>


                {/* Modal Add Product Partner Pasca bayar */}
                <Modal
                  isOpen={addProductPPOB}
                  toggle={() => showAddProductPPOB()}
                  centered
                >
                  <ModalHeader
                    toggle={() => showAddProductPPOB()}
                  >
                    {"Add Product Pasca bayar"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Code"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="ppob-product-code"
                                  type="text"
                                  name="ppob-product-code"
                                  className="form-control digits"
                                  onChange={(event)=>{
                                    setAddProductPascabayarCode(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Product Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="text"
                                  name="ppob-product-name"
                                  id="ppob-product-name"
                                  onChange={(event)=>{
                                    setAddProductPascabayarName(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Admin Charge"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {"Rp"}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    onChange={(event)=>{
                                      setAddProductPascabayarAdmin(event.target.value)
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around ">
                              <Col xl="4" md="4">
                                <Label>{"Cash Back"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {"Rp"}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    onChange={(event)=>{
                                      setAddProductPascabayarCashback(event.target.value)
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" md="3">
                              <Button
                                color="secondary"
                                type="reset"
                                onClick={() =>
                                  showAddProductPPOB()
                                }
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" md="3">
                              <Button
                                color="primary"
                                className="mr-1"
                                onClick={()=>addNewProductPascabayar()}
                              >
                                {"Confirm"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                {/* Modal Edit Product Pasca Bayar */}
                <Modal
                  isOpen={editProductPPOB}
                  toggle={() => showEditProductPPOB()}
                  centered
                >
                  <ModalHeader
                    toggle={() => showEditProductPPOB()}
                  >
                    {"Edit Product Pasca Bayar"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Code"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="ppob-product-code"
                                  type="text"
                                  name="ppob-product-code"
                                  className="form-control digits"
                                  defaultValue={editProductPascabayarCode}
                                  onChange={(event)=>{
                                    setEditProductPascabayarCode(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Product Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="text"
                                  name="ppob-product-name"
                                  id="ppob-product-name"
                                  defaultValue={editProductPascabayarName}
                                  onChange={(event)=>{
                                    setEditProductPascabayarName(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Admin Charge"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {"Rp"}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    defaultValue={editProductPascabayarAdmin}
                                  onChange={(event)=>{
                                    setEditProductPascabayarAdmin(event.target.value)
                                  }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around ">
                              <Col xl="4" md="4">
                                <Label>{"Cash Back"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {"Rp"}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    defaultValue={editProductPascabayarCashback}
                                  onChange={(event)=>{
                                    setEditProductPascabayarCashback(event.target.value)
                                  }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" md="3">
                              <Button
                                color="secondary"
                                type="reset"
                                onClick={() =>
                                  showEditProductPPOB()
                                }
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" md="3">
                              <Button
                                color="primary"
                                className="mr-1"
                                onClick={()=>editProductPascabayar(editProductPascabayarId)}
                              >
                                {"Confirm"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>


              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Partner;
