import {
    GET_USER_PERMISSION_REQUEST,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILED,
  } from "../../actionTypes";
  
  const initialState = {
    Data: {},
    error: "",
  };
  
  const getUserPermissionReducer = (state = initialState, action) => {
    switch (action.type) {
      
      // GET USER PERMISSION
      case GET_USER_PERMISSION_REQUEST:
        return {
          ...state,
        };
      case GET_USER_PERMISSION_SUCCESS:
        return {
          ...state,
          Data: action.payload,
        };
      case GET_USER_PERMISSION_FAILED:
        return {
          ...state,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default getUserPermissionReducer;