import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    EDIT_USER_REQUEST,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILED
  } from "../../actionTypes";
  
  // const jwtToken = localStorage.getItem("token");
  
  const initialState =
    {
        
        data: [],
        registerData: [],
        editData:[],
        edited:false,
        error: null,
      };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // register
    case REGISTER_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          registerData: action.payload,
        };
      case REGISTER_FAILED:
        return {
          ...state,
          isLoading: false,
          registerData: action.error,
        };
  
      // login
      case LOGIN_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          isLoggedIn: true,
        };
      case LOGIN_FAILED:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };

        // edit user
        case EDIT_USER_REQUEST:
        return {
          ...state,
          edited: false,
        };
      case EDIT_USER_SUCCESS:
        return {
          ...state,
          edited: true,
          editData: action.payload,
        };
      case EDIT_USER_FAILED:
        return {
          ...state,
          edited: false,
          editData: action.error,
        };

      default:
        return state;
    }
  };
  
  export default userReducer;