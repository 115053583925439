import {
  PERMISSION_LIST_REQUEST,
  PERMISSION_LIST_SUCCESS,
  PERMISSION_LIST_FAILED,
} from "../../actionTypes";

const initialState = {
  Data: {},
  error: "",
};

const permissionListReducer = (state = initialState, action) => {
  switch (action.type) {
    // PERMISSION LIST
    case PERMISSION_LIST_REQUEST:
      return {
        ...state,
      };
    case PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        Data: action.payload,
      };
    case PERMISSION_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default permissionListReducer;
