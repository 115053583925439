import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useSelector, useDispatch } from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from 'react-select';
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/transaction-custom.scss";
import { getListPartner } from '../../../redux/action/transaction'

import axios from "axios";
import fileDownload from "js-file-download";

const Transaction = () => {

  const columns = [
    {
      name: "Transaction Code",
      selector: "code",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Product",
      selector: "product",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Destination Number",
      selector: "destination_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Serial Number",
      selector: "serial_number",
      sortable: true,
      center: true,
      width: "350px",
      wrap: true,
    },
    {
      name: "Partner",
      selector: "partner",
      sortable: true,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      cell: (row) => (
        row.status === 'SUCCESS' ? (<Badge color="success">SUCCESS</Badge>) : (
          row.status === 'PENDING' ? (<Badge color="warning">PENDING</Badge>) : (<Badge color="danger">FAILED</Badge>)
        )
      )
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div>
          {userPermission.includes("[update] transaction") ? (
            <Button
              color="primary"
              className="btn-xs"
              onClick={() => {
                // setEditTransactionValue({
                //   code:row.code,
                //   user_id:row.user_id ,
                //   product_code:row.product_code,
                //   // veripay_product_code:'',
                //   product_name:row.product,
                //   buy_price:row.buy_price,
                //   sell_price:row.sell_price,
                //   destination_number:row.destination_number,
                //   serial_number:row.serial_number,
                //   commission_7_level:row.commission_7_level,
                //   point:row.point,
                //   gap:row.gap,
                //   profit:row.profit,
                //   channel:row.channel,
                //   partner_name:row.partner_name,
                //   date:row.date,
                //   success_time:row.success_time,
                //   status:row.status,
                //   note:row.note,
                // })
                getDetailTransaction(row.id)
                TransactionModaltoggle()
              }}
            >
              <i class="fa fa-edit"></i>
            </Button>
          ) : null}
          <Button
            color="success"
            className="btn-xs ml-2"
            onClick={() => {
              refreshTransaction(row.id)
              // setRefreshPage(!refreshPage)
            }}
          >
            <i class="fa fa-refresh"></i>
          </Button>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch()

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [sweetAlert, setSweetAlert] = useState(false);
  const [modal, setModal] = useState(false);
  const [TransactionModal, setTransactionModal] = useState(false);

  const listPartnerData = useSelector(state => state.listPartnerReducer.data.data)
  // listPartnerData && (listPartnerData[0].name !== 'ALL' && listPartnerData.unshift({id:'ALL', name:'ALL'}))
  // console.log("listParnter :", listPartnerData)


  const listPartner = listPartnerData !== undefined ? (
    listPartnerData.map((item) => (
      { value: item.name, label: item.name }
    ))
  ) : []

  const listStatus = [
    { value: 'ALL', label: 'ALL' },
    { value: 'SUCCESS', label: 'SUCCESS' },
    { value: 'PENDING', label: 'PENDING' },
    { value: 'FAILED', label: 'FAILED' },
  ]

  const Displayalert = () => {
    const URI = `transaction/download`;

    if (startDate == undefined || endDate == undefined) {
      setSweetAlert(true);
      SweetAlert.fire({
        title: "Download Failed",
        text: "Tanggal Awal dan Akhir harus diisi",
        icon: "error",
      });
      return false;
    }

    return axios
      .get(URI, {
        params: {
          keyword: keywordTransaction,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          status: status,
          partner: partner,
        },
        responseType: 'blob'
      })
      .then((response) => {
        fileDownload(response.data, 'report transaction.xlsx')
        setSweetAlert(true);
        SweetAlert.fire({
          title: "Download Success",
          text: "",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TransactionModaltoggle = () => setTransactionModal(!TransactionModal);


  // ACTION HANDLER
  const handleSortTransaction = (column, sortDirection) => {
    setSortColumnTransaction(column.selector);
    setSortDirectionTransaction(sortDirection);
  };
  const handleStartDate = (date) => { setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date); };
  const onSearchTransaction = (event) => { setKeywordTransaction(event.target.value) };

  // STATE DATA TABLE
  const [transactionTable, setTransactionTable] = useState({});
  const [pageTransaction, setPageTransaction] = useState(1);
  const [sortColumnTransaction, setSortColumnTransaction] = useState("");
  const [sortDirectionTransaction, setSortDirectionTransaction] = useState("");
  const [countPageTransaction, setCountPageTransaction] = useState(5);
  const [keywordTransaction, setKeywordTransaction] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [partner, setPartner] = useState('')
  const [status, setStatus] = useState('')

  // STATE FOR EDIT TRANSACTION
  const [editTransactionValue, setEditTransactionValue] = useState()
  // console.log("editTransactionValue: ", editTransactionValue)

  const [editTransactionResponse, setEditTransactionResponse] = useState(false)

  // STATE FOR REFRESH
  const [refreshPage, setRefreshPage] = useState(false)


  // FETCHING DATA TABLE
  const getDataTableTransaction = () => {
    const URI = `transaction/data`;

    return axios
      .get(URI, {
        params: {
          page: pageTransaction,
          per_page: countPageTransaction,
          sort_column: sortColumnTransaction,
          sort_direction: sortDirectionTransaction,
          keyword: keywordTransaction,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          status: status,
          partner: partner,
        },
      })
      .then((response) => {
        setTransactionTable(response.data);
        console.log("getTransaction response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // FETCHING DATA FOR ACTION DETAIL
  const getDetailTransaction = (id) => {
    const URI = `transaction/${id}/detail`

    axios
      .get(URI)
      .then((response) => {
        setEditTransactionValue(response.data.data)
        console.log("getDetailTransaction response: ", response)
      })
      .catch(error => {
        console.log("getDetailTransacion Error: ", error)
      })
  }

  const editTransaction = () => {
    const URI = `/transaction/${editTransactionValue.id}/update`

    axios
      .post(URI, {
        sn: editTransactionValue.serial_number,
        status: editTransactionValue.status,
        note: editTransactionValue.note
      })
      .then(response => {
        toast.success("Success Edit Verification !", {
          position: toast.POSITION.TOP_RIGHT
        });
        setEditTransactionResponse(!editTransactionResponse)
        setTransactionModal(!TransactionModal)
      })
      .catch(error => {
        let $errorMessage = "";

        for (let i in error.response.data.errors) {
          for (let j in error.response.data.errors[i]) {
            $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
          }
        }

        // console.log("eror message: ",$errorMessage);

        SweetAlert.fire({
          title: 'Error!',
          html: `<div style="text-align:left;">${$errorMessage}</div>`,
          icon: 'error'

        })
      })
  }

  const refreshTransaction = (id) => {
    const URI = `transaction/${id}/retry`

    axios
      .post(URI)
      .then(response => {
        toast.success("Transaction Refreshed !", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {

      })
  }




  useEffect(() => {
    dispatch(getListPartner())

  }, [])

  useEffect(() => {
    getDataTableTransaction()
  }, [
    pageTransaction,
    sortColumnTransaction,
    sortDirectionTransaction,
    countPageTransaction,
    refreshPage,
    editTransactionResponse,
  ]);


  return (
    <Fragment>
      <Breadcrumb parent="Transaction" title="Transaction" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event) => onSearchTransaction(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Status"}</Label>
                        <Select
                          options={listStatus}
                          onChange={(event) => {
                            setStatus(event.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Partner"}</Label>
                        <Select
                          options={listPartner}
                          onChange={(event) => {
                            setPartner(event.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={() => getDataTableTransaction()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columns}
                  data={transactionTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={transactionTable.total}
                  onChangePage={(pageTransaction) =>
                    setPageTransaction(pageTransaction)
                  }
                  onChangeRowsPerPage={(countPageTransaction) =>
                    setCountPageTransaction(
                      countPageTransaction
                    )
                  }
                  onSort={handleSortTransaction}
                  paginationPerPage={countPageTransaction}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                {editTransactionValue !== undefined ? (
                  <div>
                    <Modal
                      isOpen={TransactionModal}
                      toggle={() => TransactionModaltoggle()}
                      centered
                    >
                      <ModalHeader toggle={() => TransactionModaltoggle()}>
                        {"Transaction Detail"}
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col xl="12">
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Transaction Code</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.transaction_code}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>User ID</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.user_id}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Product Partner Code</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.product_partner_code}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Otlet Product Code</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.otlet_product_code}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Product Name</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.product_name}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Buy Price</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>
                                  :&nbsp;&nbsp;Rp.{" "}
                                  {parseFloat(`${editTransactionValue.buy_price}`).toLocaleString("en")}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Sell Price</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>
                                  :&nbsp;&nbsp;Rp.{" "}
                                  {parseFloat(`${editTransactionValue.sell_price}`).toLocaleString("en")}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Destination Number</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.destination_number}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>SN</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <Input
                                  type="textarea"
                                  rows="3"
                                  id="message-text-area"
                                  defaultValue={editTransactionValue.sn}
                                  onChange={(event) => {
                                    setEditTransactionValue({
                                      ...editTransactionValue,
                                      serial_number: event.target.value
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Commision 7 Level</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(`${editTransactionValue.commission_7_level}`).toLocaleString("en")}`}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Point</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.point}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Gap</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(`${editTransactionValue.gap}`).toLocaleString("en")}`}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Profit</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(`${editTransactionValue.profit}`).toLocaleString("en")}`}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Channel</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.channel}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Partner Name</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.partner_name}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Date</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{`${editTransactionValue.date} WIB`}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="5" sm="6" xs="6">
                                <p>Success Time</p>
                              </Col>
                              <Col xl="7" sm="6" xs="6">
                                <p>:&nbsp;&nbsp;{editTransactionValue.success_time !== "PENDING" ? `${editTransactionValue.success_time} Minute` : editTransactionValue.success_time}</p>
                              </Col>
                            </Row>
                            <FormGroup>
                              <Row>
                                <Col xl="5" sm="6" xs="6">
                                  <Label>{"Status"}</Label>
                                </Col>
                                <Col xl="7" sm="6" xs="6">
                                  <Select
                                    options={listStatus}
                                    defaultValue={listStatus.filter(list => list.label === editTransactionValue.status)}
                                    onChange={(event) => {
                                      setEditTransactionValue({
                                        ...editTransactionValue,
                                        status: event.value
                                      })
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row className="justify-content-around mb-5">
                                <Col xl="5" sm="6" xs="6">
                                  <Label>{"Note"}</Label>
                                </Col>
                                <Col xl="7" sm="6" xs="6">
                                  <Input
                                    type="textarea"
                                    name="message-text-area"
                                    id="message-text-area"
                                    rows="3"
                                    defaultValue={editTransactionValue.note}
                                    onChange={(event) => {
                                      setEditTransactionValue({
                                        ...editTransactionValue,
                                        note: event.target.value
                                      })
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <Row className="justify-content-center">
                              <Col xl="3" sm="6" xs="6">
                                <Button
                                  color="secondary"
                                  type="reset"
                                  onClick={() => TransactionModaltoggle()}
                                >
                                  {"Cancel"}
                                </Button>
                              </Col>
                              <Col xl="3" sm="6" xs="6">
                                <Button color="primary" className="mr-1" onClick={() => editTransaction()}>
                                  {"Save"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </div>
                ) : (
                  <div></div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transaction;
