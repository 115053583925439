import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useForm } from "react-hook-form";
import photo from "../../../assets/images/user/9.jpg";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Lightbox from "react-image-lightbox";
import Select from "react-select";
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/deposit-custom.scss";

import axios from "axios";

const RewardList = () => {

  const columnsRewardList = [
    {
      name: "Available",
      sortable: true,
      center: true,
      width: "250px",
      cell:(row)=>(
        // <div className="custom-control custom-switch">
        //   <label className="mr-5">No</label>
        //   <input
        //     type="checkbox"
        //     className="custom-control-input"
        //     id={`AvailableSwitch${row.id}`}
        //     defaultChecked
        //   />
        //   <label
        //     className="custom-control-label mr-5"
        //     htmlFor={`AvailableSwitch${row.id}`}
        //   >
        //     Yes
        //   </label>
        // </div>
        <div className="custom-control custom-switch">
          <label className="mr-5">NO</label>
          <input
            type="checkbox"
            className="custom-control-input"
            disabled={userPermission.includes("[update] reward list") ? false : true}
            id={row.id}
            defaultChecked={row.available === true ? true : false}
            onChange={()=>{
              

              // console.log("partner status :", partner)
              for (let i of rewardListTable.data) {
                if (i.id === row.id){
                  let value = i.available === true ? "NO" : "YES"
                  updateStatusReward(row.id,value)
                  // console.log("partner selected :", i)
                  // console.log("partner status :", i.status)
                }
              }
            }}
          />
          <label
            className="custom-control-label mr-5"
            htmlFor={row.id}
          >
            YES
          </label>
        </div>
      )
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      width:'100px',
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
      width:'200px',
    },
    // {
    //   name: "Picture",
    //   center: true,
    //   width: "200px",
    //   cell: (row) => (
    //     <img height="150px" width="133px" alt={"photo"} src={`data:image/jpeg;base64,${row.picture}`} />
    //   ),
    // },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      center: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Price",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp.${(row.price).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Stock",
      selector: "stock",
      sortable: true,
      center: true,
    },
    {
      name: "Point Requirement",
      selector: "point_requirement",
      sortable: true,
      center: true,
      width:'200px'
    },
    {
      name: "Tax",
      sortable: true,
      center: true,
      cell:(row)=>(
        <span>{`${row.tax}%`}</span>
      )
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] reward list") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditRewardListValue({
                id:row.id,
                code:row.code,
                name:row.name,
                picture:row.picture,
                information:row.information,
                type:row.type,
                price:row.price,
                stock:row.stock,
                point_requirement:row.point_requirement,
                tax:row.tax,
                available:row.available === true ? 'YES': 'NO',
              })
              showEditReward()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] reward list") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDeleteReward(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  
  const [sweetAlert, setSweetAlert] = useState(false);
  const [AlertDeleteSuccess, setAlertDeleteSuccess] = useState(false);
  const [addReward, setAddReward] = useState(false);
  const [editReward, setEditReward] = useState(false);

   // photo
   const initilindex = { index: 0, isOpen: false };
   const [photoIndex, setPhotoIndex] = useState(initilindex);

  const listType = [
    { value: "BARANG", label: "BARANG" },
    { value: "SALDO", label: "SALDO" },
  ];

  const listAvailable = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];

  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  // ALERT DELETE PRODUCT REWARD
  const DisplayAlertDeleteReward = (id_product) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReward(id_product)
        
      }
    })
  };

  // CONVERT FILE TO BASE 64
  const toBase64 = file => {
    const reader = new FileReader();
    console.log("reader: ", reader)
    reader.readAsDataURL(file);
    reader.onload = () => reader.result;
    return reader.result
    // reader.onerror = error => reject(error);
};

  // PICTURE AFTER EDIT IN MODAL EDIT
  const [pictureEdit, setPictureEdit]=useState({})
  console.log("picturEdit :", pictureEdit)

  const showAddReward = () => setAddReward(!addReward);
  const showEditReward = () => setEditReward(!editReward);

  // ACTION HANDLER
  const handleSortRewardList = (column, sortDirection) => {
    setSortColumnRewardList(column.selector);
    setSortDirectionRewardList(sortDirection);
  };

  const onSearchRewardList = (event) => {setKeywordRewardList(event.target.value)};

  // STATE DATA TABLE
  const [rewardListTable, setRewardListTable] = useState({});
  const [pageRewardList, setPageRewardList] = useState(1);
  const [sortColumnRewardList,setSortColumnRewardList] = useState("");
  const [sortDirectionRewardList,setSortDirectionRewardList] = useState("");
  const [countPageRewardList,setCountPageRewardList] = useState(5);
  const [keywordRewardList, setKeywordRewardList] = useState("");

  // STATE FOR ADD NEW REWARD
  const [addRewardListValue, setAddRewardListValue]=useState({
    code:'',
    name:'',
    picture:'',
    description:'',
    type:'',
    price:'',
    stock:'',
    point_requirement:'',
    tax:'',
    available:'',
  })
  // console.log('addRewardListValue: ', addRewardListValue)
  const [addRewardListResponse, setAddRewardListResponse]=useState(false)


  // STATE FOR EDIT DATA ON DATA-TABLE
  const [editRewardListValue, setEditRewardListValue]=useState({
    id:'',
    code:'',
    name:'',
    picture:'',
    information:'',
    type:'',
    price:'',
    stock:'',
    point_requirement:'',
    tax:'',
    available:'',
  })
  // console.log('editRewardListValue: ', editRewardListValue)
  const [editRewardListResponse, setEditRewardListResponse]=useState(false)

  const[deleteRewardResponse, setDeleteRewardResponse]=useState(false)
  

  // FETCHING DATA TABLE
  const getDataTableRewardList = () => {
    const URI = `point/reward-list/data`;

    return axios
      .get(URI, {
        params: {
          page: pageRewardList,
          per_page: countPageRewardList,
          sort_column: sortColumnRewardList,
          sort_direction: sortDirectionRewardList,
          keyword: keywordRewardList,
        },
      })
      .then((response) => {
        setRewardListTable(response.data);
        console.log("getRewardListTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addRewardList = () => {
    const URI = `/point/reward-list/store`;

    var form_data = new FormData();

    for ( let key in addRewardListValue ) {
        form_data.append(key, addRewardListValue[key]);
    }

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return  axios 
      .post(URI,form_data,config)
      .then((response) => {
        toast.success("Success Adding New Reward !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setAddReward(!addReward)
        setAddRewardListResponse(!addRewardListResponse)
        console.log("addRewardList response: ", response.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  const editRewardList = () => {
    const URI = `point/reward-list/${editRewardListValue.id}/update`;

    var form_data = new FormData();

    for ( let key in editRewardListValue ) {
      // if (typeof editRewardListValue.picture == "string") {
      //   form_data.append("id", editRewardListValue.id )
      //   form_data.append("code", editRewardListValue.code )
      //   form_data.append("name", editRewardListValue.name )
      //   form_data.append("description", editRewardListValue.description )
      //   form_data.append("type", editRewardListValue.type )
      //   form_data.append("price", editRewardListValue.price )
      //   form_data.append("stock", editRewardListValue.stock )
      //   form_data.append("point_requirement", editRewardListValue.point_requirement )
      //   form_data.append("tax", editRewardListValue.tax )
      //   form_data.append("available", editRewardListValue.available )
      // }
        form_data.append(key, editRewardListValue[key]);
    }

    // const formData = new FormData();
    // formData.append('picture',editRewardListValue.picture)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return  axios 
      .post(URI,form_data,config)
      .then((response) => {
        toast.success("Success Edit Reward List !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setEditReward(!editReward)
        setEditRewardListResponse(!editRewardListResponse)
        console.log("editRewardList response: ", response.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // UPDATE STATUS WITH TOGGLE
  const updateStatusReward = (id,value) => {
    const URI = `point/reward-list/${id}/toggle-available`

    axios.post(URI, {
      available: value,
    })
    .then(res => {
      toast.success("Success update status !", {
        position: toast.POSITION.TOP_RIGHT
        });
        let newData = [...rewardListTable.data]
        // newData[id].status = status
        for (let i=0; i < newData.length; i++) {
          if (newData[i].id == id) {
            newData[i].status = value === "YES" ? 1 : 0
            // console.log("response : ", newData[i])
            // console.log("response status : ", newData[i].status)
            // console.log("response id :", newData[i].id)
            // console.log("params id :", id)
          }
        }
      // setUpdateStatusPartnerResponse(!updateStatusPartnerResponse)
    }).catch(err => {
      toast.error("Failed to update status !", {
        position: toast.POSITION.TOP_RIGHT
        });
    });
  }

  // DELETE DATA
  const deleteReward = (id) => {
    const URI = `point/reward-list/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      // console.log("deleteProductPrabayar response: ", res)
      setDeleteRewardResponse(!deleteRewardResponse)
      toast.success("Success Delete Product!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      // console.log("deleteProductPrabayar error: ", err)
      toast.error("Failed to delete product!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }



  useEffect(() => {
    getDataTableRewardList()
  }, [
    pageRewardList,
    sortColumnRewardList,
    sortDirectionRewardList,
    countPageRewardList,
    editRewardListResponse,
    addRewardListResponse,
    deleteRewardResponse,
  ]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Point" title="Reward List" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="">
                  <div className="form-row">
                    <Col xl="8" md="8">
                      <Input
                        className="form-control mb-4"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchRewardList(event)}
                      />
                    </Col>
                    <Col md="4">
                      <Row className="justify-content-around">
                        <Button color="secondary" onClick={()=>getDataTableRewardList()}>{"Search"}</Button>
                        {userPermission.includes("[create] reward list") ? (
                          <Button
                          color="primary"
                          className="sweet-8"
                          name="alertSuccess"
                          onClick={() => showAddReward()}
                        >
                          {"Add Reward"}
                        </Button>
                        ) : null}
                      </Row>
                    </Col>
                  </div>
                  {/* Button For Action Form */}
                  <Row className="justify-content-between"></Row>
                </Form>
                <DataTable
                  columns={columnsRewardList}
                  data={rewardListTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={rewardListTable.total}
                  onChangePage={(pageRewardList) =>
                    setPageRewardList(pageRewardList)
                  }
                  onChangeRowsPerPage={(countPageRewardList) =>
                    setCountPageRewardList(
                      countPageRewardList
                    )
                  }
                  onSort={handleSortRewardList}
                  paginationPerPage={countPageRewardList}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                {/* Modal Add Reward */}
                <Modal
                  isOpen={addReward}
                  toggle={() => showAddReward()}
                  centered
                >
                  <ModalHeader toggle={() => showAddReward()}>
                    {"Add Reward"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Code</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text" 
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                code: event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Product Name</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text" 
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                name: event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Picture</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input placeholder="select Image" type="file"
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                picture: event.target.files[0]
                              })
                              // setPictureEdit(toBase64(event.target.files[0]))
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Description</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="textarea" rows="3" 
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                description: event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Type</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listType}
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                type: event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Price</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Row>
                              <Col xl="12">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{"Rp"}</InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    onChange={(event)=>{
                                      setAddRewardListValue({
                                        ...addRewardListValue,
                                        price: event.target.value
                                      })
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Stock</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                stock: event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Point Requirement</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                point_requirement: event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Tax</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text" 
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                tax: event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Available</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listAvailable}
                            onChange={(event)=>{
                              setAddRewardListValue({
                                ...addRewardListValue,
                                available: event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xl="3" sm="6" xs="6">
                          <Button
                            color="secondary"
                            type="reset"
                            onClick={() => showAddReward()}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col xl="3" sm="6" xs="6">
                          <Button color="primary" onClick={()=>addRewardList()}>Confirm</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                {/* Modal Edit Reward */}
                <Modal
                  isOpen={editReward}
                  toggle={() => showEditReward()}
                  centered
                  zIndex={10}
                >
                  <ModalHeader toggle={() => showEditReward()}>
                    {"Edit Reward"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Code</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text" 
                            defaultValue={editRewardListValue.code} 
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                code:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Name</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text" 
                            defaultValue={editRewardListValue.name} 
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                name:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Picture</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <img
                                className="img-fluid personal-information-modal-photo"
                                src={editRewardListValue.picture !== undefined ? editRewardListValue.picture :''}
                                style={{ width: 300, height: 200 }}
                                onClick={() =>
                                  setPhotoIndex({
                                    ...photoIndex,
                                    index: 1,
                                    isOpen: true,
                                  })
                                }
                                alt=""
                              />
                            <Input placeholder="select Image" type="file" onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                picture: event.target.files[0]
                              })
                              setPictureEdit(toBase64(event.target.files[0]))
                            }}/>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Information</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input
                              type="textarea"
                              rows="3"
                              defaultValue={editRewardListValue.information} 
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                information:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Type</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listType}
                            defaultValue={listType.filter(list => list.value === editRewardListValue.type)}
                            onChange={(event)=>{
                              setEditRewardListValue({
                                type:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Price</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                        <FormGroup>
                            <Row>
                              <Col xl="12">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{"Rp"}</InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control"
                                    name="saldo-nominal"
                                    type="number"
                                    onClick={(event)=>{
                                      const cancelWheel = (event) => event.preventDefault();
                                      event.target.addEventListener("wheel", cancelWheel)
                                    }}
                                    defaultValue={editRewardListValue.price} 
                                    onChange={(event)=>{
                                      setEditRewardListValue({
                                        ...editRewardListValue,
                                        price:event.target.value
                                      })
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Stock</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            defaultValue={editRewardListValue.stock} 
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                stock:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Point Requirement</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            defaultValue={editRewardListValue.point_requirement} 
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                point_requirement:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Tax</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            defaultValue={editRewardListValue.tax} 
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                tax:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Available</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listAvailable}
                            defaultValue={listAvailable.filter(list => list.value === editRewardListValue.available)}
                            onChange={(event)=>{
                              setEditRewardListValue({
                                ...editRewardListValue,
                                available:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xl="3" sm="6" xs="6">
                          <Button
                            color="secondary"
                            type="reset"
                            onClick={() => showEditReward()}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col xl="3" sm="6" xs="6">
                          <Button color="primary" onClick={()=>editRewardList()} >Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
            {photoIndex.isOpen &&
        (photoIndex.index === 1 && (
          <Lightbox
            mainSrc={editRewardListValue.picture !== undefined ? editRewardListValue.picture :''}
            onCloseRequest={() =>
              setPhotoIndex({ ...photoIndex, isOpen: false })
            }
          />
        ))}
    </Fragment>
  );
};

export default RewardList;
