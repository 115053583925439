import React, { useState, Fragment, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from "react-select"; 
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/deposit-custom.scss";
// import { getListBank } from '../../../redux/action/deposit-list/index'

import axios from "axios";
import fileDownload from "js-file-download";

const DepositList = () => {

  const columns = [
    {
      name: "Status",
      sortable: true,
      center: true,
      cell:(row)=>(
        row.status === 'SUCCESS' ? <Badge color="success">SUCCESS</Badge> : (
          row.status === 'PENDING' ? <Badge color="warning">PENDING</Badge> : (
            row.status === 'EXPIRED' ? <Badge color="danger">EXPIRED</Badge> : <Badge color="warning">UNCONFIRMED</Badge>
          )
        )
      )
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center:true,
      width: "150px",
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      center: true,
      width:"200px"
    },
    {
      name: "Payment Method",
      selector: "payment_method",
      sortable: true,
      center: true,
      width:'170px'
    },
    {
      name: "Nominal",
      selector: "nominal",
      center: true,
      width: "150px",
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Approval",
      selector: "approval",
      center: true,
    },
    {
      name: "Action",
      selector: "action",
      center: true,
      cell: (row) => (
        <div>
          {userPermission.includes("[update] deposit") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={(event) =>{ 
              setEditDepositValue({
                id:row.id,  
                code:row.code,
                user_id:row.user_id,
                channel:row.channel,
                payment_method:row.payment_method,
                nominal:row.nominal,
                date:row.date,
                approval:row.approval,
                status:row.status,
                note:row.note,
              })
              Verticalcentermodaltoggle()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[update] deposit") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDelete(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      ),
    },
  ];

  // STATE USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [date, setDate] = useState({ date: new Date() });
  const [sweetAlert, setSweetAlert] = useState(false);
  const [alertDeleteSuccess, setAlertDeleteSuccess] = useState(false);
  const [depositManual, setDepositManual] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const FileDownload = require('js-file-download');

  const [listPaymentMethodData, setListPaymentMethodData]=useState()
  // const [listPaymentMethodFilterData, setListPaymentMethodFilterData]=useState()
  // const addFilterAll = () => {
  //   if (listPaymentMethodFilterData[0].name !== "ALL") {
  //     return listPaymentMethodFilterData.unshift({id:'ALL', name:'ALL'})
  //   }
  // }
  // listPaymentMethodFilterData && addFilterAll()
  // listPaymentMethodFilterData && (listPaymentMethodFilterData[0].name !== 'ALL' && listPaymentMethodFilterData.unshift({id:'ALL', name:'ALL'}))

  // console.log('listPaymentMethodFilterData: ', listPaymentMethodFilterData)

  // const listPaymentMethodFilter = useSelector(state => state.listBankReducer.data.data)
  // console.log("list bank manual :", listBankInDepositManualData)

  const [listChannelData, setListChannelData]=useState()
  // listChannelData && (listChannelData[0].name !== 'ALL' && listChannelData.unshift({id:'ALL', name:'ALL'}))


  const listStatus = [
    { value: "ALL", label: "ALL" },
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "PENDING", label: "PENDING" },
    { value: "UNCONFIRMED", label: "UNCONFIRMED" },
    { value: "EXPIRED", label: "EXPIRED" },
  ];

  const listPaymentMethod = listPaymentMethodData !== undefined ? listPaymentMethodData.map((item)=>(
    { value:item.id, label: item.name }
  )):[]

  // const listPaymentMethodFilter = listPaymentMethodFilterData !== undefined ? listPaymentMethodFilterData.map((item)=>(
  //   {value:item.id, label:item.name}
  // )):[]
  
  const listChannel = listChannelData !== undefined ? listChannelData.map((item)=>(
    { value:item.id, label: item.name }
  )):[] 

  // const listBankInDepositManual = listBankInDepositManualData !== undefined ? listBankInDepositManualData.map((item)=>(
  //   { value:item.bank_partner, label: item.bank_partner }
  // )):[] 

  const listStatusEdit = [
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "PENDING", label: "PENDING" },
    { value: "EXPIRED", label: "EXPIRED" },
    { value: "UNCONFIRMED", label: "UNCONFIRMED" },
  ];

  
  // console.log("listBank : ", listBank)

  const dispatch = useDispatch()

  const DisplayAlertDownload = () => {

    const URI = `deposit-list/download`;

    if (startDate == undefined || endDate == undefined) {
      setSweetAlert(true);
      SweetAlert.fire({
        title: "Download Failed",
        text: "Tanggal Awal dan Akhir harus diisi",
        icon: "error",
      });
      return false;
    }

    return axios
      .get(URI, {
        params: {
          page: pageDepositList,
          per_page: countPageDepositList,
          sort_column: sortColumnDepositList,
          sort_direction: sortDirectionDepositList,
          keyword: keywordDepositList,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          payment_method: paymentMethod,
          status: status,
          channel: channel
        },
        responseType: 'blob'
      })
      .then((response) => {
        fileDownload(response.data, 'report deposit.xlsx')
        setSweetAlert(true);
        SweetAlert.fire({
          title: "Download Success",
          text: "",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });    
    // e.prevenDefault();
  };

  // ALERT DELETE PRODUCT PRA-BAYAR
  const DisplayAlertDelete = (id_product) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDeposit(id_product)
        
      }
    })
  };

  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const showDepositManual = () => setDepositManual(!depositManual);

  const handleChange = (date) => {
    setDate(date);
  };

  

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      alert(`You Search : ${data.keyword}`);
    } else {
      errors.showMessages();
    }
  };

  // const onDownloadClicked = () =>{
  //   if (data !== undefined) {
  //     alert('Download Success');
  //   } else {
  //     errors.showMessages();
  //   }
  // }

  // ACTION HANDLER
  const handleSortDepositList = (column, sortDirection) => {
    setSortColumnDepositList(column.selector);
    setSortDirectionDepositList(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchDepositList = (event) => {setKeywordDepositList(event.target.value)};

  // STATE DATA TABLE
  const [depositListTable, setDepositListTable] = useState({});
  const [pageDepositList, setPageDepositList] = useState(1);
  const [sortColumnDepositList,setSortColumnDepositList] = useState("");
  const [sortDirectionDepositList,setSortDirectionDepositList] = useState("");
  const [countPageDepositList,setCountPageDepositList] = useState(5);
  const [keywordDepositList, setKeywordDepositList] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [paymentMethod, setPaymentMethod] = useState('')
  const [status, setStatus] = useState('')
  const [channel, setChannel] = useState('')

  // STATE FOR ADD NEW DEPOSIT
  const [addDepositValue, setAddDepositValue]=useState({
    vp : '',
    payment_method_id: "",
    channel_id: "",
    nominal: "",
    note: "",
  })

  const [addDepositResponse, setAddDepositResponse]=useState(false)

  // STATE FOR DELETE DEPOSIT
  const [deleteDepositResponse, setDeleteDepositResponse]=useState(false)

  // STATE FOR EDIT
  const [editDepositValue, setEditDepositValue]=useState({
    id:"",
    code:'',
    user_id:'',
    channel:'',
    payment_method:'',
    nominal:'',
    date:'',
    approval:'',
    status:'',
    note:'',
  })

  const [editDepositResponse, setEditDepositResponse]=useState(false)

  // FETCHING DATA TABLE
  const getDataTableDepositList = () => {
    const URI = `deposit-list/data`;

    return axios
      .get(URI, {
        params: {
          page: pageDepositList,
          per_page: countPageDepositList,
          sort_column: sortColumnDepositList,
          sort_direction: sortDirectionDepositList,
          keyword: keywordDepositList,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          payment_method: paymentMethod,
          status: status,
          channel: channel
        },
      })
      .then((response) => {
        setDepositListTable(response.data);
        console.log("getDepositList response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //FETCHING DATA FOR SELECT PAYMENT METHOD
  const getPaymentMethodData = () => {
    const URI = `/deposit-list/payment-method`

    axios
    .get(URI)
    .then(response => {
      console.log("getPaymentMethodData response: ", response)
      setListPaymentMethodData(response.data.data)
      // setListPaymentMethodFilterData(response.data.data)
    })
    .catch(error => {
      console.log("getPaymentMethodData error: ", error)
    })
  }

  //FETCHING DATA FOR SELECT CHANNEL
  const getChannelData = () => {
    const URI = `/deposit-list/channel`

    axios
    .get(URI)
    .then(response => {
      console.log("getChannelData response: ", response)
      setListChannelData(response.data.data)
    })
    .catch(error => {
      console.log("getChannelData error: ", error)
    })
  }

  const addNewDeposit = () => {
    const URI = `deposit-list/deposit-manual`

    axios
    .post(URI,addDepositValue)
    .then(response => {
      console.log("addNewDeposit response: ", response)
      toast.success("Success Adding New Deposit !", {
        position: toast.POSITION.TOP_RIGHT
      });
      setDepositManual(!depositManual)
      setAddDepositResponse(!addDepositResponse)
    })
    .catch(error => {
      let $errorMessage = "";
      
        for (let i in error.response.data.errors) {
            for (let j in error.response.data.errors[i]) {
                $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
            }
        }
    
        // console.log("eror message: ",$errorMessage);
    
      SweetAlert.fire({
        title:'Error!',
        html:`<div style="text-align:left;">${$errorMessage}</div>`,
        icon:'error'
        
      })

      console.log("addNewDeposit error: ", error)
    })
  }

  const editDeposit = () => {
    const URI = `/deposit-list/${editDepositValue.id}/update`

    axios
    .post(URI,{
       status: editDepositValue.status,
       note:editDepositValue.note
    })
    .then(response => {
      toast.success("Success Edit Deposit!", {
        position: toast.POSITION.TOP_RIGHT
        });
      setVerticalcenter(!Verticalcenter)
      setEditDepositResponse(!editDepositResponse)
    })
    .catch(error => {
      let $errorMessage = "";
      
        for (let i in error.response.data.errors) {
            for (let j in error.response.data.errors[i]) {
                $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
            }
        }
    
        // console.log("eror message: ",$errorMessage);
    
      SweetAlert.fire({
        title:'Error!',
        html:`<div style="text-align:left;">${$errorMessage}</div>`,
        icon:'error'
        
      })
    })
  }

  const deleteDeposit = (id) => {
    const URI = `/deposit-list/${id}/delete`

    axios
    .post(URI)
    .then(response => {
      toast.success("Success Delete Deposit!", {
        position: toast.POSITION.TOP_RIGHT
        });
      setDeleteDepositResponse(!deleteDepositResponse)
    })
    .catch(error => {
      toast.error("Failed Delete Deposit!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }


  useEffect(() => {
    getPaymentMethodData()
    getChannelData()
  }, [])

  useEffect(() => {
   getDataTableDepositList()
  }, [
    pageDepositList,
    sortColumnDepositList,
    sortDirectionDepositList,
    countPageDepositList,
    addDepositResponse,
    deleteDepositResponse,
    editDepositResponse,
  ]);

  return (
    <Fragment>
      <Breadcrumb parent="Deposit" title="Deposit List" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  // onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <Col md="9 mt-4 pt-1 mb-2">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchDepositList(event)}
                      />
                    </Col>
                    <Col md="3 mb-2">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">
                          {"Payment Method"}
                        </Label>
                        <Select
                        options={listPaymentMethod}
                        onChange={(event)=>{
                          setPaymentMethod(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-2" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-2" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-2" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Status"}</Label>
                        <Select
                        options={listStatus}
                        onChange={(event)=>{
                          setStatus(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3 mb-2" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Channel"}</Label>
                        <Select
                        options={listChannel}
                        onChange={(event)=>{
                          setChannel(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row className="justify-content-between">
                    <Col md="5">
                      <Row className="justify-content-around">
                        <Button className="mb-4" color="secondary" onClick={()=>dispatch(getDataTableDepositList)}>{"Search"}</Button>
                        <Button
                          color="primary"
                          className="sweet-8 mb-4"
                          name="alertSuccess"
                          onClick={()=>DisplayAlertDownload()}
                        >
                          {"Download"}
                        </Button>
                      </Row>
                    </Col>
                    <Col md="3">
                      {userPermission.includes("[create] deposit") ? (
                        <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={() => showDepositManual()}
                      >
                        {"Deposit Manual"}
                      </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columns}
                  data={depositListTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={depositListTable.total}
                  onChangePage={(pageDepositList) =>
                    setPageDepositList(pageDepositList)
                  }
                  onChangeRowsPerPage={(countPageDepositList) =>
                    setCountPageDepositList(
                      countPageDepositList
                    )
                  }
                  onSort={handleSortDepositList}
                  paginationPerPage={countPageDepositList}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />

                <div>
                  {editDepositValue !== undefined ? (
                    <Modal
                    isOpen={Verticalcenter}
                    toggle={() => Verticalcentermodaltoggle()}
                    centered
                  >
                    <ModalHeader toggle={() => Verticalcentermodaltoggle()}>
                      {"Deposit Detail"}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Code</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.code}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>User ID</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.user_id}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Channel</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.channel}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Payment Method</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.payment_method}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Nominal</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.nominal}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Date</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.date}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Approval</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <p>:&nbsp;&nbsp;{editDepositValue.approval}</p>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Row>
                              <Col xl="6" sm="6" xs="6">
                                <p>Status</p>
                              </Col>
                              <Col xl="6" sm="6" xs="6">
                                <Select
                                options={listStatusEdit}
                                defaultValue={listStatusEdit.filter(list => list.label === editDepositValue.status)}
                                onChange={(event)=>{
                                  setEditDepositValue({
                                    ...editDepositValue,
                                    status:event.value
                                  })
                                }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="mb-3">
                              <Col xl="4" >
                                <p>Note</p>
                              </Col>
                              <Col xl="8" >
                                <textarea
                                  className={
                                    "ng-untouched ng-pristine ng-valid"
                                  }
                                  style={{ height: 100, width: 300 }}
                                  id="notes"
                                  placeholder="Write Your Notes Here"
                                  defaultValue={editDepositValue.note}
                                  onChange={(event)=>{
                                    setEditDepositValue({
                                      ...editDepositValue,
                                      note:event.value
                                    })
                                  }}
                                ></textarea>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" sm="6" xs="6">
                              <Button
                                color="secondary"
                                type="reset"
                                className="mr-1"
                                onClick={() => Verticalcentermodaltoggle()}
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" sm="6" xs="6">
                              <Button color="primary" onClick={(event)=>editDeposit()}>{"Save"}</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  ):(<p>data edit deposit kosong</p>)}
                </div>

                {/* Modal Deposit Manual */}
                <div>
                  <Modal
                    isOpen={depositManual}
                    toggle={() => showDepositManual()}
                    centered
                  >
                    <ModalHeader toggle={() => showDepositManual()}>
                      {"Deposit Manual"}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xl="12">
                          <Form
                          onSubmit={(event)=>{
                            event.preventDefault()
                            addNewDeposit()
                          }}
                          >
                            <FormGroup>
                              <Row>
                                <Col xl="4">
                                  <p>User ID</p>
                                </Col>
                                <Col xl="8">
                                  <Input 
                                  type="text"
                                   placeholder="OTL0000"
                                   onChange={(event)=>{
                                     setAddDepositValue({
                                       ...addDepositValue,
                                       vp:event.target.value
                                     })
                                   }}
                                   />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xl="4">
                                  <p>Pament Method</p>
                                </Col>
                                <Col xl="8">
                                  <Select
                                  options={listPaymentMethod}
                                  onChange={(event)=>{
                                    setAddDepositValue({
                                      ...addDepositValue,
                                      payment_method_id:event.value
                                    })
                                  }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xl="4">
                                  <p>Channel</p>
                                </Col>
                                <Col xl="8">
                                  <Select
                                  options={listChannel}
                                  onChange={(event)=>{
                                    setAddDepositValue({
                                      ...addDepositValue,
                                      channel_id:event.value
                                    })
                                  }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xl="4">
                                  <p>Nominal</p>
                                </Col>
                                <Col xl="8">
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>{"Rp"}</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      className="form-control"
                                      name="saldo-nominal"
                                      type="number"
                                      onClick={(event)=>{
                                        const cancelWheel = (event) => event.preventDefault();
                                        event.target.addEventListener("wheel", cancelWheel)
                                      }}
                                      onChange={(event)=>{
                                        setAddDepositValue({
                                          ...addDepositValue,
                                          nominal:event.target.value
                                        })
                                      }}
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col xl="4">
                                  <p>Note</p>
                                </Col>
                                <Col xl="8">
                                  <Input
                                    type="textarea"
                                    name="nominal"
                                    style={{ height: 100 }}
                                    onChange={(event)=>{
                                      setAddDepositValue({
                                        ...addDepositValue,
                                        note:event.target.value
                                      })
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <Row className="justify-content-center">
                              <Col xl="3" sm="6" xs="6">
                                <Button
                                  color="secondary"
                                  type="reset"
                                  className="mr-1"
                                  onClick={() => showDepositManual()}
                                >
                                  {"Cancel"}
                                </Button>
                              </Col>
                              <Col xl="3" sm="6" xs="6">
                                <Button color="primary" type="submit">{"Confirm"}</Button>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DepositList;
