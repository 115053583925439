import axios from 'axios'
import {
    GET_TIMELINE_REQUEST,
    GET_TIMELINE_SUCCESS,
    GET_TIMELINE_FAILED,
    GET_DETAIL_VERIFICATION_REQUEST,
    GET_DETAIL_VERIFICATION_SUCCESS,
    GET_DETAIL_VERIFICATION_FAILED
} from '../../actionTypes'

export const timeLineRequest = () => {
    return {
      type: GET_TIMELINE_REQUEST,
    };
  };
  
  export const timeLineSuccess = (value) => {
    return {
      type: GET_TIMELINE_SUCCESS,
      payload: value,
    };
  };
  
  export const timeLineFailed = (value) => {
    return {
      type: GET_TIMELINE_FAILED,
      error: value,
    };
  };

  // get detail
  export const detailVerificationRequest = () => {
    return {
      type: GET_DETAIL_VERIFICATION_REQUEST,
    };
  };
  
  export const detailVerificationSuccess = (value) => {
    return {
      type: GET_DETAIL_VERIFICATION_SUCCESS,
      payload: value,
    };
  };
  
  export const detailVerificationFailed = (value) => {
    return {
      type: GET_DETAIL_VERIFICATION_FAILED,
      error: value,
    };
  };

  export const getTimeLine = (id) => (dispatch) => {
    //   event.preventDefault();
      // console.log("register value");
    
      const URI = `pending-verification/${id}/timeline`;
    
      return axios
        .get(URI)
        .then((response) => {
          console.log("getTimeLine response", response);
          dispatch(timeLineSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // get detail
    export const getDetailVerification = (id) => (dispatch) => {
      //   event.preventDefault();
        // console.log("register value");
      
        const URI = `pending-verification/${id}`;
      
        return axios
          .get(URI)
          .then((response) => {
            console.log("getDetailVerification response :", response);
            dispatch(detailVerificationSuccess(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      };

      