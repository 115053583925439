import axios from 'axios'
import {
    GET_LIST_PARTNER_PRABAYAR_REQUEST,
    GET_LIST_PARTNER_PRABAYAR_SUCCESS,
    GET_LIST_PARTNER_PRABAYAR_FAILED,
    
    GET_LIST_CATEGORY_PRABAYAR_REQUEST,
    GET_LIST_CATEGORY_PRABAYAR_SUCCESS,
    GET_LIST_CATEGORY_PRABAYAR_FAILED,

    GET_LIST_PROVIDER_PRABAYAR_REQUEST,
    GET_LIST_PROVIDER_PRABAYAR_SUCCESS,
    GET_LIST_PROVIDER_PRABAYAR_FAILED
} from '../../../actionTypes'

// LIST PARTNER ACTION

export const listPartnerPrabayarRequest = () => {
    return {
        type: GET_LIST_PARTNER_PRABAYAR_REQUEST,
    }
}

export const listPartnerPrabayarSuccess = (value) => {
    return {
        type: GET_LIST_PARTNER_PRABAYAR_SUCCESS,
        payload: value
    }
}

export const listPartnerPrabayarFailed = (value) => {
    return {
        type: GET_LIST_PARTNER_PRABAYAR_FAILED,
        error: value
    }
}

export const getListPartnerPrabayar = () => (dispatch) => {
    const URI = `product-list/prabayar/partner`

    axios
    .get(URI)
    .then(res => {
        console.log("getListPartnerPrabayar respose: ", res.data)
        dispatch(listPartnerPrabayarSuccess(res.data))
    })
    .catch(err => console.log('getListPartnerPrabayar error :', err))
}


// LIST CATEGORY ACTION

export const listCategoryPrabayarRequest = () =>{
    return {
        type: GET_LIST_CATEGORY_PRABAYAR_REQUEST
    }
}

export const listCategoryPrabayarSuccess = (value) => {
    return {
        type: GET_LIST_CATEGORY_PRABAYAR_SUCCESS,
        payload: value
    }
}

export const listCategoryPrabayarFailed = (value) => {
    return {
        type: GET_LIST_CATEGORY_PRABAYAR_FAILED,
        error: value
    }
}

export const getListCategoryPrabayar = () => (dispatch) => {
    const URI = `product-list/prabayar/category`

    axios
    .get(URI)
    .then(res => {
        console.log('getListCategoryPrabayar response: ', res.data)
        dispatch(listCategoryPrabayarSuccess(res.data))
    })
    .catch(err => console.log('getListCategoryPrabayar error :', err))
}

// LIST PROVIDER ACTION

export const listProviderPrabayarRequest = () =>{
    return {
        type: GET_LIST_PROVIDER_PRABAYAR_REQUEST
    }
}

export const listProviderPrabayarSuccess = (value) => {
    return {
        type: GET_LIST_PROVIDER_PRABAYAR_SUCCESS,
        payload: value
    }
}

export const listProviderPrabayarFailed = (value) => {
    return {
        type: GET_LIST_PROVIDER_PRABAYAR_FAILED,
        error: value
    }
}

export const getListProviderPrabayar = () => (dispatch) => {
    const URI = `product-list/prabayar/provider`

    axios
    .get(URI)
    .then(res => {
        console.log('getListProviderPrabayar response :' , res.data)
        dispatch(listProviderPrabayarSuccess(res.data))
    })
    .catch(err => console.log('getListProviderPrabayar error: ', err))
}