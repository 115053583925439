import {
    GET_LIST_CATEGORY_PASCABAYAR_REQUEST,
    GET_LIST_CATEGORY_PASCABAYAR_SUCCESS,
    GET_LIST_CATEGORY_PASCABAYAR_FAILED
} from '../../../actionTypes'

const initialState = {
    data:[],
    isLoading:false,
    error:''
}

const listCategoryPascabayarReducer = (state = initialState, action ) => {
    switch (action.type) {
        case GET_LIST_CATEGORY_PASCABAYAR_REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case GET_LIST_CATEGORY_PASCABAYAR_SUCCESS:
            return {
                ...state,
                isLoading:false,
                data: action.payload
            }
        case GET_LIST_CATEGORY_PASCABAYAR_FAILED:
            return {
                ...state,
                isLoading:false,
                error: action.error
            }
    
        default:
            return state;
    }
}

export default listCategoryPascabayarReducer