import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import axios from "axios";
import "../../../assets/scss/custom/manage-user-custom.scss";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap";

const Provider = (props) => {

  const columnsProvider = [
    {
      name: "Provider Name",
      selector: "provider_name",
      sortable: true,
      center: true,
    },
    {
      name: "Provider Code",
      selector: "provider_code",
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: "action",
      center: true,
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] provider") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditProviderValue({
                id:row.id,
                code:row.provider_code,
                name:row.provider_name
              })
              showEditProvider()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] provider") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDeletePrabayar(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  const history = useHistory();

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [addProvider, setAddProvider] = useState(false);
  const [editProvider, setEditProvider] = useState(false);

  const [alertDeleteSuccess, setAlertDeleteSuccess] = useState(false);

  // ALERT DELETE PRODUCT PRA-BAYAR
  const DisplayAlertDeletePrabayar = (id) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProvider(id)
        
      }
    })
  };

  // Display Modal Form
  const showAddProvider = () => setAddProvider(!addProvider);
  const showEditProvider = () => setEditProvider(!editProvider);


  // ACTION HANDLER
  
  const handleSortProvider = (column, sortDirection) => {
    setSortColumnProvider(column.selector);
    setSortDirectionProvider(sortDirection);
  };
  const onSearchProvider = (event) => {setKeywordProvider(event.target.value)};

  // STATE DATA TABLE
  const [providerTable, setProviderTable] = useState({});
  const [pageProvider, setPageProvider] = useState(1);
  const [sortColumnProvider,setSortColumnProvider] = useState("");
  const [sortDirectionProvider,setSortDirectionProvider] = useState("");
  const [countPageProvider,setCountPageProvider] = useState(5);
  const [keywordProvider, setKeywordProvider] = useState("");

  // STATE FOR ADD PROVIDER
  const [addProviderCode, setAddProviderCode]=useState()
  const [addProviderName, setAddProviderName]=useState()

  const [addProviderStatus, setAddProviderStatus]=useState(false)
  
  // STATE FOR EDIT PROVIDER
  const [editProviderValue, setEditProviderValue]=useState({
    id:'',
    code:'',
    name:''
  })
  const [editProviderStatus, setEditProviderStatus]=useState(false)

  // STATE FOR DELETE
  const [deleteProviderStatus, setDeleteProviderStatus]=useState(false)
  

  // FETCHING DATA TABLE
  const getDataTableProvider = () => {
    const URI = `product-provider/data`;

    return axios
      .get(URI, {
        params: {
          page: pageProvider,
          per_page: countPageProvider,
          sort_column: sortColumnProvider,
          sort_direction: sortDirectionProvider,
          keyword: keywordProvider,
        },
      })
      .then((response) => {
        setProviderTable(response.data);
        console.log("getProviderTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ADD PROVIDER
  const postProvider = () => {
    const URI = `product-provider/store`;

    return axios
      .post(URI, {
        code: addProviderCode,
        name: addProviderName
      })
      .then((response) => {
        setAddProviderStatus(!addProviderStatus)
        setAddProvider(!addProvider)
        toast.success("Success Add New Provider !", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("postProvider response: ", response.status);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // EDIT PROVIDER
  const postEditProvider = () => {
    const URI = `product-provider/${editProviderValue.id}/update`;

    return axios
      .post(URI, {
        code: editProviderValue.code,
        name:editProviderValue.name
      })
      .then((response) => {
        setEditProviderStatus(!editProviderStatus)
        setEditProvider(!editProvider)
        toast.success("Success Edit Provider !", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("postProvider response: ", response.status);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // DELETE PROVIDER
  const deleteProvider = (id) => {
    const URI = `product-provider/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      setDeleteProviderStatus(!deleteProviderStatus)
      console.log("deleteProvider response: ", res)
      toast.success("Success delete provider !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      console.log("deleteProvider error: ", err)
      toast.error("Failed to delete provider !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }
  

  useEffect(() => {
    getDataTableProvider()
  }, [
    pageProvider,
    sortColumnProvider,
    sortDirectionProvider,
    countPageProvider,
    addProviderStatus,
    deleteProviderStatus,
    editProviderStatus,
  ]);

  return (
    <Fragment>
      <Breadcrumb parent="Product" title="Provider" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {userPermission.includes("[read] provider") ? (
              <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <Form className="needs-validation" noValidate="">
                      <div className="form-row">
                        <Col xl="7" className="mb-3">
                          <Input
                            className="form-control"
                            name="keyword"
                            type="text"
                            placeholder="Keyword"
                            onChange={(event)=> onSearchProvider(event)}
                          />
                        </Col>
                        <Col xl="5">
                          <Row className="justify-content-around">
                            <Button color="secondary" onClick={()=>getDataTableProvider()}>{"Search"}</Button>
                            {userPermission.includes("[create] provider") ? (
                              <Button
                              color="primary"
                              className="sweet-8"
                              name="alertSuccess"
                              onClick={() => showAddProvider()}
                            >
                              {"Add Provider"}
                            </Button>
                            ) : null}
                          </Row>
                        </Col>
                      </div>
                    </Form>
                    <DataTable
                      columns={columnsProvider}
                      data={providerTable.data}
                      pagination={true}
                      paginationServer
                      paginationTotalRows={providerTable.total}
                      onChangePage={(pageProvider) =>
                        setPageProvider(pageProvider)
                      }
                      onChangeRowsPerPage={(countPageProvider) =>
                        setCountPageProvider(
                          countPageProvider
                        )
                      }
                      onSort={handleSortProvider}
                      paginationPerPage={countPageProvider}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    />
                  </Col>
                </Row>

                {/* Modal Add Provider */}
                <Modal
                  isOpen={addProvider}
                  toggle={() => showAddProvider()}
                  centered
                >
                  <ModalHeader toggle={() => showAddProvider()}>
                    {"Add Provider"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Provider Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="ppob-product-code"
                                  type="text"
                                  name="ppob-product-code"
                                  className="form-control digits"
                                  onChange={(event)=>{
                                    setAddProviderName(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Provider Code"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="text"
                                  name="ppob-product-name"
                                  id="ppob-product-name"
                                  onChange={(event)=>{
                                    setAddProviderCode(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" md="3">
                              <Button
                                color="secondary"
                                type="reset"
                                onClick={() => showAddProvider()}
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" md="3">
                              <Button color="primary" className="mr-1"
                              onClick={()=>postProvider()}
                              >
                                {"Confirm"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                {/* Modal Edit Provider */}
                <Modal
                  isOpen={editProvider}
                  toggle={() => showEditProvider()}
                  centered
                >
                  <ModalHeader toggle={() => showEditProvider()}>
                    {"Edit Provider"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Provider Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="ppob-product-code"
                                  type="text"
                                  name="ppob-product-code"
                                  defaultValue={editProviderValue.name}
                                  onChange={(event)=>{
                                    setEditProviderValue({
                                      ...editProviderValue,
                                      name:event.target.value
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Provider Code"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="text"
                                  name="ppob-product-name"
                                  id="ppob-product-name"
                                  defaultValue={editProviderValue.code}
                                  onChange={(event)=>{
                                    setEditProviderValue({
                                      ...editProviderValue,
                                      code:event.target.value
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" md="3">
                              <Button
                                color="secondary"
                                type="reset"
                                onClick={() => showEditProvider()}
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" md="3">
                              <Button color="primary" className="mr-1" onClick={()=>postEditProvider()}>
                                {"Save"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Provider;
