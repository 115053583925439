import React, { useState, Fragment, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import Breadcrumb from "../../../layout/breadcrumb";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import dragula from "react-dragula";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import {getListCategoryProductSort, getListProviderProductSort} from '../../../redux/action/product-sort/index'

import axios from "axios";

const ProductSort = () => {

  const dispatch = useDispatch()
  const dataListCategory = useSelector(state => state.listCategoryProductSortReducer.data.data)
  const dataListProvider = useSelector(state => state.listProviderProductSortReducer.data.data)

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const listCategory = dataListCategory !== undefined ? 
    dataListCategory.map((item)=>(
      { value:item.id, label:item.name }
    ))
  :(<p>data kosong</p>);

  const listProvider = dataListProvider !== undefined ? 
  dataListProvider.map((item)=>(
    { value:item.id, label:item.name }
  ))
:(<p>data kosong</p>);

  const [addSort, setAddSort] = useState(false);
  const [alertDeleteSuccess,setAlertDeleteSuccess]=useState(false)

  // STATE PRODUCT SORT DATA FROM BACKEND
  const [productSortData, setProductSortData]=useState()


  // ALERT DELETE PRODUCT PASCA-BAYAR
  const DisplayAlertDeleteSort = (id) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductSort(id)
        
      }
    })
  };



  // STATE FILTER VALUE
  const [filterCategoryValue, setFilterCategoryValue]=useState()
  const [filterProviderValue, setFilterProviderValue]=useState()

  // STATE ADD NEW PRODUCT SORT
  const [addProductSortName, setAddProductSortName]=useState()
  const [addProductSortProviderId, setAddProductSortProviderId]=useState()
  const [addProductSortCategoryId, setAddProductSortCategoryId]=useState()
  const [addProductSortResponseStatus, setAddProductSortResponseStatus]=useState(false)

  // STATE FOR UPDATE
  // const [newDataDragula, setNewDataDragula]=useState()
  // console.log("newDataDragula: ",newDataDragula)

  // STATE DELETE PRODUCT SORT
  const [deleteProductSortResponse, setDeleteProductSortResponse]=useState()


  const DisplayAddSort = () => setAddSort(!addSort);
  
  // const DeleteProductSort = (name) => setListProductSort(
  //   listProductSort.filter((obj,)=>{
  //     return obj.length !== 0
  //   })
  // );
  

  // FETCH DATA PRODUCT SORT
  const getProductSortData = () => {
    const URI = `product-sort/data`

    axios
    .get(URI,{
      params:{
        provider:filterProviderValue,
        category:filterCategoryValue,
      }
    })
    .then(res => {
      setProductSortData(res.data.data)
      console.log("getProductSortData response: ", res)
    })
    .catch(err => {
      console.log('getProductSortData error: ', err)
    })
  }

  // ADD NEW PRODUCT SORT
  const addNewProductSort = () => {
    const URI = `product-sort/store`

    axios
    .post(URI, {
      sort_name:addProductSortName,
      provider: addProductSortProviderId,
      category: addProductSortCategoryId,
    })
    .then(res => {
      setAddProductSortResponseStatus(!addProductSortResponseStatus)
      toast.success("Success Adding New Product Sort !", {
        position: toast.POSITION.TOP_RIGHT
      });
      setAddSort(!addSort)
      console.log('addNewProductSort response: ', res)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
      console.log('addNewProductSort error: ', error)
    })
  }

  // UPDATE PRODUCT SORT
  const updateProductSort = (data) => {
    const URI = `product-sort/update-sort`

    axios
    .post(URI, data)
    .then(res => {
      toast.success("Success Update Product Sort !", {
        position: toast.POSITION.TOP_RIGHT
        });
      console.log('updateProductSort response :', res)
    })
    .catch(err =>{
      toast.error("Failed to Update Product Sort !", {
        position: toast.POSITION.TOP_RIGHT
        });
      console.log('updateProductSort error :', err)
    })
  }

  const deleteProductSort = (id) => {
    const URI = `product-sort/${id}/delete`

    axios
    .post(URI)
    .then(res=> {
      console.log('deleteProductSort response: ', res)
      setDeleteProductSortResponse(!deleteProductSortResponse)
      toast.success("Success Delete Product Sort !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      console.log('deleteProductSort delete :', err)
      toast.error("Failed to Delete Product Sort !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }


  

  useEffect(() => {
    // DRAGULA ACTION
  dragula([document.getElementById("dragula-container")])
  .on('drop', (el,_) => {
    
    const targetContainer = document.querySelector('#dragula-container')
    const selectedProductItems = targetContainer.getElementsByClassName('dragula-item')
    let newProductItems = Array.from(selectedProductItems).map(function(item){
      return {
        id: JSON.parse(item.dataset.id),
        // name:item.textContent,
        // canDelete:JSON.parse(item.dataset.delete),
        // category_id:JSON.parse(item.dataset.category),
        // provider_id:JSON.parse(item.dataset.provider)
      }
    })
    // setNewDataDragula(newProductItems)
    updateProductSort(newProductItems)
  })

// FETCH DATA TABLE
    getProductSortData()

  }, [
    addProductSortResponseStatus, 
    deleteProductSortResponse
  ]);

  useEffect(() => {
    dispatch(getListCategoryProductSort())
    dispatch(getListProviderProductSort())
  }, [])

  // eslint-disable-next-line

  return (
    <Fragment>
      <Breadcrumb parent="Product" title="Product Sort" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            {userPermission.includes("[read] product sort") ? (
              <Card>
              <CardBody>
                <Form
                // className="needs-validation"
                // noValidate=""
                // onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <Col xl="5 mt-3">
                      <Row>
                        <Col xl="6">
                          <Label>Provider</Label>
                          <Select
                            options={listProvider}
                            onChange={(event)=>{
                              setFilterProviderValue(event.value)
                            }}
                            // defaultValue={listProvider[0]}
                          />
                        </Col>
                        <Col xl="6">
                          <Label>Category</Label>
                          <Select
                            options={listCategory}
                            onChange={(event)=>{
                              setFilterCategoryValue(event.value)
                            }}
                            // defaultValue={listCategory[0]}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="6">
                      <Row className="justifi-content-betweeen ml-2">
                        <Button color="secondary" className="mt-5 mr-auto" onClick={()=>getProductSortData()} >
                          {"Search"}
                        </Button>
                        {userPermission.includes("[create] product sort") ? (
                          <Button
                          color="primary"
                          className="mt-5"
                          onClick={() => DisplayAddSort()}
                        >
                          {"Add Sort"}
                        </Button>
                        ) : null}
                      </Row>
                    </Col>
                  </div>
                  <Card className="mt-1">
                    <CardBody>
                      <Col xl="12">
                        <div
                          className="ui-sortable small-drag"
                          id="draggableMultiple"
                        >
                          <div className="container" id="dragula-container" >
                            {/* {console.log("data list: ", listProductSort)} */}
                            {productSortData !== undefined && productSortData.length !== 0 ? productSortData.map((item, index) => (
                              <div key={index} data-id={item.id} data-delete={item.canDelete} data-category={item.category_id} data-provider={item.provider_id} className="dragula-item">{item.name}{item.canDelete === true && userPermission.includes("[delete] product sort") ?
                              (
                                <Button
                                color="danger"
                                className="btn-xs ml-2"
                                onClick={() => DisplayAlertDeleteSort(item.id)}
                              >
                                <i class="fa fa-trash"></i>
                              </Button>
                              ):null}</div>
                            )):(<p style={{textAlign:"center"}}>Product Sort Kosong, Pilih Provider dan Category Lain</p>)}
                          </div>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Form>
              </CardBody>
            </Card>
            ) : null}
          </Col>
        </Row>
        
        {/* Modal Add Sort */}
        <Modal isOpen={addSort} toggle={() => DisplayAddSort()} centered>
          <ModalHeader toggle={() => DisplayAddSort()}>
            <h6>Add New Sort</h6>
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Row>
                  <Col xl="4">
                    <Label>Sort Name</Label>
                  </Col>
                  <Col xl="8">
                    <Input type="text" onChange={(event)=>{
                      setAddProductSortName(event.target.value)
                    }}/>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xl="4">
                    <Label>Provider</Label>
                  </Col>
                  <Col xl="8">
                    <Select options={listProvider} onChange={(event)=>{
                      setAddProductSortProviderId(event.value)
                    }}/>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xl="4">
                    <Label>Category</Label>
                  </Col>
                  <Col xl="8">
                    <Select options={listCategory} onChange={(event)=>{
                      setAddProductSortCategoryId(event.value)
                    }}/>
                  </Col>
                </Row>
              </FormGroup>
              <Row className="justify-content-center mt-5">
                <Button
                  color="secondary"
                  type="reset"
                  onClick={() => DisplayAddSort()}
                >
                  Cancel
                </Button>
                <Button color="primary" className="ml-3" onClick={()=>addNewProductSort()}>
                  Confirm
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default ProductSort;
