import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/deposit-custom.scss";
import Select from "react-select";

import axios from "axios";

const RedeemList = () => {

  const columnsRedeemPoint = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      center:true,
      width: "170px",
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Product Code",
      selector: "product_code",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "Price",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp.${(row.price).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Point",
      selector: "point",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Tax",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp.${(row.tax).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Total",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp.${(row.total).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      cell:(row)=>(
        row.status === 'SUCCESS' ? <Badge color="success">SUCCESS</Badge> : (
          row.status ==='PENDING' ? <Badge color="warning">PENDING</Badge>: (
            row.status === 'FAILED' ? <Badge color="danger">FAILED</Badge> : <Badge color="danger">ERROR</Badge>
          )
        )
      )
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        userPermission.includes("[update] redeem list") ? (
          <Button
          color="primary"
          onClick={() => {
            setEditRedeemListValue({
              id:row.id,
              transaction_code:row.transaction_code,
              user_id:row.user_id,
              channel:row.channel,
              product_code:row.product_code,
              product_name:row.product_name,
              type:row.type,
              price:row.price,
              point:row.point,
              tax:row.tax,
              total:row.total,
              date:row.date,
              status:row.status,
              note:row.note,
            })
            showRedeemPointDetail()
          }}
          className="btn-xs"
        >
          <i class="fa fa-edit"></i>
        </Button>
        ) : null
      )
    },
  ];

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)


  const [sweetAlert, setSweetAlert] = useState(false);
  const [redeemPointDetail, setRedeemPointDetail] = useState(false);
  const [generalData, setGeneralData] = useState([]);

  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  const listStatus = [
    { value: "ALL", label: "ALL" },
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "PENDING", label: "PENDING" },
    { value: "FAILED", label: "FAILED" },
  ];

  const listStatusEdit = [
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "PENDING", label: "PENDING" },
    { value: "FAILED", label: "FAILED" },
  ];

  const showRedeemPointDetail = () => setRedeemPointDetail(!redeemPointDetail);

  // ACTION HANDLER
  const handleSortRedeemList = (column, sortDirection) => {
    setSortColumnRedeemList(column.selector);
    setSortDirectionRedeemList(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchRedeemList = (event) => {setKeywordRedeemList(event.target.value)};

  // STATE DATA TABLE
  const [redeemListTable, setRedeemListTable] = useState({});
  const [pageRedeemList, setPageRedeemList] = useState(1);
  const [sortColumnRedeemList,setSortColumnRedeemList] = useState("");
  const [sortDirectionRedeemList,setSortDirectionRedeemList] = useState("");
  const [countPageRedeemList,setCountPageRedeemList] = useState(5);
  const [keywordRedeemList, setKeywordRedeemList] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState('')

  // STATE FOR EDIT DATA ON DATA-TABLE
  const [editRedeemListValue, setEditRedeemListValue]=useState({
    id:'',
    transaction_code:'',
    user_id:'',
    channel:'',
    product_code:'',
    product_name:'',
    type:'',
    price:'',
    point:'',
    tax:'',
    total:'',
    date:'',
    status:'',
    note:'',
  })
  console.log('editRedeemListValue: ', editRedeemListValue)
  const [editRedeemListResponse, setEditRedeemListResponse]=useState(false)
  

  // FETCHING DATA TABLE
  const getDataTableRedeemList = () => {
    const URI = `point/redeem-list/data`;

    return axios
      .get(URI, {
        params: {
          page: pageRedeemList,
          per_page: countPageRedeemList,
          sort_column: sortColumnRedeemList,
          sort_direction: sortDirectionRedeemList,
          keyword: keywordRedeemList,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          status: status,
        },
      })
      .then((response) => {
        setRedeemListTable(response.data);
        console.log("getRedeemListTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // EDIT DATA ON DATA-TABLE
  const editRedeemList = () => {
    const URI = `point/redeem-list/${editRedeemListValue.id}/update`;

    axios
      .post(URI, {
        status:editRedeemListValue.status,
        note:editRedeemListValue.note
      })
      .then((response) => {
        toast.success("Success Edit Redeem List !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setRedeemPointDetail(!redeemPointDetail)
        setEditRedeemListResponse(!editRedeemListResponse)
        console.log("editRedeemList response: ", response.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  useEffect(() => {
    getDataTableRedeemList()
  }, [
    pageRedeemList,
    sortColumnRedeemList,
    sortDirectionRedeemList,
    countPageRedeemList,
    editRedeemListResponse
  ]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Point" title="Redeem List" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form>
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchRedeemList(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="12" xs="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Status"}</Label>
                        <Select
                        options={listStatus}
                        onChange={(event)=>{
                          setStatus(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTableRedeemList()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columnsRedeemPoint}
                  data={redeemListTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={redeemListTable.total}
                  onChangePage={(pageRedeemList) =>
                    setPageRedeemList(pageRedeemList)
                  }
                  onChangeRowsPerPage={(countPageRedeemList) =>
                    setCountPageRedeemList(
                      countPageRedeemList
                    )
                  }
                  onSort={handleSortRedeemList}
                  paginationPerPage={countPageRedeemList}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                
                {/* Modal Redeem Point Detail */}
                <Modal
                  isOpen={redeemPointDetail}
                  toggle={() => showRedeemPointDetail()}
                  centered
                >
                  <ModalHeader toggle={() => showRedeemPointDetail()}>
                    {"Edit & Detail Redeem Point"}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Transaction Code</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.transaction_code}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>User ID</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.user_id}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Channel</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.channel}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Product Code</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.product_code}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Type</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.type}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Product Name</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.product_name}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Point</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editRedeemListValue.point}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Tax</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editRedeemListValue.tax).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Total</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editRedeemListValue.total).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Date</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`${editRedeemListValue.date} WIB`}</p>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Row>
                            <Col xl="6" sm="6" xs="6">
                              <p>Status</p>
                            </Col>
                            <Col xl="6" sm="6" xs="6">
                              <Select
                              options={listStatusEdit}
                              defaultValue={listStatusEdit.filter(list => list.value === editRedeemListValue.status)}
                              onChange={(event)=>{
                                setEditRedeemListValue({
                                  ...editRedeemListValue,
                                  status:event.value
                                })
                              }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="mb-3">
                            <Col xl="2">
                              <p>Note</p>
                            </Col>
                            <Col xl="10">
                              <textarea
                                className={"ng-untouched ng-pristine ng-valid"}
                                style={{ height: 100, width: 300 }}
                                id="notes"
                                placeholder="Write Your Notes Here"
                                defaultValue={editRedeemListValue.note}
                                onChange={(event)=>{
                                  setEditRedeemListValue({
                                    ...editRedeemListValue,
                                    note:event.target.value
                                  })
                                }}
                              ></textarea>
                            </Col>
                          </Row>
                        </FormGroup>
                        <Row className="justify-content-center">
                          <Col xl="3" sm="6" xs="6">
                            <Button
                              color="secondary"
                              type="reset"
                              className="mr-1"
                              onClick={() => showRedeemPointDetail()}
                            >
                              {"Cancel"}
                            </Button>
                          </Col>
                          <Col xl="3" sm="6" xs="6">
                            <Button color="primary" onClick={()=>editRedeemList()}>
                              {"Confirm"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RedeemList;
