import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "../redux/reducers/index";

const persistedState = localStorage.getItem('reduxState') 
                       ? JSON.parse(localStorage.getItem('reduxState'))
                       : {}

const store = createStore(rootReducer, persistedState, applyMiddleware(thunk,logger));

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
})

export default store;