import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { FileText, LogIn, Mail, User, MessageSquare, Bell, Minimize, Search, ShoppingCart, Minus, Plus, X } from 'react-feather';
import { useHistory } from 'react-router-dom'
import { firebase_app } from '../../data/config'
import {useAuth0} from '@auth0/auth0-react'
import SweetAlert from "sweetalert2";
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';
import axios from 'axios'


import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();

const Rightbar = (props) => {    

  
  const history = useHistory();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('')
  const [searchresponsive, setSearchresponsive] = useState(false)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  const [cartDropdown, setCartDropDown] = useState(false)
  const [notificationDropDown, setNotificationDropDown] = useState(false)
  const [profileDropDown, setProfileDropDown] = useState(false)
  const [chatDropDown, setChatDropDown] = useState(false)
  const [sweetAlert, setLogoutError] = useState(false)

  const dataAdminUser = useSelector(state => state.userReducer.data.user)

  
  // auth0 profile
  const {logout} = useAuth0()
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  const DisplayLogoutError = () => {
    // e.prevenDefault();
    setLogoutError(true);
    SweetAlert.fire({ title: "Failed Logout", text: "An error has occured, please try again later.", icon: "error" });
  };

  useEffect(() => {
    // setProfile(localStorage.getItem('profileURL') || man);
    // setName(localStorage.getItem('Name'))
    // if(localStorage.getItem("layout_version") === "dark-only"){
    //   setMoonlight(true)
    // }
  }, []);

  const Logout= () => {
    axios.post("/logout-admin")
    .then(response=>{
      if (response.data.status == 'ok') {
        localStorage.removeItem('reduxState')
        history.push(`${process.env.PUBLIC_URL}/login`)
      }
      else (
        DisplayLogoutError()
      )
    })
  }

  const UserMenuRedirect = (redirect) => {
    history.push(redirect)
  }

  return (
    <Fragment>
      <div className="nav-right col-8S pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-secondary">2</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
              <li>
                <Bell />
                <h6 className="f-18 mb-0">{"Notification"}</h6>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{"DeliveryProcessing"} <span className="pull-right">{"10 min."}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-success"></i>{"OrderComplete"}<span className="pull-right">{"1 hr"}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-info"></i>{"TicketsGenerated"}<span className="pull-right">{"3 hr"}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-danger"></i>{"DeliveryComplete"}<span className="pull-right">{"6 hr"}</span></p>
              </li>
              <li><button className="btn btn-primary" >{"CheckAllNotification"}</button>
              </li>
            </ul>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media" onClick={()=> setProfileDropDown(!profileDropDown)}>
              {/* <img className="b-r-10" src={authenticated ? auth0_profile.picture : profile} alt="" /> */}
              <div className="media-body"><span>{authenticated ? auth0_profile.name :  name}</span>
                <h6 className="mb-0 font-roboto">{dataAdminUser.full_name} <i className="middle fa fa-angle-down"></i></h6>
              </div>
            </div>
            <ul className={`profile-dropdown onhover-show-div ${profileDropDown ? "active" : ""}`}>
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/my-profile`)}><User /><span>{"Account"} </span></li>
              {/* <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app`)}><Mail /><span>{"Inbox"}</span></li>
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo`)}><FileText /><span>{"Taskboard"}</span></li> */}
              <li onClick={() => Logout()}><LogIn /><span>{"LogOut"}</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);