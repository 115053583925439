import {
    ROLE_LIST_REQUEST,
    ROLE_LIST_SUCCESS,
    ROLE_LIST_FAILED
  } from "../../actionTypes";
  
  const initialState = {
      Data : {},
      error: "",
  }
  
  const roleListReducer = (state = initialState, action) => {
    switch (action.type) {
        // register
    case ROLE_LIST_REQUEST:
        return {
          ...state,
        };
      case ROLE_LIST_SUCCESS:
        return {
          ...state,
          Data: action.payload,
        };
      case ROLE_LIST_FAILED:
        return {
          ...state,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default roleListReducer;