import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "../../../layout/breadcrumb";
import photoKTP from "../../../assets/images/CONTOH-KTP.jpg";
import photoSelfie from "../../../assets/images/user/photoSelfie.jpg";
import '../../../assets/scss/custom/location-custom.scss'
import Lightbox from "react-image-lightbox";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  CardFooter,
  Table,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
// import action redux
import { getAccountSummary } from "../../../redux/action/user-detail/account-summary/index";
import { getPersonalInformation, getPersonalPicture, getKtpPicture, postUploadPersonalPicture} from "../../../redux/action/user-detail/personal-information/index";
import { getAccountInformation} from '../../../redux/action/user-detail/account-information/index'

import { Upload, Download, UserX } from "react-feather";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import CountUp from "react-countup";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import "../../../assets/scss/custom/user-detail-custom.scss";
import Select from 'react-select'
import { toast } from 'react-toastify';
import {
  dataTableLogOTP,
  dataTableLogCredential,
} from "../../../data/dummyTableData";
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import { MapContainer, Marker, TileLayer, Popup} from "react-leaflet"
// import {Map, Marker, TileLayer, Popup} from "leaflet"
import axios from "axios";
import { map } from "leaflet";

const UserDetail = (props) => {
  
  // columns data table
  const columnsTransaction = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      width: "150px",
    },
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Product",
      selector: "product",
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "Destination Number",
      selector: "destination_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "SN",
      selector: "serial_number",
      sortable: true,
      center: true,
      width: "350px",
      wrap: true,
    },
    {
      name: "Partner",
      selector: "partner",
      sortable: true,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      cell: (row) =>
        row.status === "SUCCESS" ? (
          <Badge color="success" pill>
            {"Success"}
          </Badge>
        ) : row.status === "PENDING" ? (
          <Badge color="warning" pill>
            {"Pending"}
          </Badge>
        ) : (
          <Badge color="danger" pill>
            {"Failed"}
          </Badge>
        ),
    },
  ];

  const columnsMutation = [
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      center: true,
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: "credit",
      sortable: true,
      center: true,
    },
    {
      name: "Beginning Balance",
      selector: "beginning_balance",
      sortable: true,
      center: true,
    },
    {
      name: "Ending Balance",
      selector: "ending_balance",
      sortable: true,
      center: true,
    },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      center: true,
      width:"250px",
      wrap:true
    },
  ];

  const columnsDownline = [
    {
      name: "VP",
      selector: "vp",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Sponsor",
      selector: "sponsor_vp",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Level",
      selector: "level",
      sortable: true,
      center: true,
    },
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Saldo",
      selector: "saldo",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Commission",
      selector: "commission",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Point",
      selector: "point",
      sortable: true,
      center: true,
    },
    {
      name: "Control",
      selector: "control",
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Register Date",
      selector: "register_at",
      sortable: true,
      center: true,
      width: "250px",
    },
  ];

  const columnsLogCredential = [
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      width:"170px"
    },
    {
      name: "Update Date",
      selector: "updated_at",
      sortable: true,
      center: true,
      width: "250px",
    },
    // {
    //   name: "Time Stamp",
    //   selector: "timestamp",
    //   sortable: true,
    //   center: true,
    //   width: "150px",
    // },
    {
      name: "Update By",
      selector: "update_by_username",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      center: true,
      width:"170px"
    },
  ];

  const columnsLogOTP = [
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
    },
    {
      name: "Created Date",
      selector: "created_at",
      sortable: true,
      width: "250px",
      center: true,
    },
    {
      name: "Expired Date",
      selector: "expired_at",
      sortable: true,
      width: "250px",
      center: true,
    },
    {
      name: "OTP Token",
      selector: "otp_token",
      sortable: true,
      center: true,
    },
    {
      name: "Platform",
      selector: "platform",
      sortable: true,
      center: true,
    },
    {
      name: "Method",
      selector: "method",
      sortable: true,
      center: true,
    },
  ];

  const columnsPointMutation = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      width: "150px",
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      left: true,
      width: "150px",
    },
    {
      name: "Credit",
      selector: "credit",
      sortable: true,
      left: true,
      width: "150px",
    },
    {
      name: "Previous Point",
      selector: "previous_point",
      sortable: true,
      left: true,
      width: "150px",
    },
    {
      name: "Point Afterward",
      selector: "point_afterward",
      sortable: true,
      left: true,
      width: "150px",
    },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      left: true,
      width: "250px",
      wrap:true
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "250px",
    },
  ];

  const columnsCommissionMutation = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: "credit",
      sortable: true,
      center: true,
    },
    {
      name: "Previous Commission",
      selector: "previous_commission",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Commission Afterward",
      selector: "commission_afterward",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "250px",
    },
  ];

  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  // list react select option
  const listStatus = [
    {value:'PENDING', label:'Pending'},
    {value:'ACTIVE', label:'Active'},
    {value:'SUSPEND', label:'Suspend'}
  ]
  const listAccountType = [
    {value:'BASIC', label:'Basic'},
    {value:'FULLSERVICE', label:'Full Service'},
  ]
  const listTransactionByWhatsapp = [
    {value:'ACTIVE' , label:'Active'},
    {value:'NOT ACTIVE' , label:'Not Active'},
  ]
  const listTransactionByTelegram = [
    {value:'ACTIVE' , label:'Active'},
    {value:'NOT ACTIVE' , label:'Not Active'},
  ]
  const listTransactionByLine = [
    {value:'ACTIVE' , label:'Active'},
    {value:'NOT ACTIVE' , label:'Not Active'},
  ]

  // STATE FROM REDUX
  const [dataAccountSummary, setDataAccountSummary]=useState() 
  const dataAccountInformation = useSelector((state)=> state.accountInformationReducer.data.data)
  const dataPersonalInformation = useSelector(
    (state) => state.personalInformationReducer.data.data
  );
  const personalPicture = useSelector(state => state.personalInformationReducer.personalPicture)
  // console.log('personalPicture: ', personalPicture)
  const ktpPicture = useSelector(state => state.personalInformationReducer.ktpPicture)



  // STATE FOR CHART
  const [areaSpaline, setAreaSpaline] = useState({
    series: [
      {
        name: '',
        data: [],
      },
      {
        name: '',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#f25c00", "#a927f9"],
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "date",
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });
  // console.log("data chart: ", areaSpaline)

  // state for tab pane
  const [activeTab, setActiveTab] = useState("1");

  // datepicker
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sweetAlert, setSweetAlert] = useState(false);
  
  
  // Form Modal
  const [formUploadSelfieKtp, setFormUploadSelfieKtp] = useState(
    false
  );
  const [formUploadKTPPicture, setFormUploadKTPPicture] = useState(false);
  const [formUploadDataKtp, setFormUploadDataKtp]=useState(false)

  const [formEditSaldo, setFormEditSaldo] = useState(false);
  const [formEditPoint, setFormEditPoint] = useState(false);

  // Display Form Modal
  const DisplayFormUploadSelfieKtp = () =>
    setFormUploadSelfieKtp(!formUploadSelfieKtp);

  const DisplayFormUploadKTPPicture = () =>
    setFormUploadKTPPicture(!formUploadKTPPicture);

  const DisplayFormUploadDataKtp = () => setFormUploadDataKtp(!formUploadDataKtp)

  const DisplayFormEditSaldo = () => setFormEditSaldo(!formEditSaldo);

  const DisplayFormEditPoint = () => setFormEditPoint(!formEditPoint);

  // photo
  const initilindex = { index: 0, isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  // React leaflet function

  // Google Map
  // const BasicMap = withScriptjs(
  //   withGoogleMap((props) => (
  //     <GoogleMap
  //       google={props.google}
  //       defaultZoom={15}
  //       defaultCenter={{
  //         lat: location.mapPosition.lat,
  //         lng: location.mapPosition.lng,
  //       }}
  //     ></GoogleMap>
  //   ))
  // );

  const [location, setlocation] = useState({
    address: false,
    mapPosition: {
      lat: 18.5204,
      lng: 73.8567,
    },
    markerPosition: {
      lat: 18.5204,
      lng: 73.8567,
    },
  });

  

  const mapRef = useRef()

  // Fucntion Pop Up Sweet Alert
  const Displayalert = () => {
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  // date picker
  const handleStartDate = (date) => {
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
  };

  // STATE FOR ACTION HANDLER
  // account summary
  const [accountSummaryTable, setAccountSummaryTable] = useState();
  const [pointEdited, setPointEdited] = useState()
  const [saldoEdited, setSaldoEdited] = useState()
  const [editSaldoValue, setEditSaldoValue] = useState({
    type: "DEBIT",
    nominal: 0,
    note: "",
  });

  const [editPointValue, setEditPointValue] = useState({
    type: "DEBIT",
    nominal: 0,
    note: "",
  });
  

  const [editAccountInformationValue, setEditAccountInformationValue] = useState ()

  // console.log("editAccountInformationValue: ", editAccountInformationValue)

  // PERSONAL INFORMATION STATE
  const [uploadValue, setUploadValue] = useState()
  const [uploadKtpValue, setUploadKtpValue]=useState()
  const [uploadDataKtpValue, setUploadDataKtpValue]=useState({
    nik:"",
    nama_lengkap:"",
    tempat_lahir:"",
    tanggal_lahir:"",
    jenis_kelamin:"",
    alamat:"",
    kelurahan:"",
    kecamatan:"",
    rt:"",
    rw:"",
  })
  console.log("uploadDataKtpValue: ", uploadDataKtpValue)

  const [dataKtpLocale, setDataKtpLocale]=useState()
  console.log("dataKtpLocale: ", dataKtpLocale)

  // DATA PICTURE AFTER UPLOAD
  const [dataKtpPicture, setDataKtpPicture]=useState()
  const [dataSelfieKtp, setDataSelfieKtp]=useState()
  // console.log("dataSelfieKtp: ", dataSelfieKtp)

  // Table Mutation
  const [mutationTable, setMutationTable] = useState({});
  const [pageMutation, setPageMutation] = useState(1);
  const [sortColumnMutation, setSortColumnMutation] = useState('');
  const [sortDirectionMutation, setSortDirectionMutation] = useState('');
  const [countPageMutation, setCountPageMutation] = useState(5);
  const [typeMutation, setTypeMutation] = useState('')
  const [mutationStartDate, setMutationStartDate] = useState ()
  const [mutationEndDate, setMutationEndDate] = useState ()
  const [keywordMutation, setKeywordMutation] = useState ('')

  //Table Commission Mutation Table
  const [commissionMutationTable, setCommissionMutationTable] = useState({});
  const [pageCommissionMutation, setPageCommissionMutation] = useState(1);
  const [sortColumnCommissionMutation, setSortColumnCommissionMutation] = useState('');
  const [sortDirectionCommissionMutation, setSortDirectionCommissionMutation] = useState('');
  const [countPageCommissionMutation, setCountPageCommissionMutation] = useState(5);
  const [typeCommissionMutation, setTypeCommissionMutation] = useState('')
  const [commissionMutationStartDate, setCommissionMutationStartDate] = useState ()
  const [commissionMutationEndDate, setCommissionMutationEndDate] = useState ()
  const [keywordCommissionMutation, setKeywordCommissionMutation] = useState ('')

  // Table Point Mutation
  const [pointMutationTable, setPointMutationTable] = useState({});
  const [pagePointMutation, setPagePointMutation] = useState(1);
  const [sortColumnPointMutation, setSortColumnPointMutation] = useState('');
  const [sortDirectionPointMutation, setSortDirectionPointMutation] = useState('');
  const [countPagePointMutation, setCountPagePointMutation] = useState(5);
  const [typePointMutation, setTypePointMutation] = useState('')
  const [pointMutationStartDate, setPointMutationStartDate] = useState ()
  const [pointMutationEndDate, setPointMutationEndDate] = useState ()
  const [keywordPointMutation, setKeywordPointMutation] = useState ('')

  // Table Log OTP
  const [logOtpTable, setLogOtpTable]=useState({})
  const [pageLogOtp, setPageLogOtp] = useState(1);
  const [sortColumnLogOtp, setSortColumnLogOtp] = useState('');
  const [sortDirectionLogOtp, setSortDirectionLogOtp] = useState('');
  const [countPageLogOtp, setCountPageLogOtp] = useState(5);

  // Table Log Credential
  const [logCredentialTable, setLogCredentialTable]=useState({})

  // Table Downline
  const [downlineTableDefault, setDownlineTableDefault] = useState({});
  const [downlineTableSearchResult, setDownlineTableSearchResult]=useState({})
  const [pageDownline, setPageDownline] = useState(1);
  const [sortColumnDownline, setSortColumnDownline] = useState('');
  const [sortDirectionDownline, setSortDirectionDownline] = useState('');
  const [countPageDownline, setCountPageDownline] = useState(5);
  const [typeDownline, setTypeDownline] = useState('')
  const [downlineStartDate, setDownlineStartDate] = useState ()
  const [downlineEndDate, setDownlineEndDate] = useState ()
  const [keywordDownline, setKeywordDownline] = useState ('')
  const [filterDownlineTable, setFilterDownlineTable] = useState({
    basic: '',
    suspend:'',
    full_service:'',
    sponsor:'',
    anomali_control:'',
  })

  // STATE LOCATION
  const [dataLocation, setDataLocation]=useState()

  // LAT LONG MAP LOCATION
  const mapPosition = {
    lat: dataLocation !== undefined ? dataLocation.split(",",2)[0]:"",
    lng: dataLocation !== undefined ? dataLocation.split(",",2)[1]:"",
  }

  // ACTION HANDLER
  const handleSaldoEdit = (event) => {
    setEditSaldoValue({
      ...editSaldoValue,
      [event.target.name]: event.target.value,
    });
  };

  const handlePointEdit = (event) => {
    setEditPointValue({
      ...editPointValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditAccountInformation = (event) => {
    setEditAccountInformationValue({
      ...editAccountInformationValue,
      [event.target.name] : event.target.value
    })
  }

  // Handler Personal Information
  const handleChangeBirthDate = date => {
    setUploadDataKtpValue({
      ...uploadDataKtpValue,
      tanggal_lahir: date
    })
  };

  const handleJenisKelamin = (event) =>{
    setUploadDataKtpValue({
      ...uploadDataKtpValue,
      jenis_kelamin: event.target.value
    })
  }

  // Handler Mutation
  const handleSortMutation = (column, sortDirection) =>{
    setSortColumnMutation(column.selector)
    setSortDirectionMutation(sortDirection)
  }
  const handleChangeMutationStartDate = date => {
    setMutationStartDate(date)
  };
  const handleChangeMutationEndDate = date => {
    setMutationEndDate(date)
  };
  const onSearchMutation = (event) => {
    setKeywordMutation(event.target.value)
  }

  // Handler Commission Mutation
  const handleSortCommissionMutation = (column, sortDirection) =>{
    setSortColumnCommissionMutation(column.selector)
    setSortDirectionCommissionMutation(sortDirection)
  }
  const handleChangeCommissionMutationStartDate = date => {
    setCommissionMutationStartDate(date)
  };
  const handleChangeCommissionMutationEndDate = date => {
    setCommissionMutationEndDate(date)
  };
  const onSearchCommissionMutation = (event) => {
    setKeywordCommissionMutation(event.target.value)
  }

  // Handler Point Mutation
  const handleSortPointMutation = (column, sortDirection) =>{
    setSortColumnPointMutation(column.selector)
    setSortDirectionPointMutation(sortDirection)
  }
  const handleChangePointMutationStartDate = date => {
    setPointMutationStartDate(date)
  };
  const handleChangePointMutationEndDate = date => {
    setPointMutationEndDate(date)
  };
  const onSearchPointMutation = (event) => {
    setKeywordPointMutation(event.target.value)
  }

  // Handler Log OTP
  const handleSortLogOtp = (column, sortDirection) =>{
    setSortColumnLogOtp(column.selector)
    setSortDirectionLogOtp(sortDirection)
  }

  // Handler Downline
  const handleSortDownline = (column, sortDirection) =>{
    setSortColumnDownline(column.selector)
    setSortDirectionDownline(sortDirection)
  }
  const handleChangeDownlineStartDate = date => {
    setDownlineStartDate(date)
  };
  const handleChangeDownlineEndDate = date => {
    setDownlineEndDate(date)
  };
  const onSearchDownline = (event) => {
    setKeywordDownline(event.target.value)
  };
  const SearchDownlineAction = (keyword, data) =>{
    // console.log("keyword: ", keyword)
    // console.log("data: ", data)

    let obj = data.filter((downline) => {
      for (let i in downline) {
          if (['string','number'].includes(typeof(downline[i])) &&  downline[i].toString().toLowerCase().search(keyword) !== -1) {
              return true;
          }
      }
    });

    setDownlineTableSearchResult({data:obj, status:"ok"})
    // console.log("hasil: ", {data:obj,status:"ok"} )
  }


  // FUNCTION FETCHING DATA

  const getAccountSummary = () =>  {
    //   event.preventDefault();
      // console.log("register value");
    
      const uriUsers = `user-detail/${id}/account-summary`;
    
      return axios
        .get(uriUsers)
        .then((response) => {
          setDataAccountSummary(response.data.data)
          // console.log("getAccountsummary response", response);
          setAreaSpaline({
            series: [
              {
                name: response.data.data.mutasi.series[0].name,
                data: response.data.data.mutasi.series[0].data,
              },
              {
                name: response.data.data.mutasi.series[1].name,
                data: response.data.data.mutasi.series[1].data,
              },
            ],
            options: {
              chart: {
                height: 350,
                type: "area",
              },
              dataLabels: {
                enabled: false,
              },
              colors: ["#f25c00", "#a927f9"],
              stroke: {
                curve: "smooth",
              },
              xaxis: {
                type: "date",
                categories: response.data.data.mutasi.categories,
              },
              tooltip: {
                x: {
                  format: "dd/MM/yy HH:mm",
                },
              },
            },
          });
          // dispatch(accountSummarySuccess(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    };

  // fetching for getting account summary data table
  const getAccountSummaryDataTable = () => {
    axios
      .get(`user-detail/${id}/account-summary-datatable`)
      .then((res) => setAccountSummaryTable(res.data))
      .catch((err) => {
        // console.log("getAccountSummaryDataTable :", err)
      });
  };

  // post edit saldo
  const editAccountSummarySaldo = () => {
    axios
      .post(`user-detail/${id}/account-summary/edit-saldo`, {
        type: editSaldoValue.type,
        nominal: editSaldoValue.nominal, // 100k
        note: editSaldoValue.note,
      })
      .then((res) => {
        toast.success("Success Edit Saldo !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setFormEditSaldo(!formEditSaldo)
        setSaldoEdited(res.data.data)
        // console.log("editAccountSummarySaldo response: ", res.data.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:`${error}`,
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log("editAccountSummarySaldo error :", err)
      });
  };

  // post edit point
  const editAccountSummaryPoint = () => {
    axios
      .post(`user-detail/${id}/account-summary/edit-point`, {
        type: editPointValue.type,
        nominal: editPointValue.nominal,
        note: editPointValue.note,
      })
      .then((res) => {
        toast.success("Success Edit Point !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setFormEditPoint(!formEditPoint)
        setPointEdited(res.data.data)
        // console.log("editAccountSummaryPoint response :", res.data.data)
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:`${error}`,
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log("editAccountSummaryPoint error :", err)
      });
  };

  const getAccountInformation = () => {
    
      const uriUsers = `user-detail/${id}/account-information`;
    
      return axios
        .get(uriUsers)
        .then((response) => {
          setEditAccountInformationValue(response.data.data)
          // console.log("getAccountInformation response", response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    };

  // post edit point
  const editAccountInformation = () => {
    axios
      .post(`/user-detail/${id}/account-information`, editAccountInformationValue)
      .then((res) => {
        toast.success("Success Edit Account !", {
          position: toast.POSITION.TOP_RIGHT
          });
        getAccountInformation()
        // console.log("editAccountSummaryPoint response :", res.data.data)
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:`${error}`,
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log("editAccountSummaryPoint error :", err)
      });
  };

  // AXIOS FOR PERSONAL INFORMATION
  const uploadDataKtp = () => {
    const URI = `/user-detail/${id}/personal-information/upgrade`

    axios
    .post(URI,{
      ...uploadDataKtpValue,
      tanggal_lahir: uploadDataKtpValue.tanggal_lahir !== "" ? uploadDataKtpValue.tanggal_lahir.toLocaleDateString('en-US') : ""
    })
    .then(response => {
      console.log("uploadDataKtp response: ", response)
      toast.success("Success Upload Data KTP !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setDataKtpLocale({
        ...uploadDataKtpValue,
      tanggal_lahir: uploadDataKtpValue.tanggal_lahir !== "" ? uploadDataKtpValue.tanggal_lahir.toLocaleDateString('en-US') : ""
      })
      setFormUploadDataKtp(!formUploadDataKtp)
    })
    .catch(error =>{
      // console.log("uploadDataKtp error: ", error)
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:`${error}`,
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
    })
  }

  // get data table mutation
  const getMutationTable = () =>{
  
    const uriUsers = `user-detail/${id}/mutation`;
  
    return axios
      .get(uriUsers,{
        params: {
          page: pageMutation,
          per_page: countPageMutation,
          sort_columns: sortColumnMutation,
          sort_direction: sortDirectionMutation,
          keyword: keywordMutation,
          starting_date: mutationStartDate && mutationStartDate.toLocaleDateString('en-US'),
          ending_date: mutationEndDate && mutationEndDate.toLocaleDateString('en-US'),
          type: typeMutation
        }
      })
      .then((response) => {
        setMutationTable(response.data);
        // console.log("getMutationTable response: ", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //get commission data table
  const getCommissionMutationTable = () =>{
  
    const uriUsers = `user-detail/${id}/commission-mutation`;
  
    return axios
      .get(uriUsers,{
        params: {
          page: pageCommissionMutation,
          per_page: countPageCommissionMutation,
          sort_columns: sortColumnCommissionMutation,
          sort_direction: sortDirectionCommissionMutation,
          keyword: keywordCommissionMutation,
          starting_date: commissionMutationStartDate && commissionMutationStartDate.toLocaleDateString('en-US'),
          ending_date: commissionMutationEndDate && commissionMutationEndDate.toLocaleDateString('en-US'),
          type: typeCommissionMutation
        }
      })
      .then((response) => {
        setCommissionMutationTable(response.data);
        // console.log("getCommissionMutationTable response: ", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // get point mutation data table
  const getPointMutationTable = () =>{
  
    const uriUsers = `user-detail/${id}/point-mutation`;
  
    return axios
      .get(uriUsers,{
        params: {
          page: pagePointMutation,
          per_page: countPagePointMutation,
          sort_columns: sortColumnPointMutation,
          sort_direction: sortDirectionPointMutation,
          keyword: keywordPointMutation,
          starting_date: pointMutationStartDate && pointMutationStartDate.toLocaleDateString('en-US'),
          ending_date: pointMutationEndDate && pointMutationEndDate.toLocaleDateString('en-US'),
          type: typePointMutation
        }
      })
      .then((response) => {
        setPointMutationTable(response.data);
        // console.log("getPointMutationTable response: ", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // get log otp data table
  const getLogOtpTable = () => {
    const URI = `/user-detail/${id}/log-otp`

    axios
    .get(URI,{
      params: {
        page: pageLogOtp,
        per_page: countPageLogOtp,
        sort_columns: sortColumnLogOtp,
        sort_direction: sortDirectionLogOtp,
      }
    })
    .then(response => {
      setLogOtpTable(response.data)
    })
    .catch(error => {
      console.log("getLogOtpTable error: ", error)
    })
  }

  // get log credential table
  const getLogCredentialTable = () => {
    const URI = `user-detail/${id}/log-credential`

    axios
    .get(URI)
    .then(response => {
      setLogCredentialTable(response.data)
    })
    .catch(error => {
      console.log("getLogCredentialTable error: ", error)
    })
  }

  // get downline data table
  const getDownlineTable = () =>{
  
    const uriUsers = `user-detail/${id}/downline`;
  
    return axios
      .get(
        uriUsers
        // ,{
        //   params: {
        //     page: pageDownline,
        //     per_page: countPageDownline,
        //     sort_columns: sortColumnDownline,
        //     sort_direction: sortDirectionDownline,
        //     keyword: keywordDownline,
        //     starting_date: downlineStartDate.toLocaleDateString('en-US'),
        //     ending_date: downlineEndDate.toLocaleDateString('en-US'),
        //     type: typeDownline,
        //     ...filterDownlineTable
        //   }
        // }
        )
      .then((response) => {
        setDownlineTableDefault(response.data);
        // console.log("getDownlineTable response: ", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const uploadKtpPicture = () => {
    const URI = `/user-detail/${id}/personal-information/upload-ktp`

    const formData = new FormData();
    formData.append('ktp',uploadKtpValue)
    const config = {
      headers:{
        'content-type':'multipart/form-data'
      }
    }

    axios
    .post(URI, formData,config)
    .then(res => {
      // dispatch(uploadPersonalPicture(res.data))
      toast.success("Success Upload Picture !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setFormUploadKTPPicture(!formUploadKTPPicture)
      setDataKtpPicture(res.data.data.ktp_picture)
      //  console.log('postUploadKtpPicture :', res.data)
      })
    .catch(error => {
      // console.log('uploadPersonalPicture :', err)
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:`${error}`,
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })   
    })

  }

  const postUploadSelfieKtp = () => {

    const formData = new FormData();
    formData.append('selfie_ktp',uploadValue)
    const config = {
      headers:{
        'content-type':'multipart/form-data'
      }
    }

    axios
    .post(`user-detail/${id}/personal-information/upload-selfie-ktp`,formData,config)
    .then(res => {
      // dispatch(uploadPersonalPicture(res.data))
      toast.success("Success Upload Picture !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setDataSelfieKtp(res.data.data.selfie_ktp)
      setFormUploadSelfieKtp(!formUploadSelfieKtp)
      //  console.log('postUploadPicture :', res.data)
      })
    .catch(error => {
      // console.log('uploadPersonalPicture :', err)
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:`${error}`,
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })  
    })
  }

  const getLocation = () => {
    const URI = `user-detail/${id}/location`

    axios
    .get(URI)
    .then(response => {
      setDataLocation(response.data.data.latlong)
    })
    .catch(error => {
      console.log("getLocation Error: ", error)
    })
  }



  // for conditional rendering
  useEffect(() => {
    // window.dispatchEvent(new Event('resize'));
    getAccountSummary();
    getAccountSummaryDataTable();
  }, [])

  useEffect(() => {
    if (Object.keys(mutationTable).length != 0) {
      getMutationTable();
    }
  }, [
    pageMutation,
    countPageMutation,
    sortColumnMutation,
    sortDirectionMutation,
  ]);

  useEffect(() => {
    if (Object.keys(commissionMutationTable).length != 0){
      getCommissionMutationTable();
    }
  }, [
     pageCommissionMutation,
     countPageCommissionMutation,
     sortColumnCommissionMutation,
     sortDirectionCommissionMutation,
  ])

  useEffect(() => {
    if (Object.keys(pointMutationTable).length != 0){
      getPointMutationTable()
    }
  }, [
    pagePointMutation,
    countPagePointMutation,
    sortColumnPointMutation,
    sortDirectionPointMutation
  ])

  // useEffect(() => {
  //   if (Object.keys(downlineTable).length != 0) {
  //     getDownlineTable()
  //   }
  // }, [
  //   pageDownline,
  //   countPageDownline,
  //   sortColumnDownline,
  //   sortDirectionDownline
  // ])

  return (
    <Fragment>
      <Breadcrumb parent="User" title="User Details" />
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-6">
              <div className="email-left-aside">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      <Nav className="main-menu" role="tablist">
                        <NavItem>
                          <a
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              getAccountSummary();
                              getAccountSummaryDataTable();
                              setActiveTab("1")
                            }}
                          >
                            <span className="title"> {"Account Summary"}</span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => {
                              getAccountInformation();
                              setActiveTab("2")
                            }}
                          >
                            <span className="title">
                              {" "}
                              {"Account Information"}
                            </span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => {
                              dispatch(getPersonalInformation(id));
                              // dispatch(getPersonalPicture(id));
                              // dispatch(getKtpPicture(id))
                              setActiveTab("3")
                            }}
                          >
                            <span className="title">
                              {" "}
                              {"Personal Information"}
                            </span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => {
                              getMutationTable();
                              setActiveTab("4")
                            }}
                          >
                            <span className="title"> {"Mutation"}</span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              getCommissionMutationTable();
                              setActiveTab("5")
                            }}
                          >
                            <span className="title">
                              {"Commission Mutation"}
                            </span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "6" ? "active" : ""}
                            onClick={() => {
                              getPointMutationTable()
                              setActiveTab("6")
                            }}
                          >
                            <span className="title"> {"Point Mutation"}</span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "7" ? "show" : ""}
                            onClick={() => {
                              getLogOtpTable()
                              setActiveTab("7")
                            }}
                          >
                            <span className="title"> {"Log OTP"}</span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "8" ? "show" : ""}
                            onClick={() => {
                              getLogCredentialTable()
                              setActiveTab("8")
                            }}
                          >
                            <span className="title"> {"Log Credential"}</span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "9" ? "show" : ""}
                            onClick={() => {
                              getDownlineTable()
                              setActiveTab("9")
                            }}
                          >
                            <span className="title"> {"Downline"}</span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className={activeTab === "10" ? "show" : ""}
                            onClick={() => {
                              setActiveTab("10")
                              // setTimeout(() => {
                              //   mapRef.current.invalidateSize(  )
                              // }, 1000);
                              getLocation()
                              setTimeout(() => {
                                window.dispatchEvent(new Event('resize'));
                              }, 1000);
                            }}
                          >
                            <span className="title">{"Location"}</span>
                          </a>
                        </NavItem>
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xl="9" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="pl-0">
                    {/* Tab content start */}
                    <TabContent activeTab={activeTab}>
                      {/* Account Summary Section */}
                      <TabPane tabId="1">
                        {dataAccountSummary !== undefined ? (
                          <Card>
                            <CardHeader>
                              <h6 className="mb-0">{"Account Summary"}</h6>
                            </CardHeader>
                            <CardBody>
                              <Card>
                                <Row className="justify-content-around">
                                  {/* Point And Saldo Section */}
                                  <Col sm="12" xl="12" lg="12">
                                    <Card>
                                      <CardBody>
                                        <Row className="justify-content-around align-content-center">
                                          <Col xl="7" md="7">
                                            <div className="media user-detail-mutation-media">
                                              <div className="media-body">
                                                <span className="m-0">
                                                  {"Saldo"}
                                                </span>
                                                <h4 className="mb-0 counter">
                                                  {saldoEdited !== undefined ? `Rp.${parseFloat(
                                                    saldoEdited.saldo
                                                  ).toLocaleString("en")}`:`Rp.${parseFloat(
                                                    dataAccountSummary.saldo
                                                  ).toLocaleString("en")}`}
                                                </h4>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xl="3" md="3">
                                            <Button
                                              outline
                                              color="primary"
                                              className="mt-2"
                                              onClick={() =>
                                                DisplayFormEditSaldo()
                                              }
                                            >
                                              Edit
                                            </Button>
                                          </Col>
                                        </Row>

                                        {/* Modal Edit Saldo */}
                                        <Modal
                                          isOpen={formEditSaldo}
                                          toggle={() => DisplayFormEditSaldo()}
                                          centered
                                        >
                                          <ModalHeader
                                            toggle={() =>
                                              DisplayFormEditSaldo()
                                            }
                                          >
                                            Saldo
                                          </ModalHeader>
                                          <ModalBody>
                                            <Row>
                                              <Col xl="12">
                                                <Form
                                                  onSubmit={(event) => {
                                                    event.preventDefault();
                                                    editAccountSummarySaldo();
                                                  }}
                                                >
                                                  <FormGroup>
                                                    <Row>
                                                      <Col xl="4">
                                                        <p>Type</p>
                                                      </Col>
                                                      <Col xl="8">
                                                        <Input
                                                          type="select"
                                                          name="type"
                                                          defaultValue={editSaldoValue.type}
                                                          onChange={(event) => {
                                                            handleSaldoEdit(
                                                              event
                                                            );
                                                          }}
                                                        >
                                                          <option>DEBIT</option>
                                                          <option>
                                                            CREDIT
                                                          </option>
                                                        </Input>
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Row>
                                                      <Col xl="4">
                                                        <p>Nominal</p>
                                                      </Col>
                                                      <Col xl="8">
                                                        <InputGroup>
                                                          <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                              {"Rp"}
                                                            </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input
                                                            className="form-control"
                                                            name="nominal"
                                                            type="number"
                                                            onClick={(event)=>{
                                                              const cancelWheel = (event) => event.preventDefault();
                                                              event.target.addEventListener("wheel", cancelWheel)
                                                            }}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleSaldoEdit(
                                                                event
                                                              );
                                                            }}
                                                          />
                                                        </InputGroup>
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Row>
                                                      <Col xl="4">
                                                        <p>Note</p>
                                                      </Col>
                                                      <Col xl="8">
                                                        <Input
                                                          type="textarea"
                                                          name="note"
                                                          rows="4"
                                                          onChange={(event) => {
                                                            handleSaldoEdit(
                                                              event
                                                            );
                                                          }}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                                  <Row className="justify-content-center">
                                                    <Col xl="3" md="3">
                                                      <Button
                                                        color="secondary"
                                                        type="reset"
                                                        className="mr-1"
                                                        onClick={() =>
                                                          DisplayFormEditSaldo()
                                                        }
                                                      >
                                                        {"Cancel"}
                                                      </Button>
                                                    </Col>
                                                    <Col xl="3" md="3">
                                                      <Button
                                                        color="primary"
                                                        type="submit"
                                                      >
                                                        {"Save"}
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                              </Col>
                                            </Row>
                                          </ModalBody>
                                        </Modal>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col sm="12" xl="12" lg="12">
                                    <Card>
                                      <CardBody>
                                        <Row className="justify-content-around">
                                          <Col xl="7" md="7">
                                            <div className="media user-detail-mutation-media">
                                              <div className="media-body">
                                                <span className="m-0">
                                                  {"Point"}
                                                </span>
                                                <h4 className="mb-0 counter">
                                                  {pointEdited !== undefined ? pointEdited.point : dataAccountSummary.point}
                                                </h4>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xl="3" md="3">
                                            <Button
                                              outline
                                              color="primary"
                                              className="mt-2"
                                              onClick={() =>
                                                DisplayFormEditPoint()
                                              }
                                            >
                                              Edit
                                            </Button>
                                          </Col>
                                        </Row>

                                        {/* Modal Edit Point */}
                                        <Modal
                                          isOpen={formEditPoint}
                                          toggle={() => DisplayFormEditPoint()}
                                          centered
                                        >
                                          <ModalHeader
                                            toggle={() =>
                                              DisplayFormEditPoint()
                                            }
                                          >
                                            Point
                                          </ModalHeader>
                                          <ModalBody>
                                            <Row>
                                              <Col xl="12">
                                                <Form
                                                  onSubmit={(event) => {
                                                    event.preventDefault();
                                                    editAccountSummaryPoint();
                                                  }}
                                                >
                                                  <FormGroup>
                                                    <Row>
                                                      <Col xl="4">
                                                        <p>Type</p>
                                                      </Col>
                                                      <Col xl="8">
                                                        <Input
                                                          type="select"
                                                          name="type"
                                                          defaultValue={editPointValue.type}
                                                          onChange={(event) =>
                                                            handlePointEdit(
                                                              event
                                                            )
                                                          }
                                                        >
                                                          <option>DEBIT</option>
                                                          <option>
                                                            CREDIT
                                                          </option>
                                                        </Input>
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Row>
                                                      <Col xl="4">
                                                        <p>Point</p>
                                                      </Col>
                                                      <Col xl="8">
                                                        <InputGroup>
                                                          <Input
                                                            className="form-control"
                                                            name="nominal"
                                                            type="number"
                                                            onClick={(event)=>{
                                                              const cancelWheel = (event) => event.preventDefault();
                                                              event.target.addEventListener("wheel", cancelWheel)
                                                            }}
                                                            onChange={(event) =>
                                                              handlePointEdit(
                                                                event
                                                              )
                                                            }
                                                          />
                                                        </InputGroup>
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Row>
                                                      <Col xl="4">
                                                        <p>Note</p>
                                                      </Col>
                                                      <Col xl="8">
                                                        <Input
                                                          type="textarea"
                                                          name="note"
                                                          rows="4"
                                                          onChange={(event) =>
                                                            handlePointEdit(
                                                              event
                                                            )
                                                          }
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                                  <Row className="justify-content-center">
                                                    <Col xl="3" md="3">
                                                      <Button
                                                        color="secondary"
                                                        type="reset"
                                                        className="mr-1"
                                                        onClick={() =>
                                                          DisplayFormEditPoint()
                                                        }
                                                      >
                                                        {"Cancel"}
                                                      </Button>
                                                    </Col>
                                                    <Col xl="3" md="3">
                                                      <Button color="primary">
                                                        {"Save"}
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                              </Col>
                                            </Row>
                                          </ModalBody>
                                        </Modal>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col sm="12" xl="12" lg="12">
                                    <Card>
                                      <CardBody>
                                        <Row className="justify-content-around">
                                          <Col xl="7" md="7">
                                            <div className="media user-detail-mutation-media">
                                              <div className="media-body">
                                                <span className="m-0">
                                                  {"Control"}
                                                </span>
                                                <h4 className="mb-0 counter">
                                                  {dataAccountSummary.control}
                                                </h4>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xl="3" md="3"></Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </Card>
                              {/* Chart Debit and Credit Section */}
                              <Card>
                                <CardHeader>
                                  <h5>{"Debit & Credit"} </h5>
                                </CardHeader>
                                <CardBody>
                                  {areaSpaline !== undefined ? (
                                    <Chart
                                      options={
                                        areaSpaline !== undefined
                                          ? areaSpaline.options
                                          : ""
                                      }
                                      series={
                                        areaSpaline !== undefined
                                          ? areaSpaline.series
                                          : ""
                                      }
                                      height="350"
                                      type="area"
                                    />
                                  ) : (
                                    <Row className="justify-content-center align-items-center">
                                      <Spinner style={{ color: "#f25c00" }} />
                                    </Row>
                                  )}
                                </CardBody>
                              </Card>
                              <Col xl="12" className="dashboard-sec">
                                <Card className="earning-card">
                                  <CardBody className="p-0">
                                    <Row className="m-0">
                                      <Col xl="12" className="p-0">
                                        <Row className="border-top m-0">
                                          <Col
                                            xl="6"
                                            md="6"
                                            sm="6"
                                            className="pl-0"
                                          >
                                            <div className="media p-0">
                                              <div className="media-left">
                                                <i className="icofont icofont-cur-dollar"></i>
                                              </div>
                                              <div className="media-body">
                                                <h6>{"Transfer In"}</h6>
                                                <p>{`Rp.${parseFloat(
                                                  dataAccountSummary.transfer_in
                                                ).toLocaleString("en")}`}</p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xl="6" md="6" sm="6">
                                            <div className="media p-0">
                                              <div className="media-left bg-secondary">
                                                <i className="icofont icofont-cur-dollar"></i>
                                              </div>
                                              <div className="media-body">
                                                <h6>{"Transfer Out"}</h6>
                                                <p>{`Rp.${parseFloat(
                                                  dataAccountSummary.transfer_out
                                                ).toLocaleString("en")}`}</p>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row className="border-top m-0">
                                          <Col xl="6" md="6" sm="6">
                                            <div className="media p-0">
                                              <div className="media-left">
                                                <i className="icofont icofont-heart-alt"></i>
                                              </div>
                                              <div className="media-body">
                                                <h6>{"Commission"}</h6>
                                                <p>{`Rp.${parseFloat(
                                                  dataAccountSummary.commission
                                                ).toLocaleString("en")}`}</p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xl="6" md="6" sm="6">
                                            <div className="media p-0">
                                              <div className="media-left bg-secondary">
                                                <i className="icofont icofont-crown"></i>
                                              </div>
                                              <div className="media-body">
                                                <h6>{"Transaction"}</h6>
                                                <p>{`Rp.${parseFloat(
                                                  dataAccountSummary.transaction
                                                ).toLocaleString("en")}`}</p>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              {/* Table Transaction List */}
                              <Card>
                                <CardHeader>
                                  <Row className="justify-content-between">
                                    <Col xl="6">
                                      <h4>Transaction List</h4>
                                    </Col>
                                    <Col xl="3">
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          history.push(
                                            `${process.env.PUBLIC_URL}/transaction/transaction`
                                          )
                                        }
                                      >
                                        More
                                      </Button>
                                    </Col>
                                  </Row>
                                </CardHeader>
                                <CardBody>
                                  <DataTable
                                    columns={columnsTransaction}
                                    data={
                                      accountSummaryTable !== undefined
                                        ? accountSummaryTable.data
                                        : ""
                                    }
                                    pagination={true}
                                    paginationPerPage={5}
                                    paginationRowsPerPageOptions={[
                                      5,
                                      10,
                                      15,
                                      20,
                                    ]}
                                  />
                                </CardBody>
                              </Card>
                            </CardBody>
                          </Card>
                        ) : (
                          <Row className="justify-content-center align-items-center">
                          <Spinner style={{ color: "#f25c00" }} />
                        </Row>
                        )}
                      </TabPane>

                      {/* Account Information Section */}
                      <TabPane tabId="2">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Account Information"}</h6>
                          </CardHeader>
                          <CardBody>
                            {editAccountInformationValue !== undefined ? (
                              <Form className="form theme-form"
                              // onSubmit={(event)=>{
                              //   dispatch(editAccountInformation(id,editAccountInformationValue,event))
                              // }}
                              >
                              <CardBody>
                                <Row>
                                  <Col>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"ID User"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control user-detail-solid-input"
                                          type="text"
                                          disabled={true}
                                          value={editAccountInformationValue.id_user}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Name"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control "
                                          type="text"
                                          name='name'
                                          onChange={(event)=>handleEditAccountInformation(event)}
                                          value={editAccountInformationValue.name}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Phone Number"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control digits"
                                          type="tel"
                                          name='phone_number'
                                          onChange={(event)=>handleEditAccountInformation(event)}
                                          value={editAccountInformationValue.phone_number}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Email"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control m-input digits"
                                          type="email"
                                          onChange={(event)=>handleEditAccountInformation(event)}
                                          name='email'
                                          value={editAccountInformationValue.email}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Status"}
                                      </Label>
                                      <Col sm="9">
                                        <Select
                                        // name='status' 
                                        onChange={(event)=>{
                                          setEditAccountInformationValue({
                                            ...editAccountInformationValue,
                                            status:event.value
                                          })
                                        }}
                                        options={listStatus} value={editAccountInformationValue.status === 'PENDING' ? listStatus[0]: (
                                          editAccountInformationValue.status === 'ACTIVE' ? listStatus[1] : listStatus[2]
                                        )}/>
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Account Type"}
                                      </Label>
                                      <Col sm="9">
                                      <Select 
                                      onChange={(event)=>{
                                        setEditAccountInformationValue({
                                          ...editAccountInformationValue,
                                          account_type:event.value
                                        })
                                      }}
                                      options={listAccountType} value={editAccountInformationValue.account_type === 'BASIC' ? listAccountType[0]:listAccountType[1]}/>
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"ID Upline"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control digits"
                                          type="text"
                                          name='id_upline'
                                          onChange={(event)=>handleEditAccountInformation(event)}
                                          value={editAccountInformationValue.id_upline}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Refferal Code"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control digits user-detail-solid-input"
                                          type="text"
                                          value={editAccountInformationValue.referral_code}
                                          disabled={true}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Registration Date"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control digits user-detail-solid-input"
                                          type='text'
                                          value={`${editAccountInformationValue.registration_date} WIB`}
                                          disabled={true}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Last Login"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          className="form-control digits user-detail-solid-input"
                                          type='text'
                                          value={editAccountInformationValue.last_login}
                                          disabled={true}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label pt-0">
                                        {"Transaction By Whatsapp"}
                                      </Label>
                                      <Col sm="9">
                                      <Select 
                                      onChange={(event)=>{
                                        setEditAccountInformationValue({
                                          ...editAccountInformationValue,
                                          transaction_by_whatsapp:event.value
                                        })
                                      }}
                                      options={listTransactionByWhatsapp} value={editAccountInformationValue.transaction_by_whatsapp === 'ACTIVE' ? listTransactionByWhatsapp[0]:listTransactionByWhatsapp[1]}/>
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Transaction By Telegram"}
                                      </Label>
                                      <Col sm="9">
                                      <Select options={listTransactionByTelegram} isDisabled={true}/>
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label pt-0">
                                        {"Transaction By Line"}
                                      </Label>
                                      <Col sm="9">
                                      <Select options={listTransactionByLine} isDisabled={true}/>
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Password"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                        name='password'
                                        onChange={(event)=>handleEditAccountInformation(event)}
                                          className="form-control digits"
                                          type="password"
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                      <Label className="col-sm-3 col-form-label">
                                        {"PIN"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                        name='pin'
                                        onChange={(event)=>handleEditAccountInformation(event)}
                                          className="form-control digits"
                                          type="password"
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup className="row mb-0">
                                      <Label className="col-sm-3 col-form-label">
                                        {"Note"}
                                      </Label>
                                      <Col sm="9">
                                        <Input
                                          type="textarea"
                                          rows="4"
                                          name='note'
                                          onChange={(event)=>handleEditAccountInformation(event)}
                                          value={editAccountInformationValue.note}
                                        />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                              <CardFooter>
                                <Row className="justify-content-center">
                                  <Button
                                    color="secondary"
                                    type="reset"
                                    className="mr-5"
                                  >
                                    {"Cancel"}
                                  </Button>
                                  <Button color="primary" onClick={()=>{
                                    editAccountInformation()
                                    }}>{"Save Edit"}</Button>
                                </Row>
                              </CardFooter>
                            </Form>
                            ):(<Row className="justify-content-center align-items-center">
                            <Spinner style={{ color: "#f25c00" }} />
                          </Row>)}
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Personal Information Section */}
                      <TabPane tabId="3">
                        <Card className="mb-3">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Personal Information"}</h6>
                          </CardHeader>
                          {dataPersonalInformation !== undefined ? (
                            <CardBody className="pb-0">
                            <div
                              className={`details-bookmark text-center`}
                            >
                              <Row id="bookmarkData">
                                <Col xl="12" md="12">
                                  <Row>
                                    <Col xl="6" md="6">
                                      <Card className="card-with-border bookmark-card o-hidden">
                                        <div className="details-website">
                                          <img
                                            className="img-fluid personal-information-modal-photo"
                                            src={dataSelfieKtp !== undefined || "" ? dataSelfieKtp : dataPersonalInformation.selfie_ktp}
                                            style={{ width: 300, height: 200 }}
                                            onClick={() =>
                                              setPhotoIndex({
                                                ...photoIndex,
                                                index: 0,
                                                isOpen: true,
                                              })
                                            }
                                            alt=""
                                          />
                                          <div className="desciption-data">
                                            <div className="title-bookmark">
                                              <h6 className="title_0 text-center">
                                                {"Selfie with KTP"}
                                              </h6>
                                              <Row className="justify-content-around">
                                                <div
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    DisplayFormUploadSelfieKtp()
                                                  }
                                                >
                                                  <Upload className="mr-2" />
                                                  {"Upload"}
                                                </div>
                                                <div
                                                  className="btn btn-outline-primary"
                                                  // onClick={() =>
                                                  //   DisplayFormUploadPersonalPicture()
                                                  // }
                                                >
                                                  <Download className="mr-2" />
                                                  {"Download"}
                                                </div>
                                              </Row>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </Col>
                                    <Col xl="6" md="6">
                                      <Card className="card-with-border bookmark-card o-hidden">
                                        <div className="details-website">
                                          <img
                                            className="img-fluid personal-information-modal-photo"
                                            src={dataKtpPicture !== undefined || "" ? dataKtpPicture : dataPersonalInformation.ktp_picture}
                                            style={{ width: 300, height: 200 }}
                                            onClick={() =>
                                              setPhotoIndex({
                                                ...photoIndex,
                                                index: 1,
                                                isOpen: true,
                                              })
                                            }
                                            alt=""
                                          />
                                          <div className="desciption-data">
                                            <div className="title-bookmark">
                                              <h6 className="title_0 text-center">
                                                {"KTP Picture"}
                                              </h6>
                                              <Row className="justify-content-around">
                                                <div
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    DisplayFormUploadKTPPicture()
                                                  }
                                                >
                                                  <Upload className="mr-2" />
                                                  {"Upload"}
                                                </div>
                                                <div
                                                  className="btn btn-outline-primary"
                                                  // onClick={() =>
                                                  //   DisplayFormUploadKTPPicture()
                                                  // }
                                                >
                                                  <Download className="mr-2" />
                                                  {"Download"}
                                                </div>
                                              </Row>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="justify-content-start mb-3">
                                <Card style={{width:350}} id="data-ktp">
                                  <Col className="text-left text-black">
                                    <p>
                                      NIK &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.nik : dataPersonalInformation.no_ktp}
                                    </p>
                                    <p>Nama &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.nama_lengkap : dataPersonalInformation.nama}</p>
                                    <p>
                                      Tempat Lahir &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.tempat_lahir : dataPersonalInformation.tempat_lahir}
                                    </p>
                                    <p>
                                      Tanggal Lahir &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.tanggal_lahir : dataPersonalInformation.tanggal_lahir}
                                    </p>
                                    <p>
                                      Jenis Kelamin &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.jenis_kelamin : dataPersonalInformation.jenis_kelamin}
                                    </p>
                                    {/* <p>
                                      Kota/Kabupaten &nbsp;:&nbsp;&nbsp;{dataPersonalInformation.kabupaten}
                                    </p> */}
                                    <p>
                                      Alamat &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.alamat : dataPersonalInformation.alamat}
                                    </p>
                                    <p>Kecamatan &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.kecamatan : dataPersonalInformation.kecamatan}</p>
                                    <p>Kelurahan &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.kelurahan : dataPersonalInformation.kelurahan}</p>
                                    <p>RT &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.rt : dataPersonalInformation.rt}</p>
                                    <p>RW &nbsp;:&nbsp;&nbsp;{dataKtpLocale !== undefined ? dataKtpLocale.rw : dataPersonalInformation.rw}</p>
                                    {/* <p>Kode Pos &nbsp;:&nbsp;&nbsp;{dataPersonalInformation.kode_pos}</p> */}
                                  </Col>
                                  <Row className="justify-content-around">
                                  <div
                                className="btn btn-outline-primary mt-3"
                                onClick={() =>
                                  DisplayFormUploadDataKtp()
                                }
                              >
                                <Upload className="mr-2" />
                                {"Upload"}
                              </div>
                              <div
                                className="btn btn-outline-primary mt-3"
                                // onClick={() =>
                                //   DisplayFormUploadDataKtp()
                                // }
                              >
                                <Download className="mr-2" />
                                {"Download"}
                              </div>
                              </Row>
                                </Card>
                              </Row>
                              
                            </div>
                            {/* Modal Upload Personal Picture */}
                            <Modal
                              isOpen={formUploadSelfieKtp}
                              toggle={() => DisplayFormUploadSelfieKtp()}
                              centered
                            >
                              <ModalHeader
                                toggle={() =>
                                  DisplayFormUploadSelfieKtp()
                                }
                              >
                                Upload Personal Picture
                              </ModalHeader>
                              <ModalBody>
                                <Form
                                onSubmit={(event)=>{
                                  // event.preventDefault()
                                  // dispatch(postUploadPersonalPicture(id, uploadValue, event))
                                }}
                                >
                                  <FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="6" md="6">
                                        <Input type="file" onChange={
                                          (event)=> setUploadValue(event.target.files[0])
                                        }>
                                          Pick Your Picture
                                        </Input>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="3" md="3">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          onClick={() =>
                                            DisplayFormUploadSelfieKtp()
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </Col>
                                      <Col xl="3" md="3">
                                        <Button color="primary" onClick={()=>postUploadSelfieKtp()}>Confirm</Button>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Form>
                              </ModalBody>
                            </Modal>
                            
                            {/* Modal Upload KTP Picture */}
                            <Modal
                              isOpen={formUploadKTPPicture}
                              toggle={() => DisplayFormUploadKTPPicture()}
                              centered
                            >
                              <ModalHeader
                                toggle={() => DisplayFormUploadKTPPicture()}
                              >
                                Upload KTP Picture
                              </ModalHeader>
                              <ModalBody>
                                <Form>
                                  <FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="6" md="6">
                                        <Input type="file" onChange={
                                          (event)=> setUploadKtpValue(event.target.files[0])
                                        }>
                                          Pick Your Picture
                                        </Input>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="3" md="3">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          onClick={() =>
                                            DisplayFormUploadKTPPicture()
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </Col>
                                      <Col xl="3" md="3">
                                        <Button color="primary" onClick={()=>uploadKtpPicture()}>Confirm</Button>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Form>
                              </ModalBody>
                            </Modal>

                            {/* UPLOAD DATA KTP */}
                            <Modal
                              isOpen={formUploadDataKtp}
                              toggle={() => DisplayFormUploadDataKtp()}
                              centered
                            >
                            <ModalHeader toggle={()=>DisplayFormUploadDataKtp()}>
                              {"Upload Data KTP"}
                            </ModalHeader>
                            <ModalBody>
                              <Form>
                                <Row>
                                  <Col xl="12" md="12">
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"NIK"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                nik:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Nama"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                nama_lengkap:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Tempat Lahir"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                tempat_lahir:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Tanggal Lahir"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <DatePicker
                                        className="datepicker-here form-control"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={uploadDataKtpValue.tanggal_lahir}
                                        onChange={handleChangeBirthDate}
                                      />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Jenis Kelamin"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Row className="justify-content-center ml-2">
                                          <Col>
                                            <Label check>
                                              <Input
                                                type="radio"
                                                name="jenis-kelamin"
                                                value='Laki - Laki'
                                                onChange={(event)=>handleJenisKelamin(event)}
                                              />{" "}
                                              Laki-Laki
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label check>
                                              <Input
                                                type="radio"
                                                name="jenis-kelamin"
                                                value="Perempuan"
                                                onChange={(event)=>handleJenisKelamin(event)}
                                              />{" "}
                                              Perempuan
                                            </Label>
                                          </Col>
                                        </Row>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Alamat"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                alamat:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Kecamatan"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                kecamatan:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Kelurahan"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                kelurahan:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"RT"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                rt:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"RW"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setUploadDataKtpValue({
                                                ...uploadDataKtpValue,
                                                rw:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <Row className="justify-content-center mt-5">
                                      <Col xl="3" sm="4" xs="5">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          className=" mr-1"
                                          onClick={()=>DisplayFormUploadDataKtp()}
                                        >
                                          {"Cancel"}
                                        </Button>
                                      </Col>
                                      <Col xl="3" sm="4" xs="5">
                                        <Button color="primary"  onClick={()=>uploadDataKtp()}>{"Confirm"}</Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            </ModalBody>
                          </Modal>    

                          </CardBody>
                          ):(<Row className="justify-content-center align-items-center">
                          <Spinner style={{ color: "#f25c00" }} />
                        </Row>)}
                        </Card>
                      </TabPane>

                      {/* Mutation Section */}
                      <TabPane tabId="4">
                        {/* <Mutation
                        id={id}
                        /> */}
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Mutation"}</h6>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col xl="12">
                                <Form
                                  className="needs-validation"
                                  noValidate=""
                                >
                                  <div className="form-row">
                                    <Col md="12 mb-3">
                                      <Input
                                        className="form-control"
                                        name="keyword"
                                        type="text"
                                        placeholder="Keyword"
                                        onChange={(event) =>onSearchMutation(event)}
                                      />
                                    </Col>
                                  </div>
                                  <div className="form-row">
                                    <Col md="3 mb-3">
                                      <Label htmlFor="validationCustom03">
                                        {"Starting Date"}
                                      </Label>
                                      <DatePicker
                                        className="datepicker-here form-control"
                                        selected={mutationStartDate}
                                        onChange={handleChangeMutationStartDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <Label htmlFor="validationCustom03">
                                        {"Ending Date"}
                                      </Label>
                                      <DatePicker
                                        className="datepicker-here form-control"
                                        selected={mutationEndDate}
                                        onChange={handleChangeMutationEndDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom04">
                                          {"Type"}
                                        </Label>
                                        <select className="form-control digits" onChange={(event)=>{
                                          setTypeMutation(event.target.value)
                                        }}>
                                          <option>{"ALL"}</option>
                                          <option>{"DEBIT"}</option>
                                          <option>{"CREDIT"}</option>
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </div>
                                  <Row>
                                    <Col md="2" className="mr-5">
                                      <Button color="secondary" onClick={()=>getMutationTable()}>
                                        {"Search"}
                                      </Button>
                                    </Col>
                                    <Col md="2">
                                      <Button
                                        color="primary"
                                        className="sweet-8"
                                        name="alertSuccess"
                                        onClick={Displayalert}
                                      >
                                        {"Download"}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                                <DataTable
                                  columns={columnsMutation}
                                  data={mutationTable.data}
                                  pagination={true}
                                  paginationServer
                                  paginationTotalRows={mutationTable.total}
                                  onChangePage={pageMutation => setPageMutation(pageMutation)}
                                  onChangeRowsPerPage={countPageMutation => setCountPageMutation(countPageMutation)}
                                  onSort={handleSortMutation}
                                  paginationPerPage={countPageMutation}
                                  paginationRowsPerPageOptions={[5,10,15,20]}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Commision Mutation section */}
                      <TabPane tabId="5">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Commision Mutation"}</h6>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col xl="12">
                                <Form
                                  className="needs-validation"
                                  noValidate=""
                                  // onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div className="form-row">
                                    <Col md="12 mb-3">
                                      <Input
                                        className="form-control"
                                        name="keyword"
                                        type="text"
                                        placeholder="Keyword"
                                        onChange={(event)=>onSearchCommissionMutation(event)}
                                      />
                                    </Col>
                                  </div>
                                  <div className="form-row">
                                    <Col md="3 mb-3">
                                      <Label htmlFor="validationCustom03">
                                        {"Starting Date"}
                                      </Label>
                                      <DatePicker
                                        className="datepicker-here form-control"
                                        selected={commissionMutationStartDate}
                                        onChange={handleChangeCommissionMutationStartDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <Label htmlFor="validationCustom03">
                                        {"Ending Date"}
                                      </Label>
                                      <DatePicker
                                        className="datepicker-here form-control"
                                        selected={commissionMutationEndDate}
                                        onChange={handleChangeCommissionMutationEndDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom04">
                                          {"Type"}
                                        </Label>
                                        <select className="form-control digits" onChange={(event)=>{
                                          setTypeCommissionMutation(event.target.value)
                                        }}>
                                          <option>{"ALL"}</option>
                                          <option>{"DEBIT"}</option>
                                          <option>{"CREDIT"}</option>
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </div>
                                  <Row>
                                    <Col md="2" className="mr-5">
                                      <Button color="secondary" onClick={()=>getCommissionMutationTable()}>
                                        {"Search"}
                                      </Button>
                                    </Col>
                                    <Col md="2">
                                      <Button
                                        color="primary"
                                        className="sweet-8"
                                        name="alertSuccess"
                                        onClick={Displayalert}
                                      >
                                        {"Download"}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                                <DataTable
                                  columns={columnsCommissionMutation}
                                  data={commissionMutationTable.data}
                                  pagination={true}
                                  paginationServer
                                  paginationTotalRows={commissionMutationTable.total}
                                  onChangePage={pageCommissionMutation => setPageCommissionMutation(pageCommissionMutation)}
                                  onChangeRowsPerPage={countPageCommissionMutation => setCountPageCommissionMutation(countPageCommissionMutation)}
                                  onSort={handleSortCommissionMutation}
                                  paginationPerPage={countPageCommissionMutation}
                                  paginationRowsPerPageOptions={[5,10,15,20]}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Point Mutation Section */}
                      <TabPane tabId="6">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Point Mutation"}</h6>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col xl="12">
                                <Form
                                  className="needs-validation"
                                  noValidate=""
                                  // onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div className="form-row">
                                    <Col md="12 mb-3">
                                      <Input
                                        className="form-control"
                                        name="keyword"
                                        type="text"
                                        placeholder="Keyword"
                                        onChange={(event)=>onSearchPointMutation(event)}
                                      />
                                    </Col>
                                  </div>
                                  <div className="form-row">
                                    <Col md="3 mb-3">
                                      <Label htmlFor="validationCustom03">
                                        {"Starting Date"}
                                      </Label>
                                      <DatePicker
                                        className="datepicker-here form-control"
                                        selected={pointMutationStartDate}
                                        onChange={handleChangePointMutationStartDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <Label htmlFor="validationCustom03">
                                        {"Ending Date"}
                                      </Label>
                                      <DatePicker
                                        className="datepicker-here form-control"
                                        selected={pointMutationEndDate}
                                        onChange={handleChangePointMutationEndDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom04">
                                          {"Type"}
                                        </Label>
                                        <select className="form-control digits" onChange={(event)=> setTypePointMutation(event.target.value)}>
                                          <option>{"All"}</option>
                                          <option>{"Debit"}</option>
                                          <option>{"Credit"}</option>
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </div>
                                  <Row>
                                    <Col md="2" className="mr-5">
                                      <Button color="secondary" onClick={()=>getPointMutationTable()}>
                                        {"Search"}
                                      </Button>
                                    </Col>
                                    <Col md="2">
                                      <Button
                                        color="primary"
                                        className="sweet-8"
                                        name="alertSuccess"
                                        onClick={Displayalert}
                                      >
                                        {"Download"}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                                <DataTable
                                  columns={columnsPointMutation}
                                  data={pointMutationTable.data}
                                  pagination={true}
                                  paginationServer
                                  paginationTotalRows={pointMutationTable.total}
                                  onChangePage={pagePointMutation => setPagePointMutation(pagePointMutation)}
                                  onChangeRowsPerPage={countPagePointMutation => setCountPagePointMutation(countPagePointMutation)}
                                  onSort={handleSortPointMutation}
                                  paginationPerPage={countPagePointMutation}
                                  paginationRowsPerPageOptions={[5,10,15,20]}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Log OTP Section */}
                      <TabPane tabId="7">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Log OTP"}</h6>
                          </CardHeader>
                          <CardBody className="pt-0">
                            <DataTable
                              columns={columnsLogOTP}
                              data={logOtpTable.data}
                              paginationServer
                              paginationTotalRows={logOtpTable.total}
                              onChangePage={pageLogOtp => setPageLogOtp(pageLogOtp)}
                              onChangeRowsPerPage={countPageLogOtp => setCountPageLogOtp(countPageLogOtp)}
                              onSort={handleSortLogOtp}
                              paginationPerPage={countPageLogOtp}
                              paginationRowsPerPageOptions={[5,10,15,20]}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Log Credential Section */}
                      <TabPane tabId="8">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Log Credential"}</h6>
                          </CardHeader>
                          <CardBody className="pt-0">
                            <DataTable
                              columns={columnsLogCredential}
                              data={logCredentialTable.data}
                              pagination={true}
                              paginationPerPage={5}
                              paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Downline Section  */}
                      <TabPane tabId="9">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{"Downline"}</h6>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col xl="12">
                                <Form
                                  className="needs-validation"
                                  noValidate=""
                                  // onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div className="form-row">
                                    <Col md="12 mb-3">
                                      <Input
                                        className="form-control"
                                        name="keyword"
                                        type="text"
                                        placeholder="Keyword"
                                        onChange={(event)=>onSearchDownline(event)}
                                      />
                                    </Col>
                                  </div>
                                  <div className="form-row">
                                    {/* Filter Registration Date */}
                                    {/* <Col md="3 mb-3">
                                      <Label>{"Starting Date"}</Label>
                                      <DatePicker
                                        className="datepicker-here form-control mt-2"
                                        selected={downlineStartDate}
                                        onChange={handleChangeDownlineStartDate}
                                      />
                                    </Col>
                                    <Col md="3 mb-3">
                                      <Label>{"Ending Date"}</Label>
                                      <DatePicker
                                        className="datepicker-here form-control mt-2"
                                        selected={downlineEndDate}
                                        onChange={handleChangeDownlineEndDate}
                                      />
                                    </Col> */}
                                    {/* Filter */}
                                    <Col md="6">
                                      <Label className="ml-2">{"Filter"}</Label>
                                      <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                                        <Row>
                                          <div className="checkbox checkbox-dark ml-3">
                                            <Input id="basic" type="checkbox"
                                             onChange={
                                              (event)=>{ event.target.checked ? setFilterDownlineTable({
                                                ...filterDownlineTable,
                                                basic: "Full Service"
                                              }): setFilterDownlineTable({
                                                ...filterDownlineTable,
                                                basic:''
                                              })}
                                            }
                                             />
                                            <Label for="basic">{"Basic"}</Label>
                                          </div>
                                          <div className="checkbox checkbox-dark">
                                            <Input
                                              id="suspend"
                                              type="checkbox"
                                              onChange={
                                                (event)=>{ event.target.checked ? setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  suspend: "Full Service"
                                                }): setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  suspend:''
                                                })}
                                              }
                                            />
                                            <Label for="suspend">
                                              {"suspend"}
                                            </Label>
                                          </div>
                                          <div className="checkbox checkbox-dark">
                                            <Input
                                              id="sponsor"
                                              type="checkbox"
                                              onChange={
                                                (event)=>{ event.target.checked ? setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  sponsor: "Full Service"
                                                }): setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  sponsor:''
                                                })}
                                              }
                                            />
                                            <Label for="sponsor">
                                              {"sponsor"}
                                            </Label>
                                          </div>
                                        </Row>
                                        <Row>
                                          <div className="checkbox checkbox-dark ml-3">
                                            <Input
                                              id="full-service"
                                              type="checkbox"
                                              onChange={
                                                (event)=>{ event.target.checked ? setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  full_service: "Full Service"
                                                }): setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  full_service:''
                                                })}
                                              }
                                            />
                                            <Label for="full-service">
                                              {"Full Service"}
                                            </Label>
                                          </div>
                                          <div className="checkbox checkbox-dark">
                                            <Input
                                              id="control"
                                              type="checkbox"
                                              onChange={
                                                (event)=>{ event.target.checked ? setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  anomali_control: "Anomali Control"
                                                }): setFilterDownlineTable({
                                                  ...filterDownlineTable,
                                                  anomali_control:''
                                                })}
                                              }
                                            />
                                            <Label for="control">
                                              {"Anomali Control"}
                                            </Label>
                                          </div>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                    <Col md="5">
                                      <Row className="justify-content-around">
                                        <Button color="secondary" onClick={()=>{
                                          SearchDownlineAction(keywordDownline, downlineTableDefault.data)
                                          // console.log("jalan")
                                        }}>
                                          {"Search"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          className="sweet-8"
                                          name="alertSuccess"
                                          onClick={Displayalert}
                                        >
                                          {"Download"}
                                        </Button>
                                      </Row>
                                    </Col>
                                  </div>
                                  {/* Button For Action Form */}
                                  {/* <Row className="justify-content-between">
                                    <Col md="5">
                                      <Row className="justify-content-around">
                                        <Button color="primary" onClick={()=>{
                                          SearchDownlineAction(keywordDownline, downlineTable.data)
                                          // console.log("jalan")
                                        }}>
                                          {"Search"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          className="sweet-8"
                                          name="alertSuccess"
                                          onClick={Displayalert}
                                        >
                                          {"Download"}
                                        </Button>
                                      </Row>
                                    </Col>
                                  </Row> */}
                                </Form>
                                <DataTable
                                  columns={columnsDownline}
                                  data={Object.keys(downlineTableSearchResult).length && downlineTableSearchResult.data.length !==0 ? downlineTableSearchResult.data:downlineTableDefault.data}
                                  pagination={true}
                                  // paginationServer
                                  // paginationTotalRows={downlineTable.total}
                                  // onChangePage={pageDownline => setPageDownline(pageDownline)}
                                  // onChangeRowsPerPage={countPageDownline => setCountPageDownline(countPageDownline)}
                                  // onSort={handleSortDownline}
                                  // paginationPerPage={countPageDownline}
                                  paginationRowsPerPageOptions={[5,10,15,20]}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>

                      {/* Location Section */}
                      <TabPane tabId="10">
                        <Card>
                          <CardBody>
                            <div className="map-js-height">
                              <div id="gmap-simple" className="map-block">
                                {mapPosition.lat == 0.000000 && mapPosition.lng == 0.000000 ? (
                                  <Row className="justify-content-center">
                                    <Col md="6">
                                    <Card className="text-center">
                                      <Row className="justify-content-center">
                                        <Col>
                                          <UserX/>
                                        </Col>
                                      </Row>
                                      <Row className="justify-content-center">
                                        <Col>
                                          <h6>Data Lokasi User Tidak Ditemukan</h6>
                                        </Col>
                                      </Row>
                                    </Card>
                                    </Col>
                                  </Row>
                                ) : (
                                  <MapContainer
                                  style={{height:"100vh"}}
                                  // className="map"
                                  ref={mapRef}
                                  center={mapPosition}
                                  zoom={9}
                                  >
                                    <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                  <Marker position={mapPosition}>
                                    <Popup>
                                      Location
                                    </Popup>
                                  </Marker>
                                  </MapContainer>
                                )}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      {photoIndex.isOpen &&
        (photoIndex.index === 0 ? (
          <Lightbox
            mainSrc={ dataSelfieKtp !==undefined ? dataSelfieKtp : dataPersonalInformation.selfie_ktp}
            onCloseRequest={() =>
              setPhotoIndex({ ...photoIndex, isOpen: false })
            }
          />
        ) : (
          <Lightbox
            mainSrc={dataKtpPicture !== undefined ? dataKtpPicture : dataPersonalInformation.ktp_picture}
            onCloseRequest={() =>
              setPhotoIndex({ ...photoIndex, isOpen: false })
            }
          />
        ))}
    </Fragment>
  );
};

export default UserDetail;
