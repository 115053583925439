import {
    GET_LIST_PROVIDER_PRABAYAR_REQUEST,
    GET_LIST_PROVIDER_PRABAYAR_SUCCESS,
    GET_LIST_PROVIDER_PRABAYAR_FAILED
} from '../../../actionTypes'

const initialState = {
    data:[],
    isLoading:false,
    error:''
}

const listProviderPrabayarReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PROVIDER_PRABAYAR_REQUEST:
            return {
                isLoading: true
            }
        case GET_LIST_PROVIDER_PRABAYAR_SUCCESS:
            return {
                isLoading: false,
                data:action.payload
            }
        case GET_LIST_PROVIDER_PRABAYAR_FAILED:
            return {
                isLoading:false,
                error: action.error
            }
        default:
            return state;
    }
}

export default listProviderPrabayarReducer