import React, { Fragment, useEffect, useState } from 'react';
import {useSelector, useDispatch} from "react-redux"
import {AlertTriangle} from "react-feather"
import axios from "axios"
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Spinner} from 'reactstrap'
import { toast } from 'react-toastify';
import { TimePicker } from 'antd';
import SweetAlert from "sweetalert2";
import moment from 'moment';
import '../../assets/scss/custom/configure-maintenance-custom.scss'


const Maintenance = (props) => {

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [setMaintenance, setSetMaintenance]=useState(false)
  const [flagMaintenance, setFlagMaintenance]=useState(false)

  const showSetMaintenance = () => setSetMaintenance(!setMaintenance)

  // STATE DATA MAINTENANCE
  const [dataMaintenance, setDataMaintenance]=useState()
  console.log("dataMaintenance :", dataMaintenance)
  const [setMaintenanceValue, setSetMaintenanceValue]=useState({
    id:"",
    name:"",
    start:"",
    end:"",
    information:"",
    maintenance:Boolean
  })
  console.log("setMaintenanceValue :", setMaintenanceValue)

  // FETCHING DATA FROM BACKEND
  const getDataMaintenance = () => {
    const URI=`/configure-maintenance/data`

    axios
    .get(URI)
    .then(response => {
      setDataMaintenance(response.data.data)
    })
    .catch(error => {
      console.log("getDataMaintenance error: ", error)
    })
  }

  const setNewMaintenance = () => {
    const URI = `/configure-maintenance/${setMaintenanceValue.id}/update`

    axios
    .post(URI, {
      start:setMaintenanceValue.start,
      end:setMaintenanceValue.end,
      information: setMaintenanceValue.information
    })
    .then(response =>{
      toast.success("Success Set Maintenance !", {
        position: toast.POSITION.TOP_RIGHT
      });
      setSetMaintenance(!setMaintenance)
      setFlagMaintenance(!flagMaintenance)
    })
    .catch(error =>{
      let $errorMessage = "";
      
        for (let i in error.response.data.errors) {
            for (let j in error.response.data.errors[i]) {
                $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
            }
        }
    
        // console.log("eror message: ",$errorMessage);
    
      SweetAlert.fire({
        title:'Error!',
        html:`<div style="text-align:left;">${$errorMessage}</div>`,
        icon:'error'
        
      })
    })
  }

  const resetMaintenance = (id) => {
    const URI = `/configure-maintenance/${id}/reset`

    axios
    .post(URI)
    .then(response =>{
      toast.success("Reset Maintenance Error !", {
        position: toast.POSITION.TOP_RIGHT
      });
      setFlagMaintenance(!flagMaintenance)
    })
    .catch(error =>{
      toast.error("Reset Maintenance Error !", {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }
  
  useEffect(() => {
    getDataMaintenance()
  }, [flagMaintenance])

  return (
    <Fragment>
      <Breadcrumb parent="Home" title="Configure Maintenance" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update maintenance-container">
          <Col xl="12">
            <Card>
              <CardBody className="pt-5">
                <Row className="m-0 chart-main justify-content-around">
                  {dataMaintenance !== undefined ? (
                    dataMaintenance.map(item =>(
                    <Col xl="3" className="p-0 box-col-4 text-center" key={item.id}>
                      <Card body inverse color="#dc3545" className="card-maintenance p-4">
                        <CardTitle tag="h5" className="text-center">{item.name}</CardTitle>
                        {item.maintenance === true ? <CardText className="alert-information"><AlertTriangle/></CardText> : null}
                        {item.start && item.end !== null ? <CardText className="time-information">{`${item.start} - ${item.end} WIB`}</CardText> : null}
                        {item.information !== null ? <CardText className="text-information">{item.information}</CardText> : null}
                        {userPermission.includes("[create] configure maintenance") || userPermission.includes("[update] configure maintenance") ? (
                          <Button color="primary" onClick={()=>{
                            setSetMaintenanceValue({
                              ...setMaintenanceValue,
                              id: item.id,
                              name:item.name,
                              start:item.start,
                              end:item.end,
                              information:item.information,
                              maintenance:item.maintenance
                            })
                            showSetMaintenance()
                            }} className="mb-3">Set Maintenance</Button>
                        ) : null}
                        {item.start && item.end !== null && (userPermission.includes("[create] configure maintenance") || userPermission.includes("[update] configure maintenance")) ? <Button color="secondary" onClick={()=>resetMaintenance(item.id)}>Reset</Button> : null}
                      </Card>
                  </Col>
                    ))
                  ) : (<Spinner/>)}
                </Row>
              </CardBody>
            </Card>
            <Modal
            isOpen={setMaintenance}
            toggle={() => showSetMaintenance()}
            centered
          >
            <ModalHeader toggle={() => showSetMaintenance()}>
              {`Set Maintenance ${setMaintenanceValue.name}`}
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col xl="12" md="12">
                    <FormGroup>
                      <Row className="justify-content-around align-items-center mb-3">
                        <Col xl="4" md="4" xs="4">
                          <Label>{"Time Maintenance"}</Label>
                        </Col>
                        <Col xl="8" md="8" xs="8">
                          <Row>
                            <Col xl="6">
                              <Label>Start</Label>
                              <TimePicker  defaultValue={setMaintenanceValue.start !== null ? moment(`${setMaintenanceValue.start}`, 'HH:mm'): ""} 
                              onSelect={(value) => {
                                const timeString = moment(value).format("HH:mm");
                                setSetMaintenanceValue({
                                  ...setMaintenanceValue,
                                  start: timeString
                                });
                              }}
                              format="HH:mm"/>
                            </Col>
                            <Col xl="6">
                              <Label>End</Label>
                              <TimePicker 
                              defaultValue={setMaintenanceValue.end !== null ? moment(`${setMaintenanceValue.end}`, 'HH:mm'): ""} 
                              onSelect={(value) => {
                                const timeString = moment(value).format("HH:mm");
                                setSetMaintenanceValue({
                                  ...setMaintenanceValue,
                                  end: timeString
                                });
                              }} 
                              format="HH:mm"/>                                        
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row className="justify-content-around align-items-center mb-3">
                        <Col xl="4" md="4">
                          <Label>{"Information"}</Label>
                        </Col>
                        <Col xl="8" md="8">
                          <Input
                            type="textarea"
                            rows="3"
                            defaultValue={setMaintenanceValue.information}
                            onChange={(event)=>{
                              setSetMaintenanceValue({
                                ...setMaintenanceValue,
                                information:event.target.value
                              })
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row className="justify-content-center">
                      <Col xl="3" sm="6" xs="6">
                        <Button
                          color="secondary"
                          type="reset"
                          className="mr-1"
                          onClick={() => showSetMaintenance()}
                        >
                          {"Cancel"}
                        </Button>
                      </Col>
                      <Col xl="3" sm="6" xs="6">
                        <Button color="primary" onClick={()=>setNewMaintenance()}>
                          {"Confirm"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Maintenance;