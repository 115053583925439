import axios from "axios";

import {
  PERMISSION_LIST_REQUEST,
  PERMISSION_LIST_SUCCESS,
  PERMISSION_LIST_FAILED,
  GET_USER_PERMISSION_REQUEST,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_FAILED,
} from "../../actionTypes"

// PERMISSION LIST
export const permissionListRequest = (value) => {
  return {
    type: PERMISSION_LIST_REQUEST,
    payload: value,
  };
};

export const permissionListSuccess = (value) => {
  return {
    type: PERMISSION_LIST_SUCCESS,
    payload: value,
  };
};

export const permissionListFailed = (value) => {
  return {
    type: PERMISSION_LIST_FAILED,
    payload: value,
  };
};

// GET USER PERMISSION
export const getUserPermissionRequest = (value) => {
    return {
      type: GET_USER_PERMISSION_REQUEST,
      payload: value,
    };
  };
  
  export const getUserPermissionSuccess = (value) => {
    return {
      type: GET_USER_PERMISSION_SUCCESS,
      payload: value,
    };
  };
  
  export const getUserPermissionFailed = (value) => {
    return {
      type: GET_USER_PERMISSION_FAILED,
      payload: value,
    };
  };

// permission list fetch  
export const getPermissionList = () => (dispatch) => {
//   event.preventDefault();
//   console.log("register value");

  const uriUsers = "privilege/permissions-list";

  return axios
    .get(uriUsers)
    .then((response) => {
      console.log("response permission", response);
      dispatch(permissionListSuccess(response.data));
      // history.push("/login");
    })
    .catch((error) => {
      console.log(error);
    });
};

// get user permission fetch
export const getUserPermissionData = (id) => (dispatch) => {
    //   event.preventDefault();
    //   console.log("register value");
    
      const uriUsers = `privilege/get-role-permissions/${id}`;
    
      return axios
        .get(uriUsers)
        .then((response) => {
          console.log("response get user permission", response);
          dispatch(getUserPermissionSuccess(response.data));
          // history.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    };