import React, { useState, Fragment, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import Breadcrumb from "../../../layout/breadcrumb";
import Select from "react-select";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import DatePicker from "react-datepicker";
import "../../../assets/scss/custom/manage-product-custom.scss";
import {getListPartnerPrabayar,getListCategoryPrabayar,getListProviderPrabayar} from '../../../redux/action/product-list/pra-bayar'
import {getListPartnerPascabayar,getListCategoryPascabayar,getListProviderPascabayar} from '../../../redux/action/product-list/pasca-bayar'
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { prototype } from "react-scrollbar";

const ProductList = (props) => {

  // Columns For data table
  const columnsManageProductPascabayar = [
    {
      name: "Status",
      center: true,
      width:'400px',
      cell:(row)=>(
        <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] product list") ? false : true} id={`available-pascabayar${row.id}`} type="radio" name={`status${row.id}`} value="TERSEDIA" onClick={(event)=>{updateStatusPascabayar(row.id,event.target.value)}} defaultChecked={row.status === 'TERSEDIA' ? true : false}/>
            <Label className="mb-0" for={`available-pascabayar${row.id}`}>{Option}<span className="digits"> Available</span></Label>
          </div>
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] product list") ? false : true} id={`trouble-pascabayar${row.id}`} type="radio" name={`status${row.id}`} value="GANGGUAN"  onClick={(event)=>updateStatusPascabayar(row.id,event.target.value)} defaultChecked={row.status === 'GANGGUAN' ? true : false}/>
            <Label className="mb-0" for={`trouble-pascabayar${row.id}`}>{Option}<span className="digits"> Trouble</span></Label>
          </div>
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] product list") ? false : true} id={`disable-pascabayar${row.id}`} type="radio" name={`status${row.id}`} value="TIDAK TERSEDIA" onClick={(event)=>updateStatusPascabayar(row.id,event.target.value)} defaultChecked={row.status === 'TIDAK TERSEDIA' ? true : false}/>
            <Label className="mb-0" for={`disable-pascabayar${row.id}`}>{Option}<span className="digits"> Disable</span></Label>
          </div>
        </FormGroup>
      )
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Provider",
      selector: "provider",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Partner",
      selector: "partner",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Product Sort",
      selector: "product_sort",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Admin Charge",
      // selector: "admin_charge",
      center: true,
      width: "200px",
      cell:(row)=>(
        <span>{`Rp. ${(row.admin_charge).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Cash Back",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp. ${(row.cash_back).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Profit",
      sortable: true,
      center: true,
      width: "170px",
      cell:(row)=>(
        <span>{`Rp. ${(row.profit).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Level7 Commission",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <span>{`Rp. ${(row.level_7_commission).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Point",
      selector: "point",
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Partner_code",
      selector: "partner_code",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Buyer",
      selector: "buyer",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Action",
      center: true,
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] product list") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditProductPascabayarValue({
                id:row.id,
                code:row.code,
                product_name:row.product_name,
                category:row.category,
                provider:row.provider,
                partner:row.partner,
                partner_code:row.partner_code,
                product_sort:row.product_sort,
                profit:row.profit,
                level_7_commission:row.level_7_commission,
                point:row.point,
                buyer:row.buyer,
                description:row.description,
                admin_charge:row.admin_charge,
                cash_back:row.cash_back,
                status:row.status,
                repeat:row.repeat === true ? 'YES':'NO',
              })
              getProductSortProductListPascabayar(row.category_id, row.provider_id)
              getCodePartnerProductListPascabayar(row.partner_id)

              setIdCategoryPascabayar(row.category_id)
              setIdProviderPascabayar(row.provider_id)
              setIdPartnerPascabayar(row.partner_id)
              setIdCodePartnerPascabayar(row.partner_code_id)
              setIdProductSortPascabayar(row.product_sort_id)


              showEditManageProductPascabayar()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] product list") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDeletePascabayar(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  const columnsManageProductPrabayar = [
    {
      name: "Status",
      center: true,
      width:'400px',
      cell:(row)=>(
        <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] product list") ? false : true} id={`available-prabayar${row.id}`} type="radio" name={`status${row.id}`} value="TERSEDIA" onClick={(event)=>{updateStatusPrabayar(row.id,event.target.value)}} defaultChecked={row.status === 'TERSEDIA' ? true : false}/>
            <Label className="mb-0" for={`available-prabayar${row.id}`}>{Option}<span className="digits"> Available</span></Label>
          </div>
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] product list") ? false : true} id={`trouble-prabayar${row.id}`} type="radio" name={`status${row.id}`} value="GANGGUAN"  onClick={(event)=>updateStatusPrabayar(row.id,event.target.value)} defaultChecked={row.status === 'GANGGUAN' ? true : false}/>
            <Label className="mb-0" for={`trouble-prabayar${row.id}`}>{Option}<span className="digits"> Trouble</span></Label>
          </div>
          <div className="radio radio-primary">
            <Input disabled={userPermission.includes("[update] product list") ? false : true} id={`disable-prabayar${row.id}`} type="radio" name={`status${row.id}`} value="TIDAK TERSEDIA" onClick={(event)=>updateStatusPrabayar(row.id,event.target.value)} defaultChecked={row.status === 'TIDAK TERSEDIA' ? true : false}/>
            <Label className="mb-0" for={`disable-prabayar${row.id}`}>{Option}<span className="digits"> Disable</span></Label>
          </div>
        </FormGroup>
      )
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
      center: true,
      width: "300px",
    },
    {
      name: "Provider",
      selector: "provider",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Partner",
      selector: "partner",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Product Sort",
      selector:'product_sort',
      center: true,
      width: "170px",
    },
    {
      name: "Buy Price",
      sortable: true,
      center: true,
      width: "150px",
      cell:(row)=>(
        <span>{`Rp. ${(row.buy_price).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Real Price",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <span>{`Rp. ${(row.real_price).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Level 7 Commission",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <span>{`Rp. ${(row.level_7_commission).toLocaleString('en') }`}</span>
      )
    },
    {
      name: "Point",
      selector: "point",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Partner Code",
      selector: "partner_code",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Buyer",
      selector: "buyer",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] product list") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditProductPrabayarValue({
                id:row.id,
                code:row.code,
                product_name:row.product_name,
                category:row.category,
                provider:row.provider,
                partner:row.partner,
                partner_code:row.partner_code,
                product_sort:row.product_sort,
                buy_price:row.buy_price,
                profit:row.profit,
                sell_price:row.sell_price,
                real_price:row.real_price,
                level_7_commission:row.level_7_commission,
                point:row.point,
                buyer:row.buyer,
                description:row.description,
                status:row.status,
                repeat:row.repeat === true ? 'YES':'NO',
              })
              getProductSortProductListPrabayar(row.category_id, row.provider_id)
              getCodePartnerProductListPrabayar(row.partner_id)
              setIdCategoryPrabayar(row.category_id)
              setIdProviderPrabayar(row.provider_id)
              setIdPartnerPrabayar(row.partner_id)
              // setEditPrabayarResponse(!editPrabayarResponse)
              setIdCodePartnerPrabayar(row.partner_code_id)
              setIdProductSortPrabayar(row.product_sort_id)
              if (listCodePartnerPrabayar.length == 0 && listProductSortPrabayar.length == 0 || listCodePartnerPrabayar && listProductSortPrabayar !== undefined) {
                showEditManageProductPrabayar()
              }
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] product list") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => {
              // console.log('data on datatable :', row)
              DisplayAlertDeletePrabayar(row.id)
            }}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  const dispatch = useDispatch()

  // STATE FOR FILTER PRODUCT PRA-BAYAR
  const listParterPrabayarData = useSelector(state => state.listPartnerPrabayarReducer.data.data)
  const listCategoryPrabayarData = useSelector(state => state.listCategoryPrabayarReducer.data.data)
  const listProviderPrabayarData = useSelector(state => state.listProviderPrabayarReducer.data.data)

  // STATE FOR FILTER PRODUCT PASCA-BAYAR
  const listParterPascabayarData = useSelector(state => state.listPartnerPascabayarReducer.data.data)
  const listCategoryPascabayarData = useSelector(state => state.listCategoryPascabayarReducer.data.data)
  const listProviderPascabayarData = useSelector(state => state.listProviderPascabayarReducer.data.data)

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)



  // List Select
  // const listCategoryPascabayar = [
  //   { value: "pulsaPrabayar", label: "Pulsa Prabayar" },
  //   { value: "paketData", label: "Paket Data" },
  //   { value: "listrikPrabayar", label: "Listrik Prabayar" },
  // ];
  // const listProviderPascabayar = [
  //   { value: "pdam", label: "PDAM" },
  //   { value: "telkomsel", label: "Telkomsel" },
  //   { value: "Indosat", label: "Indosat" },
  // ];
  const listProductSort = [
    { value: "paketMantap", label: "Paket Mantap" },
    { value: "paketYellow", label: "Paket Yellow" },
    { value: "pakeHemat", label: "Paket Hemat" },
  ];
  const listBuyer = [
    { value: "ALL", label: "All" },
    { value: "FULLSERVICE", label: "Full Service" },
    { value: "BASIC", label: "Basic" },
  ];
  const listPartner = [
    { value: "veribill", label: "VeriBill" },
    { value: "siapbayar", label: "SiapBayar" },
  ];
  const listRepeat = [
    { value: "YES", label: "Yes" },
    { value: "NO", label: "No" },
  ];
  // LIST FOR SELECT FROM BACKEND START
  // PRA-BAYAR
  const listPartnerPrabayar = listParterPrabayarData !== undefined ?
    listParterPrabayarData.map((item)=>(
      {value: item.id, label: item.name}
    ))
   : []

  const listCategoryPrabayar = listCategoryPrabayarData !== undefined ?
    listCategoryPrabayarData.map((item)=>(
      {value: item.id, label: item.name}
    ))
    : []
  
  const listProviderPrabayar = listProviderPrabayarData !== undefined ?
    listProviderPrabayarData.map((item)=>(
      {value:item.id, label:item.name}
    ))
    : []

  // PASCA-BAYAR
  const listPartnerPascabayar = listParterPascabayarData !== undefined ?
    listParterPascabayarData.map((item)=>(
      {value: item.id, label: item.name}
    ))
   : []

  const listCategoryPascabayar = listCategoryPascabayarData !== undefined ?
    listCategoryPascabayarData.map((item)=>(
      {value: item.id, label: item.name}
    ))
    : []
  
  const listProviderPascabayar = listProviderPascabayarData !== undefined ?
    listProviderPascabayarData.map((item)=>(
      {value:item.id, label:item.name}
    ))
    : []

  // LIST FOR SELECT FROM BACKEND END

  const listStatus = [
    { value: "TERSEDIA", label: "AVAILABLE" },
    { value: "GANGGUAN", label: "TROUBLE" },
    { value: "TIDAK TERSEDIA", label: "DISABLE" },
  ];

  
  const [activeTab, setActiveTab] = useState("1");
  const { register, handleSubmit, errors } = useForm();

  const [addManageProductPascabayar, setAddManageProductPascabayar] = useState(false);
  const [addManageProductPrabayar, setAddManageProductPrabayar] = useState(false);

  const [editManageProductPascabayar, setEditManageProductPascabayar] = useState(false);
  const [editManageProductPrabayar, setEditManageProductPrabayar] = useState(false);

  const [addModal, setaddModal] = useState(false);
  const [alertDeleteSuccess, setAlertDeleteSuccess] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  // const number = document.getElementsByClassName('number')

  

  // ALERT DELETE PRODUCT PRA-BAYAR
  const DisplayAlertDeletePrabayar = (id_product) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductPrabayar(id_product)
        
      }
    })
  };

  // ALERT DELETE PRODUCT PASCA-BAYAR
  const DisplayAlertDeletePascabayar = (id_product) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductPascabayar(id_product)
        
      }
    })
  };


  // Function show modal
  const showAddManageProductPascabayar = () => setAddManageProductPascabayar(!addManageProductPascabayar);
  const showAddManageProductPrabayar = () => setAddManageProductPrabayar(!addManageProductPrabayar);
  const showEditManageProductPascabayar = () => setEditManageProductPascabayar(!editManageProductPascabayar);
  const showEditManageProductPrabayar = () => setEditManageProductPrabayar(!editManageProductPrabayar);


  // ACTION HANDLER START
  // handler for page pra-bayar
  const handleSortProductListPrabayar = (column, sortDirection) => {
    setSortColumnProductListPrabayar(column.selector);
    setSortDirectionProductListPrabayar(sortDirection);
  };
  const onSearchProductListPrabayar = (event) => {setKeywordProductListPrabayar(event.target.value)};

  // handler for page pasca-bayar
  const handleSortProductListPascabayar = (column, sortDirection) => {
    setSortColumnProductListPascabayar(column.selector);
    setSortDirectionProductListPascabayar(sortDirection);
  };
  const onSearchProductListPascabayar = (event) => {setKeywordProductListPascabayar(event.target.value)};
  // ACTION HANDLER END

  // STATE DATA TABLE START
  // PRA-BAYAR
  const [productListPrabayarTable, setProductListPrabayarTable] = useState({});
  // console.log("data table: ", productListPrabayarTable)
  const [pageProductListPrabayar, setPageProductListPrabayar] = useState(1);
  const [sortColumnProductListPrabayar,setSortColumnProductListPrabayar] = useState("");
  const [sortDirectionProductListPrabayar,setSortDirectionProductListPrabayar] = useState("");
  const [countPageProductListPrabayar,setCountPageProductListPrabayar] = useState(5);
  const [keywordProductListPrabayar, setKeywordProductListPrabayar] = useState("");
  const [partner, setPartner] = useState('')
  const [category, setCategory] = useState('')
  const [provider, setProvider] = useState('')

  // PASCA-BAYAR
  const [productListPascabayarTable, setProductListPascabayarTable] = useState({});
  // console.log("product pascabayar table :", productListPascabayarTable)
  const [pageProductListPascabayar, setPageProductListPascabayar] = useState(1);
  const [sortColumnProductListPascabayar,setSortColumnProductListPascabayar] = useState("");
  const [sortDirectionProductListPascabayar,setSortDirectionProductListPascabayar] = useState("");
  const [countPageProductListPascabayar,setCountPageProductListPascabayar] = useState(5);
  const [keywordProductListPascabayar, setKeywordProductListPascabayar] = useState("");
  const [partnerPascabayar, setPartnerPascabayar] = useState('')
  const [categoryPascabayar, setCategoryPascabayar] = useState('')
  const [providerPascabayar, setProviderPascabayar] = useState('')
  // STATE DATA TABLE END


  // STATE FOR HANDLER START
  // for select code partner prabayar
  const [idPartnerPrabayar, setIdPartnerPrabayar] = useState()
  // console.log("idParnerParbayar: ", idPartnerPrabayar)
  const [codePartnerPrabayar, setCodePartnerPrabayar] = useState()
  // console.log("codePartner :", codePartnerPrabayar)

  const listCodePartnerPrabayar = codePartnerPrabayar !== undefined ? 
    codePartnerPrabayar.map((item)=>(
      {value:item.id, label:item.code, price:item.price}
    ))
    : []
  console.log("listCodePartner: ", listCodePartnerPrabayar)
  // for select code partner pascabayar
  const [idPartnerPascabayar, setIdPartnerPascabayar] = useState()
  const [codePartnerPascabayar, setCodePartnerPascabayar] = useState()

  const listCodePartnerPascabayar = codePartnerPascabayar !== undefined ? 
    codePartnerPascabayar.map((item)=>(
      {value:item.id, label:item.code, admin:item.admin, price:item.price}
    ))
    : []

  // for select product sort prabayar
   const [idCategoryPrabayar, setIdCategoryPrabayar] = useState()
   const [idProviderPrabayar, setIdProviderPrabayar] = useState()
   const [productSortPrabayar, setProductSortPrabayar] = useState()

   const listProductSortPrabayar = productSortPrabayar !== undefined ? 
    productSortPrabayar.map((item)=>(
      {value:item.id, label:item.name}
    ))
    : []
  // for select product sort pascabayar
    const [idCategoryPascabayar, setIdCategoryPascabayar] = useState()
    const [idProviderPascabayar, setIdProviderPascabayar] = useState()
    const [productSortPascabayar, setProductSortPascabayar] = useState()
 
    const listProductSortPascabayar = productSortPascabayar !== undefined ? 
     productSortPascabayar.map((item)=>(
       {value:item.id, label:item.name}
     ))
     : []  

  // for add product prabayar
  const [codeAddProductPrabayar, setCodeAddProduct]=useState()
  const [productNameAddProductPrabayar, setProductNameCodeAddProduct]=useState()
  const [idCodePartnerPrabayar, setIdCodePartnerPrabayar]=useState()
  console.log("idCodePartner: ", idCodePartnerPrabayar)
  const [priceCodePartnerPrabayar, setPriceCodePartnerPrabayar]=useState()
  const [idProductSortPrabayar, setIdProductSortPrabayar]=useState()
  const [profitAddProductPrabayar, setProfitAddProductPrabayar]=useState(0)
  const [realPriceAddProductPrabayar, setRealPriceAddProductPrabayar]=useState(0)
  const [level7CommissionAddProductPrabayar, setLevel7CommissionAddProductPrabayar]=useState(0)
  const [pointAddProductPrabayar, setPointAddProductPrabayar]=useState(0)
  const [buyerAddProductPrabayar, setBuyerAddProductPrabayar]=useState()
  const [repeatAddProductPrabayar, setRepeatAddProductPrabayar]=useState()
  const [descriptionAddProductPrabayar, setDescriptionAddProductPrabayar]=useState()
  
  const addProductRepeatPrabayarValue = (event) =>{
    setRepeatAddProductPrabayar(event.target.value)
  }

  const [addProductPrabayarStatus, setAddProductPrabayarStatus]=useState(false)

  // for add product pascabayar
  const [codeAddProductPascabayar, setCodeAddProductPascabayar]=useState()
  const [productNameAddProductPascabayar, setProductNameCodeAddProductPascabayar]=useState()
  const [idCodePartnerPascabayar, setIdCodePartnerPascabayar]=useState()
  const [idProductSortPascabayar, setIdProductSortPascabayar]=useState()
  const [adminChargePascabayar, setAdminChargePascabayar]=useState()
  const [cashbackPascabayar, setCashbackPascabayar]=useState()
  const [profitAddProductPascabayar, setProfitAddProductPascabayar]=useState()
  const [level7CommissionAddProductPascabayar, setLevel7CommissionAddProductPascabayar]=useState()
  const [pointAddProductPascabayar, setPointAddProductPascabayar]=useState()
  const [buyerAddProductPascabayar, setBuyerAddProductPascabayar]=useState()
  const [repeatAddProductPascabayar, setRepeatAddProductPascabayar]=useState()
  const [descriptionAddProductPascabayar, setDescriptionAddProductPascabayar]=useState()
  
  const addProductRepeatPascabayarValue = (event) =>{
    setRepeatAddProductPascabayar(event.target.value)
  }

  const [addProductPascabayarStatus, setAddProductPascabayarStatus]=useState(false)

  //for edit product pra-bayar
  const [editProductPrabayarValue, setEditProductPrabayarValue]=useState({
    id:'',
    code:'',
    product_name:'',
    category:'',
    provider:'',
    partner:'',
    partner_code:'',
    product_sort:'',
    buy_price:'',
    profit:'',
    sell_price:'',
    real_price:'',
    level_7_commission:'',
    point:'',
    buyer:'',
    description:'',
    status:'',
    repeat:'',
  }) 
  const [editProductPrabayarStatus, setEditProductPrabayarStatus]=useState(false)

  //for edit product pasca-bayar
  const [editProductPascabayarValue, setEditProductPascabayarValue]=useState({
    id:'',
    code:'',
    product_name:'',
    category:'',
    provider:'',
    partner:'',
    partner_code:'',
    product_sort:'',
    profit:'',
    level_7_commission:'',
    point:'',
    buyer:'',
    admin_charge:'',
    cash_back:'',
    description:'',
    status:'',
    repeat:'',
  }) 
  const [editProductPascabayarStatus, setEditProductPascabayarStatus]=useState(false)


  // for delete pra-bayar
  const [deletePrabayarStatus, setDeletePrabayarStatus]=useState(false)

  // for delete pasca-bayar
  const [deletePascabayarStatus, setDeletePascabayarStatus]=useState(false)


  // for update trouble and available with toggle switch
  const [updateTroubleAndAvailableResponse, setUpdateTroubleAndAvailableResponse]=useState(false)

  // STATE FOR HANDLER END



  // FETCHING DATA TABLE PRA-BAYAR
  const getDataTableProductListPrabayar = () => {
    const URI = `product-list/prabayar/data`;

    return axios
      .get(URI, {
        params: {
          page: pageProductListPrabayar,
          per_page: countPageProductListPrabayar,
          sort_column: sortColumnProductListPrabayar,
          sort_direction: sortDirectionProductListPrabayar,
          keyword: keywordProductListPrabayar,
          partner: partner,
          category: category,
          provider: provider,
        },
      })
      .then((response) => {
        setProductListPrabayarTable(response.data);
        // console.log("getProductListPrabayarTable response: ", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // FETCHING DATA TABLE PASCA-BAYAR
  const getDataTableProductListPascabayar = () => {
    const URI = `product-list/pascabayar/data`;

    return axios
      .get(URI, {
        params: {
          page: pageProductListPascabayar,
          per_page: countPageProductListPascabayar,
          sort_column: sortColumnProductListPascabayar,
          sort_direction: sortDirectionProductListPascabayar,
          keyword: keywordProductListPascabayar,
          partner: partnerPascabayar,
          category: categoryPascabayar,
          provider: providerPascabayar,
        },
      })
      .then((response) => {
        setProductListPascabayarTable(response.data);
        // console.log("getProductListPascabayarTable response: ", response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };



  // FETCHING DATA FOR OPTION SELECT CODE PARTNER PRA-BAYAR
  const getCodePartnerProductListPrabayar = (id) => {
    const URI = `product-list/prabayar/partner-code`;

    return axios
      .get(URI, {
        params: {
          partner: id || idPartnerPrabayar
        },
      })
      .then((response) => {
        setCodePartnerPrabayar(response.data.data);
        // console.log("getCodePartnerPrabayarTable response: ", response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // FETCHING DATA FOR OPTION SELECT CODE PARTNER PASCA-BAYAR
  const getCodePartnerProductListPascabayar = (id) => {
    const URI = `product-list/pascabayar/partner-code`;

    return axios
      .get(URI, {
        params: {
          partner: id || idPartnerPascabayar
        },
      })
      .then((response) => {
        setCodePartnerPascabayar(response.data.data);
        // console.log("getCodePartnerPascabayar response: ", response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };


  // FETCHING DATA FOR OPTION SELECT PRODUCT SORT PRABAYAR
  const getProductSortProductListPrabayar = (category, provider) => {
    const URI = `product-list/prabayar/product-sort`;

    return axios
      .get(URI, {
        params: {
          category: category || idCategoryPrabayar,
          provider: provider || idProviderPrabayar,
        },
      })
      .then((response) => {
        setProductSortPrabayar(response.data.data);
        // console.log("getProductSortPrabayar response: ", response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // FETCHING DATA FOR OPTION SELECT PRODUCT SORT PASCA-BAYAR
  const getProductSortProductListPascabayar = (category, provider) => {
    const URI = `product-list/pascabayar/product-sort`;

    return axios
      .get(URI, {
        params: {
          category: category || idCategoryPascabayar,
          provider: provider || idProviderPascabayar
        },
      })
      .then((response) => {
        setProductSortPascabayar(response.data.data);
        // console.log("getProductSortPascabayar response: ", response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // POST DATA FOR ADDING PRODUCT PRABAYAR
  const postProductPrabayar = () => {
    const URI = `product-list/prabayar/store`;

    return axios
      .post(URI, {
          code: codeAddProductPrabayar,
          product_name: productNameAddProductPrabayar,
          category: idCategoryPrabayar,
          provider: idProviderPrabayar,
          partner_code: idCodePartnerPrabayar,
          product_sort: idProductSortPrabayar,
          profit: profitAddProductPrabayar,
          real_price: realPriceAddProductPrabayar,
          level_7_commission: level7CommissionAddProductPrabayar,
          point: pointAddProductPrabayar,
          buyer: buyerAddProductPrabayar,
          repeat: repeatAddProductPrabayar,
          description: descriptionAddProductPrabayar,
       
      })
      .then((response) => {
        toast.success("Success Adding New Product !", {
          position: toast.POSITION.TOP_RIGHT
        });
        setAddProductPrabayarStatus(!addProductPrabayarStatus)
        setAddManageProductPrabayar(!addManageProductPrabayar)
        // console.log("postProductPrabayar response: ", response.status);
      })
      .catch((error) => {
        
        let $errorMessage = "";
      
        for (let i in error.response.data.errors) {
            for (let j in error.response.data.errors[i]) {
                $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
            }
        }
    
        // console.log("eror message: ",$errorMessage);
    
      SweetAlert.fire({
        title:'Error!',
        html:`<div style="text-align:left;">${$errorMessage}</div>`,
        icon:'error'
        
      })
        // console.log(error.response.data.errors);
      });
  };

  // POST DATA FOR ADDING PRODUCT PASCA-BAYAR
  const postProductPascabayar = () => {
    const URI = `product-list/pascabayar/store`;

    return axios
      .post(URI, {
          code: codeAddProductPascabayar,
          product_name: productNameAddProductPascabayar,
          category: idCategoryPascabayar,
          provider: idProviderPascabayar,
          partner_code: idCodePartnerPascabayar,
          product_sort: idProductSortPascabayar,
          profit: profitAddProductPascabayar,
          level_7_commission: level7CommissionAddProductPascabayar,
          point: pointAddProductPascabayar,
          buyer: buyerAddProductPascabayar,
          repeat: repeatAddProductPascabayar,
          description: descriptionAddProductPascabayar,
       
      })
      .then((response) => {
        setAddProductPascabayarStatus(!addProductPascabayarStatus)
        setAddManageProductPascabayar(!addManageProductPascabayar)
        toast.success("Success Adding New Product !", {
          position: toast.POSITION.TOP_RIGHT
        });
        // console.log("postProductPascabayar response: ", response.data.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log(error);
      });
  };


  // EDIT DATA PRODUCT PRA-BAYAR
  const editProductPrabayar = () => {
    
    const URI = `product-list/prabayar/${editProductPrabayarValue.id}/update`;

    return axios
      .post(URI, {
        ...editProductPrabayarValue,
        category:idCategoryPrabayar,
        provider:idProviderPrabayar,
        partner:idPartnerPrabayar,
        partner_code:idCodePartnerPrabayar,
        product_sort:idProductSortPrabayar
      })
      .then((response) => {

        // let category = ""
        // for (let i of listCategoryPrabayar) {
        //   if (i.value == idCategoryPrabayar) {
        //     category = i.label
        //   }
        // }

        // let  partnerCode = ""
        // for (let  i of listCodePartnerPrabayar) {
        //   if (i.value == idCodePartnerPrabayar) {
        //      partnerCode = i.label
        //   }
        // }
        
        // let newData = [...productListPrabayarTable.data]
        // for ( let i of newData) {
        //   if (i.id == editProductPrabayarValue.id) {
        //     i.code = editProductPrabayarValue.code
        //     i.product_name = editProductPrabayarValue.product_name
        //     i.category = category
        //     i.provider = idProviderPrabayar
        //     i.partner = idPartnerPrabayar
        //     i.partner_code = partnerCode
        //     console.log("Partner Code:",i.partner_code)
        //     i.product_sort = idProductSortPrabayar
        //     i.buy_price = editProductPrabayarValue.buy_price
        //     i.profit = editProductPrabayarValue.profit
        //     i.sell_price = editProductPrabayarValue.sell_price
        //     i.real_price = editProductPrabayarValue.real_price
        //     i.level_7_commission = editProductPrabayarValue.level_7_commission
        //     i.point = editProductPrabayarValue.point
        //     i.buyer = editProductPrabayarValue.buyer
        //     i.description = editProductPrabayarValue.description
        //     i.status = editProductPrabayarValue.status
        //     i.repeat = editProductPrabayarValue.repeat
        //   }
        // }

        setEditProductPrabayarStatus(!editProductPrabayarStatus)
        toast.success("Success Edit Product !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setEditManageProductPrabayar(!editManageProductPrabayar)
        // console.log("editProductPrabayar response: ", response.data.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log(error);
      });
  };

  // EDIT DATA PRODUCT PASCA-BAYAR
  const editProductPascabayar = () => {
    
    const URI = `product-list/pascabayar/${editProductPascabayarValue.id}/update`;

    return axios
      .post(URI, {
        ...editProductPascabayarValue,
        category:idCategoryPascabayar,
        provider:idProviderPascabayar,
        partner_code:idCodePartnerPascabayar,
        product_sort:idProductSortPascabayar
      })
      .then((response) => {
        setEditProductPascabayarStatus(!editProductPascabayarStatus)
        toast.success("Success Edit Product !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setEditManageProductPascabayar(!editManageProductPascabayar)
        // console.log("editProductPascabayar response: ", response.data.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        // console.log(error);
      });
  };


  // DELETE DATA ON DATA TABLE PRA-BAYAR WITH SPECIFIC ID
  const deleteProductPrabayar = (id) => {
    const URI = `product-list/prabayar/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      // console.log("deleteProductPrabayar response: ", res)
      setDeletePrabayarStatus(!deletePrabayarStatus)
      toast.success("Success Delete Product!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      // console.log("deleteProductPrabayar error: ", err)
      toast.error("Failed to delete product!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }

  // DELETE DATA ON DATA TABLE PASCA-AYAR WITH SPECIFIC ID
  const deleteProductPascabayar = (id) => {
    const URI = `product-list/pascabayar/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      setDeletePascabayarStatus(!deletePascabayarStatus)
      // console.log("deleteProductPascabayar response: ", res)
      toast.success("Success delete product!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      // console.log("deleteProductPrabayar error: ", err)
      toast.error("Failed to delete product!", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }

  // UPDATE STATUS AVAILABLE AND TROUBLE WITH TOGGLE SWITCH
  const updateStatusPrabayar = (id,status) => {
    const URI = `/product-list/${id}/toggle-status`

    axios
    .post(URI , {
      status: status
    })
    .then(res => {
      toast.success("Success Update Status !", {
        position: toast.POSITION.TOP_RIGHT
        });
      let newData = [...productListPrabayarTable.data]
      // newData[id].status = status
      for (let i=0; i < newData.length; i++) {
        if (newData[i].id == id) {
          newData[i].status = status
          // console.log("response : ", newData[i])
          // console.log("response status : ", newData[i].status)
          // console.log("response id :", newData[i].id)
          // console.log("params id :", id)
        }
      }
      // setUpdateTroubleAndAvailableResponse(!updateTroubleAndAvailableResponse)
      // console.log("updateTroubleAndAvailable Response: ", newData[id].status)
      // console.log("DataTable Response: ", newData)
    })
    .catch(err => {
      toast.error("Failed Update Status !", {
        position: toast.POSITION.TOP_RIGHT
        });
      // console.log("updateTroubleAndAvailable Error: ", err)
    })
  }

  const updateStatusPascabayar = (id,status) => {
    const URI = `/product-list/${id}/toggle-status`

    axios
    .post(URI , {
      status: status
    })
    .then(res => {
      toast.success("Success Update Status !", {
        position: toast.POSITION.TOP_RIGHT
        });
      let newData = [...productListPascabayarTable.data]
      // newData[id].status = status
      for (let i=0; i < newData.length; i++) {
        if (newData[i].id == id) {
          newData[i].status = status
          // console.log("response : ", newData[i])
          // console.log("response status : ", newData[i].status)
          // console.log("response id :", newData[i].id)
          // console.log("params id :", id)
        }
      }
      // setUpdateTroubleAndAvailableResponse(!updateTroubleAndAvailableResponse)
      // console.log("updateTroubleAndAvailable Response: ", newData[id].status)
      // console.log("DataTable Response: ", newData)
    })
    .catch(err => {
      toast.error("Failed Update Status !", {
        position: toast.POSITION.TOP_RIGHT
        });
      // console.log("updateTroubleAndAvailable Error: ", err)
    })
  }



  // Lifecycle pra-bayar section
  useEffect(() => {
    dispatch(getListPartnerPrabayar())
    dispatch(getListCategoryPrabayar())
    dispatch(getListProviderPrabayar())
    
  }, [])
  
  useEffect(() => {
    // PRA-BAYAR
    getDataTableProductListPrabayar()
    
  }, [
    // PRA-BAYAR
    pageProductListPrabayar,
    sortColumnProductListPrabayar,
    sortDirectionProductListPrabayar,
    countPageProductListPrabayar,
    editProductPrabayarStatus,
    addProductPrabayarStatus,
    deletePrabayarStatus,

    // update trouble and available with toggle switch
    // updateTroubleAndAvailableResponse,
    
  ]);

  useEffect(() => {
    getDataTableProductListPascabayar()
    // getCodePartnerProductListPascabayar()
    // getProductSortProductListPascabayar()

    
  }, [
    // PASCA-BAYAR
    pageProductListPascabayar,
    sortColumnProductListPascabayar,
    sortDirectionProductListPascabayar,
    countPageProductListPascabayar,
    addProductPascabayarStatus,
    editProductPascabayarStatus,
    deletePascabayarStatus,

  ])

  return (
    <Fragment>
      <Breadcrumb parent="Product" title="Product List" />
      <Container fluid={true}>
        <Row>
          
            <Col sm="12">
              <Card>
                <CardBody>
                  <div className="text-center">
                    <Nav tabs className="search-list">
                      <NavItem>
                        <NavLink
                          className={activeTab === "1" ? "active" : ""}
                          onClick={() => setActiveTab("1")}
                        >
                          <i className="icon-user"></i>
                          {"Product Pra Bayar"}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === "2" ? "active" : ""}
                          onClick={() => {
                            getDataTableProductListPascabayar()

                            // this not good cz always fetch data, later will fix it
                            dispatch(getListPartnerPascabayar())
                            dispatch(getListCategoryPascabayar())
                            dispatch(getListProviderPascabayar())
                            setActiveTab("2")
                          }}
                        >
                          <i className="icon-clipboard"></i>
                          {"Product Pasca Bayar"}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <TabContent activeTab={activeTab}>
                    {/* Product Partner Pra Bayar Section */}
                    <TabPane tabId="1" className="search-links fade show">
                      <Card className="mb-0">
                        <CardHeader className="d-flex">
                          <h6 className="mb-0">{"Product Pra Bayar"}</h6>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col xl="12">
                              <Form
                              //   className="needs-validation"
                              //   noValidate=""
                              >
                                <Row className="justify-content-center mb-3">
                                  <Col xl="12">
                                    <Input
                                      className="form-control mt-4"
                                      name="keyword"
                                      type="text"
                                      placeholder="Keyword"
                                      onChange={(event)=>onSearchProductListPrabayar(event)}
                                    />
                                  </Col>
                                </Row>
                                <Row className="justify-content-center mb-5">
                                      <Col xl="4">
                                        <Label>Partner</Label>
                                        <Select options={listPartnerPrabayar} onChange={(event)=>{
                                          setPartner(event.value)
                                        }}/>
                                      </Col>
                                      <Col xl="4">
                                        <Label>Category</Label>
                                        <Select options={listCategoryPrabayar} onChange={(event)=>{
                                          setCategory(event.value)
                                        }}/>
                                      </Col>
                                      <Col xl="4">
                                        <Label>Provider</Label>
                                        <Select options={listProviderPrabayar} onChange={(event)=>{
                                          setProvider(event.value)
                                        }}/>
                                      </Col>
                                </Row>
                                <Row className="justify-content-between">
                                  <Col xl="7" md="6" xs="6">
                                    <Row>
                                      <Button
                                        color="secondary"
                                        className="mr-3 ml-3"
                                        onClick={()=>getDataTableProductListPrabayar()}
                                      >
                                        {"Search"}
                                      </Button>
                                    </Row>
                                  </Col>
                                  <Col xl="5" md="6" xs="6">
                                    <Row className="justify-content-end">
                                      {userPermission.includes("[create] product list") ? (
                                        <Button
                                        color="primary"
                                        className="sweet-8"
                                        name="alertSuccess"
                                        onClick={() =>
                                          showAddManageProductPrabayar()
                                        }
                                      >
                                        {"Add Product"}
                                      </Button>
                                      ) : null}
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                              <DataTable
                                columns={columnsManageProductPrabayar}
                                data={productListPrabayarTable.data}
                                pagination={true}
                                paginationServer
                                paginationTotalRows={productListPrabayarTable.total}
                                onChangePage={(pageProductListPrabayar) =>
                                  setPageProductListPrabayar(pageProductListPrabayar)
                                }
                                onChangeRowsPerPage={(countPageProductListPrabayar) =>
                                  setCountPageProductListPrabayar(
                                    countPageProductListPrabayar
                                  )
                                }
                                onSort={handleSortProductListPrabayar}
                                paginationPerPage={countPageProductListPrabayar}
                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                              />
                            </Col>
                          </Row>

                          {/* Modal Add Product Pra Bayar */}
                          <Modal
                            isOpen={addManageProductPrabayar}
                            toggle={() => showAddManageProductPrabayar()}
                            centered
                          >
                            <ModalHeader
                              toggle={() => showAddManageProductPrabayar()}
                            >
                              {"Add Product Pra Bayar"}
                            </ModalHeader>
                            <ModalBody>
                              <Form
                              onSubmit={(event)=>{
                                event.preventDefault()
                                postProductPrabayar()
                              }}
                              >
                                <Row>
                                  <Col xl="12" md="12">
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            id="Pascabayar-product-code"
                                            type="text"
                                            name="Pascabayar-product-code"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setCodeAddProduct(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Name"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            onChange={(event)=>{
                                              setProductNameCodeAddProduct(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Category"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          {/* <Input required> */}
                                          <Select options={listCategoryPrabayar}
                                          onChange={(event)=>{
                                            
                                            setIdCategoryPrabayar(event.value)
                                            getProductSortProductListPrabayar(event.value, idProviderPrabayar)
                                          }}/>
                                          {/* </Input> */}
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Provider"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listProviderPrabayar} onChange={(event)=>{
                                            setIdProviderPrabayar(event.value)
                                            getProductSortProductListPrabayar(idCategoryPrabayar,event.value)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listPartnerPrabayar} onChange={(event)=>{
                                            // setIdPartnerPrabayar(event.value)
                                            getCodePartnerProductListPrabayar(event.value)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listCodePartnerPrabayar !== undefined ? listCodePartnerPrabayar : ''}
                                          onChange={(event)=>{
                                            setIdCodePartnerPrabayar(event.value)
                                            setPriceCodePartnerPrabayar(event.price)
                                            // getCodePartnerProductListPrabayar()
                                          }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Sort"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listProductSortPrabayar !== undefined ? listProductSortPrabayar : ''} 
                                          
                                          onChange={(event)=>{
                                            setIdProductSortPrabayar(event.value)
                                          }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Buy Price"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="text"
                                              disabled
                                              value={priceCodePartnerPrabayar}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Profit"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              id="number1"
                                              name="saldo-nominal"
                                              type="number"
                                              onClick={(event)=>{
                                                const cancelWheel = (event) => event.preventDefault();
                                                event.target.addEventListener("wheel", cancelWheel)
                                              }}
                                              onChange={(event)=>{
                                                // const cancelWheel = (event) => event.preventDefault();
                                                // event.target.addEventListener("wheel", cancelWheel)
                                                setProfitAddProductPrabayar(event.target.value)
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Level 7 Commission"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              onClick={(event)=>{
                                                const cancelWheel = (event) => event.preventDefault();
                                                event.target.addEventListener("wheel", cancelWheel)
                                              }}
                                              onChange={(event)=>{
                                                setLevel7CommissionAddProductPrabayar(event.target.value)
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Sell Price"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="text"
                                              disabled
                                              value={parseFloat(priceCodePartnerPrabayar) + parseFloat(profitAddProductPrabayar) + parseFloat(level7CommissionAddProductPrabayar)}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Real Price"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              onClick={(event)=>{
                                                const cancelWheel = (event) => event.preventDefault();
                                                event.target.addEventListener("wheel", cancelWheel)
                                              }}
                                              onChange={(event)=>{
                                                setRealPriceAddProductPrabayar(event.target.value)
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Point"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            onChange={(event)=>{
                                              setPointAddProductPrabayar(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Buyer"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listBuyer} onChange={(event)=>{
                                            setBuyerAddProductPrabayar(event.value)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Description"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="textarea"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            rows="3"
                                            onChange={(event)=>{
                                              setDescriptionAddProductPrabayar(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Repeat"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Row className="justify-content-center ml-2">
                                            <Col>
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="addProductRepeatPrabayar"
                                                  value='YES'
                                                  onChange={(event)=>addProductRepeatPrabayarValue(event)}
                                                />{" "}
                                                Yes
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="addProductRepeatPrabayar"
                                                  value="NO"
                                                  onChange={(event)=>addProductRepeatPrabayarValue(event)}
                                                />{" "}
                                                No
                                              </Label>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="3" md="3">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          onClick={() =>
                                            showAddManageProductPrabayar()
                                          }
                                        >
                                          {"Cancel"}
                                        </Button>
                                      </Col>
                                      <Col xl="3" md="3">
                                        <Button
                                          color="primary"
                                          className="mr-1"
                                          type="submit"
                                        >
                                          {"Confirm"}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            </ModalBody>
                          </Modal>

                          {/* Modal Edit Product Pra Bayar */}
                          <Modal
                            isOpen={editManageProductPrabayar}
                            toggle={() => showEditManageProductPrabayar()}
                            centered
                          >
                            <ModalHeader
                              toggle={() => showEditManageProductPrabayar()}
                            >
                              {"Edit & Detail Product Pra Bayar"}
                            </ModalHeader>
                            <ModalBody>
                              <Form
                              onSubmit={(event)=>{
                                event.preventDefault()
                                editProductPrabayar()
                              }}
                              >
                                <Row>
                                  <Col xl="12" md="12">
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            id="Pascabayar-product-code"
                                            type="text"
                                            name="Pascabayar-product-code"
                                            className="form-control digits"
                                            value={editProductPrabayarValue.code}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                code: event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Name"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            value={editProductPrabayarValue.product_name}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                product_name: event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Category"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listCategoryPrabayar}
                                            onChange={(event)=>{
                                              setIdCategoryPrabayar(event.value)
                                            getProductSortProductListPrabayar(event.value, idProviderPrabayar)
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                category: event.label,
                                              })
                                            }}
                                            value={listCategoryPrabayar.filter(list => list.label === editProductPrabayarValue.category)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Provider"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listProviderPrabayar}
                                            onChange={(event)=>{
                                              setIdProviderPrabayar(event.value)
                                            getProductSortProductListPrabayar(idCategoryPrabayar,event.value)
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                provider: event.label,
                                              })
                                            }}
                                            value={listProviderPrabayar.filter(list => list.label === editProductPrabayarValue.provider)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listPartnerPrabayar}
                                            onChange={(event)=>{
                                              setIdPartnerPrabayar(event.value)
                                            getCodePartnerProductListPrabayar(event.value)
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                partner: event.label,
                                              })
                                            }}
                                            value={listPartnerPrabayar.filter(list => list.label === editProductPrabayarValue.partner)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select 
                                          options={listCodePartnerPrabayar}
                                          onChange={(event)=>{
                                            setIdCodePartnerPrabayar(event.value)
                                            setEditProductPrabayarValue({
                                              ...editProductPrabayarValue,
                                              partner_code: event.label,
                                              buy_price: parseFloat(event.price)
                                            })
                                          }}
                                          value={listCodePartnerPrabayar && listCodePartnerPrabayar.filter(list => list.label === editProductPrabayarValue.partner_code)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Sort"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listProductSortPrabayar}
                                            onChange={(event)=>{
                                              setIdProductSortPrabayar(event.value)
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                product_sort: event.label,
                                              })

                                            }}
                                            value={listProductSortPrabayar.filter(list => list.label === editProductPrabayarValue.product_sort)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Buy Price"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              disabled
                                              value={editProductPrabayarValue.buy_price}
                                              // onChange={(event)=>{
                                              //   setEditProductPrabayarValue({
                                              //     ...editProductPrabayarValue,
                                              //     buy_price: event.target.value
                                              //   })
                                              // }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Profit"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              onClick={(event)=>{
                                                const cancelWheel = (event) => event.preventDefault();
                                                event.target.addEventListener("wheel", cancelWheel)
                                              }}
                                              onChange={(event)=>{
                                                setEditProductPrabayarValue({
                                                  ...editProductPrabayarValue,
                                                  profit: parseFloat(event.target.value)
                                                })
                                              }}
                                              value={parseFloat(editProductPrabayarValue.profit)}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Level 7 Commission"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              onClick={(event)=>{
                                                const cancelWheel = (event) => event.preventDefault();
                                                event.target.addEventListener("wheel", cancelWheel)
                                              }}
                                              value={parseFloat(editProductPrabayarValue.level_7_commission)}
                                              onChange={(event)=>{
                                                setEditProductPrabayarValue({
                                                  ...editProductPrabayarValue,
                                                  level_7_commission: parseFloat(event.target.value)
                                                })
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Sell Price"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              disabled
                                              value={editProductPrabayarValue.buy_price + parseFloat(editProductPrabayarValue.profit) + parseFloat(editProductPrabayarValue.level_7_commission)}
                                              // onChange={(event)=>{
                                              //   setEditProductPrabayarValue({
                                              //     ...editProductPrabayarValue,
                                              //     sell_price: event.target.value
                                              //   })
                                              // }}
                                            />
                                            {/* {console.log("buy price: ", editProductPrabayarValue.buy_price)}
                                            {console.log("edit Value : ", editProductPrabayarValue)}
                                            {console.log("sell :", editProductPrabayarValue.buy_price + parseFloat(editProductPrabayarValue.profit) + parseFloat(editProductPrabayarValue.level_7_commission))} */}
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Real Price"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              onClick={(event)=>{
                                                const cancelWheel = (event) => event.preventDefault();
                                                event.target.addEventListener("wheel", cancelWheel)
                                              }}
                                              value={editProductPrabayarValue.real_price}
                                              onChange={(event)=>{
                                                setEditProductPrabayarValue({
                                                  ...editProductPrabayarValue,
                                                  real_price: event.target.value
                                                })
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Point"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            value={editProductPrabayarValue.point}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                point: event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Buyer"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listBuyer}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                buyer: event.value
                                              })
                                            }}
                                            value={listBuyer.filter(list => list.value === editProductPrabayarValue.buyer)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Description"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="textarea"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            rows="3"
                                            value={editProductPrabayarValue.description}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                description: event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Status"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listStatus}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                status: event.value
                                              })
                                            }}
                                            value={listStatus.filter(list => list.value === editProductPrabayarValue.status)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Repeat"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listRepeat}
                                            onChange={(event)=>{
                                              setEditProductPrabayarValue({
                                                ...editProductPrabayarValue,
                                                repeat: event.value
                                              })
                                            }}
                                            value={editProductPrabayarValue.repeat === 'YES' ? listRepeat[0]:listRepeat[1]}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="3" md="3">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          onClick={() =>
                                            showEditManageProductPrabayar()
                                          }
                                        >
                                          {"Cancel"}
                                        </Button>
                                      </Col>
                                      <Col xl="3" md="3">
                                        <Button
                                          color="primary"
                                          className="mr-1"
                                          type="submit"
                                        >
                                          {"Confirm"}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </CardBody>
                      </Card>
                    </TabPane>

                    {/* Product Pasca Bayar Section */}
                    <TabPane tabId="2" className="search-links fade show">
                      <Card className="mb-0">
                        <CardHeader className="d-flex">
                          <h6 className="mb-0">{"Product Pasca Bayar"}</h6>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col xl="12">
                              <Form
                              //   className="needs-validation"
                              //   noValidate=""
                              //   onSubmit={handleSubmit(onSubmit)}
                              >
                                <Row className="justify-content-center mb-3">
                                  <Col xl="12">
                                    <Input
                                      className="form-control mt-4"
                                      name="keyword"
                                      type="text"
                                      placeholder="Keyword"
                                      onChange={(event)=>onSearchProductListPascabayar(event)}
                                    />
                                  </Col>
                                </Row>
                                <Row className="justify-content-center mb-5">
                                      <Col xl="4">
                                        <Label>Partner</Label>
                                        <Select options={listPartnerPascabayar} onChange={(event)=>{
                                          setPartnerPascabayar(event.value)
                                        }}/>
                                      </Col>
                                      <Col xl="4">
                                        <Label>Category</Label>
                                        <Select options={listCategoryPascabayar} onChange={(event)=>{
                                          setCategoryPascabayar(event.value)
                                        }}/>
                                      </Col>
                                      <Col xl="4">
                                        <Label>Provider</Label>
                                        <Select options={listProviderPascabayar} onChange={(event)=>{
                                          setProviderPascabayar(event.value)
                                        }}/>
                                      </Col>
                                </Row>
                                <Row className="justify-content-between">
                                  <Col xl="7" md="7">
                                    <Row>
                                      <Button
                                        color="secondary"
                                        className="mr-3 ml-3"
                                        onClick={()=>getDataTableProductListPascabayar()}
                                      >
                                        {"Search"}
                                      </Button>
                                    </Row>
                                  </Col>
                                  <Col xl="5" md="5">
                                    <Row className="justify-content-end">
                                      {userPermission.includes("[create] product list") ? (
                                        <Button
                                        color="primary"
                                        className="sweet-8"
                                        name="alertSuccess"
                                        onClick={() =>
                                          showAddManageProductPascabayar()
                                        }
                                      >
                                        {"Add Product"}
                                      </Button>
                                      ) : null}
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                              <DataTable
                                columns={columnsManageProductPascabayar}
                                data={productListPascabayarTable.data}
                                pagination={true}
                                paginationServer
                                paginationTotalRows={productListPascabayarTable.total}
                                onChangePage={(pageProductListPascabayar) =>
                                  setPageProductListPascabayar(pageProductListPascabayar)
                                }
                                onChangeRowsPerPage={(countPageProductListPascabayar) =>
                                  setCountPageProductListPascabayar(
                                    countPageProductListPascabayar
                                  )
                                }
                                onSort={handleSortProductListPascabayar}
                                paginationPerPage={countPageProductListPascabayar}
                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                              />
                            </Col>
                          </Row>

                          {/* Modal Add Product Pasca Bayar */}
                          <Modal
                            isOpen={addManageProductPascabayar}
                            toggle={() => showAddManageProductPascabayar()}
                            centered
                          >
                            <ModalHeader
                              toggle={() => showAddManageProductPascabayar()}
                            >
                              {"Add Product Pasca Bayar"}
                            </ModalHeader>
                            <ModalBody>
                              <Form>
                                <Row>
                                  <Col xl="12" md="12">
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            id="Pascabayar-product-code"
                                            type="text"
                                            name="Pascabayar-product-code"
                                            className="form-control digits"
                                            onChange={(event)=>{
                                              setCodeAddProductPascabayar(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Name"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            onChange={(event)=>{
                                              setProductNameCodeAddProductPascabayar(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Category"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listCategoryPascabayar} onChange={(event)=>{
                                            setIdCategoryPascabayar(event.value)
                                            getProductSortProductListPascabayar(event.value, idProviderPascabayar)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Provider"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listProviderPascabayar} onChange={(event)=>{
                                            setIdProviderPascabayar(event.value)
                                            getProductSortProductListPascabayar(idCategoryPascabayar, event.value)

                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listPartnerPascabayar} onChange={(event)=>{
                                            // setIdPartnerPascabayar(event.value)
                                            getCodePartnerProductListPascabayar(event.value)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listCodePartnerPascabayar} onChange={(event)=>{
                                            setIdCodePartnerPascabayar(event.value)
                                            setAdminChargePascabayar(event.admin)
                                            setCashbackPascabayar(event.price)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Sort"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listProductSortPascabayar} onChange={(event)=>{
                                            setIdProductSortPascabayar(event.value)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Admin Charge"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              disabled={true}
                                              value={adminChargePascabayar}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Cash Back"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              disabled={true}
                                              value={cashbackPascabayar}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Profit"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              onChange={(event)=>{
                                                setProfitAddProductPascabayar(event.target.value)
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Level 7 Commission"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              onChange={(event)=>{
                                                setLevel7CommissionAddProductPascabayar(event.target.value)
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Point"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <InputGroup>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              onChange={(event)=>{
                                                setPointAddProductPascabayar(event.target.value)
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Buyer"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select options={listBuyer} onChange={(event)=>{
                                            setBuyerAddProductPascabayar(event.value)
                                          }}/>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Description"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="textarea"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            rows="3"
                                            onChange={(event)=>{
                                              setDescriptionAddProductPascabayar(event.target.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Repeat"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Row className="justify-content-center ml-2">
                                            <Col>
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="addProductRepeatPascabayar"
                                                  value="YES"
                                                  onChange={(event)=>addProductRepeatPascabayarValue(event)}
                                                />
                                                Yes
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="addProductRepeatPascabayar"
                                                  value="NO"
                                                  onChange={(event)=>addProductRepeatPascabayarValue(event)}
                                                />
                                                No
                                              </Label>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="3" md="3">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          onClick={() =>
                                            showAddManageProductPascabayar()
                                          }
                                        >
                                          {"Cancel"}
                                        </Button>
                                      </Col>
                                      <Col xl="3" md="3">
                                        <Button
                                          color="primary"
                                          className="mr-1"
                                          onClick={()=>postProductPascabayar()}
                                        >
                                          {"Confirm"}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            </ModalBody>
                          </Modal>

                          {/* Modal Edit Product Pasca Bayar */}
                          <Modal
                            isOpen={editManageProductPascabayar}
                            toggle={() => showEditManageProductPascabayar()}
                            centered
                          >
                            <ModalHeader
                              toggle={() => showEditManageProductPascabayar()}
                            >
                              {"Edit & Detail Product Pasca Bayar"}
                            </ModalHeader>
                            <ModalBody>
                              <Form
                              onSubmit={(event)=>{
                                event.preventDefault()
                                editProductPascabayar()
                              }}
                              >
                                <Row>
                                  <Col xl="12" md="12">
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            id="Pascabayar-product-code"
                                            type="text"
                                            name="Pascabayar-product-code"
                                            className="form-control digits"
                                            value={editProductPascabayarValue.code}
                                            onChange={(event)=>{
                                              setEditProductPascabayarValue({
                                                ...editProductPascabayarValue,
                                                code:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Name"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            value={editProductPascabayarValue.product_name}
                                            onChange={(event)=>{
                                              setEditProductPascabayarValue({
                                                ...editProductPascabayarValue,
                                                product_name:event.target.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Category"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listCategoryPascabayar}
                                            value={listCategoryPascabayar.filter(list => list.label === editProductPascabayarValue.category)}
                                            onChange={(event)=>{
                                              setIdCategoryPascabayar(event.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Provider"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listProviderPascabayar}
                                            value={listProviderPascabayar.filter(list => list.label === editProductPascabayarValue.provider)}
                                            onChange={(event)=>{
                                              setIdProviderPascabayar(event.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listPartnerPascabayar}
                                            value={listPartnerPascabayar.filter(list => list.label === editProductPascabayarValue.partner)}
                                            onChange={(event)=>{
                                              setIdPartnerPascabayar(event.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <Select
                                            options={listCodePartnerPascabayar}
                                            value={listCodePartnerPascabayar.filter(list => list.label === editProductPascabayarValue.partner_code)}
                                            onChange={(event)=>{
                                              setIdCodePartnerPascabayar(event.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Product Sort"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listProductSortPascabayar}
                                            value={listProductSortPascabayar.filter(list => list.label === editProductPascabayarValue.product_sort)}
                                            onChange={(event)=>{
                                              setIdProductSortPascabayar(event.value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Admin Charge"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              value={editProductPascabayarValue.admin_charge}
                                              disabled={true}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Cash Back"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              value={editProductPascabayarValue.cash_back}
                                              disabled={true}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Profit"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              type="text"
                                              name="Pascabayar-product-name"
                                              id="Pascabayar-product-name"
                                              value={editProductPascabayarValue.profit}
                                              onChange={(event)=>{
                                                setEditProductPascabayarValue({
                                                  ...editProductPascabayarValue,
                                                  profit:event.target.value
                                                })
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Level 7 Commission"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                {"Rp"}
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                              className="form-control"
                                              name="saldo-nominal"
                                              type="number"
                                              value={editProductPascabayarValue.level_7_commission}
                                              onChange={(event)=>{
                                                setEditProductPascabayarValue({
                                                  ...editProductPascabayarValue,
                                                  level_7_commission:event.target.value
                                                })
                                              }}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Point"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            value={editProductPascabayarValue.point}
                                              onChange={(event)=>{
                                                setEditProductPascabayarValue({
                                                  ...editProductPascabayarValue,
                                                  point:event.target.value
                                                })
                                              }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Partner Code"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="text"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            value={editProductPascabayarValue.partner_code}
                                              onChange={(event)=>{
                                                setEditProductPascabayarValue({
                                                  ...editProductPascabayarValue,
                                                  partner_code:event.target.value
                                                })
                                              }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Buyer"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listBuyer}
                                            value={listBuyer.filter(list => list.value === editProductPascabayarValue.buyer)}
                                            onChange={(event)=>{
                                              setEditProductPascabayarValue({
                                                ...editProductPascabayarValue,
                                                buyer:event.value
                                              })
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Description"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Input
                                            type="textarea"
                                            name="Pascabayar-product-name"
                                            id="Pascabayar-product-name"
                                            rows="3"
                                            value={editProductPascabayarValue.description}
                                              onChange={(event)=>{
                                                setEditProductPascabayarValue({
                                                  ...editProductPascabayarValue,
                                                  description:event.target.value
                                                })
                                              }}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Status"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listStatus}
                                            onChange={(event)=>{
                                              setEditProductPascabayarValue({
                                                ...editProductPascabayarValue,
                                                status: event.value
                                              })
                                            }}
                                            value={listStatus.filter(list => list.value === editProductPascabayarValue.status)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <FormGroup>
                                      <Row className="justify-content-around">
                                        <Col xl="4" md="4">
                                          <Label>{"Repeat"}</Label>
                                        </Col>
                                        <Col xl="6" md="6">
                                          <Select
                                            options={listRepeat}
                                            onChange={(event)=>{
                                              setEditProductPascabayarValue({
                                                ...editProductPascabayarValue,
                                                repeat: event.value
                                              })
                                            }}
                                            value={listRepeat.filter(list => list.value === editProductPascabayarValue.repeat)}
                                          />
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                    <Row className="justify-content-center">
                                      <Col xl="3" md="3">
                                        <Button
                                          color="secondary"
                                          type="reset"
                                          onClick={() =>
                                            showEditManageProductPascabayar()
                                          }
                                        >
                                          {"Cancel"}
                                        </Button>
                                      </Col>
                                      <Col xl="3" md="3">
                                        <Button
                                          color="primary"
                                          className="mr-1"
                                          type="submit"
                                        >
                                          {"Confirm"}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          
        </Row>
      </Container>
      
    </Fragment>
  );
};

export default ProductList;
