import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useSelector } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/bank-statement-custom.scss";
// import {dataTableTransaction} from '../../data/dummyTableData'

import axios from "axios";

const BankAccount = () => {

  const columnsBankAccount = [
    {
      name: "status",
      center: true,
      width:"300px",
      cell:(row)=>(
        <div className="custom-control custom-switch">
        <label className="mr-5">Non Active</label>
        <input
          type="checkbox"
          disabled={userPermission.includes("[update] bank account") ? false : true}
          className="custom-control-input mr-1"
          id={`status-bank-account-${row.id}`}
          defaultChecked={row.status === 1 ? true :false}
          onClick={(event)=>{
            // row.status === 0 ? setUpdateStatusValue("ACTIVE") : setUpdateStatusValue("NON ACTIVE")
            updateStatusBankAccount(row.id, row.status, event)
          }}
        />
        <label
          className="custom-control-label mr-5"
          htmlFor={`status-bank-account-${row.id}`}
        >
          Active
        </label>
      </div>
      )
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
      width:"150px"
    },
    {
      name: "Bank_partner",
      selector: "bank_partner",
      sortable: true,
      center: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Account user",
      selector: "account_user",
      sortable: true,
      center: true,
      width:"150px",
      wrap: true,
    },
    {
      name: "Account Number",
      selector: "account_number",
      sortable: true,
      center: true,
      width:"170px",
      wrap: true,
    },
    {
      name: "Account Recipient",
      selector: "account_recipient",
      sortable: true,
      center: true,
      width:"190px",
      wrap: true,
    },
    {
      name: "Mutation Check",
      sortable: true,
      center: true,
      width:"200px",
      cell:(row)=>(
        <span>{`Every ${row.mutation_check} Minutes`}</span>
      )
    },
    {
      name: "VIA",
      selector: "via",
      sortable: true,
      center: true,
      width:"150px",
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] bank account") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={()=>{
              setEditBankAccountValue({
                id:row.id,
                code:row.code,
                bank_partner:row.bank_partner,
                account_user:row.account_user,    
                account_recipient:row.account_recipient,    
                account_number:row.account_number,
                mutation_check:row.mutation_check,
                via:row.via,
                status:row.status === 1 ? 'ACTIVE':'NON ACTIVE'
              })
              setModalEditAccount(true)
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {row.canDelete === true && userPermission.includes("[delete] bank account") ? <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={()=>DisplayAlertDelete(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>:<Button
            color="danger"
            className="btn-xs ml-2"
            disabled
          >
            <i class="fa fa-trash"></i>
          </Button>}
        </div>
      )
    },
  ];

  // STATE USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [sweetAlert, setSweetAlert] = useState(false);
  const [alertDeleteSuccess, setAlertDeleteSuccess]=useState(false)
  const [modal, setModal] = useState(false);

  const [modalAddAccount, setModalAddAccount] = useState(false);
  const [modalEditAccount, setModalEditAccount]=useState(false)

  const [togglePassword, setTogglePassword] = useState(false);

  const listBankPartner = [
    {value:'BNI', label:'BNI'},
    {value:'BCA', label:'BCA'},
    {value:'BRI', label:'BRI'},
    {value:'MANDIRI', label:'MANDIRI'},
  ]

  const listVia = [
    {value:'CRAWLING', label:'CRAWLING'},
    {value:'API', label:'API'},
  ]

  const listStatus = [
    {value:'ACTIVE', label:'ACTIVE'},
    {value:'NON ACTIVE', label:'NON ACTIVE'},
  ]

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };
  
  const DisplayAlertDelete = (id) => {
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBankAccount(id)
        
      }
    })
  }

  const addAccountModaltoggle = () => setModalAddAccount(!modalAddAccount);
  const editAccountModaltoggle = () => setModalEditAccount(!modalEditAccount);

  // ACTION HANDLER
  const handleSortBankAccount = (column, sortDirection) => {
    setSortColumnBankAccount(column.selector);
    setSortDirectionBankAccount(sortDirection);
  };
  const onSearchBankAccount = (event) => {setKeywordBankAccount(event.target.value)};

  // STATE DATA TABLE
  const [bankAccountTable, setBankAccountTable] = useState({});
  const [pageBankAccount, setPageBankAccount] = useState(1);
  const [sortColumnBankAccount,setSortColumnBankAccount] = useState("");
  const [sortDirectionBankAccount,setSortDirectionBankAccount] = useState("");
  const [countPageBankAccount,setCountPageBankAccount] = useState(5);
  const [keywordBankAccount, setKeywordBankAccount] = useState("");

  // STATE VALUE ADD NEW BANK ACCOUNT
  const [addBankAccountValue, setAddBankAccountValue]=useState({
    code:'',
    bank_partner:'',
    account_user:'',    
    account_recipient:'',    
    account_number:'',    
    account_password:'',
    mutation_check:'',
    via:'',
    status:''    
  })
  const [addBankAccountResponse, setAddBankAccountResponse]=useState(false)

  // STATE VALUE EDIT BANK ACCOUNT
  const [editBankAccountValue, setEditBankAccountValue]=useState({
    id:'',
    code:'',
    bank_partner:'',
    account_user:'',    
    account_recipient:'',    
    account_number:'',    
    account_password:'',
    mutation_check:'',
    via:'',
    status:''    
  })
  const [editBankAccountResponse, setEditBankAccountResponse]=useState(false)

  // STATE FOR UPDATE STATUS
  const [updateStatusValue, setUpdateStatusValue]=useState()
  const [updateStatusBankAccountResponse, setUpdateStatusBankAccountResponse]=useState(false)

  // STATE FOR DELETE
  const [deleteBankAccountResponse, setDeleteBankAccountResponse]=useState(false)
  

  // FETCHING DATA TABLE
  const getDataTableBankAccount = () => {
    const URI = `bank-account/data`;

    return axios
      .get(URI, {
        params: {
          page: pageBankAccount,
          per_page: countPageBankAccount,
          sort_column: sortColumnBankAccount,
          sort_direction: sortDirectionBankAccount,
          keyword: keywordBankAccount,
        },
      })
      .then((response) => {
        setBankAccountTable(response.data);
        console.log("getBankAccountTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // POST NEW BANK ACCOUNT
  const addNewBankAccount = () => {
    const URI = `bank-account/store`;

    return axios
      .post(URI, addBankAccountValue)
      .then((response) => {
        setAddBankAccountResponse(!addBankAccountResponse);
        setModalAddAccount(!modalAddAccount)
        toast.success("Success Adding New Account!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount Response: ", response);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log("addBankAccount error: ",error);
      });
  };

  // EDIT BANK ACCOUNT
  const editBankAccount = () => {
    const URI = `bank-account/${editBankAccountValue.id}/update`;

    return axios
      .post(URI, {
        code:editBankAccountValue.code,
        bank_partner:editBankAccountValue.bank_partner,
        account_user:editBankAccountValue.account_user,    
        account_recipient:editBankAccountValue.account_recipient,    
        account_number:editBankAccountValue.account_number,    
        account_password:editBankAccountValue.account_password !== undefined ? editBankAccountValue.account_password:"",
        mutation_check:editBankAccountValue.mutation_check,
        via:editBankAccountValue.via,
        status:editBankAccountValue.status  
      })
      .then((response) => {
        setEditBankAccountResponse(!editBankAccountResponse);
        setModalEditAccount(!modalEditAccount)
        toast.success("Edit Success!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount Response: ", response);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log("addBankAccount error: ",error);
      });
  };

  // UPDATE STATUS BANK ACCOUNT WITH SWITCH
  const updateStatusBankAccount = (id,status,event) => {
    event.preventDefault()

    const URI = `bank-account/${id}/toggle-status`;

    return axios
      .post(URI, {
        status: status === 0 ? 'ACTIVE' : 'NON ACTIVE'
      })
      .then((response) => {
        setUpdateStatusBankAccountResponse(!updateStatusBankAccountResponse);
        toast.success("Update Success!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount Response: ", response);
      })
      .catch((error) => {
        toast.error("Update Failed!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount error: ",error);
      });
  };

  // DELETE BANK ACCOUNT
  const deleteBankAccount = (id) => {
    const URI = `bank-account/${id}/delete`;

    return axios
      .post(URI)
      .then((response) => {
        setDeleteBankAccountResponse(!deleteBankAccountResponse);
        toast.success("Delete Account Success!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount Response: ", response);
      })
      .catch((error) => {
        toast.error("Delete Account Failed!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount error: ",error);
      });
  };
  
  

  useEffect(() => {
    getDataTableBankAccount()
  }, [
    pageBankAccount,
    sortColumnBankAccount,
    sortDirectionBankAccount,
    countPageBankAccount,
    editBankAccountResponse,
    addBankAccountResponse,
    updateStatusBankAccountResponse,
    deleteBankAccountResponse,
  ]);

  // eslint-disable-next-line

  return (
    <Fragment>
      <Breadcrumb parent="Deposit" title="Bank Account" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form>
                    <Row className="mb-4">
                        <Col xl="9" md="9">
                        <Input
                        className="form-control mb-3"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchBankAccount(event)}
                      />
                        </Col>
                        <Col xl="3" md="3">
                          <Button color="secondary" onClick={()=>getDataTableBankAccount()}>{"Search"}</Button>
                        </Col>
                    </Row>
                      <Row>
                        <Col md="4">
                          <Row className="justify-content-around">
                          {userPermission.includes("[create] bank account") ? (
                            <Button
                            color="primary"
                            className="sweet-8"
                            name="alertSuccess"
                            onClick={()=>addAccountModaltoggle()}
                          >
                            {"Add Account"}
                          </Button>
                          ) : null}
                          </Row>
                        </Col>
                      </Row>
                </Form>
                <DataTable
                  columns={columnsBankAccount}
                  data={bankAccountTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={bankAccountTable.total}
                  onChangePage={(pageBankAccount) =>
                    setPageBankAccount(pageBankAccount)
                  }
                  onChangeRowsPerPage={(countPageBankAccount) =>
                    setCountPageBankAccount(
                      countPageBankAccount
                    )
                  }
                  onSort={handleSortBankAccount}
                  paginationPerPage={countPageBankAccount}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                
                {/* Modal Add Account */}
                <Modal
                  isOpen={modalAddAccount}
                  toggle={() => addAccountModaltoggle()}
                  centered
                >
                  <ModalHeader toggle={() => addAccountModaltoggle()}>
                    {"Add Account"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Code</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                code:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Bank partner</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listBankPartner}
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                bank_partner:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Account User</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            placeholder="user name untuk login ibanking"
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                account_user:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Account Number</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            placeholder="nomor rekening"
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                account_number:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Account Recipient</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            placeholder="atas nama rekening"
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                account_recipient:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                          <Label>Account Password</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="8">
                          <FormGroup>
                          <Input
                            className="form-control"
                            type={
                              togglePassword ? "text" : "password"
                            }
                            name="password"
                            placeholder="password untuk ibanking"
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                account_password:event.target.value
                              })
                            }}
                            required=""
                          />
                          <div
                            className="show-hide custom-show-hide"
                            onClick={() =>
                              setTogglePassword(!togglePassword)
                            }
                          >
                            <span
                              className={togglePassword ? "" : "show"}
                            ></span>
                          </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Mutation Check</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="8">
                          <FormGroup>
                            <Input type="number"
                            placeholder="per menit"
                            onClick={(event)=>{
                              const cancelWheel = (event) => event.preventDefault();
                              event.target.addEventListener("wheel", cancelWheel)
                            }}
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                mutation_check:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>VIA</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listVia}
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                via:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Status</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listStatus}
                            onChange={(event)=>{
                              setAddBankAccountValue({
                                ...addBankAccountValue,
                                status:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xl="3" sm="6" xs="6">
                          <Button
                            color="secondary"
                            type="reset"
                            onClick={() =>addAccountModaltoggle()}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col xl="3" sm="6" xs="6">
                          <Button color="primary" onClick={()=>addNewBankAccount()}>Confirm</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                {/* Modal Edit Account */}
                <Modal
                  isOpen={modalEditAccount}
                  toggle={() => editAccountModaltoggle()}
                  centered
                >
                  <ModalHeader toggle={() => editAccountModaltoggle()}>
                    {"Edit Account"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Code</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            defaultValue={editBankAccountValue.code}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                code:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Bank partner</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listBankPartner}
                            defaultValue={listBankPartner.filter(list =>list.value === editBankAccountValue.bank_partner)}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                bank_partner:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Account User</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            placeholder="user name untuk login ibanking"
                            defaultValue={editBankAccountValue.account_user}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                account_user:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Account Number</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            placeholder="nomor rekening"
                            defaultValue={editBankAccountValue.account_number}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                account_number:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Account Recipient</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Input type="text"
                            placeholder="atas nama rekening"
                            defaultValue={editBankAccountValue.account_recipient}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                account_recipient:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                          <Label>Account Password</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="8">
                          <FormGroup>
                          <Input
                            className="form-control"
                            type={
                              togglePassword ? "text" : "password"
                            }
                            name="password"
                            placeholder="password untuk ibanking"
                            defaultValue={editBankAccountValue.account_password}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                account_password:event.target.value
                              })
                            }}
                            required=""
                          />
                          <div
                            className="show-hide custom-show-hide"
                            onClick={() =>
                              setTogglePassword(!togglePassword)
                            }
                          >
                            <span
                              className={togglePassword ? "" : "show"}
                            ></span>
                          </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Mutation Check</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="8">
                          <FormGroup>
                            <Input type="number"
                            placeholder="per menit"
                            onClick={(event)=>{
                              const cancelWheel = (event) => event.preventDefault();
                              event.target.addEventListener("wheel", cancelWheel)
                            }}
                            defaultValue={editBankAccountValue.mutation_check}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                mutation_check:event.target.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>VIA</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listVia}
                            defaultValue={listVia.filter(list => list.value === editBankAccountValue.via)}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                via:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xl="4" md="4">
                          <FormGroup>
                            <Label>Status</Label>
                          </FormGroup>
                        </Col>
                        <Col xl="8" md="4">
                          <FormGroup>
                            <Select
                            options={listStatus}
                            defaultValue={listStatus.filter(list => list.value === editBankAccountValue.status)}
                            onChange={(event)=>{
                              setEditBankAccountValue({
                                ...editBankAccountValue,
                                status:event.value
                              })
                            }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xl="3" sm="6" xs="6">
                          <Button
                            color="secondary"
                            type="reset"
                            onClick={() =>editAccountModaltoggle()}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col xl="3" sm="6" xs="6">
                          <Button color="primary" onClick={()=>editBankAccount()}>Confirm</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BankAccount;
