import axios from 'axios'
import {
    GET_LIST_PARTNER_PASCABAYAR_REQUEST,
    GET_LIST_PARTNER_PASCABAYAR_SUCCESS,
    GET_LIST_PARTNER_PASCABAYAR_FAILED,
    
    GET_LIST_CATEGORY_PASCABAYAR_REQUEST,
    GET_LIST_CATEGORY_PASCABAYAR_SUCCESS,
    GET_LIST_CATEGORY_PASCABAYAR_FAILED,

    GET_LIST_PROVIDER_PASCABAYAR_REQUEST,
    GET_LIST_PROVIDER_PASCABAYAR_SUCCESS,
    GET_LIST_PROVIDER_PASCABAYAR_FAILED
} from '../../../actionTypes'

// LIST PARTNER ACTION

export const listPartnerPascabayarRequest = () => {
    return {
        type: GET_LIST_PARTNER_PASCABAYAR_REQUEST,
    }
}

export const listPartnerPascabayarSuccess = (value) => {
    return {
        type: GET_LIST_PARTNER_PASCABAYAR_SUCCESS,
        payload: value
    }
}

export const listPartnerPascabayarFailed = (value) => {
    return {
        type: GET_LIST_PARTNER_PASCABAYAR_FAILED,
        error: value
    }
}

export const getListPartnerPascabayar = () => (dispatch) => {
    const URI = `product-list/pascabayar/partner`

    axios
    .get(URI)
    .then(res => {
        console.log("getListPartnerPascabayar respose: ", res.data)
        dispatch(listPartnerPascabayarSuccess(res.data))
    })
    .catch(err => console.log('getListPartnerPascabayar error :', err))
}


// LIST CATEGORY ACTION

export const listCategoryPascabayarRequest = () =>{
    return {
        type: GET_LIST_CATEGORY_PASCABAYAR_REQUEST
    }
}

export const listCategoryPascabayarSuccess = (value) => {
    return {
        type: GET_LIST_CATEGORY_PASCABAYAR_SUCCESS,
        payload: value
    }
}

export const listCategoryPascabayarFailed = (value) => {
    return {
        type: GET_LIST_CATEGORY_PASCABAYAR_FAILED,
        error: value
    }
}

export const getListCategoryPascabayar = () => (dispatch) => {
    const URI = `product-list/pascabayar/category`

    axios
    .get(URI)
    .then(res => {
        console.log('getListCategoryPascabayar response: ', res.data)
        dispatch(listCategoryPascabayarSuccess(res.data))
    })
    .catch(err => console.log('getListCategoryPascabayar error :', err))
}

// LIST PROVIDER ACTION

export const listProviderPascabayarRequest = () =>{
    return {
        type: GET_LIST_PROVIDER_PASCABAYAR_REQUEST
    }
}

export const listProviderPascabayarSuccess = (value) => {
    return {
        type: GET_LIST_PROVIDER_PASCABAYAR_SUCCESS,
        payload: value
    }
}

export const listProviderPascabayarFailed = (value) => {
    return {
        type: GET_LIST_PROVIDER_PASCABAYAR_FAILED,
        error: value
    }
}

export const getListProviderPascabayar = () => (dispatch) => {
    const URI = `product-list/pascabayar/provider`

    axios
    .get(URI)
    .then(res => {
        console.log('getListProviderPascabayar response :' , res.data)
        dispatch(listProviderPascabayarSuccess(res.data))
    })
    .catch(err => console.log('getListProviderPascabayar error: ', err))
}