import axios from 'axios'
import {
    DASHBOARD_TRANSACTION_REQUEST,
    DASHBOARD_TRANSACTION_SUCCESS,
    DASHBOARD_TRANSACTION_FAILED,
} from '../../actionTypes'

// TRANSACTION
export const dashboardTransactionRequest = () => {
    return {
      type: DASHBOARD_TRANSACTION_REQUEST,
    };
  };
  
  export const dashboardTransactionSuccess = (value) => {
    return {
      type: DASHBOARD_TRANSACTION_SUCCESS,
      payload: value,
    };
  };
  
  export const dashboardTransactionFailed = (value) => {
    return {
      type: DASHBOARD_TRANSACTION_FAILED,
      error: value,
    };
  };


//   action transaction
export const getDashboardTransaction = () => (dispatch) => {
    
      const uriUsers = "dashboard/transaction";
    
      return axios
        .get(uriUsers)
        .then((response) => {
          console.log("res", response);
          dispatch(dashboardTransactionSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };