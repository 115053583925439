import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {useDispatch,useSelector} from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from "react-select"; 
import "../../../assets/scss/custom/bank-statement-custom.scss";
import {getListBank} from '../../../redux/action/deposit-list/index'

import axios from "axios";
import fileDownload from "js-file-download";

const BankStatement = () => {
  const columnsBankStatement = [
    {
      name: "Bank",
      selector: "bank",
      sortable: true,
      center: true,
    },
    {
      name: "Transaction_Date",
      // selector: "transaction_date",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <p>{`${row.transaction_date} WIB`}</p>
      )
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      center: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Nominal",
      selector: "nominal",
      sortable: true,
      center: true,
    },
  ];

  const dispatch = useDispatch()

  const [sweetAlert, setSweetAlert] = useState(false);
  const [modal, setModal] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);

  const listBankData = useSelector((state)=>state.listBankReducer.data.data)
  // listBankData && (listBankData[0].bank_partner !== 'ALL' && listBankData.unshift({id:'ALL', bank_partner:'ALL'}))

  const listBank = listBankData !== undefined ? listBankData.map((item)=>(
    { value:item.bank_partner, label: item.bank_partner }
  )):[]

  const listType = [
    { value: "ALL", label: "ALL" },
    { value: "CREDIT", label: "CREDIT" },
    { value: "DEBIT", label: "DEBIT" },
  ];

  const DisplayAlertDownload = () => {

    const URI = `bank-account/download`;

    if (startDate == undefined || endDate == undefined) {
      setSweetAlert(true);
      SweetAlert.fire({
        title: "Download Failed",
        text: "Tanggal Awal dan Akhir harus diisi",
        icon: "error",
      });
      return false;
    }

    return axios
      .get(URI, {
        params: {
          keyword: keywordBankStatement,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          type: type,
          bank: bank,
        },
        responseType: 'blob'
      })
      .then((response) => {
        fileDownload(response.data, 'report bank statement.xlsx')
        setSweetAlert(true);
        SweetAlert.fire({
          title: "Download Success",
          text: "",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });    
    // e.prevenDefault();
  };

  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);

  

  const { register, handleSubmit, errors } = useForm();

  // ACTION HANDLER
  const handleSortBankStatement = (column, sortDirection) => {
    setSortColumnBankStatement(column.selector);
    setSortDirectionBankStatement(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchBankStatement = (event) => {setKeywordBankStatement(event.target.value)};

  // STATE DATA TABLE
  const [bankStatementTable, setBankStatementTable] = useState({});
  const [pageBankStatement, setPageBankStatement] = useState(1);
  const [sortColumnBankStatement,setSortColumnBankStatement] = useState("");
  const [sortDirectionBankStatement,setSortDirectionBankStatement] = useState("");
  const [countPageBankStatement,setCountPageBankStatement] = useState(5);
  const [keywordBankStatement, setKeywordBankStatement] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [bank, setBank] = useState('')
  const [type, setType] = useState('')

  // FETCHING DATA TABLE
  const getDataTableBankStatement = () => {
    const URI = `bank-statement/data`;

    return axios
      .get(URI, {
        params: {
          page: pageBankStatement,
          per_page: countPageBankStatement,
          sort_column: sortColumnBankStatement,
          sort_direction: sortDirectionBankStatement,
          keyword: keywordBankStatement,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          type: type,
          bank: bank,
        },
      })
      .then((response) => {
        setBankStatementTable(response.data);
        console.log("getBankStatement response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(getListBank())
  }, [])

  useEffect(() => {
    getDataTableBankStatement()
  }, [
    pageBankStatement,
    sortColumnBankStatement,
    sortDirectionBankStatement,
    countPageBankStatement
  ]);

  return (
    <Fragment>
      <Breadcrumb parent="Deposit" title="Bank Statement" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>{
                          onSearchBankStatement(event)
                        }}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Type"}</Label>
                        <Select
                        options={listType}
                        onChange={(event)=>{
                          setType(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <FormGroup>
                        <Label>Bank</Label>
                        <Select
                        options={listBank}
                        onChange={(event)=>{
                          setBank(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTableBankStatement()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={DisplayAlertDownload}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columnsBankStatement}
                  data={bankStatementTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={bankStatementTable.total}
                  onChangePage={(pageBankStatement) =>
                    setPageBankStatement(pageBankStatement)
                  }
                  onChangeRowsPerPage={(countPageBankStatement) =>
                    setCountPageBankStatement(
                      countPageBankStatement
                    )
                  }
                  onSort={handleSortBankStatement}
                  paginationPerPage={countPageBankStatement}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                {/* {bankStatementTable !== undefined ? (
                  <div>
                    <Modal
                      isOpen={Verticalcenter}
                      toggle={() => Verticalcentermodaltoggle()}
                      centered
                    >
                      <ModalHeader toggle={() => Verticalcentermodaltoggle()}>
                        {"Transaction Detail"}
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col xl="12">
                            <Row>
                              <Col xl="6">
                                <p>Transaction Code</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;TRX02345O27</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>ID User</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;VP002346</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Product Partner Code</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;SPA5</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Veripay Product Code</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;S5</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Product Name</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Telkomsel 5000</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Buy Price</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Rp.5650</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Sell Price</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Rp.5750</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Destionation Number</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;08126655347</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>SN</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;109801279879823</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Commision 7 Level</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Rp.35</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Point</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;5</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Channel</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Whatsapp</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Partner Name</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Veribill</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Date</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;01 jan 2020 08:12:32 WIB</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Success Time</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;03:00 Minute</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <p>Status</p>
                              </Col>
                              <Col xl="6">
                                <p>:&nbsp;&nbsp;Success</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="2">
                                <p>Notes</p>
                              </Col>
                              <Col xl="10">
                                <textarea
                                  className={
                                    "ng-untouched ng-pristine ng-valid"
                                  }
                                  style={{ height: 100, width: 380 }}
                                  id="newtask"
                                  placeholder="Write Your Notes Here"
                                  // defaultValue={task}
                                  // onChange={onTaskChanged}
                                ></textarea>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </div>
                ) : (
                  <h1>data modal kosong</h1>
                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BankStatement;
