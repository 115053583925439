// dashbaord
import Default from '../components/dashboard/default'

// User
import UserDetail from '../components/user/user-detail/index'
import ManageUser from '../components/user/manage-user/index'
import PendingVerification from "../components/user/pending-verification/index"
import UserList from '../components/user/user-list/index'
import UserMapping from '../components/user/user-mapping/index'
import Suspend from '../components/user/suspend/index'

// Deposit
import DepositList from "../components/deposit/deposit-list/index"
import BankStatement from "../components/deposit/bank-statement/index"
import PaymentMethod from "../components/deposit/payment-method/index"
import BankAccount from "../components/deposit/bank-account/index"


// Transaction
import Transaction from "../components/transaction/transaction/index"
import Transfer from "../components/transaction/transfer/index"
import Point from "../components/transaction/point/index"

// Mutation
import Mutation from "../components/mutation/index"

//Commission 
import Commission from "../components/commission/index"
import CommissionMutation from "../components/commission/commission-mutation/index"
import CommissionPayment from "../components/commission/commission-payment/index"

// Point
import RedeemList from "../components/point/redeem-list/index"
import PointMutation from "../components/point/point-mutation/index"
import RewardList from "../components/point/reward-list/index"

// Products
import ProductPartner from '../components/product/product-partner'
import Partner from '../components/product/partner/index'
import Provider from '../components/product/provider/index'
import ProductSort from "../components/product/product-sort/index"
import ProductList from '../components/product/product-list'

// Category
import Category from '../components/category/index'

// Config Admin
import ConfigAdmin from "../components/configure-admin/index"

// Congfig Maintenance
import Maintenance from "../components/maintenance/index"

// My Profile
import MyProfile from "../components/my-profile/index"

// Bank

// const user = {
//         roles: ['user'],
//         rights: ['can_view_articles']
//       };

      
      



export const routes = [
        { path:"/dashboard", Component:Default},
        
        { path:"/user/user-detail/:id", Component:UserDetail},
        // { path:"/user/manage-user", Component:ManageUser, roles: ['Super Administrator']},
        { path:"/user/user-list", Component:UserList, permission: "[read] user list"},
        { path:"/user/pending-verification", Component:PendingVerification, permission: "[read] pending verification"},
        { path:"/user/user-mapping", Component:UserMapping, permission: "[read] user mapping"},
        { path:"/user/suspend", Component:Suspend,permission: "[read] suspend" },

        { path:"/deposit/deposit-list", Component:DepositList, permission: "[read] deposit"},
        { path:"/deposit/bank-statement", Component:BankStatement, permission: "[read] bank statement"},
        { path:"/deposit/bank-account", Component:BankAccount, permission: "[read] bank account"},
        { path:"/deposit/payment-method", Component:PaymentMethod, permission: "[read] payment method"},

        { path:"/transaction/transaction", Component:Transaction, permission: "[read] transaction"},
        { path:"/transaction/transfer", Component:Transfer, permission: "[read] transfer"},

        { path:"/mutation", Component:Mutation, permission: "[read] mutation"},

        // { path:"/commission", Component:Commission, permission: "[read] user list"},
        { path:"/commission-mutation", Component:CommissionMutation, permission: "[read] commission mutation"},
        { path:"/commission-payment", Component:CommissionPayment, permission: "[read] commission payment"},

        // { path:"/point", Component:Point, roles: ['Super Administrator']},
        { path:"/point/redeem-list", Component:RedeemList, permission: "[read] redeem list"},
        { path:"/point/point-mutation", Component:PointMutation, permission: "[read] point mutation"},
        { path:"/point/reward-list", Component:RewardList, permission: "[read] reward list"},

        // { path:"/product/product-partner", Component:ProductPartner, roles: ['Super Administrator']},
        { path:"/product/product-list", Component:ProductList, permission: "[read] product list"},
        { path:"/product/product-sort", Component:ProductSort, permission: "[read] product sort"},
        { path:"/product/partner", Component:Partner, permission: "[read] partner"},
        { path:"/product/provider", Component:Provider, permission: "[read] provider"},

        { path:"/category", Component:Category, permission: "[read] category"},
        
        { path:"/configure-admin", Component:ConfigAdmin, permission: "[read] configure admin"},
        
        { path:"/configure-maintenance", Component:Maintenance, permission: "[read] configure maintenance"},
        // { path:"/my-profile", Component:MyProfile, roles: ['Super Administrator']},
        
        // { path:"/bank/bank-account", Component:BankAccount},
]