import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import Breadcrumb from "../../../layout/breadcrumb";
import 'antd/dist/antd.css';
import { TimePicker } from 'antd';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/bank-statement-custom.scss";
// import {dataTableTransaction} from '../../data/dummyTableData'

import axios from "axios";

const PaymentMethod = () => {

  const columnsPaymentMethod = [
    {
      name: "Status",
      center: true,
      width:"300px",
      cell:(row)=>(
        <div className="custom-control custom-switch">
        <label className="mr-5">Non Active</label>
        <input
          type="checkbox"
          disabled={userPermission.includes("[update] payment method") ? false : true}
          className="custom-control-input mr-1"
          id={`status-bank-account-${row.id}`}
          defaultChecked={row.status === 1 ? true :false}
          onChange={(event)=>{
            updateStatusPaymentMethod(row.id, row.status, event)
          }}
        />
        <label
          className="custom-control-label mr-5"
          htmlFor={`status-bank-account-${row.id}`}
        >
          Active
        </label>
      </div>
      )
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
      width:"150px"
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      center: true,
      width:"150px"
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      center: true,
      width: "300px",
      wrap:true
    },
    {
      name: "Down Time",
      sortable: true,
      center: true,
      width: "250px",
      cell:(row)=>(
        <span>{`${row.down_time} WIB`}</span>
      )
      //   wrap: true,
    },
    {
      name: "Down Time Cause",
      selector: "down_time_cause",
      sortable: true,
      center: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] payment method") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditPaymentMethodValue({
                id:row.id,
                code:row.code,
                name:row.name,
                bank_id:row.bank_id,
                description:row.description,
                down_time_start:row.down_time.split("-")[0],
                down_time_end:row.down_time.split("-")[1],
                down_time_cause:row.down_time_cause,
                status:row.status === 1 ? "ACTIVE" : "NON ACTIVE",
              })
              DisplayEditPaymentMethod()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] payment method") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDelete(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)
  
  const [alertDeleteSuccess, setAlertDeleteSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  
  const listStatus = [
    {value:'ACTIVE', label:'ACTIVE'},
    {value:'NON ACTIVE', label:'NON ACTIVE'},
  ]

  const DisplayAlertDelete = (id) => {
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deletePaymentMethod(id)
        
      }
    })
  }


  const showAddPaymentMethod = () => setAddPaymentMethod(!addPaymentMethod);
  const DisplayEditPaymentMethod = () => setEditPaymentMethod(!editPaymentMethod);

  
  // ACTION HANDLER
  const handleSortPaymentMethod = (column, sortDirection) => {
    setSortColumnPaymentMethod(column.selector);
    setSortDirectionPaymentMethod(sortDirection);
  };
  const onSearchPaymentMethod = (event) => {setKeywordPaymentMethod(event.target.value)};

  // STATE FOR LIST BANK
  const [listBankData, setListBankData]=useState()

  const listBank = listBankData !== undefined ? listBankData.map((item)=>(
    {value:item.id, label: item.name}
  )): (<p>data kosong</p>)

  // STATE DATA TABLE
  const [paymentMethodTable, setPaymentMethodTable] = useState({});
  const [pagePaymentMethod, setPagePaymentMethod] = useState(1);
  const [sortColumnPaymentMethod,setSortColumnPaymentMethod] = useState("");
  const [sortDirectionPaymentMethod,setSortDirectionPaymentMethod] = useState("");
  const [countPagePaymentMethod,setCountPagePaymentMethod] = useState(5);
  const [keywordPaymentMethod, setKeywordPaymentMethod] = useState("");

  // STATE FOR ADD NEW PAYMENT METHOD
  const [addPaymentMethodValue, setAddPaymentMethodValue]=useState({
    code:'',
    name:'',
    bank_id:'',
    description:'',
    down_time_start:"20:00",
    down_time_end:'05:00',
    down_time_cause:'',
    status:'',
  })
  const [addPaymentMethodResponse, setAddPaymentMethodResponse]=useState(false)

  // STATE FOR EDIT PAYMENT METHOD
  const [editPaymentMethodValue, setEditPaymentMethodValue]=useState({
    id:'',
    code:'',
    name:'',
    bank_id:'',
    description:'',
    down_time_start:"20:00",
    down_time_end:'05:00',
    down_time_cause:'',
    status:'',
  })
  const [editPaymentMethodResponse, setEditPaymentMethodResponse]=useState(false)

  // STATE FOR UPDATE STATUS
  const [updateStatusValue, setUpdateStatusValue]=useState(false)
  const [updateStatusPaymentMethodResponse, setUpdateStatusPaymentMethodResponse]=useState(false)

  // STATE FOR DELETE PAYMENT METHOD
   const [deletePaymentMethodResponse, setDeletePaymentMethodResponse]=useState(false)


  // FETCHING LIST BANK FOR SELECT
  const getListBank = () => {
    const URI = `payment-method/bank`;

    return axios
      .get(URI)
      .then((response) => {
        setListBankData(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // FETCHING DATA TABLE
  const getDataTablePaymentMethod = () => {
    const URI = `payment-method/data`;

    return axios
      .get(URI, {
        params: {
          page: pagePaymentMethod,
          per_page: countPagePaymentMethod,
          sort_column: sortColumnPaymentMethod,
          sort_direction: sortDirectionPaymentMethod,
          keyword: keywordPaymentMethod,
        },
      })
      .then((response) => {
        setPaymentMethodTable(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ADD NEW PAYMENT METHOD
  const addNewPaymentMethod = () => {
    const URI = `payment-method/store`;

    return axios
      .post(URI,addPaymentMethodValue)
      .then((response) => {
        setAddPaymentMethod(!addPaymentMethod)
        setAddPaymentMethodResponse(!addPaymentMethodResponse);
        toast.success("Success Adding Payment Method!", {
          position: toast.POSITION.TOP_RIGHT
          });
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // EDIT PAYMENT METHOD
  const updatePaymentMethod = () => {
    const URI = `payment-method/${editPaymentMethodValue.id}/update`;

    return axios
      .post(URI, {
        code:editPaymentMethodValue.code,
        name:editPaymentMethodValue.name,
        bank_id:editPaymentMethodValue.bank_id,
        description:editPaymentMethodValue.description,
        down_time_start:editPaymentMethodValue.down_time_start,
        down_time_end:editPaymentMethodValue.down_time_end,
        down_time_cause:editPaymentMethodValue.down_time_cause,
        status:editPaymentMethodValue.status,
      })
      .then((response) => {
        setEditPaymentMethod(!editPaymentMethod)
        setTimeout(setEditPaymentMethodResponse(!editPaymentMethodResponse), 1000)
        toast.success("Success Update Payment Method!", {
          position: toast.POSITION.TOP_RIGHT
          });
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // UPDATE STATUS PAYMENT METHOD WITH SWITCH
  const updateStatusPaymentMethod = (id,status,event) => {
    event.preventDefault();

    const URI = `payment-method/${id}/toggle-status`;

    return axios
      .post(URI, {
        status: status === 0 ? 'ACTIVE' : 'NON ACTIVE'
      })
      .then((response) => {
        setUpdateStatusPaymentMethodResponse(!updateStatusPaymentMethodResponse);
        toast.success("Update Success!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount Response: ", response);
      })
      .catch((error) => {
        toast.error("Update Failed!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount error: ",error);
      });
  };

  // DELETE PAYMENT METHOD
  const deletePaymentMethod = (id) => {
    const URI = `payment-method/${id}/delete`;

    return axios
      .post(URI)
      .then((response) => {
        setDeletePaymentMethodResponse(!deletePaymentMethodResponse);
        toast.success("Delete Payment Method Success!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount Response: ", response);
      })
      .catch((error) => {
        toast.error("Delete Payment Method Failed!", {
          position: toast.POSITION.TOP_RIGHT
          });
        console.log("addBankAccount error: ",error);
      });
  };

  useEffect(() => {
    getListBank()
  }, [])

  useEffect(() => {
    getDataTablePaymentMethod()
  }, [
    pagePaymentMethod,
    sortColumnPaymentMethod,
    sortDirectionPaymentMethod,
    countPagePaymentMethod,
    addPaymentMethodResponse,
    editPaymentMethodResponse,
    deletePaymentMethodResponse,
    updateStatusPaymentMethodResponse,
  ]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Deposit" title="Payment Method" />
      
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                <Row className="mb-4">
                        <Col xl="9" md="9">
                        <Input
                        className="form-control mb-3"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchPaymentMethod(event)}
                      />
                        </Col>
                        <Col xl="3" md="3">
                          <Button color="secondary" onClick={()=>getDataTablePaymentMethod()}>{"Search"}</Button>
                        </Col>
                    </Row>
                  <Row className="justify-content-start pl-3">
                    {userPermission.includes("[create] payment method") ? (
                      <Button
                      color="primary"
                      className="sweet-8"
                      name="showAddPayment"
                      onClick={() => showAddPaymentMethod()}
                    >
                      {"Add Payment Method"}
                    </Button>
                    ) : null}
                  </Row>
                  <DataTable
                    columns={columnsPaymentMethod}
                    data={paymentMethodTable.data}
                    pagination={true}
                    paginationServer
                    paginationTotalRows={paymentMethodTable.total}
                    onChangePage={(pagePaymentMethod) =>
                      setPagePaymentMethod(pagePaymentMethod)
                    }
                    onChangeRowsPerPage={(countPagePaymentMethod) =>
                      setCountPagePaymentMethod(
                        countPagePaymentMethod
                      )
                    }
                    onSort={handleSortPaymentMethod}
                    paginationPerPage={countPagePaymentMethod}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  />

                  {/* Modal Add Payment method */}
                  <div>
                      <Modal
                        isOpen={addPaymentMethod}
                        toggle={() => showAddPaymentMethod()}
                        centered
                      >
                        <ModalHeader toggle={() => showAddPaymentMethod()}>
                          {"Add Payment Method"}
                        </ModalHeader>
                        <ModalBody>
                          <Form>
                            <Row>
                              <Col xl="12" md="12">
                              <FormGroup>
                                  <Row className="justify-content-around align-items-center mb-3">
                                    <Col xl="3" md="3">
                                      <Label>{"Code"}</Label>
                                    </Col>
                                    <Col xl="7" md="7">
                                      <Input
                                        id="Payment-method-name"
                                        type="text"
                                        name="payment-method-name"
                                        className="form-control digits"
                                        onChange={(event)=>{
                                          setAddPaymentMethodValue({
                                            ...addPaymentMethodValue,
                                            code:event.target.value
                                          })
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around align-items-center mb-3">
                                    <Col xl="3" md="3">
                                      <Label>{"Name"}</Label>
                                    </Col>
                                    <Col xl="7" md="7">
                                      <Input
                                        id="Payment-method-name"
                                        type="text"
                                        name="payment-method-name"
                                        className="form-control digits"
                                        onChange={(event)=>{
                                          setAddPaymentMethodValue({
                                            ...addPaymentMethodValue,
                                            name:event.target.value
                                          })
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around align-items-center mb-3">
                                    <Col xl="3" md="3">
                                      <Label>{"Bank Account"}</Label>
                                    </Col>
                                    <Col xl="7" md="7">
                                      <Select
                                      options={listBank}
                                      onChange={(event)=>{
                                        setAddPaymentMethodValue({
                                          ...addPaymentMethodValue,
                                          bank_id:event.value
                                        })
                                      }}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around mb-5">
                                    <Col xl="3" md="3">
                                      <Label>{"Description"}</Label>
                                    </Col>
                                    <Col xl="7" md="7">
                                      <Input
                                        type="textarea"
                                        name="message-text-area"
                                        id="message-text-area"
                                        rows="3"
                                        onChange={(event)=>{
                                          setAddPaymentMethodValue({
                                            ...addPaymentMethodValue,
                                            description:event.target.value
                                          })
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around align-items-center mb-3">
                                    <Col xl="4" md="4" xs="4">
                                      <Label>{"Down Time"}</Label>
                                    </Col>
                                    <Col xl="8" md="8" xs="8">
                                      <Row>
                                        <Col xl="6">
                                          <Label>Start</Label>
                                          <TimePicker  defaultValue={moment('20:00', 'HH:mm')} 
                                          onSelect={(value) => {
                                            const timeString = moment(value).format("HH:mm");
                                            setAddPaymentMethodValue({
                                              ...addPaymentMethodValue,
                                              down_time_start: timeString
                                            });
                                          }}
                                          format="HH:mm"/>                                        </Col>
                                        <Col xl="6">
                                          <Label>End</Label>
                                          <TimePicker 
                                          defaultValue={moment('05:00', 'HH:mm')}  
                                          onSelect={(value) => {
                                            const timeString = moment(value).format("HH:mm");
                                            setAddPaymentMethodValue({
                                              ...addPaymentMethodValue,
                                              down_time_end: timeString
                                            });
                                          }} 
                                          format="HH:mm"/>                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around align-items-center mb-3">
                                    <Col xl="3" md="3">
                                      <Label>{"Downtime Cause"}</Label>
                                    </Col>
                                    <Col xl="7" md="7">
                                      <Input
                                        id="downtime-cause"
                                        type="textarea"
                                        name="downtime-cause"
                                        rows="3"
                                        onChange={(event)=>{
                                          setAddPaymentMethodValue({
                                            ...addPaymentMethodValue,
                                            down_time_cause:event.target.value
                                          })
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row className="justify-content-around align-items-center mb-3">
                                    <Col xl="3" md="3">
                                      <Label>{"Status"}</Label>
                                    </Col>
                                    <Col xl="7" md="7">
                                      <Select
                                      options={listStatus}
                                      onChange={(event)=>{
                                        setAddPaymentMethodValue({
                                          ...addPaymentMethodValue,
                                          status:event.value
                                        })
                                      }}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <Row className="justify-content-center">
                                  <Col xl="3" sm="6" xs="6">
                                    <Button
                                      color="secondary"
                                      type="reset"
                                      className="mr-1"
                                      onClick={() => showAddPaymentMethod()}
                                    >
                                      {"Cancel"}
                                    </Button>
                                  </Col>
                                  <Col xl="3" sm="6" xs="6">
                                    <Button color="primary" onClick={()=>addNewPaymentMethod()}>
                                      {"Confirm"}
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </div>

                  {/* Modal Edit Payment Method */}
                  {editPaymentMethodValue && listBankData !== undefined ? (
                    <Modal
                    isOpen={editPaymentMethod}
                    toggle={() => DisplayEditPaymentMethod()}
                    centered
                  >
                    <ModalHeader toggle={() => DisplayEditPaymentMethod()}>
                      {"Edit Payment Method"}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xl="12">
                        <FormGroup>
                            <Row>
                              <Col xl="4">
                                <p>Code</p>
                              </Col>
                              <Col xl="8">
                                <Input type="text" 
                                defaultValue={editPaymentMethodValue.code} 
                                onChange={(event)=>{
                                  setEditPaymentMethodValue({
                                    ...editPaymentMethodValue,
                                    code: event.target.value
                                  })
                                }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col xl="4">
                                <p>Name</p>
                              </Col>
                              <Col xl="8">
                                <Input type="text"
                                defaultValue={editPaymentMethodValue.name}
                                onChange={(event)=>{
                                  setEditPaymentMethodValue({
                                    ...editPaymentMethodValue,
                                    name: event.target.value
                                  })
                                }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around align-items-center mb-3">
                              <Col xl="4" md="4">
                                <Label>{"Bank Account"}</Label>
                              </Col>
                              <Col xl="8" md="8">
                                <Select
                                options={listBank}
                                defaultValue={listBank.filter(list => list.value === editPaymentMethodValue.bank_id)}
                                onChange={(event)=>{
                                  setEditPaymentMethodValue({
                                    ...editPaymentMethodValue,
                                    bank_id:event.value
                                  })
                                }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col xl="4">
                                <p>Description</p>
                              </Col>
                              <Col xl="8">
                                <Input
                                  type="textarea"
                                  rows="3"
                                  defaultValue={editPaymentMethodValue.description}
                                  onChange={(event)=>{
                                    setEditPaymentMethodValue({
                                      ...editPaymentMethodValue,
                                      description: event.target.value
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around align-items-center mb-3">
                              <Col xl="4" sm="4" xs="4">
                                <Label>{"Down Time"}</Label>
                              </Col>
                              <Col xl="8" sm="8" xs="8">
                                <Row>
                                  <Col xl="6">
                                    <Label>Start</Label>
                                    <TimePicker 
                                    defaultValue={moment(`${editPaymentMethodValue.down_time_start}`, 'HH:mm')}  
                                    onSelect={(value) => {
                                      const timeString = moment(value).format("HH:mm");
                                      setEditPaymentMethodValue({
                                        ...editPaymentMethodValue,
                                        down_time_start: timeString
                                      });
                                    }} 
                                    format="HH:mm"
                                    />
                                  </Col>
                                  <Col xl="6">
                                    <Label>End</Label>
                                    <TimePicker 
                                    defaultValue={moment(`${editPaymentMethodValue.down_time_end}`, 'HH:mm')}  
                                    onSelect={(value) => {
                                      const timeString = moment(value).format("HH:mm");
                                      setEditPaymentMethodValue({
                                        ...editPaymentMethodValue,
                                        down_time_end: timeString
                                      });
                                    }} 
                                    format="HH:mm"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col xl="4">
                                <p>Down Time Cause</p>
                              </Col>
                              <Col xl="8">
                                <Input
                                  type="textarea"
                                  rows="2"
                                  defaultValue={editPaymentMethodValue.down_time_cause}
                                  onChange={(event)=>{
                                    setEditPaymentMethodValue({
                                      ...editPaymentMethodValue,
                                      down_time_cause: event.target.value
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col xl="4">
                                <p>Status</p>
                              </Col>
                              <Col xl="8">
                              <Select
                                options={listStatus}
                                defaultValue={listStatus.filter(list => list.value === editPaymentMethodValue.status)}
                                onChange={(event)=>{
                                  setEditPaymentMethodValue({
                                    ...editPaymentMethodValue,
                                    status:event.value
                                  })
                                }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" sm="6" xs="6">
                              <Button
                                color="secondary"
                                type="reset"
                                className="mr-1"
                                onClick={() => DisplayEditPaymentMethod()}
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" sm="6" xs="6">
                              <Button color="primary" onClick={()=>updatePaymentMethod()}>
                                {"Save"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  ):(<p>data kosong</p>)}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
     
    </Fragment>
  );
};

export default PaymentMethod;
