import {
    GET_DETAIL_VERIFICATION_REQUEST,
    GET_DETAIL_VERIFICATION_SUCCESS,
    GET_DETAIL_VERIFICATION_FAILED
} from '../../actionTypes'

const initialState = {
    data:[],
    error: '',
    isLoading: false
}

const detailVerificationReducer = (state = initialState, action) =>{
    switch (action.type) {
        case GET_DETAIL_VERIFICATION_REQUEST:
            return {
                ...state,
                isLoading:true,
            }
        case GET_DETAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                isLoading:false,
                data: action.payload
            }
        case GET_DETAIL_VERIFICATION_FAILED:
            return {
                ...state,
                isLoading:false,
                error: action.error
            }
    
        default:
            return state;
    }
}

export default detailVerificationReducer;