import axios from "axios";

import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAILED
} from "../../actionTypes"

export const roleListRequest = (value) => {
  return {
    type: ROLE_LIST_REQUEST,
    payload: value,
  };
};

export const roleListSuccess = (value) => {
  return {
    type: ROLE_LIST_SUCCESS,
    payload: value,
  };
};

export const roleListFailed = (value) => {
  return {
    type: ROLE_LIST_FAILED,
    payload: value,
  };
};

export const getRoleList = () => (dispatch) => {
//   event.preventDefault();
  // console.log("register value");

  const uriUsers = "privilege/roles-list";

  return axios
    .get(uriUsers)
    .then((response) => {
      console.log("res", response);
      dispatch(roleListSuccess(response.data));
      // history.push("/login");
    })
    .catch((error) => {
      console.log(error);
    });
};
