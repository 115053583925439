import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import axios from "axios";
import "../../assets/scss/custom/manage-user-custom.scss";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap";

const Category = (props) => {

  const columnsCategory = [
    {
      name: "Category Name",
      selector: "category_name",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      center: true,
      cell:(row)=>(
        <div>
          {userPermission.includes("[update] category") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              setEditCategoryValue({
                id: row.id,
                type:row.type,
                name: row.category_name,
              })
              showEditCategory()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
          {userPermission.includes("[delete] category") ? (
            <Button
            color="danger"
            className="btn-xs ml-2"
            onClick={() => DisplayAlertDeletecategory(row.id)}
          >
            <i class="fa fa-trash"></i>
          </Button>
          ) : null}
        </div>
      )
    },
  ];

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)


  const history = useHistory();

  const [addCategory, setAddCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(false);

  const [alertDeleteSuccess, setAlertDeleteSuccess] = useState(false);

  // ALERT DELETE PRODUCT PRA-BAYAR
  const DisplayAlertDeletecategory = (id) => {
    // e.prevenDefault();
    setAlertDeleteSuccess(true);
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons:true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(id)
        
      }
    })
  };

  // Display Modal Form
  const showAddCategory = () => setAddCategory(!addCategory);
  const showEditCategory = () => setEditCategory(!editCategory);

  // ACTION HANDLER
  
  const handleSortCategory = (column, sortDirection) => {
    setSortColumnCategory(column.selector);
    setSortDirectionCategory(sortDirection);
  };
  const onSearchCategory = (event) => {setKeywordCategory(event.target.value)};

  // STATE DATA TABLE
  const [categoryTable, setCategoryTable] = useState({});
  const [pageCategory, setPageCategory] = useState(1);
  const [sortColumnCategory,setSortColumnCategory] = useState("");
  const [sortDirectionCategory,setSortDirectionCategory] = useState("");
  const [countPageCategory,setCountPageCategory] = useState(5);
  const [keywordCategory, setKeywordCategory] = useState("");

  // STATE FOR ADD CATEGORY
  const [addCategoryType, setAddCategoryType]=useState('PRABAYAR')
  const [addCategoryName, setAddCategoryName]=useState()

  const [addCategoryStatus, setAddCategoryStatus]=useState(false)

  // STATE FOR EDIT CATEGORY
  const [editCategoryValue, setEditCategoryValue]=useState({
    id:'',
    type:'',
    name:''
  })
  const [editCategoryStatus, setEditCategoryStatus]=useState(false)

  // STATE FOR DELETE
  const [deleteCategoryStatus, setDeleteCategoryStatus]=useState(false)


  // FETCHING DATA TABLE
  const getDataTableCategory = () => {
    const URI = `category/data`;

    return axios
      .get(URI, {
        params: {
          page: pageCategory,
          per_page: countPageCategory,
          sort_column: sortColumnCategory,
          sort_direction: sortDirectionCategory,
          keyword: keywordCategory,
        },
      })
      .then((response) => {
        setCategoryTable(response.data);
        console.log("getCategoryTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ADD CATEGORY
  const postCategory = () => {
    const URI = `category/store`;

    return axios
      .post(URI, {
        type: addCategoryType,
        name: addCategoryName
      })
      .then((response) => {
        toast.success("Success Adding New Category !", {
          position: toast.POSITION.TOP_RIGHT
        })
        setAddCategory(!addCategory)
        setAddCategoryStatus(!addCategoryStatus)
        console.log("postCategory response: ", response.status);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // EDIT CATEGORY
  const postEditCategory = () => {
    const URI = `category/${editCategoryValue.id}/update`;

    return axios
      .post(URI, {
        type: editCategoryValue.type,
        name:editCategoryValue.name
      })
      .then((response) => {
        toast.success("Success Edit Category !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setEditCategory(!editCategory)
        setEditCategoryStatus(!editCategoryStatus)
        console.log("postCategory response: ", response.status);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };

  // DELETE CATEGORY
  const deleteCategory = (id) => {
    const URI = `category/${id}/delete`

    axios
    .post(URI)
    .then(res => {
      setDeleteCategoryStatus(!deleteCategoryStatus)
      console.log("deleteCategory response: ", res)
      toast.success("Success Delete Category !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => {
      console.log("deleteCategory error: ", err)
      toast.error("Failed to Delete Category !", {
        position: toast.POSITION.TOP_RIGHT
        });
    })
  }





  useEffect(() => {
    getDataTableCategory()
  }, [
    pageCategory,
    countPageCategory,
    sortColumnCategory,
    sortDirectionCategory,
    addCategoryStatus,
    editCategoryStatus,
    deleteCategoryStatus,
  ]);

  return (
    <Fragment>
      <Breadcrumb parent="Home" title="Category" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <Form className="needs-validation" noValidate="">
                      <div className="form-row">
                        <Col xl="7" className="mb-3">
                          <Input
                            className="form-control"
                            name="keyword"
                            type="text"
                            placeholder="Keyword"
                            onChange={(event)=>onSearchCategory(event)}
                          />
                        </Col>
                        <Col xl="5">
                          <Row className="justify-content-around">
                            <Button color="secondary" onClick={()=>getDataTableCategory()}>{"Search"}</Button>
                            {userPermission.includes("[create] category") ? (
                              <Button
                              color="primary"
                              className="sweet-8"
                              name="alertSuccess"
                              onClick={() => showAddCategory()}
                            >
                              {"Add Category"}
                            </Button>
                            ) : null}
                          </Row>
                        </Col>
                      </div>
                    </Form>
                    <DataTable
                      columns={columnsCategory}
                      data={categoryTable.data}
                      pagination={true}
                      paginationServer
                      paginationTotalRows={categoryTable.total}
                      onChangePage={(pageCategory) =>
                        setPageCategory(pageCategory)
                      }
                      onChangeRowsPerPage={(countPageCategory) =>
                        setCountPageCategory(
                          countPageCategory
                        )
                      }
                      onSort={handleSortCategory}
                      paginationPerPage={countPageCategory}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    />
                  </Col>
                </Row>

                {/* Modal Add Category */}
                <Modal
                  isOpen={addCategory}
                  toggle={() => showAddCategory()}
                  centered
                >
                  <ModalHeader toggle={() => showAddCategory()}>
                    {"Add Category"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Category Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="ppob-product-code"
                                  type="text"
                                  name="ppob-product-code"
                                  className="form-control digits"
                                  onChange={(event)=>{
                                    setAddCategoryName(event.target.value)
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Type"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="select"
                                  name="ppob-product-name"
                                  id="ppob-product-name"
                                  onChange={(event)=>{
                                    setAddCategoryType(event.target.value)
                                  }}
                                >
                                  <option>PRABAYAR</option>
                                  <option>PASCABAYAR</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" sm="6" xs="5 ml-2">
                              <Button
                                color="secondary"
                                type="reset"
                                className=" mr-1"
                                onClick={() => showAddCategory()}
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" sm="6" xs="5">
                              <Button color="primary" onClick={()=>postCategory()} >{"Confirm"}</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                {/* Modal Edit Category */}
                <Modal
                  isOpen={editCategory}
                  toggle={() => showEditCategory()}
                  centered
                >
                  <ModalHeader toggle={() => showEditCategory()}>
                    {"Edit Category"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      <Row>
                        <Col xl="12" md="12">
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Category Name"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  id="ppob-product-code"
                                  type="text"
                                  name="ppob-product-code"
                                  defaultValue={editCategoryValue.name}
                                  onChange={(event)=>{
                                    setEditCategoryValue({
                                      ...editCategoryValue,
                                      name:event.target.value
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="justify-content-around">
                              <Col xl="4" md="4">
                                <Label>{"Type"}</Label>
                              </Col>
                              <Col xl="6" md="6">
                                <Input
                                  type="select"
                                  name="ppob-product-name"
                                  id="ppob-product-name"
                                  defaultValue={editCategoryValue.type}
                                  onChange={(event)=>{
                                    setEditCategoryValue({
                                      ...editCategoryValue,
                                      type:event.target.value
                                    })
                                  }}
                                >
                                  <option>PRABAYAR</option>
                                  <option>PASCABAYAR</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="justify-content-center">
                            <Col xl="3" sm="6" xs="5 ml-2">
                              <Button
                                color="secondary"
                                type="reset"
                                className="mr-1"
                                onClick={() => showEditCategory()}
                              >
                                {"Cancel"}
                              </Button>
                            </Col>
                            <Col xl="3" sm="6" xs="5">
                              <Button color="primary" onClick={()=>postEditCategory()}>{"Save"}</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Category;
