import React, { Fragment, useEffect, useState } from 'react';
import {useSelector, useDispatch} from "react-redux"
import axios from "axios"
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Badge, Button} from 'reactstrap'
// import ApexCharts from 'react-apexcharts'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import {dataTableSaldoPartner, dataTablePending} from '../../data/dummyTableData'
import '../../assets/scss/custom/dashboard-custom.scss'
import { Currentlysale } from './chartsData/apex-charts-data'
import {getDashboardTransaction} from '../../redux/action/dashboard/index'
import { smallchart1data, smallchart1option, smallchart2data, smallchart2option, smallchart3data, smallchart3option, smallchart4data, smallchart4option } from './chartsData/chartist-charts-data'


const Default = (props) => {

  const columnsTablePending =[
    {
      name: 'Code',
      selector: 'code',
      sortable: true,
      center:true,
      width:"200px"
    },
    {
      name: 'Server',
      selector: 'server',
      sortable: true,
      center:true,
      width:"170px"
    },
    {
      name: 'Number',
      selector: 'number',
      sortable: true,
      center:true,
      width:"200px"
    },
    {
      name: 'Time',
      selector: 'time',
      sortable: true,
      center:true,
      widht:"150px"
    },
    {
      name: 'Action',
      center:true,
      cell:(row)=>(<Button color="primary" className="btn-xs ml-2" onClick={()=>{
        // setCopyPending(row.copy)
        toast.success("Copied !", {
          position: toast.POSITION.TOP_RIGHT
          });
        navigator.clipboard.writeText(row.copy)
        }}>
      <i class="fa fa-copy"></i>
      </Button>)
    },
  ]

  const columnsTablePartner = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      center:true,
      width:"200px"
    },
    {
      name: 'Saldo',
      sortable: true,
      center:true,
      width:"200px",
      cell:(row) =>(
        <p>{`Rp. ${parseFloat(`${row.saldo}`).toLocaleString('en')}`}</p>
      )     
    },
    {
      name: 'Status',
      selector: 'status',
      // sortable: true,
      center:true,
      width:"300px",
      cell: (row)=> (
        <div className="custom-control custom-switch">
          <label className="mr-5">Non Active</label>
          <input
            type="checkbox"
            className="custom-control-input"
            id={row.id}
            defaultChecked={row.status === 1 ? true : false}
            onChange={()=>{
              

              // console.log("partner status :", partner)
              for (let i of partner.data) {
                if (i.id === row.id){
                  let value = i.status === 1 ? "NO" : "YES"
                  updateStatusPartner(row.id,value)
                  // console.log("partner selected :", i)
                  // console.log("partner status :", i.status)
                }
              }
            }}
          />
          <label
            className="custom-control-label mr-5"
            htmlFor={row.id}
          >
            Active
          </label>
        </div>
      )
    },
  ];

  // data earning
  const omsetThisMonth = 10000000;
  const profitThisMonth = 5000000;
  const profitPercentage = (profitThisMonth/omsetThisMonth)*100;

  
  const dispatch = useDispatch()
  // selector transaction
  const transactionData = useSelector(state => state.dashboardDataReducer.dataTransaction.data)
  // eslint-disable-next-line
  const [date, setDate] = useState({ date: new Date() });
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(new Date());
  const [alertCopy, setAlertCopy] = useState(false);

  
  // DataTable partner State
  const [partner, setPartner] = useState({});
  const [pagePartner, setPagePartner] = useState(1);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [countPagePartner, setCountPagePartner] = useState(5);
  // const stateDashboard = useSelector(state => state)
  // console.log("data state : ", stateDashboard);

  // DataTable Pending
  const [pendingTable, setPendingTable] = useState({});
  const [pagePending, setPagePending] = useState(1);
  const [sortColumnPending, setSortColumnPending] = useState('');
  const [sortDirectionPending, setSortDirectionPending] = useState('');
  const [countPagePending, setCountPagePending] = useState(5);
  const [copyPending, setCopyPending] = useState('')

  console.log("copy pending : ", copyPending)
  // Data Dashboard
  // const dataDashboard = useSelector(state => state)
  // console.log("data dashboard: ", dataDashboard)

  // STATE UPDATE STATUS PARTNER
  const [updateStatusPartnerResponse, setUpdateStatusPartnerResponse]=useState(false)

  
  

  const handleChange = date => {
    setDate(date)
  };

  const getDashboardPending = () =>{
  
    const uriUsers = `dashboard/pending`;
  
    return axios
      .get(uriUsers, {
        params : {
          page: pagePending,
          per_page: countPagePending,
          sort_column: sortColumnPending,
          sort_direction: sortDirectionPending,
        }
      })
      .then((response) => {
        console.log("getDashboardPending response: ", response);
        setPendingTable(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getDashboardPartnerTable = () => {
    axios.get(`dashboard/partner`, {
      params : {
        page: pagePartner,
        per_page: countPagePartner,
        sort_column: sortColumn,
        sort_direction: sortDirection,
      }
    })
    .then(res => {
      setPartner(res.data);
      console.log("getDashboardPartnerTable response :", res.data);
    }).catch(err => {
      setPartner({});
    });
  }

  // UPDATE STATUS WITH TOGGLE
  const updateStatusPartner = (id,value) => {
    axios.post(`/dashboard/partner/toggle-status`, {
      partner_id : id,
      status: value, // atau NO
    })
    .then(res => {
      toast.success("Success update status !", {
        position: toast.POSITION.TOP_RIGHT
        });
        let newData = [...partner.data]
        // newData[id].status = status
        for (let i=0; i < newData.length; i++) {
          if (newData[i].id == id) {
            newData[i].status = value === "YES" ? 1 : 0
            // console.log("response : ", newData[i])
            // console.log("response status : ", newData[i].status)
            // console.log("response id :", newData[i].id)
            // console.log("params id :", id)
          }
        }
      // setUpdateStatusPartnerResponse(!updateStatusPartnerResponse)
    }).catch(err => {
      toast.error("Failed to update status !", {
        position: toast.POSITION.TOP_RIGHT
        });
    });
  }

  const handleSort = (column, sortDirection) =>{
    setSortColumn(column.selector)
    setSortDirection(sortDirection)
  }

  const handleSortPending = (column, sortDirection) =>{
    setSortColumnPending(column.selector)
    setSortDirectionPending(sortDirection)
  }


  useEffect(() => {
    dispatch(getDashboardTransaction())
    
  }, [])

  useEffect(() => {
    getDashboardPartnerTable()
    
  }, [
    pagePartner, 
    countPagePartner, 
    sortColumn, 
    sortDirection, 
    updateStatusPartnerResponse
  ])

  useEffect(() => {
    getDashboardPending()
    
  }, [
    pagePending, 
    countPagePending,
    sortColumnPending, 
    sortDirectionPending
  ])

  

  return (
    <Fragment>
      <Breadcrumb parent="Home" title="Dashboard" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          {/* Omset and Profit */}
          {/* <Col xl="12" lg="12" md="12" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  <Col xl="12" md="12" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>{"Omset And Profit"}</h5>
                        <p className="font-roboto">
                          {"Overview of last month"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 chart-left">
                    <Col xl="12" md="12">
                      <Row className="m-0">
                        <Col xl="4" className="p-0 left_side_earning">
                          <h5>{`Rp. ${(omsetThisMonth).toLocaleString('en')}`} </h5>
                          <p className="font-roboto">{"This Month Omset"}</p>
                        </Col>
                        <Col xl="4" className="p-0 left_side_earning">
                          <h5>{`Rp. ${(profitThisMonth).toLocaleString('en')}`}</h5>
                          <p className="font-roboto">{"This Month Profit"}</p>
                        </Col>
                        <Col xl="4" className="p-0 left_side_earning">
                          <h5>{`${profitPercentage}%`}</h5>
                          <p className="font-roboto">{"This Month Profit Percentage"}</p>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="m-0">
                <Col xl="12" md="12" className="p-0">
                    <div className="chart-right">
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <ApexCharts
                                id="chart-currently"
                                options={Currentlysale.options}
                                series={Currentlysale.series}
                                type="area"
                                height={240}
                              />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="6" md="6" sm="6" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left">
                            <i className="icofont icofont-crown"></i>
                          </div>
                          <div className="media-body">
                            <h6>{"Deposit"}</h6>
                            <p>{`Rp.${(300000000).toLocaleString('en')}`}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="6" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left">
                            <i className="icofont icofont-heart-alt"></i>
                          </div>
                          <div className="media-body">
                            <h6>{"Commission"}</h6>
                            <p>{`Rp.${(10000000).toLocaleString('en')}`}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}

          {/* Transaction */}
          <Col xl="12" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                <Col xl="4" className="p-0 box-col-4">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-success50px">
                        <i class="fa fa-check-circle fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.transaction_success : ''}</h4>
                          <span>{"Transaction Success"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" className="p-0 box-col-4">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-pending50px">
                        <i class="fa fa-minus-circle fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.transaction_pending : ''}</h4>
                          <span>{"Transaction Pending"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" className="p-0 box-col-4">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-failed50px">
                        <i class="fa fa-times-circle fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.transaction_failed : ''}</h4>
                          <span>{"Transaction Failed"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 chart-main justify-content-center">
                <Col xl="3" className="p-0 box-col-3">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-pending50px">
                          <i class="fa fa-minus-circle fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.customer_verification_pending : ''}</h4>
                          <span>{"Verification Pending"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" className="p-0 box-col-3">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-pending50px">
                          <i class="fa fa-minus-circle fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.deposit_pending : ''}</h4>
                          <span>{"Deposit Pending"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" className="p-0 box-col-3">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-50px">
                          <i class="fa fa-money fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.total_transaction : ''}</h4>
                          <span>{"Total Transaction"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" className="p-0 box-col-3">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart" style={{marginLeft:50}}>
                        <div className="small-bar dashboard-icon-50px">
                          <i class="fa fa-users fa-2x"></i>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{transactionData ? transactionData.user_registration : ''}</h4>
                          <span>{"User Registration"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="m-0 chart-main justify-content-center">
                
                </Row> */}
              </CardBody>
            </Card>
          </Col>
          
          {/* Table Pending */}
          <Col xl="12" className="appointment">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">{"Pending > 3 minute"}</h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
              <DataTable
                    // title="Arnold Movies"
                    columns={columnsTablePending}
                    data={pendingTable.data}
                    pagination={true}
                    paginationServer
                    paginationTotalRows={pendingTable.total}
                    onChangePage={pagePending => setPagePending(pagePending)}
                    onChangeRowsPerPage={countPagePending => setCountPagePending(countPagePending)}
                    onSort={handleSortPending}
                    paginationPerPage={countPagePending}
                    paginationRowsPerPageOptions={[5,10,15,20]}
                  />
              </CardBody>
            </Card>
          </Col>
          {/* Table Partner */}
          <Col xl="12" className="appointment">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">{"Partner"}</h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                {/* {console.log("data table partner :", partner)} */}
                {/* {console.log("data count table partner :", countPagePartner)} */}
                <DataTable
                    // title="Arnold Movies"
                    columns={columnsTablePartner}
                    data={partner.data}
                    pagination={true}
                    paginationServer
                    paginationTotalRows={partner.total}
                    onChangePage={pagePartner => setPagePartner(pagePartner)}
                    onChangeRowsPerPage={countPagePartner => setCountPagePartner(countPagePartner)}
                    onSort={handleSort}
                    paginationPerPage={countPagePartner}
                    paginationRowsPerPageOptions={[5,10,15,20]}
                    // customStyles={<Badge>success</Badge>}
                  />
              </CardBody>
            </Card>
          </Col>
          {/* Table Other Notification */}
          {/* <Col xl="12" className="appointment">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">{"Other Notification"}</h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
              <DataTable
                    columns={columnsTablePartner}
                    data={dataTableSaldoPartner}
                    pagination={true}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5,10,15,20]}
                  />
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </Fragment>
  );
}

export default Default;