import {
    GET_LIST_PARTNER_PRABAYAR_REQUEST,
    GET_LIST_PARTNER_PRABAYAR_SUCCESS,
    GET_LIST_PARTNER_PRABAYAR_FAILED
} from '../../../actionTypes'

const initialState = {
    data:[],
    isLoading:false,
    error: ''
}

const listPartnerPrabayarReducer = (state = initialState, action) =>{
    switch (action.type) {
        case GET_LIST_PARTNER_PRABAYAR_REQUEST :
            return {
                ...state,
                isLoading: true
            }
        case GET_LIST_PARTNER_PRABAYAR_SUCCESS :
            return {
                ...state,
                isLoading:false,
                data:action.payload
            }
        case GET_LIST_PARTNER_PRABAYAR_FAILED :
            return {
                ...state,
                isLoading:false,
                error: action.error
            }
    
        default:
            return state;
    }
}

export default listPartnerPrabayarReducer