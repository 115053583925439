import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { dataMutationOnCommission } from "../../../data/dummyTableData";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import "../../../assets/scss/custom/deposit-custom.scss";
// import {dataTableTransaction} from '../../data/dummyTableData'

import axios from "axios";

const CommissionMutation = () => {
  const columnsMutationOnCommission = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      center:true,
      width:'170px'
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      center: true,
    },
    {
      name: "Credit",
      selector: "credit",
      sortable: true,
      center: true,
    },
    {
      name: "Previous Commission",
      sortable: true,
      center: true,
      width:'200px',
      cell:(row)=>(
        <span>{`Rp. ${(row.previous_commission).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Commission Afterward",
      sortable: true,
      center: true,
      width:'210px',
      cell:(row)=>(
        <span>{`Rp. ${(row.commission_afterward).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
  ];

  const listType = [
    { value: "ALL", label: "ALL" },
    { value: "DEBIT", label: "DEBIT" },
    { value: "CREDIT", label: "CREDIT" },
  ];

  const [sweetAlert, setSweetAlert] = useState(false);
  const [pointMutationDetail, setPointMutationDetail] = useState(false);

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      alert(`You Search : ${data.keyword}`);
    } else {
      errors.showMessages();
    }
  };

  // ACTION HANDLER
  const handleSortCommissionMutation = (column, sortDirection) => {
    setSortColumnCommissionMutation(column.selector);
    setSortDirectionCommissionMutation(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchCommissionMutation = (event) => {setKeywordCommissionMutation(event.target.value)};

  // STATE DATA TABLE
  const [commissionMutationTable, setCommissionMutationTable] = useState({});
  const [pageCommissionMutation, setPageCommissionMutation] = useState(1);
  const [sortColumnCommissionMutation,setSortColumnCommissionMutation] = useState("");
  const [sortDirectionCommissionMutation,setSortDirectionCommissionMutation] = useState("");
  const [countPageCommissionMutation,setCountPageCommissionMutation] = useState(5);
  const [keywordCommissionMutation, setKeywordCommissionMutation] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState('')
  

  // FETCHING DATA TABLE
  const getDataTableCommissionMutation = () => {
    const URI = `commission/mutation/data`;

    return axios
      .get(URI, {
        params: {
          page: pageCommissionMutation,
          per_page: countPageCommissionMutation,
          sort_column: sortColumnCommissionMutation,
          sort_direction: sortDirectionCommissionMutation,
          keyword: keywordCommissionMutation,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          type: type,
        },
      })
      .then((response) => {
        setCommissionMutationTable(response.data);
        console.log("getCommissionMutationTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDataTableCommissionMutation()
  }, [pageCommissionMutation,sortColumnCommissionMutation,sortDirectionCommissionMutation,countPageCommissionMutation]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Commission" title="Commission Mutation" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                //   className="needs-validation"
                //   noValidate=""
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchCommissionMutation(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="12" xs="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Type"}</Label>
                        <Select
                        options={listType}
                        onChange={(event)=>{
                          setType(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTableCommissionMutation()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columnsMutationOnCommission}
                  data={commissionMutationTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={commissionMutationTable.total}
                  onChangePage={(pageCommissionMutation) =>
                    setPageCommissionMutation(pageCommissionMutation)
                  }
                  onChangeRowsPerPage={(countPageCommissionMutation) =>
                    setCountPageCommissionMutation(
                      countPageCommissionMutation
                    )
                  }
                  onSort={handleSortCommissionMutation}
                  paginationPerPage={countPageCommissionMutation}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CommissionMutation;
