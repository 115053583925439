import { 
    PERSONAL_INFORMATION_REQUEST,
    PERSONAL_INFORMATION_SUCCESS,
    PERSONAL_INFORMATION_FAILED,
    PERSONAL_PICTURE,
    UPLOAD_PERSONAL_PICTURE,
    KTP_PICTURE
 } from '../../../actionTypes'

 const initialState = {
     data:[],
     personalPicture:{},
     uploadPersonalPicture:{},
     ktpPicture:{},
     isLoading: false
 }

 const personalInformationReducer = (state = initialState, action) =>{
     switch (action.type) {
          // edit user
        case PERSONAL_INFORMATION_REQUEST:
            return {
              ...state,
              isLoading: true,
            };
          case PERSONAL_INFORMATION_SUCCESS:
            return {
              ...state,
              isLoading: false,
              data: action.payload,
            };
          case PERSONAL_INFORMATION_FAILED:
            return {
              ...state,
              isLoading: false,
              error: action.error,
            };
          case PERSONAL_PICTURE:
            return{
              ...state,
              isLoading:false,
              personalPicture: action.payload
            }
          case UPLOAD_PERSONAL_PICTURE:
            return {
              ...state,
              isLoading:false,
              uploadPersonalPicture: action.payload
            }
          case KTP_PICTURE:
            return {
              ...state,
              isLoading:false,
              ktpPicture: action.payload
            }
    
          default:
            return state;
     }
 };

 export default personalInformationReducer;