import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import Breadcrumb from "../../../layout/breadcrumb";
import { Edit } from "react-feather";
import axios from "axios";
import photoKTP from "../../../assets/images/CONTOH-KTP.jpg";
import photoSelfie from "../../../assets/images/user/photoSelfie.jpg";
import "../../../assets/scss/custom/pending-verification-custom.scss";
import Lightbox from "react-image-lightbox";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  Spinner,
} from "reactstrap";
import {
  getTimeLine,
  getDetailVerification,
} from "../../../redux/action/pending-verification/index";

const PendingVerification = (props) => {
  const columnsPendingVerification = [
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      width: "200px",
    },
    // {
    //   name: "Photo KTP",
    //   center: true,
    //   width: "200px",
    //   cell: (row) => (
    //     <img
    //       height="150px"
    //       width="150px"
    //       alt={"photo"}
    //       src={photoKTP}
    //       className="img-verification-modal"
    //       onClick={() => setPhotoIndex({ ...photoIndex,index:1, isOpen: true })}
    //     />
    //   ),
    // },
    // {
    //   name: "Photo Selfie",
    //   center: true,
    //   width: "200px",
    //   cell: (row) => (
    //     <img
    //       height="150px"
    //       width="150px"
    //       alt={"photo"}
    //       src={photoSelfie}
    //       className="img-verification-modal"
    //       onClick={() => setPhotoIndex({ ...photoIndex,index:0, isOpen: true })}
    //     />
    //   ),
    // },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Application",
      selector: "application",
      sortable: true,
      center: true,
    },
    // {
    //   name: "Photo Revision",
    //   center: true,
    //   width: "200px",
    //   cell: (row) => (
    //     <img height="150px" width="133px" alt={"photo"} src={photo} />
    //   ),
    // },
    // {
    //   name: "Date Revision",
    //   selector: "date_revision",
    //   sortable: true,
    //   center: true,
    //   width: "150px",
    // },
    // {
    //   name: "Status",
    //   selector: "status",
    //   sortable: true,
    //   center: true,
    //   width: "150px",
    // },
    // {
    //   name: "Information",
    //   selector: "information",
    //   center: true,
    //   wrap: true,
    //   width: "200px",
    // },
    {
      name: "Action",
      center: true,
      width: "200px",
      cell: (row) => (
        <div>
          {userPermission.includes("[update] pending verification") ? (
            <Button
            color="primary"
            className="btn-xs"
            onClick={() => {
              dispatch(getDetailVerification(row.id));
              // getPersonalPicture(row.id)
              // getKtpPicture(row.id)
              DisplayEditPendingVerification(row.id);
            }}
          >
            <i class="fa fa-edit fa-2x"></i>
          </Button>
          ) : null}
          <Button
            color="primary"
            className="btn-xs ml-2"
            onClick={() => {
              dispatch(getTimeLine(row.id));
              DisplayTimeLine();
            }}
          >
            <i class="fa fa-clock-o fa-2x"></i>
          </Button>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  // STATE FROM REDUX
  const dataTimeLine = useSelector((state) => state.timeLineReducer.data.data);
  // console.log("dataTimeLine :", dataTimeLine);
  const dataDetailVerification = useSelector(
    (state) => state.detailVerificationReducer.data.data
  );
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [images, setImage] = useState([]);
  const [smallImages, setsmallImages] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [editPendingVerification, setEditPendingVerification] = useState(false);
  const [showTimeLine, setShowTimeLine] = useState(false);

  // STATE FOR EDIT
  const [editVerificationValue, setEditVerificationValue] = useState({
    ktpPicture: dataDetailVerification !== undefined ? dataDetailVerification.ktp_picture : "",
    personalPicture: dataDetailVerification !== undefined ? dataDetailVerification.selfie_ktp_picture : "",
    name: dataDetailVerification !== undefined ? dataDetailVerification.nama : "",
    noKtp: dataDetailVerification !== undefined ? dataDetailVerification.no_ktp : "",
    birthDate: dataDetailVerification !== undefined ? dataDetailVerification.tanggal_lahir : "",
    birthPlace: dataDetailVerification !== undefined ? dataDetailVerification.birthPlace : "",
    kecamatan: dataDetailVerification !== undefined ? dataDetailVerification.kecamatan : "",
    kelurahan: dataDetailVerification !== undefined ? dataDetailVerification.kelurahan : "",
    alamat: dataDetailVerification !== undefined ? dataDetailVerification.alamat : "",
    rt: dataDetailVerification !== undefined ? dataDetailVerification.rt : "",
    rw: dataDetailVerification !== undefined ? dataDetailVerification.rw : "",
    status: dataDetailVerification !== undefined ? dataDetailVerification.status : "",
    information: dataDetailVerification !== undefined ? dataDetailVerification.information : "",
  });

  console.log("edit value :", editVerificationValue)

  //  STATE FOR PICTURE
  const [personalPictureValue, setPersonalPictureValue]=useState()
  const [ktpPictureValue, setKtpPictureValue]=useState()

  // options for react select
  const listStatus = [
    {value:'APPROVED', label:'APPROVED'},
    {value:'PENDING', label:'PENDING'},
    {value:'REJECTED', label:'REJECTED'}
  ]

  const [sweetAlert, setSweetAlert] = useState(false);

  const Displayalert = () => {
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  // Display Modal Form
  const DisplayEditPendingVerification = () =>
    setEditPendingVerification(!editPendingVerification);
  const DisplayTimeLine = () => setShowTimeLine(!showTimeLine);

  // ACTION HANDLER
  const handleSortPendingVerification = (column, sortDirection) => {
    setSortColumnPendingVerification(column.selector);
    setSortDirectionPendingVerification(sortDirection);
  };
  const handleChangeApplicationDate = (date) => {
    setPendingVerificationApplicationDate(date);
  };
  const onSearchPendingVerification = (event) => {
    setKeywordPendingVerification(event.target.value);
  };

  // STATE DATA TABLE
  const [pendingVerificationTable, setPendingVerificationTable] = useState({});
  const [pagePendingVerification, setPagePendingVerification] = useState(1);
  const [
    sortColumnPendingVerification,
    setSortColumnPendingVerification,
  ] = useState("");
  const [
    sortDirectionPendingVerification,
    setSortDirectionPendingVerification,
  ] = useState("");
  const [
    countPagePendingVerification,
    setCountPagePendingVerification,
  ] = useState(5);
  const [
    pendingVerificationApplicationDate,
    setPendingVerificationApplicationDate,
  ] = useState();
  const [keywordPendingVerification, setKeywordPendingVerification] = useState(
    ""
  );

  // FETCHING DATA TABLE
  const getDataTablePendingVerification = () => {
    const URI = `pending-verification/data`;

    return axios
      .get(URI, {
        params: {
          page: pagePendingVerification,
          per_page: countPagePendingVerification,
          sort_column: sortColumnPendingVerification,
          sort_direction: sortDirectionPendingVerification,
          keyword: keywordPendingVerification,
          application_date: pendingVerificationApplicationDate && pendingVerificationApplicationDate.toLocaleDateString(
            "en-US"
          ),
        },
      })
      .then((response) => {
        setPendingVerificationTable(response.data);
        console.log("getDownlineTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //GET PERSONAL PICTURE
  const getPersonalPicture = (id) => {
    axios
    .get(`https://neko01.otlet.id/api/user-detail/${id}/personal-picture`)
    .then(res => {
      setPersonalPictureValue(res.data.data.image)
      console.log("personal picture: ", res)
    })
    .catch(err => console.log("getPersonalPicture error :", err))

  } 

  // GET KTP PICTURE
  const getKtpPicture = (id) => {
    axios
    .get(`https://neko01.otlet.id/api/user-detail/${id}/foto-ktp`)
    .then(res => {
      setKtpPictureValue(res.data.data.image)
    })
    .catch(err => {
      console.log('getKtpPicture error :', err)
    })
  }


  // POST EDIT VERIFICATION
  const editDetailVerification = () => {
    

    const URI = dataDetailVerification !== undefined ? `pending-verification/${dataDetailVerification.customer_id}/update` : ''

    return axios
        .post(URI, {
          status :editVerificationValue.status,
          information: editVerificationValue.information
        })
        .then(res => {
          toast.success("Success Edit Verification !", {
            position: toast.POSITION.TOP_RIGHT
            });
          setEditPendingVerification(!editPendingVerification)
          console.log('editDetailVerification response :', res)
        })
        .catch(error => {
          let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
          console.log('editDetailVerification :', error)
        })
  }

  useEffect(() => {
    getDataTablePendingVerification();
  }, [
    pagePendingVerification,
    countPagePendingVerification,
    sortColumnPendingVerification,
    sortDirectionPendingVerification,
  ]);

  const initilindex = { isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const onMovePrev = () => {
    const prev = (photoIndex.index + images.length - 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: prev });
  };
  const onMoveNext = () => {
    const next = (photoIndex.index + 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: next });
  };

  return (
    <Fragment>
      <Breadcrumb parent="User" title="Pending Verification" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Row>
              <Col xl="12">
                {userPermission.includes("[read] pending verification") ? (
                  <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate="">
                      <div className="form-row">
                        <Col md="12 mb-3">
                          <Input
                            className="form-control"
                            name="keyword"
                            type="text"
                            placeholder="Cari berdasarkan ID user, Nama, No.HP, Email"
                            onChange={(event) =>
                              onSearchPendingVerification(event)
                            }
                          />
                        </Col>
                      </div>
                      <div className="form-row">
                        {/* Filter Registration Date */}
                        <Col md="12 mb-3">
                          <Row className="align-items-center">
                            <Col md="4">
                              <Label htmlFor="validationCustom01">
                                {"Application Date"}
                              </Label>
                              <DatePicker
                                className="datepicker-here form-control"
                                selected={pendingVerificationApplicationDate}
                                onChange={handleChangeApplicationDate}
                              />
                            </Col>
                            <Col xl="5 mt-4">
                              <Button
                                color="secondary"
                                onClick={() =>
                                  getDataTablePendingVerification()
                                }
                              >
                                {"Search"}
                              </Button>
                              <Button
                                color="primary"
                                className="sweet-8 ml-5"
                                name="alertSuccess"
                                onClick={Displayalert}
                              >
                                {"Download"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </Form>
                    <DataTable
                      columns={columnsPendingVerification}
                      data={pendingVerificationTable.data}
                      pagination={true}
                      paginationServer
                      paginationTotalRows={pendingVerificationTable.total}
                      onChangePage={(pagePendingVerification) =>
                        setPagePendingVerification(pagePendingVerification)
                      }
                      onChangeRowsPerPage={(countPagePendingVerification) =>
                        setCountPagePendingVerification(
                          countPagePendingVerification
                        )
                      }
                      onSort={handleSortPendingVerification}
                      paginationPerPage={countPagePendingVerification}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    />
                  </CardBody>
                </Card>
                ) : null}
              </Col>
            </Row>

            {/* Modal Edit Pending Verification */}
            <Modal
              isOpen={editPendingVerification}
              toggle={() => DisplayEditPendingVerification()}
              centered
              zIndex={100}
            >
              <ModalHeader toggle={() => DisplayEditPendingVerification()}>
                {"User Verification"}
              </ModalHeader>
              <ModalBody>
                {dataDetailVerification !== undefined ? (
                  <Row>
                    <Col xl="12">
                      <Form 
                      onSubmit={
                        (event)=>{
                          event.preventDefault();
                          editDetailVerification()
                        }
                      }
                      >
                      <FormGroup>
                        <Row>
                          <Col xl="3">
                            <p>Photo</p>
                          </Col>
                          <Col xl="9">
                            <Row>
                              <Col xl="6" className="text-center">
                                <img
                                  src={dataDetailVerification.ktp_picture}
                                  height="150px"
                                  width="133px"
                                  className="img-verification-modal"
                                  onClick={() =>
                                    setPhotoIndex({
                                      ...photoIndex,
                                      index: 1,
                                      isOpen: true,
                                    })
                                  }
                                />

                                <Label>KTP</Label>
                              </Col>
                              <Col xl="6" className="text-center">
                                <img
                                  src={dataDetailVerification.selfie_ktp_picture}
                                  height="150px"
                                  width="133px"
                                  className="img-verification-modal"
                                  onClick={() =>
                                    setPhotoIndex({
                                      ...photoIndex,
                                      index: 0,
                                      isOpen: true,
                                    })
                                  }
                                />
                                <Label>Selfie With KTP</Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Nama</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;{dataDetailVerification.nama}</p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>No. KTP</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;{dataDetailVerification.no_ktp}</p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Tempat Lahir</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;{dataDetailVerification.tempat_lahir}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Tanggal Lahir</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;
                              {dataDetailVerification.tanggal_lahir}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Jenis Kelamin</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;
                              {dataDetailVerification.jenis_kelamin}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      {/* <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Provinsi</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;{dataDetailVerification.provinsi}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Kota/Kabupaten</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;{dataDetailVerification.kabupaten}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup> */}
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Kecamatan</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;{dataDetailVerification.kecamatan}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Kelurahan</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;{dataDetailVerification.kelurahan}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Alamat</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;{dataDetailVerification.alamat}</p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>RT</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;{dataDetailVerification.rt}</p>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>RW</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;{dataDetailVerification.rw}</p>
                          </Col>
                        </Row>
                      </FormGroup>
                      {/* <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Kode Pos</p>
                          </Col>
                          <Col xl="6">
                            <p>
                              :&nbsp;&nbsp;{dataDetailVerification.kode_pos}
                            </p>
                          </Col>
                        </Row>
                      </FormGroup> */}
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Status</p>
                          </Col>
                          <Col xl="6">
                            <Select
                              // name='status'
                              onChange={(event) => {
                                setEditVerificationValue({
                                  ...editVerificationValue,
                                  status: event.value,
                                });
                              }}
                              options={listStatus}
                              value={listStatus.filter(list => list.label === editVerificationValue.status)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="justify-content-start align-items-center">
                          <Col xl="3">
                            <p>Information</p>
                          </Col>
                          <Col xl="9">
                            <Input
                              type="textarea"
                              rows="4"
                              onChange={(event)=>{
                                setEditVerificationValue({
                                  ...editVerificationValue,
                                  information:event.target.value
                                })
                              }}
                              value={editVerificationValue.information}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <Row className="justify-content-center">
                        <Col xl="3" md="3">
                          <Button
                            color="secondary"
                            type="reset"
                            className="mr-1"
                            onClick={() => DisplayEditPendingVerification()}
                          >
                            {"Cancel"}
                          </Button>
                        </Col>
                        <Col xl="3" md="3">
                          <Button color="primary" type="submit">{"Save"}</Button>
                        </Col>
                      </Row>
                      </Form>
                    </Col>
                  </Row>
                ) : (
                  <p>data detail verification kosong</p>
                )}
              </ModalBody>
            </Modal>

            {/* Modal Time Line Application */}
            <Modal
              isOpen={showTimeLine}
              toggle={() => DisplayTimeLine()}
              centered
              size="lg"
            >
              <ModalHeader toggle={() => DisplayTimeLine()}>
                Time Line Application
              </ModalHeader>
              <ModalBody>
                {dataTimeLine !== undefined ? (
                  <VerticalTimeline>
                    {dataTimeLine.map((items, index) => (
                      <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element--work"
                        date={items.date}
                        iconStyle={
                          items.status === "APPROVED"
                            ? { background: "#51bb25", color: "#fff" }
                            : items.status === "PENDING"
                            ? { background: "#f8d62b", color: "#fff" }
                            : { background: "#dc3545", color: "#fff" }
                        }
                        icon={<Edit />}
                      >
                        <h4 className="vertical-timeline-element-subtitle">
                          {items.status}
                        </h4>
                        <p>{items.information}</p>
                      </VerticalTimelineElement>
                    ))}
                  </VerticalTimeline>
                ) : (
                  <Row className="justify-content-center align-items-center">
                    <Spinner style={{ color: "#f25c00" }} />
                  </Row>
                )}
              </ModalBody>
            </Modal>
            
          </Col>
        </Row>
      </Container>
      {/* {console.log("data picture: ", personalPictureValue)} */}
      {photoIndex.isOpen &&
        (photoIndex.index === 0 ? (
          <Lightbox
            mainSrc={dataDetailVerification.selfie_ktp_picture}
            // imageTitle={photoIndex.index + 1 + "/" + images.length}
            onCloseRequest={() =>
              setPhotoIndex({ ...photoIndex, isOpen: false })
            }
            // onMovePrevRequest={onMovePrev}
            // onMoveNextRequest={onMoveNext}
          />
        ) : (
          <Lightbox
            mainSrc={dataDetailVerification.ktp_picture}
            // imageTitle={photoIndex.index + 1 + "/" + images.length}
            onCloseRequest={() =>
              setPhotoIndex({ ...photoIndex, isOpen: false })
            }
            // onMovePrevRequest={onMovePrev}
            // onMoveNextRequest={onMoveNext}
          />
        ))}
    </Fragment>
  );
};

export default PendingVerification;
