import {
    GET_LIST_CHANNEL_REQUEST,
    GET_LIST_CHANNEL_SUCCESS,
    GET_LIST_CHANNEL_FAILED
} from '../../actionTypes'

const initialState = {
    data:[],
    isLoading: false,
    error:''
}

const listChannelReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_CHANNEL_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_LIST_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case GET_LIST_CHANNEL_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }    
        default:
            return state;
    }
}

export default listChannelReducer;