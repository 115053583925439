import React,{useState,useEffect} from 'react';
import { useDispatch } from "react-redux";
import {useHistory, Redirect} from 'react-router-dom'

import man from '../assets/images/dashboard/profile.jpg';
import LogoLogin from '../assets/images/logo/logo-login.png';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,NavItem, NavLink, Nav,TabContent,TabPane} from 'reactstrap'
import { handleResponse } from '../services/fack.backend'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import { loginActions } from "../redux/action/user"
import axios from 'axios'

const Logins = (props) => {
  
    const dispatch = useDispatch();
    const history = useHistory();
    const [loginState, setLoginState] = useState({
      email:"",
      password:""
    })
    const [loading,setLoading] = useState(false) 
    const [selected, setSelected] = useState("jwt");
    const [togglePassword,setTogglePassword] = useState(false)

    const handleChange = (event) => {
      setLoginState({
        ...loginState,
        [event.target.name]: event.target.value,
      });
    };

    return (
        localStorage.getItem('reduxState')
        ? <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
        :
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={LogoLogin} alt=""/>
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/Veripay_logo.png")} alt=""/>
                </a>
              </div>
              <div className="login-main login-tab"> 
                <TabContent activeTab={selected} className="content-login">
                  <TabPane  className="fade show" tabId={"jwt"}>
                    <Form className="theme-form" onSubmit={(event) => {dispatch(loginActions(loginState, event, history))}}>
                      <h4>{"Sign In"}</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <FormGroup>
                        <Label className="col-form-label">{"EmailAddress"}</Label>
                        <Input className="form-control" type="email" required="" name="email" onChange={(event) => handleChange(event)} placeholder="Contoh : nama@email.com" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{"Password"}</Label>
                        <Input className="form-control" type={togglePassword ?  "text" : "password"} name="password" onChange={(event) => handleChange(event)} placeholder="Masukan Password Anda" required=""/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{"RememberPassword"}</Label>
                        </div><a className="link" href="#javascript">{"ForgotPassword"}</a>
                        <Button color="primary" className="btn-block" type="submit">{"Login"}</Button>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);