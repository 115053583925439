import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {useSelector, useDispatch} from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from 'react-select'
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/transfer-custom.scss";
import {getListChannel} from '../../../redux/action/transfer/index'
import axios from "axios";
import fileDownload from "js-file-download";

const Transfer = () => {

  const columns = [
    {
      name: "Transfer Code",
      selector: "code",
      sortable: true,
      width:"150px"
    },
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      center: true,
      width:"150px"
    },

    {
      name: "Sender ID",
      selector: "sender_id",
      sortable: true,
      center: true,
      width:"150px"
    },
    {
      name: "Recipient ID",
      selector: "recipient_id",
      sortable: true,
      center: true,
      width:"150px"
    },
    {
      name: "Nominal",
      sortable: true,
      center: true,
      width:"150px",
      cell:(row)=>(
        <span>{`Rp.${parseFloat(`${row.nominal}`).toLocaleString('en')}`}</span>
      )
    },
    {
      name: "Note",
      selector: "note",
      center: true,
      width:"200px",
      wrap:true
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width:"200px"
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      cell:(row)=>(
        row.status === 'SUCCESS' ? (<Badge color="success">SUCCESS</Badge>) : (
          row.status === 'PENDING' ? (<Badge color="warning">PENDING</Badge>) : (<Badge color="danger">FAILED</Badge>)
        )
      )
    },
    {
      name: "Action",
      center: true,
      width: "100px",
      cell: (row) => (
        <div>
          {userPermission.includes("[update] transfer") ? (
            <Button
            color="primary"
            className="btn-xs ml-2"
            onClick={() => {
              setEditTransferValue({
                id:row.id,
                code:row.code,
                channel:row.channel,
                sender_id:row.sender_id,
                sender_name:row.sender_name,
                recipient_id:row.recipient_id,
                recipient_name:row.recipient_name,
                nominal:row.nominal,
                date:row.date,
                note:row.note,
                status:row.status,
              })
              TransferModaltoggle()
            }}
          >
            <i class="fa fa-edit"></i>
          </Button>
          ) : null}
        </div>
      ),
    },
  ];

  const dispatch = useDispatch()

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)

  const [sweetAlert, setSweetAlert] = useState(false);
  const [modal, setModal] = useState(false);
  const [TransferModal, setTransferModal] = useState(false);

  const listStatus = [
    {value:'ALL', label: 'ALL'},
    {value:'SUCCESS', label: 'SUCCESS'},
    {value:'PENDING', label: 'PENDING'},
    {value:'FAILED', label: 'FAILED'},
  ]

  const listChannelData = useSelector(state => state.listChannelReducer.data.data)
  listChannelData && (listChannelData[0].name !== 'ALL' && listChannelData.unshift({id:'ALL', name:'ALL'}))

  
  const listChannel = listChannelData !== undefined ? (
    listChannelData.map((item)=>(
      {value:item.id, label: item.name}
    ))
  ):(<p>data kosong</p>)

  const Displayalert = () => {
    const URI = `transfer/download`;

    if (startDate == undefined || endDate == undefined) {
      setSweetAlert(true);
      SweetAlert.fire({
        title: "Download Failed",
        text: "Tanggal Awal dan Akhir harus diisi",
        icon: "error",
      });
      return false;
    }

    return axios
      .get(URI, {
        params: {
          keyword: keywordTransfer,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          channel: channel,
          status: status,
        },
        responseType: 'blob'
      })
      .then((response) => {
        fileDownload(response.data, 'report transfer.xlsx')
        setSweetAlert(true);
        SweetAlert.fire({
          title: "Download Success",
          text: "",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TransferModaltoggle = () => setTransferModal(!TransferModal);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      alert(`You Search : ${data.keyword}`);
    } else {
      errors.showMessages();
    }
  };
  
  // ACTION HANDLER
  const handleSortTransfer = (column, sortDirection) => {
    setSortColumnTransfer(column.selector);
    setSortDirectionTransfer(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchTransfer = (event) => {setKeywordTransfer(event.target.value)};

  // STATE DATA TABLE
  const [transferTable, setTransferTable] = useState({});
  const [pageTransfer, setPageTransfer] = useState(1);
  const [sortColumnTransfer,setSortColumnTransfer] = useState("");
  const [sortDirectionTransfer,setSortDirectionTransfer] = useState("");
  const [countPageTransfer,setCountPageTransfer] = useState(5);
  const [keywordTransfer, setKeywordTransfer] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [channel, setChannel] = useState('')
  const [status, setStatus] = useState('')

  // STATE EDIT TRANSACTION
  const [editTransferValue, setEditTransferValue]=useState({
    id:"",
    code:'',
    channel:'',
    sender_id:'',
    sender_name:'',
    recipient_id:'',
    recipient_name:'',
    nominal:'',
    date:'',
    note:'',
    status:'',
  })

  const [editTransferResponse, setEditTransferResponse]=useState(false)

  
  

  // FETCHING DATA TABLE
  const getDataTableTransaction = () => {
    const URI = `transfer/data`;

    return axios
      .get(URI, {
        params: {
          page: pageTransfer,
          per_page: countPageTransfer,
          sort_column: sortColumnTransfer,
          sort_direction: sortDirectionTransfer,
          keyword: keywordTransfer,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          channel: channel,
          status: status,
        },
      })
      .then((response) => {
        setTransferTable(response.data);
        console.log("getTransfer response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editTransfer = () => {
    const URI = `/transfer/${editTransferValue.id}/update`

    axios
    .post(URI,{
      status:editTransferValue.status,
      note: editTransferValue.note
    })
    .then(response => {
      toast.success("Success Edit Transfer !", {
        position: toast.POSITION.TOP_RIGHT
        });
      setEditTransferResponse(!editTransferResponse)
      setTransferModal(!TransferModal)
    })
    .catch(error => {
      let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          // console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
    })
  }



  useEffect(() => {
    dispatch(getListChannel())
  }, [])

  useEffect(() => {
    getDataTableTransaction()
  }, [
    pageTransfer,
    sortColumnTransfer,
    sortDirectionTransfer,
    countPageTransfer,
    editTransferResponse,
  ]);


  return (
    <Fragment>
      <Breadcrumb parent="Transaction" title="Transfer" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchTransfer(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Status"}</Label>
                        <Select
                        options={listStatus}
                        onChange={(event)=>{
                          setStatus(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Channel"}</Label>
                        {listChannel !== undefined ? (
                          <Select
                          options={listChannel}
                          onChange={(event)=>{
                            setChannel(event.value)
                          }}
                          />
                        ):(<option>list channel kosong</option>)}
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTableTransaction()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columns}
                  data={transferTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={transferTable.total}
                  onChangePage={(pageTransfer) =>
                    setPageTransfer(pageTransfer)
                  }
                  onChangeRowsPerPage={(countPageTransfer) =>
                    setCountPageTransfer(
                      countPageTransfer
                    )
                  }
                  onSort={handleSortTransfer}
                  paginationPerPage={countPageTransfer}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />

                {transferTable !== undefined ? (
                  <div>
                    <Modal
                      isOpen={TransferModal}
                      toggle={() => TransferModaltoggle()}
                      centered
                    >
                      <ModalHeader toggle={() => TransferModaltoggle()}>
                        {"Transfer Detail"}
                      </ModalHeader>
                      <ModalBody>
                        <Form>
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Transfer Code</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.code}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Channel</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.channel}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Sender ID</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.sender_id}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Sender Name</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.sender_name}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Recipient ID</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.recipient_id}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Recipient Name</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.recipient_name}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Nominal</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.nominal}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="6" sm="6" xs="6">
                                  <p>Date</p>
                                </Col>
                                <Col xl="6" sm="6" xs="6">
                                  <p>:&nbsp;&nbsp;{editTransferValue.date}</p>
                                </Col>
                              </Row>
                              <FormGroup>
                                <Row>
                                  <Col xl="6" sm="6" xs="6">
                                    <p>Note</p>
                                  </Col>
                                  <Col xl="6" sm="6" xs="6">
                                    <Input 
                                    type="textarea" 
                                    rows="3" 
                                    defaultValue={editTransferValue.note}
                                    onChange={(event)=>{
                                      setEditTransferValue({
                                        ...editTransferValue,
                                        note: event.target.value
                                      })
                                    }}
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row>
                                  <Col xl="6" sm="6" xs="6">
                                    <p>Status</p>
                                  </Col>
                                  <Col xl="6" sm="6" xs="6">
                                    <Select
                                    options={listStatus}
                                    defaultValue={listStatus.filter(list => list.label === editTransferValue.status)}
                                    onChange={(event)=>{
                                      setEditTransferValue({
                                        ...editTransferValue,
                                        status: event.value
                                      })
                                    }}
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <Row className="justify-content-center">
                                <Col xl="3" sm="6" xs="6">
                                  <Button
                                    color="secondary"
                                    type="reset"
                                    onClick={() => TransferModaltoggle()}
                                  >
                                    {"Cancel"}
                                  </Button>
                                </Col>
                                <Col xl="3" sm="6" xs="6">
                                  <Button color="primary" className="mr-1" onClick={()=>editTransfer()}>
                                    {"Save"}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </div>
                ) : (
                  <h1>data modal kosong</h1>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transfer;
