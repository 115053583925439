import { Home, Settings,DollarSign, Users, ShoppingBag, Share, Clipboard, Gift, Star, Folder, AlertTriangle} from 'react-feather'
// import {CreditCard} from "@material-ui/icons"

export const MENUITEMS = [
    {
        menutitle:"General",
        menucontent:"Dashboards,Widgets",
        Items:[
            { path: `${process.env.PUBLIC_URL}/dashboard`,icon: Home, title: 'Dashboard', type: 'link' },
            {
                title: 'User', icon:Users, type: 'sub', active: false, permission:["[read] user list","[read] pending verification","[read] suspend","[read] user mapping"], children: [
                    // { path: `${process.env.PUBLIC_URL}/user/user-detail`, title: 'User Detail', type: 'link', roles: ['Super Administrator'] },
                    { path: `${process.env.PUBLIC_URL}/user/user-list`, title: 'User List', type: 'link', permission: "[read] user list" },
                    { path: `${process.env.PUBLIC_URL}/user/pending-verification`, title: 'Pending Verification', type: 'link',permission: "[read] pending verification"},
                    { path: `${process.env.PUBLIC_URL}/user/suspend`, title: 'Suspend', type: 'link', permission: "[read] suspend" },
                    { path: `${process.env.PUBLIC_URL}/user/user-mapping`, title: 'User Mapping', type: 'link', permission: "[read] user mapping"},
                ]
            },
            {
                title: 'Product', icon:ShoppingBag, type: 'sub', active: false,permission:["[read] product list","[read] product sort","[read] provider","[read] partner"], children: [
                    { path: `${process.env.PUBLIC_URL}/product/product-list`, title: 'Product List', type: 'link',permission: "[read] product list" },
                    { path: `${process.env.PUBLIC_URL}/product/product-sort`, title: 'Product Sort', type: 'link',permission: "[read] product sort" },
                    { path: `${process.env.PUBLIC_URL}/product/provider`, title: 'Provider', type: 'link',permission: "[read] provider" },
                    { path: `${process.env.PUBLIC_URL}/product/partner`, title: 'Partner', type: 'link',permission: "[read] partner" },
                    
                ]
            },
            {
                title: 'Deposit', icon:Share, type: 'sub', active: false, permission:["[read] deposit","[read] bank statement","[read] bank account","[read] payment method"] ,children: [
                    { path: `${process.env.PUBLIC_URL}/deposit/deposit-list`, title: 'Deposit List', type: 'link', permission: "[read] deposit" },
                    { path: `${process.env.PUBLIC_URL}/deposit/bank-statement`, title: 'Bank Statement', type: 'link', permission: "[read] bank statement"},
                    { path: `${process.env.PUBLIC_URL}/deposit/bank-account`, title: 'Bank Account', type: 'link', permission: "[read] bank account"},
                    { path: `${process.env.PUBLIC_URL}/deposit/payment-method`, title: 'Payment Method', type: 'link', permission: "[read] payment method" },
                ]
            },
            {
                title: 'Transaction', icon:DollarSign, type:'sub', active: false, permission:["[read] transaction", "[read] transfer"],  children: [
                    { path: `${process.env.PUBLIC_URL}/transaction/transaction`, title: 'Transaction', type: 'link',permission: "[read] transaction"},
                    { path: `${process.env.PUBLIC_URL}/transaction/transfer`, title: 'Transfer', type: 'link',permission: "[read] transfer"},
                ]
            },
            { path: `${process.env.PUBLIC_URL}/mutation`,icon:Clipboard, title: 'Mutation', type: 'link',permission: "[read] mutation" },
            {
                title: 'Commission', icon:Star, type: 'sub', active: false,permission:["[read] commission mutation","[read] commission payment"], children: [
                    { path: `${process.env.PUBLIC_URL}/commission-mutation`,icon:Gift, title: 'Commission Mutation', type: 'link',permission: "[read] commission mutation" },
                    { path: `${process.env.PUBLIC_URL}/commission-payment`,icon:Gift, title: 'Commission Payment', type: 'link',permission: "[read] commission payment" },
                ]
            },
            {
                title: 'Point', icon:Star, type: 'sub', active: false,permission:["[read] redeem list","[read] point mutation","[read] reward list"], children: [
                    { path: `${process.env.PUBLIC_URL}/point/redeem-list`, title: 'Redeem List', type: 'link',permission: "[read] redeem list" },
                    { path: `${process.env.PUBLIC_URL}/point/point-mutation`, title: 'Point Mutation', type: 'link',permission: "[read] point mutation" },
                    { path: `${process.env.PUBLIC_URL}/point/reward-list`, title: 'Reward List', type: 'link',permission: "[read] reward list" },
                ]
            },
            { path: `${process.env.PUBLIC_URL}/category`,icon:Folder, title: 'Category', type: 'link',permission: "[read] category" },
            { path: `${process.env.PUBLIC_URL}/configure-admin`,icon:Settings, title: 'Configure Admin', type: 'link',permission: "[read] configure admin" },
            { path: `${process.env.PUBLIC_URL}/configure-maintenance`,icon:Settings, title: 'Configure Maintenance', type: 'link',permission: "[read] configure maintenance" },
            // {
            //     title: 'Bank', icon:CreditCard, type: 'sub', active: false, children: [
                    
            //     ]
            // },
        ]
    },

    
    // },
]