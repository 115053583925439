import {
    GET_LIST_PROVIDER_PRODUCT_SORT_REQUEST,
    GET_LIST_PROVIDER_PRODUCT_SORT_SUCCESS,
    GET_LIST_PROVIDER_PRODUCT_SORT_FAILED,
} from '../../../actionTypes'

const initialState = {
    data:[],
    isLoading:false,
    error:''
}

const listProviderProductSortReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PROVIDER_PRODUCT_SORT_REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case GET_LIST_PROVIDER_PRODUCT_SORT_SUCCESS:
            return {
                ...state,
                isLoading:false,
                data:action.payload
            }
        case GET_LIST_PROVIDER_PRODUCT_SORT_FAILED:
            return {
                ...state,
                isLoading:false,
                error: action.payload
            }
    
        default:
            return state;
    }
}

export default listProviderProductSortReducer