import React from 'react'
import { Button, Badge, Modal, ModalBody } from 'reactstrap'
// import {  } from "feather-icons"


export const tableData = [
    {
        id:"1",
        name: "Product Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"2",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"3",
        name: "Subcategory Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"4",
        name: "Sales  Menu",
        status: <i className="fa fa-circle font-danger f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"5",
        name: "Vendor Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"6",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    }
]

export const dataTableTransaction = [
    { 
      id: 1, 
      tcode: 'TRX0245o63', 
      uid: '1982',
      channel:"Whatsapp",
      product:"Telkomsel 500",
      dnumber:"0818374937399",
      sn:"12879871627276",
      partner:"VeriBill",
      date:"01 jan 2020 08:15:22 WIB",
      status:"success",
      action:"" 
    },
    { 
      id: 2, 
      tcode: 'TRX0245o7', 
      uid: '19234',
      channel:"Whatsapp",
      product:"Telkomsel 10000",
      dnumber:"0818372376399",
      sn:"12879237627276",
      partner:"VeriBill",
      date:"01 jan 2020 08:15:22 WIB",
      status:"success",
      action:"" 
    },
    { 
      id: 3, 
      tcode: 'TRX0245b45', 
      uid: '19832',
      channel:"Mobile Apps",
      product:"Telkomsel 30000",
      dnumber:"0818373827399",
      sn:"28399871627276",
      partner:"SiapBayar",
      date:"01 jan 2020 10:12:32 WIB",
      status:"success",
      action:"" 
    },
    // { 
    //   id: 4, 
    //   tcode: 'TRX0245r74', 
    //   uid: '23482',
    //   channel:"Whatsapp",
    //   product:"Three 50000",
    //   dnumber:"081823437399",
    //   sn:"1287983232476",
    //   partner:"VeriBill",
    //   date:"01 jan 2020 07:15:22 WIB",
    //   status:<p style={{backgroundColor:'#51bb25', color:"white"}}>Success</p>,
    //   action:<Button onClick={() => Displayalert()}>View </Button >
    // }
  ];

export const dataTableSaldoPartner = [
      { 
        id: 1, 
        server: 'VeriBill', 
        saldo: 'Rp. 5,000,000',
        status:<Badge color="success" pill>{"Success"}</Badge>,
      },
      { 
        id: 2, 
        server: 'SiapBayar', 
        saldo: 'Rp. 3,000,000',
        status:<Badge color="danger" pill>{"Failed"}</Badge>,
      },
];

export const dataTablePending = [
      {
        id: 1,
        code: "TRX0245c53",
        server: "VeriBill",
        number: "09821783232",
        time: "10 Minute",
        action: <Button color="primary" className="btn-xs ml-2" >
        <i class="fa fa-copy"></i>
        </Button>
      },
      {
        id: 2,
        code: "TRX0245b41",
        server: "SiapBayar",
        number: "08197343232",
        time: "5 Minute",
        action: <Button color="primary" className="btn-xs ml-2" >
        <i class="fa fa-copy"></i>
        </Button>
      },
      {
        id: 3,
        code: "TRX0345r23",
        server: "VeriBill",
        number: "08961783232",
        time: "7 Minute",
        action: <Button color="primary" className="btn-xs ml-2" >
        <i class="fa fa-copy"></i>
        </Button>
      },
      {
        id: 4,
        code: "TRX0234o33",
        server: "SiapBayar",
        number: "09821783232",
        time: "10 Minute",
        action: <Button color="primary" className="btn-xs ml-2" >
        <i class="fa fa-copy"></i>
        </Button>
      },
];

export const  dataCommissionMutation = [
  {
    id: 1,
    tcode: "TRX0245o63",
    debit: "Rp. 0",
    credit: "Rp. 10",
    prevcommision: "Rp. 30",
    comafter: "Rp. 40",
    information: "Bonus Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
  {
    id: 2,
    tcode: "TRX2243e63",
    debit: "Rp. 10",
    credit: "Rp. 20",
    prevcommision: "Rp. 0",
    comafter: "Rp. 20",
    information: "Refund Komisi",
    date: "01 jan 2020 18:15:22 WIB",
  },
  {
    id: 3,
    tcode: "TRX0445z23",
    debit: "Rp. 300",
    credit: "Rp. 20",
    prevcommision: "Rp. 10",
    comafter: "Rp. 30",
    information: "Bonus Komisi",
    date: "01 jan 2020 10:15:26 WIB",
  },
  {
    id: 4,
    tcode: "TRX0345c73",
    debit: "Rp. 200",
    credit: "Rp. 10",
    prevcommision: "Rp. 30",
    comafter: "Rp. 40",
    information: "Pencairan Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
];

export const  dataPointMutation = [
  {
    id: 1,
    tcode: "TRX0245o63",
    debit: "Rp. 0",
    credit: "Rp. 10",
    prevpoint: " 30",
    pointafter: " 40",
    information: "Bonus Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
  {
    id: 2,
    tcode: "TRX2243e63",
    debit: "Rp. 10",
    credit: "Rp. 20",
    prevpoint: " 0",
    pointafter: " 20",
    information: "Refund Komisi",
    date: "01 jan 2020 18:15:22 WIB",
  },
  {
    id: 3,
    tcode: "TRX0445z23",
    debit: "Rp. 300",
    credit: "Rp. 20",
    prevpoint: " 10",
    pointafter: " 30",
    information: "Bonus Komisi",
    date: "01 jan 2020 10:15:26 WIB",
  },
  {
    id: 4,
    tcode: "TRX0345c73",
    debit: "Rp. 200",
    credit: "Rp. 10",
    prevpoint: " 30",
    pointafter: " 40",
    information: "Pencairan Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
];

export const  dataTableLogOTP = [
  {
    id: 1,
    uid: "VP0245063",
    date: "01 jan 2020 08:15:22 WIB",
    otptoken: "xxxxxxxx",
    platform: "IOS",
    method: "SMS",
  },
  {
    id: 2,
    uid: "VP0493063",
    date: "01 jan 2020 18:15:22 WIB",
    otptoken: "xxxxxxxx",
    platform: "ANDROID",
    method: "SMS",
  },
  {
    id: 3,
    uid: "VP3455063",
    date: "11 jan 2020 12:15:22 WIB",
    otptoken: "xxxxxxxx",
    platform: "IOS",
    method: "SMS",
  },
  {
    id: 4,
    uid: "VP0245063",
    date: "21 jan 2020 08:15:22 WIB",
    otptoken: "xxxxxxxx",
    platform: "ANDROID",
    method: "SMS",
  },
];

export const  dataTableLogCredential = [
  {
    id: 1,
    uid: "VP0245063",
    udate: "01 jan 2020 08:15:22 WIB",
    ubyusername: "xxxxxxxx",
    action: "Change Phone",
  },
  {
    id: 2,
    uid: "VP0385063",
    udate: "01 jan 2020 18:15:22 WIB",
    ubyusername: "jhon",
    action: "Change Phone",
  },
  {
    id: 3,
    uid: "VP0345063",
    udate: "01 jan 2020 10:15:22 WIB",
    ubyusername: "cena",
    action: "Change Phone",
  },
  {
    id: 4,
    uid: "VP0455063",
    udate: "01 jan 2020 15:25:17 WIB",
    ubyusername: "ryuk",
    action: "Change Phone",
  },
];

export const  dataDownline = [
  {
    id: 1,
    uid: "VP0035063",
    sponsor: "VP0245063",
    lvl: "2",
    full_name: "Samsul Baharudin",
    phone_number: "083947584653",
    email:"samsul@gmail.com",
    saldo:"Rp. 105.503",
    commission:"Rp. 100.000",
    point:"120",
    control:"0",
    status:"Active",
    type:"Full Service",
    register_date:"01 jan 2020 08:15:22 WIB",
  },
  {
    id: 2,
    uid: "VP0023989",
    sponsor: "VP0384733",
    lvl: "1",
    full_name: "Jhon Cena",
    phone_number: "02391748347",
    email:"cena@gmail.com",
    saldo:"Rp. 200.503",
    commission:"Rp. 50.000",
    point:"70",
    control:"0",
    status:"Active",
    type:"Basic",
    register_date:"03 jan 2020 18:15:22 WIB"
  },
  {
    id: 3,
    uid: "VP0234958",
    sponsor: "VP0047563",
    lvl: "3",
    full_name: "Nemo",
    phone_number: "08463846239",
    email:"nemo@gmail.com",
    saldo:"Rp. 505.503",
    commission:"Rp. 0",
    point:"0",
    control:"0",
    status:"Suspend",
    type:"Basic",
    register_date:"11 jan 2020 10:15:25 WIB"
  },
  {
    id: 4,
    uid: "VP0035063",
    sponsor: "VP0245063",
    lvl: "1",
    full_name: "Erik Jeko",
    phone_number: "028947584653",
    email:"jeko@gmail.com",
    saldo:"Rp. 105.503",
    commission:"Rp. 100.000",
    point:"120",
    control:"0",
    status:"Active",
    type:"Full Service",
    register_date:"06 jan 2020 19:39:42 WIB"
  },
];

// Data Mutation
export const  dataMutation = [
  {
    id: 1,
    date: "01 jan 2020 08:15:22 WIB",
    transaction_code: "xxxxxxxxx",
    debit: "Rp. 10,000",
    credit: "Rp. 0",
    beginning_balance:"Rp. 100,000",
    ending_balance:"Rp. 90,000",
    information:"Transaction",
  },
  {
    id: 2,
    date: "21 jan 2020 10:15:22 WIB",
    transaction_code: "xxxxxxxxx",
    debit: "Rp. 50,000",
    credit: "Rp. 10,000",
    beginning_balance:"Rp. 500,000",
    ending_balance:"Rp. 100,000",
    information:"Transaction"
  },
  {
    id: 3,
    date: "11 jan 2020 18:15:22 WIB",
    transaction_code: "xxxxxxxxx",
    debit: "Rp. 20,000",
    credit: "Rp. 10,000",
    beginning_balance:"Rp. 200,000",
    ending_balance:"Rp. 190,000",
    information:"Transaction"
  },
  {
    id: 4,
    date: "14 jan 2020 20:15:22 WIB",
    transaction_code: "xxxxxxxxx",
    debit: "Rp. 50,000",
    credit: "Rp. 0",
    beginning_balance:"Rp. 400,000",
    ending_balance:"Rp. 290,000",
    information:"Transaction"
  },
];

// Transaction > Commission > Commission Mutation Data
export const  dataMutationOnCommission = [
  {
    id: 1,
    tcode: "TRX0245o63",
    uid:"VP01827364",
    debit: "Rp. 0",
    credit: "Rp. 10",
    prevcommision: "Rp. 30",
    comafter: "Rp. 40",
    information: "Bonus Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
  {
    id: 2,
    tcode: "TRX2243e63",
    uid:"VP0237364",
    debit: "Rp. 10",
    credit: "Rp. 20",
    prevcommision: "Rp. 0",
    comafter: "Rp. 20",
    information: "Refund Komisi",
    date: "01 jan 2020 18:15:22 WIB",
  },
  {
    id: 3,
    tcode: "TRX0445z23",
    uid:"VP04397364",
    debit: "Rp. 300",
    credit: "Rp. 20",
    prevcommision: "Rp. 10",
    comafter: "Rp. 30",
    information: "Bonus Komisi",
    date: "01 jan 2020 10:15:26 WIB",
  },
  {
    id: 4,
    tcode: "TRX0345c73",
    uid:"VP0402364",
    debit: "Rp. 200",
    credit: "Rp. 10",
    prevcommision: "Rp. 30",
    comafter: "Rp. 40",
    information: "Pencairan Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
];

// Transaction > Point > Point Mutation Data
export const  dataMutationOnPoint = [
  {
    id: 1,
    tcode: "TRX0245o63",
    uid:"VP01827364",
    debit: "Rp. 0",
    credit: "Rp. 10",
    prevpoint: " 30",
    pointafter: "40",
    information: "Bonus Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
  {
    id: 2,
    tcode: "TRX2243e63",
    uid:"VP0237364",
    debit: "Rp. 10",
    credit: "Rp. 20",
    prevpoint: "0",
    pointafter: "20",
    information: "Refund Komisi",
    date: "01 jan 2020 18:15:22 WIB",
  },
  {
    id: 3,
    tcode: "TRX0445z23",
    uid:"VP04397364",
    debit: "Rp. 300",
    credit: "Rp. 20",
    prevpoint: "10",
    pointafter: "30",
    information: "Bonus Komisi",
    date: "01 jan 2020 10:15:26 WIB",
  },
  {
    id: 4,
    tcode: "TRX0345c73",
    uid:"VP0402364",
    debit: "Rp. 200",
    credit: "Rp. 10",
    prevpoint: "30",
    pointafter: "40",
    information: "Pencairan Komisi",
    date: "01 jan 2020 08:15:22 WIB",
  },
];