import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux"
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { toast } from 'react-toastify';
import "../../../assets/scss/custom/deposit-custom.scss";
// import {dataTableTransaction} from '../../data/dummyTableData'

import axios from "axios";

const CommissionPayment = () => {
  
  const columnsCommissionPayment = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      center:true,
      width:"170px",
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width:"170px",
    },
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      center: true,
      width:"170px",
    },
    {
      name: "Payment Nominal",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        <span>{`Rp.${row.payment_nominal}`}</span>
      )
    },
    {
      name: "Tax Nominal",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        <span>{`Rp.${row.tax_nominal}`}</span>
      )
    },
    {
      name: "Total Nominal Tax For 1 Year",
      sortable: true,
      center: true,
      width:"250px",
      cell:(row)=>(
        <span>{`Rp.${row.total_tax_nominal_for_one_year}`}</span>
      )
    },
    {
      name: "Commission Tax",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        <span>{`${row.commission_tax}%`}</span>
      )
    },
    {
      name: "Tax Total",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        <span>{`Rp.${row.tax_total}`}</span>
      )
    },
    {
      name: "Admin Fee",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        <span>{`Rp.${row.admin_fee}`}</span>
      )
    },
    {
      name: "Commission Payment",
      sortable: true,
      center: true,
      width:"200px",
      cell:(row)=>(
        <span>{`Rp.${row.commission_payment}`}</span>
      )
    },
    {
      name: "Destination",
      selector: "destination",
      sortable: true,
      center: true,
      width:"170px",
    },
    {
      name: "Account Number",
      selector: "account_number",
      sortable: true,
      center: true,
      width:"170px",
    },
    {
      name: "Owner Name",
      selector: "owner_name",
      sortable: true,
      center: true,
      width:"200px",
    },
    {
      name: "Date",
      sortable: true,
      center: true,
      width: "200px",
      cell:(row)=>(
        <span>{`${row.date} WIB`}</span>
      )
    },
    {
      name: "Approval",
      selector: "approval",
      sortable: true,
      center: true,
      width:"200px",
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      width:"150px",
      cell:(row)=>(
        row.status === 'SUCCESS' ? <Badge color="success">SUCCESS</Badge> : (
          row.status ==='PENDING' ? <Badge color="warning">PENDING</Badge>:<Badge color="danger">FAILED</Badge>
        )
      )
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      width:"170px",
      cell:(row)=>(
        userPermission.includes("[update] commission payment") ? (
          <Button
          color="primary"
          onClick={() => {
            setEditCommissionPaymentValue({
              id:row.id,
              transaction_code:row.transaction_code,
              user_id:row.user_id,
              channel:row.channel,
              payment_nominal:row.payment_nominal,
              tax_nominal:row.tax_nominal,
              total_tax_nominal_for_one_year:row.total_tax_nominal_for_one_year,
              commission_tax:row.commission_tax,
              tax_total:row.tax_total,
              admin_fee:row.admin_fee,
              commission_payment:row.commission_payment,
              destination:row.destination,
              account_number:row.account_number,
              owner_name:row.owner_name,
              date: row.date,
              approval:row.approval,
              last_update:row.last_update,
              status:row.status
            })
            showCommissionDetail()
          }}
          className="btn-xs"
        >
          <i class="fa fa-edit"></i>
        </Button>
        ) : null
      )
    },
  ];

  // USER PERMISSION
  const userPermission = useSelector(state => state.userReducer.data.user.permissions)


  const listStatus = [
    { value: "ALL", label: "ALL" },
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "PENDING", label: "PENDING" },
    { value: "FAILED", label: "FAILED" },
  ];

  const listStatusEdit = [
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "PENDING", label: "PENDING" },
    { value: "FAILED", label: "FAILED" },
  ];

  const [sweetAlert, setSweetAlert] = useState(false);
  const [commissionDetail, setCommissionDetail] = useState(false);

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  const showCommissionDetail = () => setCommissionDetail(!commissionDetail);

  

  // ACTION HANDLER
  const handleSortCommissionPayment = (column, sortDirection) => {
    setSortColumnCommissionPayment(column.selector);
    setSortDirectionCommissionPayment(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchCommissionPayment = (event) => {setKeywordCommissionPayment(event.target.value)};

  // STATE DATA TABLE
  const [commissionPaymentTable, setCommissionPaymentTable] = useState({});
  const [pageCommissionPayment, setPageCommissionPayment] = useState(1);
  const [sortColumnCommissionPayment,setSortColumnCommissionPayment] = useState("");
  const [sortDirectionCommissionPayment,setSortDirectionCommissionPayment] = useState("");
  const [countPageCommissionPayment,setCountPageCommissionPayment] = useState(5);
  const [keywordCommissionPayment, setKeywordCommissionPayment] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState('')

  // STATE FOR EDIT DATA ON DATA-TABLE
  const [editCommissionPaymentValue, setEditCommissionPaymentValue]=useState({
    id:"",
    transaction_code:"",
    user_id:"",
    channel:"",
    payment_nominal:"",
    tax_nominal:"",
    total_tax_nominal_for_one_year:"",
    commission_tax:"",
    tax_total:"",
    admin_fee:"",
    commission_payment:"",
    destination:"",
    account_number:"",
    owner_name:"",
    date: "",
    approval:"",
    last_update:"",
    status:""
  })
  console.log('editCommissionPaymentValue: ', editCommissionPaymentValue)
  const [editCommissionPaymentResponse, setEditCommissionPaymentResponse]=useState(false)
  

  // FETCHING DATA TABLE
  const getDataTableCommissionPayment = () => {
    const URI = `commission/payment/data`;

    return axios
      .get(URI, {
        params: {
          page: pageCommissionPayment,
          per_page: countPageCommissionPayment,
          sort_column: sortColumnCommissionPayment,
          sort_direction: sortDirectionCommissionPayment,
          keyword: keywordCommissionPayment,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          status: status,
        },
      })
      .then((response) => {
        setCommissionPaymentTable(response.data);
        console.log("getCommissionPaymentTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // EDIT DATA ON DATA-TABLE
  const editCommissionPayment = () => {
    const URI = `commission/payment/${editCommissionPaymentValue.id}/update`;

    axios
      .post(URI, {
        status:editCommissionPaymentValue.status
      })
      .then((response) => {
        toast.success("Success Edit Commission Payment !", {
          position: toast.POSITION.TOP_RIGHT
          });
        setCommissionDetail(!commissionDetail)
        setEditCommissionPaymentResponse(!editCommissionPaymentResponse)
        console.log("editCommissionPayment response: ", response.data);
      })
      .catch((error) => {
        let $errorMessage = "";
      
          for (let i in error.response.data.errors) {
              for (let j in error.response.data.errors[i]) {
                  $errorMessage += `<li>${error.response.data.errors[i][j]}</li>`;
              }
          }
      
          console.log("eror message: ",$errorMessage);
      
        SweetAlert.fire({
          title:'Error!',
          html:`<div style="text-align:left;">${$errorMessage}</div>`,
          icon:'error'
          
        })
        console.log(error);
      });
  };


  useEffect(() => {
    getDataTableCommissionPayment()
  }, [
    pageCommissionPayment,
    sortColumnCommissionPayment,
    sortDirectionCommissionPayment,
    countPageCommissionPayment,
    editCommissionPaymentResponse
  ]);


  return (
    <Fragment>
      <Breadcrumb parent="Commission" title="Commission Payment" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                //   className="needs-validation"
                //   noValidate=""
                //   onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=>onSearchCommissionPayment(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="12" xs="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Status"}</Label>
                        <Select
                        options={listStatus}
                        onChange={(event)=>{
                          setStatus(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTableCommissionPayment()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columnsCommissionPayment}
                  data={commissionPaymentTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={commissionPaymentTable.total}
                  onChangePage={(pageCommissionPayment) =>
                    setPageCommissionPayment(pageCommissionPayment)
                  }
                  onChangeRowsPerPage={(countPageCommissionPayment) =>
                    setCountPageCommissionPayment(
                      countPageCommissionPayment
                    )
                  }
                  onSort={handleSortCommissionPayment}
                  paginationPerPage={countPageCommissionPayment}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />

                {/* Modal Detail */}
                <Modal
                  isOpen={commissionDetail}
                  toggle={() => showCommissionDetail()}
                  centered
                >
                  <ModalHeader toggle={() => showCommissionDetail()}>
                    {"Commission Payment Detail"}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Transaction Code</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.transaction_code}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>User ID</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.user_id}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Channel</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.channel}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Payment Nominal</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editCommissionPaymentValue.payment_nominal).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Tax Nominal</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editCommissionPaymentValue.tax_nominal).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Total Nominal Tax For 1 Year</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editCommissionPaymentValue.total_tax_nominal_for_one_year).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Commission Tax</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`${editCommissionPaymentValue.commission_tax} %`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Tax Total</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editCommissionPaymentValue.tax_total).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Admin Fee</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editCommissionPaymentValue.admin_fee).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Payment Commission</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`Rp. ${parseFloat(editCommissionPaymentValue.commission_payment).toLocaleString('en')}`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Destination</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.destination}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Account Number</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.account_number}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Owner Name</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.owner_name}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Date</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`${editCommissionPaymentValue.date} WIB`}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Approval</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{editCommissionPaymentValue.approval}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" sm="6" xs="6">
                            <p>Update</p>
                          </Col>
                          <Col xl="6" sm="6" xs="6">
                            <p>:&nbsp;&nbsp;{`${editCommissionPaymentValue.last_update} WIB`}</p>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Row className="justify-content-around align-items-center mb-3">
                            <Col xl="6">
                              <Label>{"Status"}</Label>
                            </Col>
                            <Col xl="6">
                              <Select
                              options={listStatusEdit}
                              defaultValue={listStatusEdit.filter(filter => filter.value === editCommissionPaymentValue.status)}
                              onChange={(event)=>{
                                setEditCommissionPaymentValue({
                                  ...editCommissionPaymentValue,
                                  status:event.value
                                })
                              }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <Row className="justify-content-center">
                          <Col xl="3" sm="6" xs="6">
                            <Button
                              color="secondary"
                              type="reset"
                              onClick={() => showCommissionDetail()}
                            >
                              {"Cancel"}
                            </Button>
                          </Col>
                          <Col xl="3" sm="6" xs="6">
                            <Button color="primary" className="mr-1" onClick={()=>editCommissionPayment()}>
                              {"Save"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CommissionPayment;
