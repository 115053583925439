import {
  DASHBOARD_TRANSACTION_REQUEST,
  DASHBOARD_TRANSACTION_SUCCESS,
  DASHBOARD_TRANSACTION_FAILED,
} from "../../actionTypes";

const initialState = {
  dataTransaction: {},
  error: null,
  isLoading: false,
};

const dashboardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DASHBOARD_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataTransaction: action.payload,
      };
    case DASHBOARD_TRANSACTION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default dashboardDataReducer;
