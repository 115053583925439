import { 
    ACCOUNT_SUMMARY_REQUEST,
    ACCOUNT_SUMMARY_SUCCESS,
    ACCOUNT_SUMMARY_FAILED
 } from '../../../actionTypes'

 const initialState = {
     data:[],
     isLoading: false
 }

 const accountSummaryReducer = (state = initialState, action) =>{
     switch (action.type) {
          // edit user
        case ACCOUNT_SUMMARY_REQUEST:
            return {
              ...state,
              isLoading: true,
            };
          case ACCOUNT_SUMMARY_SUCCESS:
            return {
              ...state,
              isLoading: false,
              data: action.payload,
            };
          case ACCOUNT_SUMMARY_FAILED:
            return {
              ...state,
              isLoading: false,
              error: action.error,
            };
    
          default:
            return state;
     }
 };

 export default accountSummaryReducer;