import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import Select from 'react-select'
import "../../../assets/scss/custom/deposit-custom.scss";
// import {dataTableTransaction} from '../../data/dummyTableData'

import axios from "axios";

const PointMutation = () => {

  const columnsPointMutation = [
    {
      name: "Transaction Code",
      selector: "transaction_code",
      sortable: true,
      center:true,
      width: "200px",
    },
    {
      name: "User ID",
      selector: "user_id",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Debit",
      selector: "debit",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Credit",
      selector: "credit",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Previous Point",
      selector: "previous_point",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Point Afterward",
      selector: "point_afterward",
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Information",
      selector: "information",
      sortable: true,
      center: true,
      width: "400px",
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
      width: "200px",
    },
  ];

  const [sweetAlert, setSweetAlert] = useState(false);
  const [pointMutationDetail, setPointMutationDetail] = useState(false);

  const listType = [
    {value:"ALL", label:"ALL"},
    {value:"DEBIT", label:"DEBIT"},
    {value:"CREDIT", label:"CREDIT"},
  ]

  const DisplayAlertDelete = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({
      title: "Delete Item Success",
      text: "",
      icon: "success",
    });
  };

  const Displayalert = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert.fire({ title: "Download Success", text: "", icon: "success" });
  };

  
  
  // ACTION HANDLER
  const showPointMutationDetail = () => setPointMutationDetail(!pointMutationDetail);
  
  const handleSortPointMutation = (column, sortDirection) => {
    setSortColumnPointMutation(column.selector);
    setSortDirectionPointMutation(sortDirection);
  };
  const handleStartDate = (date) => {setStartDate(date); };

  const handleEndDate = (date) => { setEndDate(date);};
  const onSearchPointMutation = (event) => {setKeywordPointMutation(event.target.value)};

  // STATE DATA TABLE
  const [pointMutationTable, setPointMutationTable] = useState({});
  const [pagePointMutation, setPagePointMutation] = useState(1);
  const [sortColumnPointMutation,setSortColumnPointMutation] = useState("");
  const [sortDirectionPointMutation,setSortDirectionPointMutation] = useState("");
  const [countPagePointMutation,setCountPagePointMutation] = useState(5);
  const [keywordPointMutation, setKeywordPointMutation] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState('')
  

  // FETCHING DATA TABLE
  const getDataTablePointMutation = () => {
    const URI = `point/mutation/data`;

    return axios
      .get(URI, {
        params: {
          page: pagePointMutation,
          per_page: countPagePointMutation,
          sort_column: sortColumnPointMutation,
          sort_direction: sortDirectionPointMutation,
          keyword: keywordPointMutation,
          starting_date: startDate && startDate.toLocaleDateString("en-US"),
          ending_date: endDate && endDate.toLocaleDateString("en-US"),
          type: type,
        },
      })
      .then((response) => {
        setPointMutationTable(response.data);
        console.log("getPointMutationTable response: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
   getDataTablePointMutation()
  }, [
    pagePointMutation,
    sortColumnPointMutation,
    sortDirectionPointMutation,
    countPagePointMutation
  ]);

  

  return (
    <Fragment>
      <Breadcrumb parent="Point" title="Point Mutation" />
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                //   className="needs-validation"
                //   noValidate=""
                >
                  <div className="form-row">
                    <Col md="12 mb-3">
                      <Input
                        className="form-control"
                        name="keyword"
                        type="text"
                        placeholder="Keyword"
                        onChange={(event)=> onSearchPointMutation(event)}
                      />
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Starting Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="6" xs="6">
                      <Label htmlFor="validationCustom03">
                        {"Ending Date"}
                      </Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        selected={endDate}
                        onChange={handleEndDate}
                      />
                    </Col>
                    <Col md="3 mb-3" sm="12" xs="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">{"Type"}</Label>
                        <Select
                        options={listType}
                        onChange={(event)=>{
                          setType(event.value)
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Row>
                    <Col md="2" sm="6" xs="6">
                      <Button color="secondary" onClick={()=>getDataTablePointMutation()}>{"Search"}</Button>
                    </Col>
                    <Col md="2" sm="6" xs="6">
                      <Button
                        color="primary"
                        className="sweet-8"
                        name="alertSuccess"
                        onClick={Displayalert}
                      >
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <DataTable
                  columns={columnsPointMutation}
                  data={pointMutationTable.data}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={pointMutationTable.total}
                  onChangePage={(pagePointMutation) =>
                    setPagePointMutation(pagePointMutation)
                  }
                  onChangeRowsPerPage={(countPagePointMutation) =>
                    setCountPagePointMutation(
                      countPagePointMutation
                    )
                  }
                  onSort={handleSortPointMutation}
                  paginationPerPage={countPagePointMutation}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
                {/* Modal Point Mutation Detail */}
                <Modal
                  isOpen={pointMutationDetail}
                  toggle={() => showPointMutationDetail()}
                  centered
                >
                  <ModalHeader toggle={() => showPointMutationDetail()}>
                    {"Redeem Point Detail"}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                          <Col xl="6">
                            <p>Transaction Code</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;TRX02345O27</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>User ID</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;VP033246</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Channel</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;Deposit Manual</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Product Code</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;SLD5</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Type</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;Saldo</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Product Name</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;Telkomsel 5000</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Point</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;1000</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Tax</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;Rp. 1,250</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Total</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;Rp. 3,750</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <p>Date</p>
                          </Col>
                          <Col xl="6">
                            <p>:&nbsp;&nbsp;1 jan 2021 08:21:12 WIB</p>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Row>
                            <Col xl="6">
                              <p>Status</p>
                            </Col>
                            <Col xl="6">
                              <Input type="select">
                                <option>Success</option>
                                <option>Failed</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row className="mb-3">
                            <Col xl="2">
                              <p>Note</p>
                            </Col>
                            <Col xl="10">
                              <textarea
                                className={"ng-untouched ng-pristine ng-valid"}
                                style={{ height: 100, width: 380 }}
                                id="notes"
                                placeholder="Write Your Notes Here"
                                defaultValue={"Bayar Beras"}
                                // onChange={onTaskChanged}
                              ></textarea>
                            </Col>
                          </Row>
                        </FormGroup>
                        <Row className="justify-content-center">
                          <Col xl="3" md="3">
                            <Button
                              color="secondary"
                              type="reset"
                              className="mr-1"
                              onClick={() => showPointMutationDetail()}
                            >
                              {"Cancel"}
                            </Button>
                          </Col>
                          <Col xl="3" md="3">
                            <Button color="primary" >
                              {"Confirm"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PointMutation;
